import { notification } from "antd";
import { useNavigate } from "react-router-dom";

import { useGeneratePath } from "@root/hooks/useGeneratePath";
import { useReturnLocationParam } from "@root/hooks/useReturnLocation";
import { RouteConfig } from "@root/routes/config";

import { ProductFormValues } from "../types/productFormValues";

export const useProductCreationFormActions = <T extends ProductFormValues>({
  mutation,
  returnRoute,
}: {
  mutation: (values: T) => Promise<unknown>;
  returnRoute: RouteConfig<{}>;
}) => {
  const navigate = useNavigate();
  const generatePath = useGeneratePath();

  const returnLocation = useReturnLocationParam();

  const navigateBack = () => {
    if (returnLocation) {
      navigate(returnLocation);
    } else {
      navigate(generatePath(returnRoute, {}));
    }
  };

  const handleSubmit = async (values: T) => {
    await mutation(values);
    notification.success({
      message: "Product created",
    });
    navigateBack();
  };

  const handleCancel = () => navigateBack();

  return {
    handleSubmit,
    handleCancel,
  };
};
