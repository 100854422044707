import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Row,
  Select,
  Skeleton,
  Space,
} from "antd";
import React from "react";

import { getCurrencyCodeByCountryCode } from "@retail/currency";

import { PercentInput } from "@root/components/PercentInput";
import { PriceInput } from "@root/components/PriceInput";
import {
  selectQaSelectors,
  validationRuleIntegerNumber,
} from "@root/utils/antd";
import { options as countryOptions } from "@root/utils/fields/country";
import { options as publishedScopeOptions } from "@root/utils/fields/publishedScope";
import { options as vatKeyOptions } from "@root/utils/fields/vatKey";
import { filterOptionsByLabelAndValue } from "@root/utils/select";

import { ProductFormValues, formField } from "../../types/productFormValues";
import { ItemGroup } from "./ItemGroup";

const { Item } = Form;

const REQUIRED_RULE = {
  required: true,
};

export interface ProductFormProps<T extends ProductFormValues> {
  isLoading?: boolean;
  isSubmitting: boolean;
  mode: "EDIT" | "CREATE";
  formInstance: FormInstance<T>;
  productCurrencyCode?: string;
  slots?: {
    beforePrices?: React.ReactNode;
    afterCheckboxes?: React.ReactNode;
    afterCountry?: React.ReactNode;
  };
  onCountryChange?: () => void;
  onSubmit: (values: T) => Promise<unknown>;
  onCancel: () => void;
}

export function ProductForm<T extends ProductFormValues>({
  isLoading,
  isSubmitting,
  mode,
  formInstance,
  productCurrencyCode,
  slots,
  onCountryChange,
  onSubmit,
  onCancel,
}: ProductFormProps<T>) {
  const formValues = {
    country: Form.useWatch(formField("country"), formInstance),
  };
  const currencyCode =
    productCurrencyCode ?? getCurrencyCodeByCountryCode(formValues.country);

  if (isLoading) {
    return (
      <Card type="inner" title="Product Details">
        <Row gutter={64}>
          <Col xs={12}>
            <Skeleton active paragraph={{ rows: 24 }} />
          </Col>
          <Col xs={12}>
            <Skeleton active paragraph={{ rows: 6 }} />
          </Col>
        </Row>
      </Card>
    );
  }

  return (
    <Card type="inner" title="Product Details">
      <Form form={formInstance} onFinish={onSubmit} layout="vertical">
        <Row gutter={64}>
          <Col xs={24} md={12}>
            <Item
              name={formField("refId")}
              label="Ref ID"
              rules={[REQUIRED_RULE]}
            >
              <Input
                data-qa-selector="input_refId"
                disabled={mode === "EDIT"}
              />
            </Item>
            <Item
              name={formField("country")}
              label="Country"
              rules={[REQUIRED_RULE]}
            >
              <Select
                showSearch
                filterOption={filterOptionsByLabelAndValue}
                options={countryOptions}
                onChange={onCountryChange}
                {...selectQaSelectors("country")}
              />
            </Item>
            {slots?.afterCountry}
            <Item
              name={formField("internalReferenceName")}
              label="Internal reference name"
              rules={[REQUIRED_RULE]}
            >
              <Input data-qa-selector="input_internalReferenceName" />
            </Item>
            <Item
              name={formField("invoiceName")}
              label="Invoice name"
              rules={[REQUIRED_RULE]}
            >
              <Input data-qa-selector="input_invoiceName" />
            </Item>
            <Item
              name={formField("invoiceDescription")}
              label="Invoice description"
              rules={[REQUIRED_RULE]}
            >
              <Input data-qa-selector="input_invoiceDescription" />
            </Item>
            <Item
              name={formField("publishedScope")}
              label="Published scope"
              rules={[REQUIRED_RULE]}
            >
              <Select
                showSearch
                options={publishedScopeOptions}
                {...selectQaSelectors("publishedScope")}
              />
            </Item>
            <Item
              name={formField("partnerName")}
              label="Partner name"
              rules={[REQUIRED_RULE]}
            >
              <Input data-qa-selector="input_partnerName" />
            </Item>
            <Item
              name={formField("quantity")}
              label="Quantity"
              rules={[
                REQUIRED_RULE,
                { type: "number", min: 0, ...validationRuleIntegerNumber },
              ]}
            >
              <InputNumber
                style={{ width: "100%" }}
                data-qa-selector="input_quantity"
              />
            </Item>
            {slots?.beforePrices}
            <ItemGroup title="Price">
              <Item
                name={formField("price", "grossPriceMinorUnits")}
                label="Gross price"
                rules={[REQUIRED_RULE]}
                style={{ flex: 3 }}
              >
                <PriceInput
                  currencyCode={currencyCode}
                  qaSelector="price_grossPriceMinorUnits"
                />
              </Item>
              <Item
                name={formField("price", "vatKey")}
                label="VAT Key"
                rules={[REQUIRED_RULE]}
                style={{ flex: 2 }}
              >
                <Select
                  options={vatKeyOptions}
                  {...selectQaSelectors("price_vatKey")}
                />
              </Item>
              <Item
                name={formField("price", "vatPercentage")}
                label="VAT percentage"
                rules={[REQUIRED_RULE]}
                style={{ flex: 3 }}
              >
                <PercentInput qaSelector="input_price_vatPercentage" />
              </Item>
            </ItemGroup>
            <ItemGroup title="P & L price">
              <Item
                name={formField("pnlCostPrice", "grossPriceMinorUnits")}
                label="Gross price"
                rules={[REQUIRED_RULE]}
                style={{ flex: 3 }}
              >
                <PriceInput
                  currencyCode={currencyCode}
                  qaSelector="pnlCostPrice_grossPriceMinorUnits"
                />
              </Item>
              <Item
                name={formField("pnlCostPrice", "vatKey")}
                label="VAT Key"
                rules={[REQUIRED_RULE]}
                style={{ flex: 2 }}
              >
                <Select
                  options={vatKeyOptions}
                  {...selectQaSelectors("pnlCostPrice_vatKey")}
                />
              </Item>
              <Item
                name={formField("pnlCostPrice", "vatPercentage")}
                label="VAT percentage"
                rules={[REQUIRED_RULE]}
                style={{ flex: 3 }}
              >
                <PercentInput qaSelector="input_pnlCostPrice_vatPercentage" />
              </Item>
            </ItemGroup>
          </Col>
          <Col xs={24} md={12} style={{ paddingTop: "24px" }}>
            <Item
              name={formField("shippingRequired")}
              valuePropName="checked"
              initialValue={false}
            >
              <Checkbox data-qa-selector="checkbox_shippingRequired">
                Requires shipping
              </Checkbox>
            </Item>
            <Item
              name={formField("active")}
              valuePropName="checked"
              initialValue={false}
            >
              <Checkbox data-qa-selector="checkbox_active">Active</Checkbox>
            </Item>
            <Item
              name={formField("defaulted")}
              valuePropName="checked"
              initialValue={false}
            >
              <Checkbox data-qa-selector="checkbox_defaulted">
                Defaulted
              </Checkbox>
            </Item>
            {slots?.afterCheckboxes}
          </Col>
        </Row>
        <Space align="center" style={{ width: "100%", justifyContent: "end" }}>
          <Button
            size="large"
            onClick={onCancel}
            data-qa-selector="button_cancel"
          >
            Cancel
          </Button>
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            data-qa-selector="button_submit"
            loading={isSubmitting}
          >
            Submit
          </Button>
        </Space>
      </Form>
    </Card>
  );
}
