import { SearchOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row, Select, SelectProps, Space } from "antd";
import React, { useEffect, useState } from "react";

import { options as countryOptions } from "@root/utils/fields/country";
import { options as registrationTypeOptions } from "@root/utils/fields/registrationType";
import { options as warrantyOptions } from "@root/utils/fields/warrantyLevel";
import { filterOptionsByLabelAndValue } from "@root/utils/select";

import { FilterValues } from "../../types/filterValues";
import { FeeType } from "./FeeType";
import { Field } from "./Field";

const activeOptions = [
  { value: "true", label: "Only active" },
  { value: "false", label: "Only inactive" },
];

interface ProductsFiltersProps {
  filterValues: FilterValues;
  reinitializeKey: string;
  withWarrantyLevel?: boolean;
  withRegistrationType?: boolean;
  withFeeType?: boolean;
  onUpdateFilterValues: (update: FilterValues) => void;
}

export const ProductsFilters = ({
  filterValues,
  reinitializeKey,
  withWarrantyLevel,
  withRegistrationType,
  withFeeType,
  onUpdateFilterValues,
}: ProductsFiltersProps) => {
  const [uiValues, setUiValues] = useState<FilterValues>(filterValues);

  useEffect(() => {
    // When reinitializeKey changes we re-initialize internal state to values from props.
    // It's important in scenarios when user goes "back" or "forward" in browser history
    setUiValues(filterValues);
  }, [reinitializeKey]);

  const handleValuesChange = (update: Partial<FilterValues>) =>
    setUiValues((values) => ({ ...values, ...update }));

  const commonInputsStyle: React.CSSProperties = { width: "186px" };
  const commonSelectProps: SelectProps = {
    allowClear: true,
    showSearch: true,
    filterOption: true,
    optionFilterProp: "label",
    style: commonInputsStyle,
  };
  return (
    <div style={{ padding: "0 12px" }}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onUpdateFilterValues(uiValues);
        }}
      >
        <Row align="bottom" justify="space-between" wrap={false}>
          <Col flex="auto">
            <Space size={["small", 8]} align="end" wrap>
              <Field label="Country">
                <Select
                  {...commonSelectProps}
                  placeholder="Any country"
                  value={uiValues.country}
                  options={countryOptions}
                  filterOption={filterOptionsByLabelAndValue}
                  data-qa-selector="select_country"
                  dropdownClassName="QA_ID_dropdown_country"
                  onChange={(country) =>
                    handleValuesChange({ country, feeType: null })
                  }
                />
              </Field>
              <Field label="Ref id">
                <Input
                  allowClear
                  placeholder="Ref id contains"
                  style={commonInputsStyle}
                  value={uiValues.refId || ""}
                  data-qa-selector="input_ref-id-contains"
                  onChange={(e) =>
                    handleValuesChange({ refId: e.target.value })
                  }
                />
              </Field>
              <Field label="Active">
                <Select
                  {...commonSelectProps}
                  placeholder="Any"
                  value={uiValues.active}
                  options={activeOptions}
                  filterOption={filterOptionsByLabelAndValue}
                  data-qa-selector="select_active"
                  dropdownClassName="QA_ID_dropdown_active"
                  onChange={(active) => handleValuesChange({ active })}
                />
              </Field>
              {withWarrantyLevel && (
                <Field label="Level">
                  <Select
                    {...commonSelectProps}
                    placeholder="Any level"
                    value={uiValues.level}
                    options={warrantyOptions}
                    data-qa-selector="select_level"
                    dropdownClassName="QA_ID_dropdown_level"
                    onChange={(level) => handleValuesChange({ level })}
                  />
                </Field>
              )}
              {withRegistrationType && (
                <Field label="Registration type">
                  <Select
                    {...commonSelectProps}
                    placeholder="Any type"
                    value={uiValues.registrationType}
                    options={registrationTypeOptions}
                    dropdownMatchSelectWidth={false}
                    data-qa-selector="select_registration-type"
                    dropdownClassName="QA_ID_dropdown_registration-type"
                    onChange={(registrationType) =>
                      handleValuesChange({ registrationType })
                    }
                  />
                </Field>
              )}
              {withFeeType && (
                <Field label="Fee type">
                  <FeeType
                    country={uiValues.country}
                    selectProps={commonSelectProps}
                    value={uiValues.feeType}
                    onChange={(feeType) => handleValuesChange({ feeType })}
                  />
                </Field>
              )}
            </Space>
          </Col>
          <Col flex="none">
            <Button
              icon={<SearchOutlined />}
              type="primary"
              htmlType="submit"
              data-qa-selector="button_search-submit"
            >
              Search
            </Button>
          </Col>
        </Row>
      </form>
    </div>
  );
};
