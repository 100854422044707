import { Typography } from "antd";
import React from "react";

import cn from "./styles.less";

interface ItemGroupProps {
  title: React.ReactNode;
  children: React.ReactNode;
}

export const ItemGroup = ({ title, children }: ItemGroupProps) => (
  <>
    <Typography.Title level={4} className={cn.requiredMark}>
      {title}
    </Typography.Title>
    <div style={{ display: "flex", gap: "8px" }}>{children}</div>
  </>
);
