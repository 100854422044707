import { Checkbox, Form, InputNumber, Select } from "antd";
import React from "react";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";

import { useHasPermission } from "@root/acl";
import { getApiClient } from "@root/apiClient";
import { PercentInput } from "@root/components/PercentInput";
import { routesConfig } from "@root/routes/config";
import { selectQaSelectors } from "@root/utils/antd";
import { options as vatKeyOptions } from "@root/utils/fields/vatKey";
import { options as levelOptions } from "@root/utils/fields/warrantyLevel";
import { options as sourceOptions } from "@root/utils/fields/warrantySourceType";

import { EditPageLayout } from "../common/components/EditPageLayout";
import { ProductChangelog } from "../common/components/ProductChangelog";
import { ProductForm } from "../common/components/ProductForm";
import { useProductEditingFormActions } from "../common/hooks/useProductEditingFormActions";
import { mapProductFormValuesForEditSubmit } from "../common/utils/mapProductFormValuesForEditSubmit";
import { changelogFieldsConfig } from "./changelogFieldsConfig";
import { WarrantyProductFormValues, formField } from "./types";

const { Item } = Form;

export const WarrantyEdit = () => {
  const { id: productId } = useParams<"id">();
  const hasPermission = useHasPermission();

  const [formInstance] = Form.useForm<WarrantyProductFormValues>();

  const { data, isFetching: isLoading } = useQuery(
    ["warranty-product", productId],
    () =>
      getApiClient()
        .LoadWarrantyProduct({ id: productId! })
        .then((data) => data.product),
    {
      onSuccess: (data) =>
        formInstance.setFieldsValue(data as WarrantyProductFormValues),
    }
  );

  const { mutateAsync: update, isLoading: isSubmitting } = useMutation(
    getApiClient().UpdateWarrantyProduct,
    {
      onSuccess: (data) =>
        formInstance.setFieldsValue(data.update as WarrantyProductFormValues),
    }
  );

  const { handleSubmit, handleCancel } =
    useProductEditingFormActions<WarrantyProductFormValues>({
      returnRoute: routesConfig.warranty.overview,
      mutation: ({ level: _, monthsCovered: __, ...values }) =>
        update({
          id: data!.id!,
          product: mapProductFormValuesForEditSubmit(values),
        }),
    });

  return (
    <EditPageLayout title="Warranty edit">
      <ProductForm
        isLoading={isLoading}
        isSubmitting={isSubmitting}
        mode="EDIT"
        formInstance={formInstance}
        productCurrencyCode={data?.currencyCode}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        slots={{
          beforePrices: (
            <>
              <Item
                name={formField("level")}
                label="Level"
                rules={[{ required: true }]}
              >
                <Select
                  disabled
                  showSearch
                  options={levelOptions}
                  {...selectQaSelectors("level")}
                />
              </Item>
              <Item
                name={formField("monthsCovered")}
                label="Month covered"
                rules={[{ required: true }]}
              >
                <InputNumber
                  disabled
                  data-qa-selector="input_monthsCovered"
                  style={{ width: "100%" }}
                />
              </Item>
              <Item
                name={formField("source")}
                label="Source"
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  options={sourceOptions}
                  {...selectQaSelectors("source")}
                />
              </Item>
              <Item
                name={formField("insuranceTax")}
                label="Insurance tax"
                rules={[{ required: true }]}
              >
                <PercentInput
                  qaSelector="insuranceTax"
                  style={{ width: "100%" }}
                />
              </Item>
              <Item
                name={formField("applicableCarVatKey")}
                label="Applicable car VAT Key"
              >
                <Select
                  options={vatKeyOptions}
                  {...selectQaSelectors("applicableCarVatKey")}
                />
              </Item>
            </>
          ),
          afterCheckboxes: (
            <>
              <Item name={formField("recommended")} valuePropName="checked">
                <Checkbox data-qa-selector="checkbox_recommended">
                  Recommended
                </Checkbox>
              </Item>
            </>
          ),
        }}
      />
      {data?.id &&
        data?.currencyCode &&
        hasPermission("productChangelogSearch") && (
          <ProductChangelog
            productId={data.id}
            currencyCode={data.currencyCode}
            extraFieldsConfig={changelogFieldsConfig}
          />
        )}
    </EditPageLayout>
  );
};
