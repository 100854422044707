import { MinusOutlined } from "@ant-design/icons";
import React from "react";

import { Nil } from "@root/types";
import { formatCurrency } from "@root/utils/format/currency";

import { PriceEntity } from "../types";

export const FormattedPrice = ({ price }: { price: PriceEntity | Nil }) =>
  price?.currencyCode && price.amountMinorUnits != null ? (
    formatCurrency({
      amountMinorUnits: price.amountMinorUnits,
      currencyCode: price.currencyCode,
    })
  ) : (
    <MinusOutlined />
  );
