import { Space, Typography } from "antd";
import React from "react";

interface FieldProps {
  label: React.ReactNode;
  children: React.ReactNode;
}

export const Field = ({ label, children }: FieldProps) => (
  <Space size={0} direction="vertical" align="start">
    <Typography.Text>{label}</Typography.Text>
    {children}
  </Space>
);
