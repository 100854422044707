import { notification } from "antd";
import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";

import { ANTD_EXTERNAL_VERSION_LESS } from "@root/antdConstants";

import Root from "./root.component";

if (__LOAD_BOOTSTRAP__) {
  /**
   We need to include bootstrap's global styles to ensure that
   the page looks the same either in development mode or when embedded
   into the legacy BO (as it has these global styles)
   */
  require("bootswatch/cosmo/bootstrap.min.css");
}

if (__WEBPACK_DEV_SERVER__) {
  require("antd/dist/antd.less");
}

notification.config({
  top: 55,
  prefixCls: `${ANTD_EXTERNAL_VERSION_LESS}-notification`,
});

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary: (err, info) => {
    console.error(err, info);

    return <>Some error occurred!</>;
  },
});

export const { bootstrap, mount, unmount } = lifecycles;
