import { options as registrationTypeOptions } from "@root/utils/fields/registrationType";

import {
  FieldConfig,
  booleanFormatter,
  createOptionsFormatter,
} from "../common/components/ProductChangelog";

export const changelogFieldsConfig: Record<string, FieldConfig> = {
  registrationInRetailCountry: {
    label: "Registration in retail country",
    formatter: booleanFormatter,
    simplified: true,
  },
  registrationInAnyCountry: {
    label: "Registration in any country",
    formatter: booleanFormatter,
    simplified: true,
  },
  registrationType: {
    label: "Registration type",
    formatter: createOptionsFormatter(registrationTypeOptions),
  },
};
