import { Card, Form, notification } from "antd";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";

import { useHasPermission } from "@root/acl";
import { getApiClient } from "@root/apiClient";
import { useGeneratePath } from "@root/hooks/useGeneratePath";
import { routesConfig } from "@root/routes/config";

import { BasketItemForm, FormValues } from "../common/BasketItemForm";
import { EditingPageLayout } from "../common/components/EditingPageLayout";

export const Create = () => {
  const hasPermission = useHasPermission();
  const generatePath = useGeneratePath();
  const navigate = useNavigate();

  const [form] = Form.useForm<FormValues>();
  const mutation = useMutation(getApiClient().CreateBasketItem);

  const handleSubmit = async (values: FormValues) => {
    const { createProduct } = await mutation.mutateAsync({
      product: {
        ...values,
        depositApplicable: false,
        paidService: false,
        pnlVatApplicable: (values.pnlVatRate ?? 0) > 0,
      },
    });
    if (createProduct?.id) {
      notification.success({
        message: "Basket item successfully created",
      });
      if (hasPermission("basketItemsEditPage")) {
        navigate(
          generatePath(routesConfig.basketItems.edit, { id: createProduct.id })
        );
      } else {
        navigate(generatePath(routesConfig.basketItems.overview, {}));
      }
    }
  };

  return (
    <EditingPageLayout title="Basket Items / New">
      <Card type="inner" title="Basket item Details">
        <BasketItemForm
          submitText="Create"
          formInstance={form}
          onSubmit={handleSubmit}
        />
      </Card>
    </EditingPageLayout>
  );
};
