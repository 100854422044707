import { options as countryOptions } from "@root/utils/fields/country";
import { options as publishedScopeOptions } from "@root/utils/fields/publishedScope";
import { options as vatKeyOptions } from "@root/utils/fields/vatKey";
import { formatCurrency } from "@root/utils/format/currency";
import { formatVatRate } from "@root/utils/format/vatRate";

export const createOptionsFormatter =
  (options: { label: string; value: string }[]) => (value: string) =>
    options.find((it) => it.value === value)?.label ?? value;

interface FieldFormatterContext {
  currencyCode: string;
}

export type FieldFormatter = (
  value: string,
  context: FieldFormatterContext
) => string;

export interface FieldConfig {
  label: string;
  formatter?: FieldFormatter;
  simplified?: boolean;
}

export const BOOLEAN_VALUES = {
  TRUE: "1",
  FALSE: "",
};

export const booleanFormatter: FieldFormatter = (value) =>
  value === BOOLEAN_VALUES.TRUE ? "Enabled" : "Disabled";

const priceFormatter: FieldFormatter = (value, context) =>
  formatCurrency({
    currencyCode: context.currencyCode,
    amountMinorUnits: Number(value),
  });

export const fieldsConfig: Record<string, FieldConfig> = {
  country: {
    label: "Country",
    formatter: createOptionsFormatter(countryOptions),
  },
  invoiceName: {
    label: "Invoice name",
  },
  invoiceDescription: {
    label: "Invoice description",
  },
  internalReferenceName: {
    label: "Internal reference name",
  },
  publishedScope: {
    label: "Published scope",
    formatter: createOptionsFormatter(publishedScopeOptions),
  },
  partnerName: {
    label: "Partner name",
  },
  quantity: {
    label: "Quantity",
  },
  grossPriceMinorUnits: {
    label: "Gross price",
    formatter: priceFormatter,
  },
  pnlCostGrossPriceMinorUnits: {
    label: "P&L Gross price",
    formatter: priceFormatter,
  },
  vatKey: {
    label: "VAT key",
    formatter: createOptionsFormatter(vatKeyOptions),
  },
  pnlCostVatKey: {
    label: "P&L VAT key",
    formatter: createOptionsFormatter(vatKeyOptions),
  },
  vatPercentage: {
    label: "VAT percentage",
    formatter: (value) => formatVatRate(Number(value)),
  },
  pnlCostVatPercentage: {
    label: "P&L VAT percentage",
    formatter: (value) => formatVatRate(Number(value)),
  },
  defaulted: {
    label: "Defaulted",
    formatter: booleanFormatter,
    simplified: true,
  },
  shippingRequired: {
    label: "Requires shipping",
    formatter: booleanFormatter,
    simplified: true,
  },
};
