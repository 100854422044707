import { currencyInfoByCode, unitsFromMinorToMajor } from "@retail/currency";
import { LOCALES } from "@retail/i18n/constants";

const formattersCache = new Map<string, Intl.NumberFormat>();

const getCurrencyFormat = (
  currencyCode: string,
  locale: string = LOCALES.EN
) => {
  const cacheKey = `${locale}__${currencyCode}`;
  const cached = formattersCache.get(cacheKey);

  if (cached) {
    return cached;
  }

  try {
    const currencyFormat = new Intl.NumberFormat(locale, {
      currency: currencyCode,
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
      style: "currency",
      currencyDisplay: "symbol",
    });
    formattersCache.set(cacheKey, currencyFormat);
    return currencyFormat;
  } catch (e) {
    return null;
  }
};

interface FormatCurrencyParams {
  amountMinorUnits: number;
  currencyCode: string;
}

export const formatCurrency = ({
  amountMinorUnits,
  currencyCode,
}: FormatCurrencyParams) => {
  const currencyInfo = currencyInfoByCode[currencyCode];

  const currencyFormat = getCurrencyFormat(
    currencyCode,
    currencyInfo?.formatLocale
  );

  const amount = unitsFromMinorToMajor(
    amountMinorUnits,
    currencyInfo?.conversionMajor
  );

  if (!currencyFormat) {
    return amount;
  }

  return currencyFormat.format(amount);
};
