import { Form } from "antd";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";

import { useHasPermission } from "@root/acl";
import { getApiClient } from "@root/apiClient";
import { routesConfig } from "@root/routes/config";

import { EditPageLayout } from "../common/components/EditPageLayout";
import {
  FeeProductForm,
  FeeProductFormValues,
} from "../common/components/FeeProductForm";
import { ProductChangelog } from "../common/components/ProductChangelog";
import { useProductEditingFormActions } from "../common/hooks/useProductEditingFormActions";
import { mapProductFormValuesForEditSubmit } from "../common/utils/mapProductFormValuesForEditSubmit";

export const FeeEdit = () => {
  const { id: productId } = useParams<"id">();
  const hasPermission = useHasPermission();

  const [formInstance] = Form.useForm<FeeProductFormValues>();

  const { data, isFetching: isLoading } = useQuery(
    ["fee-product", productId],
    () =>
      getApiClient()
        .LoadFeeProduct({ id: productId! })
        .then((data) => data.product),
    {
      onSuccess: (data) =>
        formInstance.setFieldsValue(data as FeeProductFormValues),
    }
  );

  const { mutateAsync: update, isLoading: isSubmitting } = useMutation(
    getApiClient().UpdateFeeProduct,
    {
      onSuccess: (data) =>
        formInstance.setFieldsValue(data.update as FeeProductFormValues),
    }
  );

  const { handleSubmit, handleCancel } =
    useProductEditingFormActions<FeeProductFormValues>({
      returnRoute: routesConfig.fee.overview,
      mutation: ({ feeType: _, ...values }) =>
        update({
          id: data!.id!,
          product: mapProductFormValuesForEditSubmit(values),
        }),
    });

  return (
    <EditPageLayout title="Fee edit">
      <FeeProductForm
        mode="EDIT"
        isLoading={isLoading}
        isSubmitting={isSubmitting}
        formInstance={formInstance}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />
      {data?.id &&
        data?.currencyCode &&
        hasPermission("productChangelogSearch") && (
          <ProductChangelog
            productId={data.id}
            currencyCode={data.currencyCode}
          />
        )}
    </EditPageLayout>
  );
};
