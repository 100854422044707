import { Form } from "antd";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";

import { useHasPermission } from "@root/acl";
import { getApiClient } from "@root/apiClient";
import { routesConfig } from "@root/routes/config";

import { EditPageLayout } from "../common/components/EditPageLayout";
import { ProductChangelog } from "../common/components/ProductChangelog";
import { ProductForm } from "../common/components/ProductForm";
import { useProductEditingFormActions } from "../common/hooks/useProductEditingFormActions";
import { ProductFormValues } from "../common/types/productFormValues";
import { mapProductFormValuesForEditSubmit } from "../common/utils/mapProductFormValuesForEditSubmit";

export const VehicleInsuranceEdit = () => {
  const { id: productId } = useParams<"id">();
  const hasPermission = useHasPermission();

  const [formInstance] = Form.useForm<ProductFormValues>();

  const { data, isFetching: isLoading } = useQuery(
    ["vehicle-insurance-product", productId],
    () =>
      getApiClient()
        .LoadVehicleInsuranceProduct({ id: productId! })
        .then((data) => data.product),
    {
      onSuccess: (data) =>
        formInstance.setFieldsValue(data as ProductFormValues),
    }
  );

  const { mutateAsync: update, isLoading: isSubmitting } = useMutation(
    getApiClient().UpdateVehicleInsuranceProduct,
    {
      onSuccess: (data) =>
        formInstance.setFieldsValue(data.update as ProductFormValues),
    }
  );

  const { handleSubmit, handleCancel } = useProductEditingFormActions({
    returnRoute: routesConfig.vehicleInsurance.overview,
    mutation: (values) =>
      update({
        id: data!.id!,
        product: mapProductFormValuesForEditSubmit(values),
      }),
  });

  return (
    <EditPageLayout title="Vehicle insurance edit">
      <ProductForm
        isLoading={isLoading}
        isSubmitting={isSubmitting}
        mode="EDIT"
        formInstance={formInstance}
        productCurrencyCode={data?.currencyCode}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />
      {data?.id &&
        data?.currencyCode &&
        hasPermission("productChangelogSearch") && (
          <ProductChangelog
            productId={data.id}
            currencyCode={data.currencyCode}
          />
        )}
    </EditPageLayout>
  );
};
