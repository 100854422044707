import { Tooltip } from "antd";
import { useQuery } from "react-query";

import { getApiClient } from "@root/apiClient";
import { CreatableSelect } from "@root/components/CreatableSelect";

import { useWatchField } from "../useWatchField";

interface PartnerSelectProps {
  onChange?: (value: string) => void;
}

export const PartnerSelect = (props: PartnerSelectProps) => {
  const country = useWatchField("country");
  const category = useWatchField("category");
  const type = useWatchField("type");

  const partnerDependenciesMet = Boolean(country && category && type);

  const { data, isLoading } = useQuery(
    ["partner-options", country, category, type],
    () =>
      getApiClient().LoadPartnerOptions({
        country: country!,
        category: category!,
        type: type!,
      }),
    {
      enabled: partnerDependenciesMet,
      select: (data) =>
        data.partners?.entities?.map((partner) => ({
          value: partner!,
          label: partner!,
        })),
    }
  );

  return (
    <Tooltip
      title={
        partnerDependenciesMet ? "" : "Please select Country, Category and Type"
      }
    >
      <CreatableSelect
        options={data ?? []}
        isLoading={isLoading}
        disabled={!partnerDependenciesMet}
        data-qa-selector="select_partner"
        dropdownClassName="QA_ID_dropdown_partner"
        {...props}
      />
    </Tooltip>
  );
};
