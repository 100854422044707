import { EditFilled, MinusOutlined } from "@ant-design/icons";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

import { useHasPermission } from "@root/acl";
import { useGeneratePath } from "@root/hooks/useGeneratePath";
import { routesConfig } from "@root/routes/config";

import { BasketItemEntity } from "../types";

interface ActionsProps {
  item: BasketItemEntity;
}

export const Actions = ({ item }: ActionsProps) => {
  const generatePath = useGeneratePath();
  const hasPermission = useHasPermission();

  return hasPermission("basketItemsEditPage") ? (
    <RouterLink
      to={generatePath(routesConfig.basketItems.edit, { id: item.id })}
    >
      <EditFilled style={{ fontSize: "18px" }} />
    </RouterLink>
  ) : (
    <MinusOutlined />
  );
};
