import { Space } from "antd";
import React from "react";
import { useMutation } from "react-query";

import { useHasPermission } from "@root/acl";
import { getApiClient } from "@root/apiClient";
import { FIRST_PAGE_NUMBER } from "@root/hooks/useSearchValues";
import { routesConfig } from "@root/routes/config";
import { toStableValuesAsString } from "@root/utils/string";

import { ProductsFilters } from "../common/components/ProductsFilters";
import { ProductsTable } from "../common/components/ProductsTable";
import { useSearchValues } from "../common/hooks/useSearchValues";
import { useFeesList } from "../fees/useFeesList";
import { FeeProduct } from "./types";

export const Fees = () => {
  const hasPermission = useHasPermission();
  const [searchValues, updateSearchValues] = useSearchValues();
  const { data, isLoading, refetch, pageSize } = useFeesList(searchValues);

  const { mutateAsync: updateActive } = useMutation(
    getApiClient().UpdateFeeProductActive
  );

  const handleSwitchActive = async (id: string, active: boolean) => {
    await updateActive({ id, active });
    await refetch();
  };

  return (
    <Space size="middle" direction="vertical" style={{ width: "100%" }}>
      <ProductsFilters
        withFeeType
        filterValues={searchValues.filters}
        reinitializeKey={toStableValuesAsString(searchValues.filters)}
        onUpdateFilterValues={(filters) =>
          updateSearchValues({ page: FIRST_PAGE_NUMBER, filters })
        }
      />
      <ProductsTable<FeeProduct>
        data={data?.entities ?? []}
        isLoading={isLoading}
        pageSize={pageSize}
        currentPage={searchValues.page}
        totalEntityCount={data?.totalEntityCount ?? 0}
        canSwitchActive={hasPermission("feeProductUpdate")}
        editRoute={
          hasPermission("feeProductUpdate") ? routesConfig.fee.edit : undefined
        }
        createRoute={
          hasPermission("feeProductCreate")
            ? routesConfig.fee.create
            : undefined
        }
        onSwitchActive={handleSwitchActive}
        onChangePage={(page) => updateSearchValues({ ...searchValues, page })}
        extraColumns={[
          {
            key: "title",
            title: "Title",
            render: (_, entity) => entity.title,
          },
          {
            key: "feeType",
            title: "Fee type",
            render: (_, entity) => entity.feeType,
          },
        ]}
      />
    </Space>
  );
};
