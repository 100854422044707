import Cookies from "js-cookie";

import { AccessDomain } from "@root/acl";
import { getSdk } from "@root/generated/graphql";

import { graphqlClient } from "./graphqlClient";

interface GetApiClientInput {
  domain?: AccessDomain | null;
}

export const getApiClient = ({
  domain = AccessDomain.ProductManagement,
}: GetApiClientInput = {}) =>
  getSdk(graphqlClient, (action) =>
    action({
      Authorization: `Bearer ${Cookies.get("t")}`,
      ...(domain !== null && { "access-domain": domain }),
    })
  );
