import { Card, Col, Form, Row, Skeleton, notification } from "antd";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

import { getApiClient } from "@root/apiClient";
import { useGeneratePath } from "@root/hooks/useGeneratePath";
import { routesConfig } from "@root/routes/config";
import { truncateWithEllipsis } from "@root/utils/string";

import { BasketItemForm, FormValues } from "../common/BasketItemForm";
import { EditingPageLayout } from "../common/components/EditingPageLayout";

export const Edit = () => {
  const { id: itemId } = useParams<{ id: string }>();
  const [form] = Form.useForm<FormValues>();
  const navigate = useNavigate();
  const generatePath = useGeneratePath();

  const { isFetching } = useQuery(
    ["basket-item", itemId],
    () => getApiClient().LoadBasketItem({ id: itemId! }),
    {
      enabled: Boolean(itemId),
      refetchOnMount: "always",
      onSuccess: (data) => {
        if (data?.product) {
          form.setFieldsValue(data.product);
        }
      },
      onError: (e) => {
        notification.error({
          message: "Error while loading basket item",
          description: truncateWithEllipsis(
            e instanceof Error ? e.message : String(e),
            120
          ),
        });
      },
    }
  );

  const mutation = useMutation(getApiClient().UpdateBasketItem);

  const handleSubmit = async ({ ...values }: FormValues) => {
    const { updateProduct } = await mutation.mutateAsync({
      id: itemId!,
      product: { ...values, pnlVatApplicable: (values.pnlVatRate ?? 0) > 0 },
    });
    if (updateProduct) {
      notification.success({
        message: "Basket item successfully updated",
      });
      navigate(generatePath(routesConfig.basketItems.overview, {}));
    }
  };

  return (
    <EditingPageLayout title="Basket Items / Edit">
      <Card type="inner" title="Basket item Details">
        {isFetching ? (
          <Row gutter={64}>
            <Col xs={12}>
              <Skeleton active paragraph={{ rows: 24 }} />
            </Col>
            <Col xs={12}>
              <Skeleton active paragraph={{ rows: 6 }} />
            </Col>
          </Row>
        ) : (
          <BasketItemForm
            submitText="Update"
            formInstance={form}
            onSubmit={handleSubmit}
          />
        )}
      </Card>
    </EditingPageLayout>
  );
};
