import { generatePath } from "react-router-dom";

export type ParamsType = Record<string, string | number>;

const createRoute = <T extends ParamsType>(
  routePath: string
): RouteConfig<T> => {
  const path = `/:lang${routePath}`;
  return {
    path,
    generatePath: (params) => generatePath(path, params),
  };
};

export interface RouteConfig<T extends ParamsType> {
  path: string;
  generatePath: (params: { lang: string } & T) => string;
}

export const routesConfig = {
  login: createRoute("/login"),
  basketItems: {
    overview: createRoute("/product-catalog/basket-items"),
    create: createRoute("/product-catalog/basket-items/new"),
    edit: createRoute<{ id: string }>("/product-catalog/basket-items/:id/edit"),
  },
  warranty: {
    overview: createRoute("/product-catalog/warranties"),
    edit: createRoute("/product-catalog/warranty/:id/edit"),
  },
  carRegistration: {
    overview: createRoute("/product-catalog/car-registrations"),
    edit: createRoute("/product-catalog/car-registrations/:id/edit"),
  },
  downPayment: {
    overview: createRoute("/product-catalog/down-payments"),
    edit: createRoute("/product-catalog/down-payments/:id/edit"),
  },
  vehiclePreparation: {
    overview: createRoute("/product-catalog/vehicle-preparations"),
    edit: createRoute("/product-catalog/vehicle-preparations/:id/edit"),
  },
  vehicleInsurance: {
    overview: createRoute("/product-catalog/vehicle-insurances"),
    edit: createRoute("/product-catalog/vehicle-insurances/:id/edit"),
  },
  fee: {
    overview: createRoute("/product-catalog/fees"),
    create: createRoute("/product-catalog/fees/new"),
    edit: createRoute("/product-catalog/fees/:id/edit"),
  },
};
