import { FilterValues } from "./types";

export const searchParamsToFilters = (
  params: URLSearchParams
): FilterValues => ({
  category: params.get("category"),
  country: params.get("country"),
  type: params.get("type"),
  title: params.get("title"),
  partner: params.get("partner"),
  sku: params.get("sku"),
  enabled: params.get("enabled") === "true",
});
