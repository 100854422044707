import { Button } from "antd";
import { BaseButtonProps } from "antd/es/button/button";
import React from "react";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";

type RouterLinkPropsToForward =
  | "to"
  | "relative"
  | "target"
  | "replace"
  | "state"
  | "preventScrollReset";

interface LinkProps
  extends BaseButtonProps,
    Pick<RouterLinkProps, RouterLinkPropsToForward> {
  style?: React.CSSProperties;
}

export const Link = ({
  to,
  target,
  relative,
  replace,
  state,
  preventScrollReset,
  children,
  ...props
}: LinkProps) => {
  // Here we are rendering <button> inside <a>.
  // We are forced to do it this way, since antd's Button component styles
  // does not handle combination of `href` prop together with non-default
  // value of `type` - it renders blue text on blue background :(
  return (
    <RouterLink
      {...{ to, target, relative, replace, state, preventScrollReset }}
    >
      <Button {...props}>{children}</Button>
    </RouterLink>
  );
};
