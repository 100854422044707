import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import { FormInstance } from "antd/lib/form/hooks/useForm";
import React, { useState } from "react";

import { getCurrencyCodeByCountryCode } from "@retail/currency";

import { Link } from "@root/components/Link";
import { PriceInput } from "@root/components/PriceInput";
import { FeatureFlag, useFeatureFlags } from "@root/featureFlags";
import { useGeneratePath } from "@root/hooks/useGeneratePath";
import { routesConfig } from "@root/routes/config";
import { Country } from "@root/types";
import { options as categoryOptions } from "@root/utils/fields/category";
import { options as countryOptions } from "@root/utils/fields/country";
import { options as vatKeyOptions } from "@root/utils/fields/vatKey";
import { filterOptionsByLabelAndValue } from "@root/utils/select";

import { GrossPriceIndicator } from "./GrossPriceIndicator";
import { PartnerSelect } from "./PartnerSelect";
import { TypeSelect } from "./TypeSelect";
import { VatRateInput } from "./VatRateInput";
import cn from "./styles.less";
import { FormValues, formField } from "./types";
import { useWatchField } from "./useWatchField";

interface BasketItemFormProps {
  submitText: React.ReactNode;
  formInstance: FormInstance<FormValues>;
  onSubmit: (values: FormValues) => Promise<void>;
}

const REQUIRED_RULE = {
  required: true,
};

export const BasketItemForm = ({
  submitText,
  formInstance: form,
  onSubmit,
}: BasketItemFormProps) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const generatePath = useGeneratePath();
  const formValues = {
    country: useWatchField("country", form),
    excludedFromInvoice: useWatchField("excludedFromInvoice", form),
  };
  const invoicePriceRequired = !formValues.excludedFromInvoice;
  const currencyCode = formValues.country
    ? getCurrencyCodeByCountryCode(formValues.country)
    : undefined;
  const { isFlagEnabled } = useFeatureFlags({
    skip: !formValues.country,
    country: formValues.country as Country,
  });
  const allowNonZeroPriceForExcluded = isFlagEnabled(
    FeatureFlag.BO_BASKET_ITEM_EXCLUDE_FROM_INVOICE_WITH_NON_ZERO_AMOUNT
  );

  const handleExcludeFromInvoiceChange = (isChecked: boolean) => {
    if (isChecked && !allowNonZeroPriceForExcluded) {
      form.setFieldsValue({
        invoiceItemNetMinorUnits: 0,
        vatRate: 0,
      });
    }
  };

  const handleSubmit = async (values: FormValues) => {
    try {
      setSubmitting(true);
      await onSubmit(values);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Form form={form} layout="vertical" onFinish={handleSubmit}>
      <Row gutter={64}>
        <Col xs={24} md={12}>
          <Form.Item
            name={formField("country")}
            label="Country"
            rules={[REQUIRED_RULE]}
          >
            <Select
              showSearch
              filterOption={filterOptionsByLabelAndValue}
              options={countryOptions}
              data-qa-selector="select_country"
              dropdownClassName="QA_ID_dropdown_country"
              onChange={() =>
                form.resetFields([formField("type"), formField("partner")])
              }
            />
          </Form.Item>
          <Form.Item
            name={formField("category")}
            label="Category"
            rules={[REQUIRED_RULE]}
          >
            <Select
              showSearch
              filterOption={filterOptionsByLabelAndValue}
              options={categoryOptions}
              data-qa-selector="select_category"
              dropdownClassName="QA_ID_dropdown_category"
              onChange={() =>
                form.resetFields([formField("type"), formField("partner")])
              }
            />
          </Form.Item>
          <Form.Item
            name={formField("type")}
            label="Type"
            rules={[REQUIRED_RULE]}
          >
            <TypeSelect
              onChange={() => form.resetFields([formField("partner")])}
            />
          </Form.Item>
          <Form.Item
            name={formField("partner")}
            label="Partner"
            rules={[REQUIRED_RULE]}
          >
            <PartnerSelect />
          </Form.Item>
          <Form.Item
            name={formField("sku")}
            label="SKU"
            rules={[REQUIRED_RULE]}
          >
            <Input data-qa-selector="input_sku" />
          </Form.Item>
          <Form.Item
            name={formField("title")}
            label="Title"
            rules={[REQUIRED_RULE]}
          >
            <Input data-qa-selector="input_title" />
          </Form.Item>
          <Form.Item
            name={formField("invoiceDescription")}
            label="Translation for invoice"
            rules={[REQUIRED_RULE]}
          >
            <Input data-qa-selector="input_invoice-description" />
          </Form.Item>
          <Form.Item
            name={formField("quantity")}
            label="Quantity"
            rules={[{ type: "number", min: 0 }, REQUIRED_RULE]}
          >
            <InputNumber
              style={{ width: "100%" }}
              data-qa-selector="input_quantity"
            />
          </Form.Item>
          <Typography.Title level={4} className={cn.requiredMark}>
            P&L Costs
          </Typography.Title>
          <div style={{ display: "flex", gap: "8px" }}>
            <Form.Item
              name={formField("pnlCostNetMinorUnits")}
              label="Net Price"
              style={{ flex: 3 }}
              rules={[{ type: "number", min: 0 }, REQUIRED_RULE]}
            >
              <PriceInput
                currencyCode={currencyCode}
                qaSelector="input_pnlCostNetMinorUnits"
              />
            </Form.Item>
            <Form.Item
              name={formField("pnlVatRate")}
              label="VAT Rate"
              rules={[REQUIRED_RULE]}
              style={{ flex: 2 }}
            >
              <VatRateInput qaSelector="select_pnlVatRate" />
            </Form.Item>
            <Form.Item label="Gross Price" style={{ flex: 3 }} required>
              <GrossPriceIndicator
                currencyCode={currencyCode}
                netPriceField={formField("pnlCostNetMinorUnits")}
                vatRateField={formField("pnlVatRate")}
              />
            </Form.Item>
          </div>
          <Typography.Title
            level={4}
            className={invoicePriceRequired ? cn.requiredMark : ""}
          >
            Invoice for customer
          </Typography.Title>
          <div style={{ display: "flex", gap: "8px" }}>
            <Form.Item
              name={formField("invoiceItemNetMinorUnits")}
              label="Net Price"
              rules={[
                { type: "number", min: 0 },
                ...(invoicePriceRequired ? [REQUIRED_RULE] : []),
              ]}
              style={{ flex: 3 }}
            >
              <PriceInput
                currencyCode={currencyCode}
                disabled={
                  !allowNonZeroPriceForExcluded &&
                  Boolean(formValues.excludedFromInvoice)
                }
                qaSelector="input_invoiceItemNetMinorUnits"
              />
            </Form.Item>
            <Form.Item
              name={formField("vatRate")}
              label="VAT Rate"
              rules={invoicePriceRequired ? [REQUIRED_RULE] : []}
              style={{ flex: 2 }}
            >
              <VatRateInput qaSelector="select_vatRate" />
            </Form.Item>
            <Form.Item
              label="Gross Price"
              style={{ flex: 3 }}
              required={invoicePriceRequired}
            >
              <GrossPriceIndicator
                currencyCode={currencyCode}
                netPriceField={formField("invoiceItemNetMinorUnits")}
                vatRateField={formField("vatRate")}
              />
            </Form.Item>
          </div>
          <div style={{ display: "flex", gap: "8px" }}>
            <Form.Item
              name={formField("vatKey")}
              label="VAT Key"
              style={{ flex: 3 }}
              rules={[REQUIRED_RULE]}
            >
              <Select
                options={vatKeyOptions}
                data-qa-selector="select_vatKey"
                dropdownClassName="QA_ID_dropdown_vatKey"
              />
            </Form.Item>
            <div style={{ flex: 5 }} />
          </div>
        </Col>
        <Col xs={24} md={12} style={{ paddingTop: "24px" }}>
          <Form.Item
            name={formField("shippingRequired")}
            valuePropName="checked"
            initialValue={false}
          >
            <Checkbox data-qa-selector="checkbox_shippingRequired">
              Requires shipping
            </Checkbox>
          </Form.Item>
          <Form.Item
            name={formField("active")}
            valuePropName="checked"
            initialValue={false}
          >
            <Checkbox data-qa-selector="checkbox_active">Active</Checkbox>
          </Form.Item>
          <Form.Item
            name={formField("defaulted")}
            valuePropName="checked"
            initialValue={false}
          >
            <Checkbox data-qa-selector="checkbox_defaulted">Default</Checkbox>
          </Form.Item>
          <Form.Item
            name={formField("excludedFromInvoice")}
            valuePropName="checked"
            initialValue={false}
          >
            <Checkbox
              data-qa-selector="checkbox_excludedFromInvoice"
              onChange={({ target }) =>
                handleExcludeFromInvoiceChange(target.checked)
              }
            >
              Exclude from invoice
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Space align="center" style={{ width: "100%", justifyContent: "end" }}>
        <Link
          to={generatePath(routesConfig.basketItems.overview, {})}
          size="large"
          data-qa-selector="link_cancel"
        >
          Cancel
        </Link>
        <Button
          size="large"
          type="primary"
          htmlType="submit"
          loading={isSubmitting}
          data-qa-selector="button_submit"
        >
          {submitText}
        </Button>
      </Space>
    </Form>
  );
};
