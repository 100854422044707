import { Space } from "antd";

import {
  FIRST_PAGE_NUMBER,
  useSearchValues,
} from "@root/hooks/useSearchValues";
import { toStableValuesAsString } from "@root/utils/string";

import { FiltersPanel } from "./FiltersPanel";
import { ItemsTable } from "./ItemsTable";
import { searchParamsToFilters } from "./searchParamsToFilters";

export const Overview = () => {
  const [searchValues, updateSearchValues] = useSearchValues({
    paramsToFilters: searchParamsToFilters,
  });

  return (
    <Space size="middle" direction="vertical" style={{ width: "100%" }}>
      <FiltersPanel
        filterValues={searchValues.filters}
        reinitializeKey={toStableValuesAsString(searchValues.filters)}
        onUpdateFilterValues={(filters) =>
          updateSearchValues({ filters, page: FIRST_PAGE_NUMBER })
        }
      />
      <ItemsTable
        searchValues={searchValues}
        onChangePage={(page) => updateSearchValues({ page })}
      />
    </Space>
  );
};
