import {
  ArrowRightOutlined,
  PlayCircleTwoTone,
  PlusSquareTwoTone,
  SlidersTwoTone,
  StopTwoTone,
} from "@ant-design/icons";
import { Space, Typography } from "antd";
import { CSSProperties } from "react";

import { BOOLEAN_VALUES, FieldConfig, fieldsConfig } from "./fieldsConfig";
import { ChangelogEntity } from "./types";

const iconStyle: CSSProperties = { fontSize: "24px" };

interface ChangeViewProps {
  entity: ChangelogEntity;
  currencyCode: string;
  extraFieldsConfig?: Record<string, FieldConfig>;
}

export const ChangeView = ({
  currencyCode,
  entity: { field, action, oldValue, newValue },
  extraFieldsConfig,
}: ChangeViewProps) => {
  if (field === null && action === "insert") {
    return (
      <Space size="small">
        <PlusSquareTwoTone style={iconStyle} />
        Product created
      </Space>
    );
  }

  if (field === "active" && action === "update") {
    const isNowActive = newValue === BOOLEAN_VALUES.TRUE;
    return (
      <Space direction="vertical">
        <Space size="small">
          {isNowActive ? (
            <PlayCircleTwoTone style={iconStyle} />
          ) : (
            <StopTwoTone style={iconStyle} />
          )}
          {isNowActive ? "Product activated" : "Product deactivated"}
        </Space>
      </Space>
    );
  }

  if (field && action === "update") {
    const icon = <SlidersTwoTone style={iconStyle} />;
    const fieldConfig = extraFieldsConfig?.[field] ?? fieldsConfig[field];
    const label = fieldConfig?.label ?? field;
    const emptyText = <Typography.Text code>Empty</Typography.Text>;
    const formatter = fieldConfig?.formatter ?? ((arg: string) => arg);
    const formatterContext = {
      currencyCode,
    };
    return (
      <Space direction="vertical">
        <Space size="small">
          {icon}
          {label}
        </Space>
        <Space size="small">
          {!fieldConfig?.simplified && (
            <>
              {oldValue != null
                ? formatter(oldValue, formatterContext)
                : emptyText}
              <ArrowRightOutlined style={{ color: "#1890ff" }} />{" "}
            </>
          )}
          {newValue != null ? formatter(newValue, formatterContext) : emptyText}
        </Space>
      </Space>
    );
  }

  return <Typography.Text>Unknown change</Typography.Text>;
};
