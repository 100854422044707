// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cafp72L0jRuh6PByfFzc .KLw3rH6GTfH5nx3WAqzm {\n  cursor: pointer;\n  opacity: 0;\n  transition: opacity 0.5s;\n}\n.cafp72L0jRuh6PByfFzc:hover .KLw3rH6GTfH5nx3WAqzm {\n  opacity: 1;\n}\n", "",{"version":3,"sources":["webpack://./src/routes/products/common/components/CopyableText/styles.local.less"],"names":[],"mappings":"AACE;EACE,eAAA;EACA,UAAA;EACA,wBAAA;AAAJ;AAGE;EAEI,UAAA;AAFN","sourcesContent":[".text {\n  & .icon {\n    cursor: pointer;\n    opacity: 0;\n    transition: opacity 0.5s;\n  }\n\n  &:hover {\n    .icon {\n      opacity: 1;\n    }\n  }\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": "cafp72L0jRuh6PByfFzc",
	"icon": "KLw3rH6GTfH5nx3WAqzm"
};
export default ___CSS_LOADER_EXPORT___;
