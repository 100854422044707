import { Result } from "antd";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";

export const ErrorElement = () => {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return (
        <Result
          status={404}
          title={404}
          subTitle="Sorry, this page does not exist."
        />
      );
    }
    if (error.status === 403) {
      return <Result status={403} />;
    }
  }

  return <Result status={500} title="Error" subTitle="Something went wrong" />;
};
