import { Form, Input, Tooltip } from "antd";
import React from "react";

import { CreatableSelect } from "@root/components/CreatableSelect";
import { selectQaSelectors } from "@root/utils/antd";

import { useFeeTypeOptions } from "../../hooks/useFeeTypeOptions";
import { ProductForm, ProductFormProps } from "../ProductForm";
import { FeeProductFormValues, formField } from "./types";

type ForwardedProps = Pick<
  ProductFormProps<FeeProductFormValues>,
  | "mode"
  | "isSubmitting"
  | "isLoading"
  | "formInstance"
  | "onSubmit"
  | "onCancel"
>;

interface FeeProductFormProps extends ForwardedProps {}

export const FeeProductForm = (props: FeeProductFormProps) => {
  const { formInstance, mode } = props;
  const countryValue = Form.useWatch<string>(
    formField("country"),
    formInstance
  );

  const { options: feeTypeOptions, isLoading: isFeeTypesLoading } =
    useFeeTypeOptions({ country: countryValue });

  const handleCountryChange = () =>
    formInstance.resetFields([formField("feeType")]);

  return (
    <ProductForm
      {...props}
      onCountryChange={handleCountryChange}
      slots={{
        afterCountry: (
          <>
            <Tooltip
              title={countryValue ? "" : "Please select country"}
              placement="bottom"
            >
              <Form.Item
                name={formField("feeType")}
                label="Fee type"
                rules={[{ required: true }]}
              >
                <CreatableSelect
                  options={feeTypeOptions}
                  isLoading={isFeeTypesLoading}
                  disabled={!countryValue || mode === "EDIT"}
                  {...selectQaSelectors("feeType")}
                />
              </Form.Item>
              <Form.Item
                name={formField("title")}
                label="Title"
                rules={[{ required: true }]}
              >
                <Input data-qa-selector="input_title" />
              </Form.Item>
            </Tooltip>
          </>
        ),
      }}
    />
  );
};
