import { GraphQLClient } from "graphql-request";
import * as Dom from "graphql-request/dist/types.dom";

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** java.math.BigDecimal */
  BigDecimal: any;
  /** java.math.BigInteger */
  BigInteger: any;
  /** Any JSON value */
  Json: any;
  /** Built-in scalar representing a local date */
  LocalDate: any;
  /** Built-in scalar representing a local date-time */
  LocalDateTime: any;
  /** Long type */
  Long: number;
  /** Built-in scalar for map-like structures */
  Map_RefurbishmentServiceType_List_RefurbishmentServiceNameScalar: any;
  /** Built-in scalar for map-like structures */
  Map_RetailNotificationEntityType_IntegerScalar: any;
  /** Built-in scalar for map-like structures */
  Map_String_List_WheelScalar: any;
  /** Built-in scalar for map-like structures */
  Map_String_Map_String_OptionProjectionScalar: any;
  /** Built-in scalar for map-like structures */
  Map_String_ObjectScalar: any;
  /** Built-in scalar for map-like structures */
  Map_String_OpeningHoursDTOScalar: any;
  /** Built-in scalar for map-like structures */
  Map_String_PriceModifierPayloadScalar: any;
  /** Built-in scalar for map-like structures */
  Map_String_RetailOutboundPaymentOptionProjectionScalar: any;
  /** Built-in scalar for map-like structures */
  Map_String_StringScalar: any;
  /** Built-in scalar for map-like structures */
  Map_String_SurveyAggregationAnswerProjectionScalar: any;
  /** Built-in scalar for map-like structures */
  Map_UUID_LongScalar: any;
  /** Built-in scalar for map-like structures */
  Map_UUID_URLScalar: any;
  /** Built-in scalar for map-like structures */
  Map_WheelPlacementType_RimDetailsProjectionScalar: any;
  /** Built-in scalar for map-like structures */
  Map_WheelPlacementType_RimDetailsScalar: any;
  /** Built-in scalar for map-like structures */
  Map_WheelPlacementType_TireDetailsProjectionScalar: any;
  /** Built-in scalar for map-like structures */
  Map_WheelPlacementType_TireDetailsScalar: any;
  /** Built-in scalar for dynamic values */
  ObjectScalar: any;
  /** Use SPQR's SchemaPrinter to remove this from SDL */
  UNREPRESENTABLE: any;
  /** UUID String */
  UUID: string;
}

export interface AbTestingExperimentAuthor {
  __typename?: "AbTestingExperimentAuthor";
  email: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  name: Maybe<Scalars["String"]>;
}

export interface AbTestingExperimentInputInput {
  condition?: InputMaybe<Scalars["String"]>;
  enabled: Scalars["Boolean"];
  environment?: InputMaybe<Scalars["String"]>;
  expireAt?: InputMaybe<Scalars["String"]>;
  gaExperimentId?: InputMaybe<Scalars["String"]>;
  multiStep: Scalars["Boolean"];
  name: Scalars["String"];
  nextStepVariants?: InputMaybe<
    Array<InputMaybe<RetailAbTestingNextStepVariantProjectionInput>>
  >;
  startAt: Scalars["String"];
  trafficPercents: Scalars["Int"];
  variants?: InputMaybe<
    Array<InputMaybe<RetailAbTestingVariantProjectionInput>>
  >;
  viewNames: Array<InputMaybe<Scalars["String"]>>;
}

export interface AccountDataDto {
  __typename?: "AccountDataDTO";
  accountDetails: Maybe<AccountDetailsDto>;
  createdBy: Maybe<Scalars["Long"]>;
  createdOn: Maybe<Scalars["String"]>;
  uuid: Maybe<Scalars["UUID"]>;
}

export interface AccountDetailsDto {
  __typename?: "AccountDetailsDTO";
  bankName: Maybe<Scalars["String"]>;
  bic: Maybe<Scalars["String"]>;
  blz: Maybe<Scalars["String"]>;
  holder: Maybe<Scalars["String"]>;
  iban: Maybe<Scalars["String"]>;
  number: Maybe<Scalars["String"]>;
}

export interface AccountDetailsDtoInput {
  bankName?: InputMaybe<Scalars["String"]>;
  bic?: InputMaybe<Scalars["String"]>;
  blz?: InputMaybe<Scalars["String"]>;
  holder?: InputMaybe<Scalars["String"]>;
  iban?: InputMaybe<Scalars["String"]>;
  number?: InputMaybe<Scalars["String"]>;
}

export interface AccountingDatePendingTaskProjection {
  __typename?: "AccountingDatePendingTaskProjection";
  action: Maybe<Scalars["String"]>;
  assignedOn: Maybe<Scalars["String"]>;
  assignedTo: Maybe<UserProjection>;
  assignee: Maybe<UserProjection>;
  cancellationReasonComment: Maybe<Scalars["String"]>;
  cashOutReason: Maybe<Scalars["String"]>;
  cashOutRequestDate: Maybe<Scalars["String"]>;
  closedBy: Maybe<UserProjection>;
  closedOn: Maybe<Scalars["String"]>;
  comment: Maybe<Scalars["String"]>;
  commentsCount: Maybe<Scalars["Int"]>;
  completedBy: Maybe<UserProjection>;
  completedOn: Maybe<Scalars["String"]>;
  createdOn: Maybe<Scalars["String"]>;
  financingStatus: Maybe<Scalars["String"]>;
  flowVersion: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  name: Maybe<Scalars["String"]>;
  order: Maybe<OrderProjection>;
  orderId: Maybe<Scalars["UUID"]>;
  orderNumber: Maybe<Scalars["String"]>;
  orderState: Maybe<Scalars["String"]>;
  paymentStatus: Maybe<Scalars["String"]>;
  paymentType: Maybe<Scalars["String"]>;
  reason: Maybe<Scalars["String"]>;
  refundAmount: Maybe<PriceDto>;
  repeatAt: Maybe<Scalars["String"]>;
  sequenceIndex: Scalars["Int"];
  state: Maybe<Scalars["String"]>;
  stockNumber: Maybe<Scalars["String"]>;
  taskDefinitionKey: Maybe<Scalars["String"]>;
  taskStatus: Maybe<Scalars["String"]>;
  taskType: Maybe<Scalars["String"]>;
  taskUpdatedAt: Maybe<Scalars["String"]>;
}

export interface AdDetailsProjection {
  __typename?: "AdDetailsProjection";
  ad: Maybe<AdProjection>;
  damages: Maybe<DamagesProjection>;
  estimatedCosts: Maybe<Array<Maybe<RetailPricingCostDto>>>;
  retailAd: Maybe<RetailAdProjection>;
  serviceBookImages: Maybe<Array<Maybe<ImageProjection>>>;
  technicalInspectionCertificateImages: Maybe<Array<Maybe<ImageProjection>>>;
  vehicleRegistration: Maybe<ImageProjection>;
}

export interface AdImageProjectionInput {
  composites?: InputMaybe<Array<InputMaybe<CompositeImageProjectionInput>>>;
}

export interface AdItemProjection {
  __typename?: "AdItemProjection";
  ad: Maybe<AdProjection>;
  created: Maybe<Scalars["String"]>;
  currencyCode: Maybe<Scalars["String"]>;
  discountGrossMinorUnits: Maybe<Scalars["Long"]>;
  discountMinorUnits: Maybe<Scalars["Long"]>;
  externalId: Maybe<Scalars["UUID"]>;
  externalType: Maybe<Scalars["Int"]>;
  id: Maybe<Scalars["UUID"]>;
  invoiceDescription: Maybe<Scalars["String"]>;
  invoiceName: Maybe<Scalars["String"]>;
  orderId: Maybe<Scalars["UUID"]>;
  parentId: Maybe<Scalars["UUID"]>;
  priceDiscountGross: Maybe<PriceDto>;
  priceDiscountNet: Maybe<PriceDto>;
  priceDiscountVat: Maybe<PriceDto>;
  priceGross: Maybe<PriceDto>;
  priceGrossMinorUnits: Maybe<Scalars["Long"]>;
  priceNet: Maybe<PriceDto>;
  priceNetMinorUnits: Maybe<Scalars["Long"]>;
  priceTaxAdvantageAmount: Maybe<PriceDto>;
  priceVatAmount: Maybe<PriceDto>;
  retailAd: Maybe<RetailAdProjection>;
  type: Maybe<Scalars["String"]>;
  updated: Maybe<Scalars["String"]>;
  vatAmountMinorUnits: Maybe<Scalars["Long"]>;
  vatKey: Maybe<Scalars["String"]>;
  vatRate: Maybe<Scalars["Float"]>;
}

export interface AdProjection {
  __typename?: "AdProjection";
  adType: Maybe<AdType>;
  autoimported: Scalars["Boolean"];
  city: Maybe<Scalars["String"]>;
  composites: Maybe<Array<Maybe<CompositeImageProjection>>>;
  contact: Maybe<SellerContactDto>;
  countryCode: Maybe<Scalars["String"]>;
  created: Maybe<Scalars["String"]>;
  description: Maybe<Scalars["String"]>;
  documentInventoryItems: Maybe<DocumentInventoryItemConnection_DocumentInventoryItem>;
  exclusive: Maybe<Scalars["Boolean"]>;
  firstPublishedAt: Maybe<Scalars["String"]>;
  highlights: Maybe<Array<Maybe<HighlightDto>>>;
  id: Maybe<Scalars["UUID"]>;
  inShowroom: Maybe<Scalars["Boolean"]>;
  latitude: Maybe<Scalars["Float"]>;
  location: Maybe<SellerAddressDto>;
  longitude: Maybe<Scalars["Float"]>;
  manualImportDate: Maybe<Scalars["String"]>;
  pathTitle: Maybe<Scalars["String"]>;
  pdfs: Maybe<Array<Maybe<PdfDto>>>;
  price: Maybe<Price>;
  priceType: Maybe<PriceType>;
  published: Maybe<Scalars["Boolean"]>;
  publishedAt: Maybe<Scalars["String"]>;
  seller: Maybe<SellerDto>;
  sellerId: Maybe<Scalars["UUID"]>;
  source: Maybe<Source>;
  subtitle: Maybe<Scalars["String"]>;
  title: Maybe<Scalars["String"]>;
  unpublishedAt: Maybe<Scalars["String"]>;
  unpublishedReason: Maybe<UnpublishedReason>;
  updated: Maybe<Scalars["String"]>;
  vatRate: Maybe<Scalars["Float"]>;
  vehicle: Maybe<VehicleProjection>;
  version: Maybe<Scalars["Int"]>;
  videos: Maybe<Array<Maybe<AdVideoDto>>>;
  zipcode: Maybe<Scalars["String"]>;
}

export type AdType =
  | "MERCHANT"
  | "PRIVATE_LISTING"
  | "PRIVATE_SALES"
  | "RETAIL";

export interface AdUpdateProjectionInput {
  description?: InputMaybe<Scalars["String"]>;
  inShowroom?: InputMaybe<Scalars["Boolean"]>;
  pdfs?: InputMaybe<Array<InputMaybe<PdfDtoInput>>>;
  price?: InputMaybe<PriceInput>;
  published?: InputMaybe<Scalars["Boolean"]>;
  subtitle?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
  videos?: InputMaybe<Array<InputMaybe<AdVideoDtoInput>>>;
}

export interface AdVehicleProjectionInput {
  bodyType?: InputMaybe<Scalars["Int"]>;
  builtYear?: InputMaybe<Scalars["Int"]>;
  carAttrAccidentBool?: InputMaybe<Scalars["Boolean"]>;
  carPreOwnerCount?: InputMaybe<Scalars["Int"]>;
  carType?: InputMaybe<CarType>;
  categorizedCustomEquipment?: InputMaybe<
    Array<InputMaybe<CategorizedCustomEquipmentInput>>
  >;
  co2Value?: InputMaybe<Scalars["Int"]>;
  emissionSticker?: InputMaybe<Scalars["String"]>;
  equipments?: InputMaybe<Array<InputMaybe<Scalars["Long"]>>>;
  fuelConsumption?: InputMaybe<FuelConsumptionDtoInput>;
  fuelType?: InputMaybe<Scalars["Int"]>;
  gearType?: InputMaybe<Scalars["Int"]>;
  handoverKeyCount?: InputMaybe<Scalars["Int"]>;
  huCertificate?: InputMaybe<Scalars["Int"]>;
  inspectionExpiryDate?: InputMaybe<Scalars["String"]>;
  lastServiceOn?: InputMaybe<Scalars["String"]>;
  mileage?: InputMaybe<MileageDtoInput>;
  originalEngine?: InputMaybe<Scalars["Boolean"]>;
  subType?: InputMaybe<Scalars["String"]>;
  subTypeExtra?: InputMaybe<Scalars["String"]>;
  taxRating?: InputMaybe<Scalars["Int"]>;
  tuv?: InputMaybe<TuvdtoInput>;
  wasInCommercialUse?: InputMaybe<Scalars["Boolean"]>;
  wheelSets?: InputMaybe<WheelSetsDtoInput>;
}

export interface AdVideoDto {
  __typename?: "AdVideoDTO";
  adId: Maybe<Scalars["UUID"]>;
  created: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  updated: Maybe<Scalars["String"]>;
  video: Maybe<Scalars["String"]>;
}

export interface AdVideoDtoInput {
  adId?: InputMaybe<Scalars["UUID"]>;
  created?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["UUID"]>;
  updated?: InputMaybe<Scalars["String"]>;
  video?: InputMaybe<Scalars["String"]>;
}

export interface AmountInfo {
  __typename?: "AmountInfo";
  amount: Maybe<Scalars["Float"]>;
  currency: Maybe<Scalars["String"]>;
}

export interface AmountInfoInput {
  amount?: InputMaybe<Scalars["Float"]>;
  currency?: InputMaybe<Scalars["String"]>;
}

export interface ApplyPriceUpdateProjectionInput {
  dataImportId?: InputMaybe<Scalars["UUID"]>;
  priceUpdateType?: InputMaybe<PriceUpdateType>;
}

export type ApprovalLevel = "LEVEL_1" | "LEVEL_2" | "LEVEL_3";

export interface AssignedRiskAgentProjection {
  __typename?: "AssignedRiskAgentProjection";
  firstName: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  lastName: Maybe<Scalars["String"]>;
}

export interface AttachmentUploadPresignedUrlProjection {
  __typename?: "AttachmentUploadPresignedUrlProjection";
  httpMethod: Maybe<Scalars["String"]>;
  internalFilename: Maybe<Scalars["String"]>;
  presignedUrl: Maybe<Scalars["String"]>;
}

export type AutomaticVerificationReason =
  | "BASED_ON_PREVIOUS_ORDER"
  | "HIGH_CREDIT_SCORE";

export interface AuxUserDto {
  __typename?: "AuxUserDTO";
  billingAddressId: Maybe<Scalars["UUID"]>;
  carRegistrationAddressId: Maybe<Scalars["UUID"]>;
  city: Maybe<Scalars["String"]>;
  country: Maybe<Scalars["String"]>;
  createdOn: Maybe<Scalars["String"]>;
  deliveryAddressId: Maybe<Scalars["UUID"]>;
  doiSentOn: Maybe<Scalars["String"]>;
  doiVerifiedOn: Maybe<Scalars["String"]>;
  email: Maybe<Scalars["String"]>;
  enabled: Scalars["Boolean"];
  firstName: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  lastName: Maybe<Scalars["String"]>;
  locale: Maybe<Scalars["String"]>;
  loginTime: Maybe<Scalars["String"]>;
  phone: Maybe<Scalars["String"]>;
  postcode: Maybe<Scalars["String"]>;
  registeredFrom: Maybe<Scalars["Int"]>;
  roles: Maybe<Array<Maybe<RoleDto>>>;
  salutation: Maybe<Scalars["Int"]>;
  state: Maybe<Scalars["String"]>;
  street: Maybe<Scalars["String"]>;
  streetNumber: Maybe<Scalars["String"]>;
  userRegistrationSource: Maybe<Scalars["String"]>;
  userType: Maybe<Scalars["Int"]>;
  verified: Scalars["Boolean"];
}

export interface AvailabilityConditionCreateProjectionInput {
  name: Scalars["String"];
  parameters: ConditionParametersProjectionInput;
  type: ConditionType;
}

export interface AvailabilityConditionProjection {
  __typename?: "AvailabilityConditionProjection";
  createdBy: Maybe<Scalars["UUID"]>;
  createdOn: Scalars["String"];
  id: Scalars["UUID"];
  name: Scalars["String"];
  parameters: ConditionParametersProjection;
  type: ConditionType;
  updatedBy: Maybe<Scalars["UUID"]>;
  updatedOn: Maybe<Scalars["String"]>;
}

export interface AvailabilityRuleCreateProjectionInput {
  active: Scalars["Boolean"];
  conditions: Array<InputMaybe<AvailabilityConditionCreateProjectionInput>>;
  expression: Scalars["Map_String_ObjectScalar"];
  productId: Scalars["UUID"];
}

export interface AvailabilityRuleProjection {
  __typename?: "AvailabilityRuleProjection";
  active: Scalars["Boolean"];
  conditions: Array<Maybe<AvailabilityConditionProjection>>;
  createdBy: Maybe<Scalars["UUID"]>;
  createdOn: Scalars["String"];
  expression: Scalars["Map_String_ObjectScalar"];
  id: Scalars["UUID"];
  productId: Scalars["UUID"];
  updatedBy: Maybe<Scalars["UUID"]>;
  updatedOn: Maybe<Scalars["String"]>;
}

export interface AvailabilityRuleUpdateProjectionInput {
  active: Scalars["Boolean"];
  conditions: Array<InputMaybe<AvailabilityConditionCreateProjectionInput>>;
  expression: Scalars["Map_String_ObjectScalar"];
}

export interface AwsImageUploadRequestProjectionInput {
  contentType?: InputMaybe<Scalars["String"]>;
}

export interface AwsImageUploadResponseProjection {
  __typename?: "AwsImageUploadResponseProjection";
  fileName: Maybe<Scalars["String"]>;
  formData: Maybe<Scalars["Map_String_StringScalar"]>;
  host: Maybe<Scalars["String"]>;
  id: Maybe<ImageIdDto>;
}

export interface AwsS3AdVideoPresignedUrlDto {
  __typename?: "AwsS3AdVideoPresignedUrlDTO";
  adId: Maybe<Scalars["UUID"]>;
  fileName: Maybe<Scalars["String"]>;
  httpMethod: Maybe<Scalars["String"]>;
  presignedUrl: Maybe<Scalars["String"]>;
  type: Maybe<Scalars["String"]>;
}

export interface AwsS3AdVideoPresignedUrlRequestDtoInput {
  adId?: InputMaybe<Scalars["UUID"]>;
  fileName?: InputMaybe<Scalars["String"]>;
  httpMethod?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
}

export interface AwsS3PresignedUrlDto {
  __typename?: "AwsS3PresignedUrlDTO";
  fileName: Maybe<Scalars["String"]>;
  httpMethod: Maybe<Scalars["String"]>;
  presignedUrl: Maybe<Scalars["String"]>;
}

export interface AwsS3PresignedUrlRequestDtoInput {
  fileName?: InputMaybe<Scalars["String"]>;
  httpMethod?: InputMaybe<Scalars["String"]>;
}

export interface AwsUploadCredentialsDto {
  __typename?: "AwsUploadCredentialsDTO";
  cdn: Maybe<Scalars["String"]>;
  fileName: Maybe<Scalars["String"]>;
  formData: Maybe<Scalars["Map_String_StringScalar"]>;
  host: Maybe<Scalars["String"]>;
}

export interface AwsUploadCredentialsProjection {
  __typename?: "AwsUploadCredentialsProjection";
  awsUploadCredentialsDTOS: Maybe<Array<Maybe<AwsUploadCredentialsDto>>>;
}

export interface AwsUploadCredentialsRequestDtoInput {
  documentTypeId?: InputMaybe<Scalars["UUID"]>;
  fileExt?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["UUID"]>;
}

export interface AwsUploadCredentialsRequestProjectionInput {
  uploadCredentialsRequestDTOs?: InputMaybe<
    Array<InputMaybe<AwsUploadCredentialsRequestDtoInput>>
  >;
}

export interface BpmTaxTaskProjection {
  __typename?: "BPMTaxTaskProjection";
  action: Maybe<Scalars["String"]>;
  assignedOn: Maybe<Scalars["String"]>;
  assignedTo: Maybe<UserProjection>;
  assignee: Maybe<UserProjection>;
  closedBy: Maybe<UserProjection>;
  closedOn: Maybe<Scalars["String"]>;
  comment: Maybe<Scalars["String"]>;
  commentsCount: Maybe<Scalars["Int"]>;
  completedBy: Maybe<UserProjection>;
  completedOn: Maybe<Scalars["String"]>;
  createdOn: Maybe<Scalars["String"]>;
  financingStatus: Maybe<Scalars["String"]>;
  flowVersion: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  name: Maybe<Scalars["String"]>;
  order: Maybe<OrderProjection>;
  orderId: Maybe<Scalars["UUID"]>;
  orderNumber: Maybe<Scalars["String"]>;
  orderState: Maybe<Scalars["String"]>;
  paymentStatus: Maybe<Scalars["String"]>;
  paymentType: Maybe<Scalars["String"]>;
  reason: Maybe<Scalars["String"]>;
  repeatAt: Maybe<Scalars["String"]>;
  sequenceIndex: Scalars["Int"];
  state: Maybe<Scalars["String"]>;
  stockNumber: Maybe<Scalars["String"]>;
  taskDefinitionKey: Maybe<Scalars["String"]>;
  taskStatus: Maybe<Scalars["String"]>;
  taskType: Maybe<Scalars["String"]>;
  taskUpdatedAt: Maybe<Scalars["String"]>;
}

export interface BankAccountDetailsProjection {
  __typename?: "BankAccountDetailsProjection";
  accountDataId: Maybe<Scalars["UUID"]>;
  bic: Maybe<Scalars["String"]>;
  holder: Maybe<Scalars["String"]>;
  iban: Maybe<Scalars["String"]>;
}

export interface BankAccountDetailsProjectionInput {
  accountDataId?: InputMaybe<Scalars["UUID"]>;
  bic?: InputMaybe<Scalars["String"]>;
  holder?: InputMaybe<Scalars["String"]>;
  iban?: InputMaybe<Scalars["String"]>;
}

export interface BankAccountDetailsWithDocumentsProjection {
  __typename?: "BankAccountDetailsWithDocumentsProjection";
  accountDataId: Maybe<Scalars["UUID"]>;
  bic: Maybe<Scalars["String"]>;
  documents: Maybe<Array<Maybe<RetailDocumentProjection>>>;
  holder: Maybe<Scalars["String"]>;
  iban: Maybe<Scalars["String"]>;
}

export interface BankDetailsPendingTaskProjection {
  __typename?: "BankDetailsPendingTaskProjection";
  action: Maybe<Scalars["String"]>;
  assignedOn: Maybe<Scalars["String"]>;
  assignedTo: Maybe<UserProjection>;
  assignee: Maybe<UserProjection>;
  claim: Maybe<RetailClaimProjection>;
  closedBy: Maybe<UserProjection>;
  closedOn: Maybe<Scalars["String"]>;
  comment: Maybe<Scalars["String"]>;
  commentsCount: Maybe<Scalars["Int"]>;
  completedBy: Maybe<UserProjection>;
  completedOn: Maybe<Scalars["String"]>;
  cost: Maybe<ClaimCostWrapperProjection>;
  createdOn: Maybe<Scalars["String"]>;
  financingStatus: Maybe<Scalars["String"]>;
  flowVersion: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  name: Maybe<Scalars["String"]>;
  order: Maybe<OrderProjection>;
  orderCanceledOn: Maybe<Scalars["String"]>;
  orderId: Maybe<Scalars["UUID"]>;
  orderNumber: Maybe<Scalars["String"]>;
  orderState: Maybe<Scalars["String"]>;
  paymentStatus: Maybe<Scalars["String"]>;
  paymentType: Maybe<Scalars["String"]>;
  reason: Maybe<Scalars["String"]>;
  refundAmount: Maybe<PriceDto>;
  repeatAt: Maybe<Scalars["String"]>;
  requestedAmount: Maybe<Scalars["Long"]>;
  sequenceIndex: Scalars["Int"];
  state: Maybe<Scalars["String"]>;
  stockNumber: Maybe<Scalars["String"]>;
  taskDefinitionKey: Maybe<Scalars["String"]>;
  taskStatus: Maybe<Scalars["String"]>;
  taskType: Maybe<Scalars["String"]>;
  taskUpdatedAt: Maybe<Scalars["String"]>;
  type: Maybe<Scalars["String"]>;
}

export interface BatchProjection_AwsS3AdVideoPresignedUrlRequestDtoInput {
  entities?: InputMaybe<
    Array<InputMaybe<AwsS3AdVideoPresignedUrlRequestDtoInput>>
  >;
}

export interface BatchProjection_AwsS3PresignedUrlRequestDtoInput {
  entities?: InputMaybe<Array<InputMaybe<AwsS3PresignedUrlRequestDtoInput>>>;
}

export interface BatchProjection_CarRegistrationActionImportCreateProjectionInput {
  entities?: InputMaybe<
    Array<InputMaybe<CarRegistrationActionImportCreateProjectionInput>>
  >;
}

export interface BatchProjection_ChatbotQuestionImportCreateProjectionInput {
  entities?: InputMaybe<
    Array<InputMaybe<ChatbotQuestionImportCreateProjectionInput>>
  >;
}

export interface BatchProjection_ClaimCostDistributionProjectionInput {
  entities?: InputMaybe<
    Array<InputMaybe<ClaimCostDistributionProjectionInput>>
  >;
}

export interface BatchProjection_HighlightProjectionInput {
  entities?: InputMaybe<Array<InputMaybe<HighlightProjectionInput>>>;
}

export interface BatchProjection_InventorySourcingImportCreateProjectionInput {
  entities?: InputMaybe<
    Array<InputMaybe<InventorySourcingImportCreateProjectionInput>>
  >;
}

export interface BatchProjection_OfflineLeadsNewsletterSubscriptionImportProjectionInput {
  entities?: InputMaybe<
    Array<InputMaybe<OfflineLeadsNewsletterSubscriptionImportProjectionInput>>
  >;
}

export interface BatchProjection_PriceUpdateItemImportCreateProjectionInput {
  entities?: InputMaybe<
    Array<InputMaybe<PriceUpdateItemImportCreateProjectionInput>>
  >;
}

export interface BatchProjection_ProfitAndLossImportCreateProjectionInput {
  entities?: InputMaybe<
    Array<InputMaybe<ProfitAndLossImportCreateProjectionInput>>
  >;
}

export interface BatchProjection_RetailAdDeliveryOptionProjection {
  __typename?: "BatchProjection_RetailAdDeliveryOptionProjection";
  entities: Maybe<Array<Maybe<RetailAdDeliveryOptionProjection>>>;
}

export interface BatchProjection_RetailDocumentInventoryImportCreateProjectionInput {
  entities?: InputMaybe<
    Array<InputMaybe<RetailDocumentInventoryImportCreateProjectionInput>>
  >;
}

export interface BatchProjection_RetailDocumentPackageUpdateImportProjectionInput {
  entities?: InputMaybe<
    Array<InputMaybe<RetailDocumentPackageUpdateImportProjectionInput>>
  >;
}

export interface BatchProjection_RetailInternalTransferPaymentProjection {
  __typename?: "BatchProjection_RetailInternalTransferPaymentProjection";
  entities: Maybe<Array<Maybe<RetailInternalTransferPaymentProjection>>>;
}

export interface BatchProjection_RetailPaymentImportCreateProjectionInput {
  entities?: InputMaybe<
    Array<InputMaybe<RetailPaymentImportCreateProjectionInput>>
  >;
}

export interface BatchProjection_RetailTransitionInternalTransferDtoInput {
  entities?: InputMaybe<
    Array<InputMaybe<RetailTransitionInternalTransferDtoInput>>
  >;
}

export interface BrakeConditionProjection {
  __typename?: "BrakeConditionProjection";
  condition: Maybe<Scalars["String"]>;
  position: Maybe<Scalars["String"]>;
}

export interface BranchDto {
  __typename?: "BranchDTO";
  address1: Maybe<Scalars["String"]>;
  address2: Maybe<Scalars["String"]>;
  city: Maybe<Scalars["String"]>;
  country: Maybe<Scalars["String"]>;
  deliveryETA: Maybe<RangeDto_Integer>;
  deliveryETADate: Maybe<Scalars["LocalDate"]>;
  distance: Maybe<Scalars["Float"]>;
  id: Maybe<Scalars["Int"]>;
  name: Maybe<Scalars["String"]>;
  properties: Maybe<Array<Maybe<Scalars["String"]>>>;
  street: Maybe<Scalars["String"]>;
  type: Maybe<Scalars["Int"]>;
  zipCode: Maybe<Scalars["String"]>;
}

export interface BranchDeliveryOptionRequestDtoInput {
  branchTypes?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  direction?: InputMaybe<Direction>;
  excludedBranches?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  first?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  pointToCalculateDistanceFrom?: InputMaybe<LatLngInput>;
  pointToSearchBranches?: InputMaybe<LatLngInput>;
}

export interface BranchEntryCheckCarSupportedProjection {
  __typename?: "BranchEntryCheckCarSupportedProjection";
  isSupported: Maybe<Scalars["Boolean"]>;
}

export interface BranchEntryCheckComplementaryProjection {
  __typename?: "BranchEntryCheckComplementaryProjection";
  adminUser: Maybe<Scalars["String"]>;
  deliveryDate: Maybe<Scalars["LocalDate"]>;
  deliveryLocation: Maybe<Scalars["String"]>;
  manufacturer: Maybe<Scalars["String"]>;
  model: Maybe<Scalars["String"]>;
  stockNumber: Maybe<Scalars["String"]>;
  vin: Maybe<Scalars["String"]>;
}

export interface BranchEntryCheckDetailsProjection {
  __typename?: "BranchEntryCheckDetailsProjection";
  adId: Maybe<Scalars["UUID"]>;
  country: Maybe<Scalars["String"]>;
  createdBy: Maybe<Scalars["UUID"]>;
  createdOn: Maybe<Scalars["LocalDateTime"]>;
  data: Maybe<BranchEntrySubmissionDto>;
  id: Maybe<Scalars["UUID"]>;
  images: Maybe<Scalars["Map_UUID_URLScalar"]>;
  location: Maybe<Scalars["String"]>;
  locationId: Maybe<Scalars["Int"]>;
  mostRecent: Scalars["Boolean"];
  orderId: Maybe<Scalars["UUID"]>;
  orderNumber: Maybe<Scalars["String"]>;
  plannedHandoverDate: Maybe<Scalars["LocalDate"]>;
  refId: Maybe<Scalars["UUID"]>;
  refNumber: Maybe<Scalars["String"]>;
  status: Maybe<BranchEntryCheckStatus>;
  statusSetBy: Maybe<Scalars["UUID"]>;
  statusSetOn: Maybe<Scalars["LocalDateTime"]>;
  stockNumber: Maybe<Scalars["String"]>;
  vin: Maybe<Scalars["String"]>;
}

export interface BranchEntryCheckSearchItemDto {
  __typename?: "BranchEntryCheckSearchItemDTO";
  adId: Maybe<Scalars["UUID"]>;
  country: Maybe<Scalars["String"]>;
  entryCheckDate: Maybe<Scalars["LocalDate"]>;
  id: Maybe<Scalars["UUID"]>;
  location: Maybe<Scalars["String"]>;
  orderId: Maybe<Scalars["UUID"]>;
  orderNumber: Maybe<Scalars["String"]>;
  plannedHandoverDate: Maybe<Scalars["LocalDate"]>;
  refId: Maybe<Scalars["UUID"]>;
  refNumber: Maybe<Scalars["String"]>;
  status: Maybe<BranchEntryCheckStatus>;
  stockNumber: Maybe<Scalars["String"]>;
  vin: Maybe<Scalars["String"]>;
}

export type BranchEntryCheckStatus = "APPROVED" | "PENDING" | "REJECTED";

export interface BranchEntryCheckSubmissionResultProjection {
  __typename?: "BranchEntryCheckSubmissionResultProjection";
  id: Maybe<Scalars["UUID"]>;
}

export interface BranchEntrySubmissionDto {
  __typename?: "BranchEntrySubmissionDTO";
  comment: Maybe<Scalars["String"]>;
  commentPhotos: Maybe<Array<Maybe<Scalars["UUID"]>>>;
  damages: Maybe<Array<Maybe<DamageDto>>>;
  exterior: Maybe<ExteriorDto>;
  general: Maybe<GeneralDto>;
  hasDamages: Maybe<Scalars["Boolean"]>;
  interior: Maybe<InteriorDto>;
}

export interface BranchEntrySubmissionDtoInput {
  comment?: InputMaybe<Scalars["String"]>;
  commentPhotos?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  damages?: InputMaybe<Array<InputMaybe<DamageDtoInput>>>;
  exterior?: InputMaybe<ExteriorDtoInput>;
  general?: InputMaybe<GeneralDtoInput>;
  hasDamages?: InputMaybe<Scalars["Boolean"]>;
  interior?: InputMaybe<InteriorDtoInput>;
}

export interface BranchForSelectionSearchProjectionInput {
  adId?: InputMaybe<Scalars["UUID"]>;
  branchTypes?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  distance?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  lat?: InputMaybe<Scalars["Float"]>;
  lng?: InputMaybe<Scalars["Float"]>;
  offset?: InputMaybe<Scalars["Int"]>;
}

export interface BranchProjection {
  __typename?: "BranchProjection";
  address1: Maybe<Scalars["String"]>;
  address2: Maybe<Scalars["String"]>;
  area: Maybe<Scalars["String"]>;
  branchHead: Maybe<Scalars["String"]>;
  capacityPlanningEnabled: Scalars["Boolean"];
  carShippingProvider: Maybe<Scalars["Int"]>;
  city: Maybe<Scalars["String"]>;
  country: Maybe<Scalars["String"]>;
  defaultDocumentStorageId: Maybe<Scalars["Int"]>;
  directionsHtml: Maybe<Scalars["String"]>;
  documentShippingProvider: Maybe<Scalars["Int"]>;
  gmapsLarge: Maybe<Scalars["String"]>;
  gmapsLink: Maybe<Scalars["String"]>;
  handoverAppointmentsEnabled: Scalars["Boolean"];
  handoverProtocolEnabled: Maybe<Scalars["Boolean"]>;
  id: Maybe<Scalars["Int"]>;
  lang: Maybe<Scalars["String"]>;
  lat: Maybe<Scalars["Float"]>;
  lng: Maybe<Scalars["Float"]>;
  name: Maybe<Scalars["String"]>;
  navigationInfo: Maybe<Scalars["String"]>;
  openingHoursMerchantText: Maybe<Scalars["String"]>;
  openingHoursText: Maybe<Scalars["String"]>;
  overbookingEnabled: Scalars["Boolean"];
  partner: Maybe<PartnerDto>;
  phone: Maybe<Scalars["String"]>;
  premiumHandlingEnabledOn: Maybe<Scalars["String"]>;
  priorityBookingEnabled: Scalars["Boolean"];
  privateSaleEnabled: Maybe<Scalars["Boolean"]>;
  refurbishmentPartnerId: Maybe<Scalars["Int"]>;
  status: Maybe<Scalars["Int"]>;
  street: Maybe<Scalars["String"]>;
  timeZone: Maybe<Scalars["String"]>;
  twilioRetriesEnabled: Maybe<Scalars["Boolean"]>;
  type: Maybe<Scalars["Int"]>;
  videoUploadEnabled: Scalars["Boolean"];
  zipcode: Maybe<Scalars["String"]>;
}

export interface BranchSearchingResult_DocumentBranchProjection {
  __typename?: "BranchSearchingResult_DocumentBranchProjection";
  hubId: Maybe<Scalars["Int"]>;
  nodes: Array<Maybe<DocumentBranchProjection>>;
  totalCount: Scalars["Long"];
}

export interface CamundaSearchProjection2Input {
  filter?: InputMaybe<Scalars["Map_String_ObjectScalar"]>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sorts?: InputMaybe<Array<InputMaybe<SortOrderProjectionInput>>>;
}

export interface CancelRequestDto {
  __typename?: "CancelRequestDTO";
  cancelPeriod: Maybe<Scalars["String"]>;
  cancelReason: Maybe<Scalars["String"]>;
  cancelType: Maybe<Scalars["String"]>;
  subCancelReason: Maybe<Scalars["String"]>;
}

export interface CancellationRequestedTaskProjection {
  __typename?: "CancellationRequestedTaskProjection";
  action: Maybe<Scalars["String"]>;
  assignedOn: Maybe<Scalars["String"]>;
  assignedTo: Maybe<UserProjection>;
  assignee: Maybe<UserProjection>;
  cancelPeriod: Maybe<Scalars["String"]>;
  cancelReason: Maybe<Scalars["String"]>;
  cancelType: Maybe<Scalars["String"]>;
  cancellationRequestedDate: Maybe<Scalars["String"]>;
  carHandoverOnDate: Maybe<Scalars["String"]>;
  closedBy: Maybe<UserProjection>;
  closedOn: Maybe<Scalars["String"]>;
  comment: Maybe<Scalars["String"]>;
  commentsCount: Maybe<Scalars["Int"]>;
  completedBy: Maybe<UserProjection>;
  completedOn: Maybe<Scalars["String"]>;
  createdOn: Maybe<Scalars["String"]>;
  documentsRegistrationReadyDate: Maybe<Scalars["String"]>;
  documentsRequestedDate: Maybe<Scalars["String"]>;
  finalDocumentHandoverDate: Maybe<Scalars["String"]>;
  financingStatus: Maybe<Scalars["String"]>;
  flowVersion: Maybe<Scalars["String"]>;
  hasTradeInItem: Maybe<Scalars["Boolean"]>;
  id: Maybe<Scalars["UUID"]>;
  name: Maybe<Scalars["String"]>;
  order: Maybe<OrderProjection>;
  orderFinanceRejectReasons: Maybe<
    Array<Maybe<OrderFinanceRejectReasonProjection>>
  >;
  orderId: Maybe<Scalars["UUID"]>;
  orderNumber: Maybe<Scalars["String"]>;
  orderState: Maybe<Scalars["String"]>;
  paymentStatus: Maybe<Scalars["String"]>;
  paymentType: Maybe<Scalars["String"]>;
  reason: Maybe<Scalars["String"]>;
  registrationInitiatedDate: Maybe<Scalars["String"]>;
  repeatAt: Maybe<Scalars["String"]>;
  returnDeliveryType: Maybe<Scalars["String"]>;
  sequenceIndex: Scalars["Int"];
  state: Maybe<Scalars["String"]>;
  stockNumber: Maybe<Scalars["String"]>;
  subCancelReason: Maybe<Scalars["String"]>;
  taskDefinitionKey: Maybe<Scalars["String"]>;
  taskStatus: Maybe<Scalars["String"]>;
  taskType: Maybe<Scalars["String"]>;
  taskUpdatedAt: Maybe<Scalars["String"]>;
}

export interface CancellationTaskProjection {
  __typename?: "CancellationTaskProjection";
  action: Maybe<Scalars["String"]>;
  assignedOn: Maybe<Scalars["String"]>;
  assignedTo: Maybe<UserProjection>;
  assignee: Maybe<UserProjection>;
  cancellationReasonComment: Maybe<Scalars["String"]>;
  cashOutReason: Maybe<Scalars["String"]>;
  cashOutRequestDate: Maybe<Scalars["String"]>;
  closedBy: Maybe<UserProjection>;
  closedOn: Maybe<Scalars["String"]>;
  comment: Maybe<Scalars["String"]>;
  commentsCount: Maybe<Scalars["Int"]>;
  completedBy: Maybe<UserProjection>;
  completedOn: Maybe<Scalars["String"]>;
  createdOn: Maybe<Scalars["String"]>;
  financingStatus: Maybe<Scalars["String"]>;
  flowVersion: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  name: Maybe<Scalars["String"]>;
  order: Maybe<OrderProjection>;
  orderId: Maybe<Scalars["UUID"]>;
  orderNumber: Maybe<Scalars["String"]>;
  orderState: Maybe<Scalars["String"]>;
  paymentStatus: Maybe<Scalars["String"]>;
  paymentType: Maybe<Scalars["String"]>;
  reason: Maybe<Scalars["String"]>;
  refundAmount: Maybe<PriceDto>;
  repeatAt: Maybe<Scalars["String"]>;
  sequenceIndex: Scalars["Int"];
  state: Maybe<Scalars["String"]>;
  stockNumber: Maybe<Scalars["String"]>;
  taskDefinitionKey: Maybe<Scalars["String"]>;
  taskStatus: Maybe<Scalars["String"]>;
  taskType: Maybe<Scalars["String"]>;
  taskUpdatedAt: Maybe<Scalars["String"]>;
}

export interface CarDtoInput {
  gps?: InputMaybe<GpsDtoInput>;
  hasFirstAidKit?: InputMaybe<Scalars["Boolean"]>;
  hasFloorMat?: InputMaybe<Scalars["Boolean"]>;
  hasKey?: InputMaybe<Scalars["Boolean"]>;
  hasSecondKey?: InputMaybe<Scalars["Boolean"]>;
  hasSecurityTriangle?: InputMaybe<Scalars["Boolean"]>;
  hasSpareTire?: InputMaybe<Scalars["Boolean"]>;
  hasTireFit?: InputMaybe<Scalars["Boolean"]>;
  hasTrunkCover?: InputMaybe<Scalars["Boolean"]>;
  hasWarningVest?: InputMaybe<Scalars["Boolean"]>;
  millage?: InputMaybe<Scalars["Int"]>;
  primaryWheels?: InputMaybe<WheelDtoInput>;
  radio?: InputMaybe<RadioDtoInput>;
  secondaryWheels?: InputMaybe<WheelDtoInput>;
}

export interface CarDetailsStoreAdPlainProjection {
  __typename?: "CarDetailsStoreAdPlainProjection";
  adId: Maybe<Scalars["UUID"]>;
  ahMainImageUrl: Maybe<Scalars["String"]>;
  builtYear: Maybe<Scalars["Int"]>;
  co2Value: Maybe<Scalars["Float"]>;
  demandScore: Maybe<Scalars["Float"]>;
  downPriceMargin: Maybe<Price>;
  drivetrain: Maybe<Scalars["String"]>;
  energyEfficiencyClass: Maybe<Scalars["String"]>;
  financedPrice: Maybe<Price>;
  firstFinancedPrice: Maybe<Price>;
  firstPrice: Maybe<Price>;
  fuelConsumption: Maybe<FuelConsumptionDto>;
  fuelType: Maybe<Scalars["Int"]>;
  gearType: Maybe<Scalars["Int"]>;
  isComingSoon: Maybe<Scalars["Boolean"]>;
  mainImageUrl: Maybe<Scalars["String"]>;
  make: Maybe<Scalars["String"]>;
  manufacturer: Maybe<Scalars["String"]>;
  mileage: Maybe<MileageDto>;
  model: Maybe<Scalars["String"]>;
  price: Maybe<Price>;
  published: Maybe<Scalars["Boolean"]>;
  registration: Maybe<Scalars["String"]>;
  retailCountry: Maybe<Scalars["String"]>;
  state: Maybe<Scalars["String"]>;
  stockNumber: Maybe<Scalars["String"]>;
  subType: Maybe<Scalars["String"]>;
  subTypeExtra: Maybe<Scalars["String"]>;
  updated: Maybe<Scalars["String"]>;
  vatType: Maybe<Scalars["Int"]>;
  vehicleId: Maybe<Scalars["Long"]>;
  vin: Maybe<Scalars["String"]>;
}

export interface CarRegistrationActionImportCreateProjectionInput {
  fileName?: InputMaybe<Scalars["String"]>;
}

export interface CarRegistrationActionProjection {
  __typename?: "CarRegistrationActionProjection";
  actionCanonical: Maybe<Scalars["String"]>;
  actionHumanReadable: Maybe<Scalars["String"]>;
  applied: Scalars["Boolean"];
  appliedOn: Maybe<Scalars["LocalDate"]>;
  canBeApplied: Scalars["Boolean"];
  eta: Maybe<Scalars["LocalDate"]>;
  reasonNotApplicable: Maybe<Scalars["String"]>;
}

export interface CarRegistrationItemProjection {
  __typename?: "CarRegistrationItemProjection";
  carRegistration: Maybe<RetailCarRegistrationProjection>;
  created: Maybe<Scalars["String"]>;
  currencyCode: Maybe<Scalars["String"]>;
  discountGrossMinorUnits: Maybe<Scalars["Long"]>;
  discountMinorUnits: Maybe<Scalars["Long"]>;
  externalId: Maybe<Scalars["UUID"]>;
  externalType: Maybe<Scalars["Int"]>;
  id: Maybe<Scalars["UUID"]>;
  invoiceDescription: Maybe<Scalars["String"]>;
  invoiceName: Maybe<Scalars["String"]>;
  orderId: Maybe<Scalars["UUID"]>;
  parentId: Maybe<Scalars["UUID"]>;
  priceDiscountGross: Maybe<PriceDto>;
  priceDiscountNet: Maybe<PriceDto>;
  priceDiscountVat: Maybe<PriceDto>;
  priceGross: Maybe<PriceDto>;
  priceGrossMinorUnits: Maybe<Scalars["Long"]>;
  priceNet: Maybe<PriceDto>;
  priceNetMinorUnits: Maybe<Scalars["Long"]>;
  priceTaxAdvantageAmount: Maybe<PriceDto>;
  priceVatAmount: Maybe<PriceDto>;
  type: Maybe<Scalars["String"]>;
  updated: Maybe<Scalars["String"]>;
  vatAmountMinorUnits: Maybe<Scalars["Long"]>;
  vatKey: Maybe<Scalars["String"]>;
  vatRate: Maybe<Scalars["Float"]>;
}

export interface CarRegistrationStatusDictionaryDto {
  __typename?: "CarRegistrationStatusDictionaryDTO";
  name: Maybe<Scalars["String"]>;
  status: Maybe<Scalars["String"]>;
}

export type CarRegistrationType =
  | "AH_DIGITAL_CAR_REGISTRATION"
  | "AH_DIGITAL_CAR_REGISTRATION_WITH_CUSTOM_PLATE"
  | "AH_REGISTRATION"
  | "AH_REGISTRATION_TEMPORARY"
  | "AH_REGISTRATION_WITH_LICENSE_PLATE"
  | "SELF_REGISTRATION";

export interface CarReturnHandoverAppointmentTaskProjection {
  __typename?: "CarReturnHandoverAppointmentTaskProjection";
  action: Maybe<Scalars["String"]>;
  assignedOn: Maybe<Scalars["String"]>;
  assignedTo: Maybe<UserProjection>;
  assignee: Maybe<UserProjection>;
  carReturnDate: Maybe<Scalars["String"]>;
  closedBy: Maybe<UserProjection>;
  closedOn: Maybe<Scalars["String"]>;
  comment: Maybe<Scalars["String"]>;
  commentsCount: Maybe<Scalars["Int"]>;
  completedBy: Maybe<UserProjection>;
  completedOn: Maybe<Scalars["String"]>;
  createdOn: Maybe<Scalars["String"]>;
  financingStatus: Maybe<Scalars["String"]>;
  flowVersion: Maybe<Scalars["String"]>;
  handoverDate: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  name: Maybe<Scalars["String"]>;
  order: Maybe<OrderProjection>;
  orderId: Maybe<Scalars["UUID"]>;
  orderNumber: Maybe<Scalars["String"]>;
  orderState: Maybe<Scalars["String"]>;
  paymentStatus: Maybe<Scalars["String"]>;
  paymentType: Maybe<Scalars["String"]>;
  phoneNumber: Maybe<Scalars["String"]>;
  reason: Maybe<Scalars["String"]>;
  repeatAt: Maybe<Scalars["String"]>;
  returnDeliveryType: Maybe<Scalars["String"]>;
  sequenceIndex: Scalars["Int"];
  state: Maybe<Scalars["String"]>;
  stockNumber: Maybe<Scalars["String"]>;
  taskDefinitionKey: Maybe<Scalars["String"]>;
  taskStatus: Maybe<Scalars["String"]>;
  taskType: Maybe<Scalars["String"]>;
  taskUpdatedAt: Maybe<Scalars["String"]>;
}

export interface CarReturnedTaskProjection {
  __typename?: "CarReturnedTaskProjection";
  action: Maybe<Scalars["String"]>;
  assignedOn: Maybe<Scalars["String"]>;
  assignedTo: Maybe<UserProjection>;
  assignee: Maybe<UserProjection>;
  carReturnDate: Maybe<Scalars["String"]>;
  closedBy: Maybe<UserProjection>;
  closedOn: Maybe<Scalars["String"]>;
  comment: Maybe<Scalars["String"]>;
  commentsCount: Maybe<Scalars["Int"]>;
  completedBy: Maybe<UserProjection>;
  completedOn: Maybe<Scalars["String"]>;
  createdOn: Maybe<Scalars["String"]>;
  financingStatus: Maybe<Scalars["String"]>;
  flowVersion: Maybe<Scalars["String"]>;
  handoverDate: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  name: Maybe<Scalars["String"]>;
  order: Maybe<OrderProjection>;
  orderId: Maybe<Scalars["UUID"]>;
  orderNumber: Maybe<Scalars["String"]>;
  orderState: Maybe<Scalars["String"]>;
  paymentStatus: Maybe<Scalars["String"]>;
  paymentType: Maybe<Scalars["String"]>;
  phoneNumber: Maybe<Scalars["String"]>;
  reason: Maybe<Scalars["String"]>;
  repeatAt: Maybe<Scalars["String"]>;
  returnDeliveryType: Maybe<Scalars["String"]>;
  sequenceIndex: Scalars["Int"];
  state: Maybe<Scalars["String"]>;
  stockNumber: Maybe<Scalars["String"]>;
  taskDefinitionKey: Maybe<Scalars["String"]>;
  taskStatus: Maybe<Scalars["String"]>;
  taskType: Maybe<Scalars["String"]>;
  taskUpdatedAt: Maybe<Scalars["String"]>;
}

export type CarType = "CTTE" | "VP";

export interface CarTypeBasedPriceModifierProjection {
  __typename?: "CarTypeBasedPriceModifierProjection";
  active: Scalars["Boolean"];
  carType: Scalars["String"];
  createdBy: Scalars["UUID"];
  createdOn: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  pnlCostPrice: Maybe<PriceDetailsProjection>;
  price: Maybe<PriceDetailsProjection>;
  priceMinorUnits: Scalars["Int"];
  priceModifierInstance: Scalars["String"];
  productId: Scalars["UUID"];
  updatedOn: Maybe<Scalars["String"]>;
}

export interface CarTypeBasedPriceModifierUpdateProjectionInput {
  active?: InputMaybe<Scalars["Boolean"]>;
  grossPriceMinorUnits?: InputMaybe<Scalars["Long"]>;
  pnlCostGrossPriceMinorUnits?: InputMaybe<Scalars["Long"]>;
  pnlCostVatKey?: InputMaybe<VatKeyType>;
  pnlCostVatPercentage?: InputMaybe<Scalars["Float"]>;
  priceMinorUnits?: InputMaybe<Scalars["Int"]>;
  vatKey?: InputMaybe<VatKeyType>;
  vatPercentage?: InputMaybe<Scalars["Float"]>;
}

export interface CareCallTaskProjection {
  __typename?: "CareCallTaskProjection";
  action: Maybe<Scalars["String"]>;
  assignedOn: Maybe<Scalars["String"]>;
  assignedTo: Maybe<UserProjection>;
  assignee: Maybe<UserProjection>;
  carHandoverOnDate: Maybe<Scalars["String"]>;
  carReservedOnDate: Maybe<Scalars["String"]>;
  closedBy: Maybe<UserProjection>;
  closedOn: Maybe<Scalars["String"]>;
  comment: Maybe<Scalars["String"]>;
  commentsCount: Maybe<Scalars["Int"]>;
  completedBy: Maybe<UserProjection>;
  completedOn: Maybe<Scalars["String"]>;
  createdOn: Maybe<Scalars["String"]>;
  customerName: Maybe<Scalars["String"]>;
  financingStatus: Maybe<Scalars["String"]>;
  flowVersion: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  name: Maybe<Scalars["String"]>;
  order: Maybe<OrderProjection>;
  orderContractSignedOn: Maybe<Scalars["String"]>;
  orderId: Maybe<Scalars["UUID"]>;
  orderNumber: Maybe<Scalars["String"]>;
  orderState: Maybe<Scalars["String"]>;
  paymentStatus: Maybe<Scalars["String"]>;
  paymentType: Maybe<Scalars["String"]>;
  phoneNumber: Maybe<Scalars["String"]>;
  reason: Maybe<Scalars["String"]>;
  repeatAt: Maybe<Scalars["String"]>;
  sequenceIndex: Scalars["Int"];
  state: Maybe<Scalars["String"]>;
  stockNumber: Maybe<Scalars["String"]>;
  taskDefinitionKey: Maybe<Scalars["String"]>;
  taskStatus: Maybe<Scalars["String"]>;
  taskType: Maybe<Scalars["String"]>;
  taskUpdatedAt: Maybe<Scalars["String"]>;
}

export interface CashOutRefundApprovalPendingTaskProjection {
  __typename?: "CashOutRefundApprovalPendingTaskProjection";
  action: Maybe<Scalars["String"]>;
  assignedOn: Maybe<Scalars["String"]>;
  assignedTo: Maybe<UserProjection>;
  assignee: Maybe<UserProjection>;
  cancellationReasonComment: Maybe<Scalars["String"]>;
  cashOutReason: Maybe<Scalars["String"]>;
  cashOutRequestDate: Maybe<Scalars["String"]>;
  closedBy: Maybe<UserProjection>;
  closedOn: Maybe<Scalars["String"]>;
  comment: Maybe<Scalars["String"]>;
  commentsCount: Maybe<Scalars["Int"]>;
  completedBy: Maybe<UserProjection>;
  completedOn: Maybe<Scalars["String"]>;
  createdOn: Maybe<Scalars["String"]>;
  financingStatus: Maybe<Scalars["String"]>;
  flowVersion: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  name: Maybe<Scalars["String"]>;
  order: Maybe<OrderProjection>;
  orderId: Maybe<Scalars["UUID"]>;
  orderNumber: Maybe<Scalars["String"]>;
  orderState: Maybe<Scalars["String"]>;
  paymentStatus: Maybe<Scalars["String"]>;
  paymentType: Maybe<Scalars["String"]>;
  reason: Maybe<Scalars["String"]>;
  refundAmount: Maybe<PriceDto>;
  repeatAt: Maybe<Scalars["String"]>;
  sequenceIndex: Scalars["Int"];
  state: Maybe<Scalars["String"]>;
  stockNumber: Maybe<Scalars["String"]>;
  taskDefinitionKey: Maybe<Scalars["String"]>;
  taskStatus: Maybe<Scalars["String"]>;
  taskType: Maybe<Scalars["String"]>;
  taskUpdatedAt: Maybe<Scalars["String"]>;
}

export interface CategorizedCustomEquipment {
  __typename?: "CategorizedCustomEquipment";
  categoryId: Maybe<Scalars["UUID"]>;
  customEquipments: Maybe<Array<Maybe<CustomEquipment>>>;
}

export interface CategorizedCustomEquipmentInput {
  categoryId?: InputMaybe<Scalars["UUID"]>;
  customEquipments?: InputMaybe<Array<InputMaybe<CustomEquipmentInput>>>;
}

export interface CategorizedEquipmentsDto {
  __typename?: "CategorizedEquipmentsDTO";
  categoryId: Maybe<Scalars["UUID"]>;
  categoryName: Maybe<Scalars["String"]>;
  items: Maybe<Array<Maybe<EquipmentItemDto>>>;
  name: Maybe<Scalars["String"]>;
  order: Maybe<Scalars["Int"]>;
  translationKey: Maybe<Scalars["String"]>;
}

export interface ChangeDocumentPackageStatusInput {
  deliveredOn?: InputMaybe<Scalars["String"]>;
  estimatedDeliveryDate?: InputMaybe<Scalars["String"]>;
  id: Scalars["UUID"];
  pickedUpOn?: InputMaybe<Scalars["String"]>;
  status: Scalars["String"];
}

export interface ChangeDocumentPackageStatusProjection {
  __typename?: "ChangeDocumentPackageStatusProjection";
  documentInventoryItems: Maybe<ResultConnection_DocumentInventoryItem>;
  documentInventoryPackages: Maybe<ResultConnection_DocumentInventoryPackageProjection>;
}

export type ChangeDocumentPackageStatusResult =
  | ChangeDocumentPackageStatusProjection
  | DocumentPackageAlreadyCanceledProblem
  | DocumentPackageAlreadyDeliveredProblem
  | DocumentPackageEmptyDeliveredOnProblem
  | DocumentPackageEmptyPickedUpOnProblem
  | DocumentPackageSameStatusProblem
  | DocumentPackagesGeneralProblem;

export interface ChangeInput {
  id?: InputMaybe<Scalars["UUID"]>;
  value: Scalars["Boolean"];
}

export interface ChangelogProjection {
  __typename?: "ChangelogProjection";
  action: Maybe<Scalars["String"]>;
  commentId: Maybe<Scalars["Int"]>;
  createdBy: Maybe<RetailUserDto>;
  createdOn: Maybe<Scalars["String"]>;
  field: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["Long"]>;
  newValue: Maybe<Scalars["String"]>;
  objectId: Maybe<Scalars["UUID"]>;
  objectType: Maybe<Scalars["String"]>;
  oldValue: Maybe<Scalars["String"]>;
  parentId: Maybe<Scalars["UUID"]>;
  parentType: Maybe<Scalars["String"]>;
}

export interface ChatbotQuestionCreateProjectionInput {
  answer?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  question?: InputMaybe<Scalars["String"]>;
}

export interface ChatbotQuestionDto {
  __typename?: "ChatbotQuestionDTO";
  answer: Maybe<Scalars["String"]>;
  country: Maybe<Scalars["String"]>;
  created: Maybe<Scalars["String"]>;
  createdBy: Maybe<Scalars["UUID"]>;
  enabled: Maybe<Scalars["Boolean"]>;
  id: Maybe<Scalars["UUID"]>;
  question: Maybe<Scalars["String"]>;
  updated: Maybe<Scalars["String"]>;
}

export interface ChatbotQuestionImportCreateProjectionInput {
  fileName?: InputMaybe<Scalars["String"]>;
}

export interface ChatbotQuestionUpdateProjectionInput {
  answer?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  question?: InputMaybe<Scalars["String"]>;
}

export interface CheckoutPaymentDetailsProjection {
  __typename?: "CheckoutPaymentDetailsProjection";
  directPaymentMethod: Maybe<Scalars["String"]>;
  directPaymentOption: Maybe<Scalars["String"]>;
}

export interface CheckoutPaymentDetailsUpdateProjectionInput {
  directPaymentOption?: InputMaybe<Scalars["String"]>;
}

export type CheckoutSource = "AUTOHERO" | "BACKOFFICE";

export interface ClaimChangelogResponseProjection {
  __typename?: "ClaimChangelogResponseProjection";
  claimId: Maybe<Scalars["UUID"]>;
  createdBy: Maybe<UserProjection>;
  createdOn: Maybe<Scalars["String"]>;
  field: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["Long"]>;
  newValue: Maybe<Scalars["String"]>;
  oldValue: Maybe<Scalars["String"]>;
  operation: Maybe<Scalars["String"]>;
  type: Maybe<Scalars["String"]>;
  typeNumber: Maybe<Scalars["String"]>;
}

export interface ClaimCostCompensationProjection {
  __typename?: "ClaimCostCompensationProjection";
  accountId: Maybe<Scalars["UUID"]>;
  approveStatus: Maybe<Scalars["String"]>;
  approvedBy: Maybe<UserProjection>;
  approvedOn: Maybe<Scalars["String"]>;
  claimCostNumber: Maybe<Scalars["String"]>;
  claimId: Maybe<Scalars["UUID"]>;
  costCurrencyCode: Maybe<Scalars["String"]>;
  costMinorUnits: Maybe<Scalars["Long"]>;
  createdBy: Maybe<UserProjection>;
  createdOn: Maybe<Scalars["String"]>;
  description: Maybe<Scalars["String"]>;
  editState: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  orderId: Maybe<Scalars["UUID"]>;
  retailOutboundPayment: Maybe<RetailOutboundPaymentProjection>;
}

export interface ClaimCostCompensationUpdateProjectionInput {
  accountDataId?: InputMaybe<Scalars["UUID"]>;
  approvalStatus?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  requestBankDetails?: InputMaybe<Scalars["Boolean"]>;
}

export interface ClaimCostDistributionProjectionInput {
  amountMinorUnits?: InputMaybe<Scalars["Long"]>;
  subClaimId?: InputMaybe<Scalars["UUID"]>;
}

export interface ClaimCostWrapperProjection {
  __typename?: "ClaimCostWrapperProjection";
  approvalLevel: Maybe<Scalars["String"]>;
  approvalLevelSetBy: Maybe<UserProjection>;
  approvalLevelSetOn: Maybe<Scalars["String"]>;
  costCurrencyCode: Maybe<Scalars["String"]>;
  costMinorUnits: Maybe<Scalars["Long"]>;
  costType: Maybe<Scalars["String"]>;
  costTypeCategory: Maybe<Scalars["String"]>;
  customerDecision: Maybe<Scalars["String"]>;
  customerDecisionSetBy: Maybe<UserProjection>;
  customerDecisionSetOn: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  level: Maybe<ApprovalLevel>;
  number: Maybe<Scalars["String"]>;
  orderItemId: Maybe<Scalars["UUID"]>;
}

export interface ClaimDetailsProjection {
  __typename?: "ClaimDetailsProjection";
  claimCostId: Maybe<Scalars["UUID"]>;
}

export interface ClaimDetailsProjectionInput {
  claimCostId?: InputMaybe<Scalars["UUID"]>;
}

export interface ClaimPartialRefundPendingTaskProjection {
  __typename?: "ClaimPartialRefundPendingTaskProjection";
  action: Maybe<Scalars["String"]>;
  assignedOn: Maybe<Scalars["String"]>;
  assignedTo: Maybe<UserProjection>;
  assignee: Maybe<UserProjection>;
  claim: Maybe<RetailClaimProjection>;
  closedBy: Maybe<UserProjection>;
  closedOn: Maybe<Scalars["String"]>;
  comment: Maybe<Scalars["String"]>;
  commentsCount: Maybe<Scalars["Int"]>;
  completedBy: Maybe<UserProjection>;
  completedOn: Maybe<Scalars["String"]>;
  cost: Maybe<ClaimCostWrapperProjection>;
  createdOn: Maybe<Scalars["String"]>;
  financingStatus: Maybe<Scalars["String"]>;
  flowVersion: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  name: Maybe<Scalars["String"]>;
  order: Maybe<OrderProjection>;
  orderId: Maybe<Scalars["UUID"]>;
  orderNumber: Maybe<Scalars["String"]>;
  orderState: Maybe<Scalars["String"]>;
  paymentStatus: Maybe<Scalars["String"]>;
  paymentType: Maybe<Scalars["String"]>;
  reason: Maybe<Scalars["String"]>;
  repeatAt: Maybe<Scalars["String"]>;
  sequenceIndex: Scalars["Int"];
  state: Maybe<Scalars["String"]>;
  stockNumber: Maybe<Scalars["String"]>;
  taskDefinitionKey: Maybe<Scalars["String"]>;
  taskStatus: Maybe<Scalars["String"]>;
  taskType: Maybe<Scalars["String"]>;
  taskUpdatedAt: Maybe<Scalars["String"]>;
}

export interface ClaimPartialRefundProjection {
  __typename?: "ClaimPartialRefundProjection";
  accountDataId: Maybe<Scalars["UUID"]>;
  accountType: Maybe<Scalars["String"]>;
  createdOn: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  state: Maybe<Scalars["String"]>;
  updatedOn: Maybe<Scalars["String"]>;
}

export interface ClaimProofPendingTaskProjection {
  __typename?: "ClaimProofPendingTaskProjection";
  action: Maybe<Scalars["String"]>;
  assignedOn: Maybe<Scalars["String"]>;
  assignedTo: Maybe<UserProjection>;
  assignee: Maybe<UserProjection>;
  claim: Maybe<RetailClaimProjection>;
  claimGroup: Maybe<RetailClaimGroupProjection>;
  closedBy: Maybe<UserProjection>;
  closedOn: Maybe<Scalars["String"]>;
  comment: Maybe<Scalars["String"]>;
  commentsCount: Maybe<Scalars["Int"]>;
  completedBy: Maybe<UserProjection>;
  completedOn: Maybe<Scalars["String"]>;
  createdOn: Maybe<Scalars["String"]>;
  financingStatus: Maybe<Scalars["String"]>;
  flowVersion: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  name: Maybe<Scalars["String"]>;
  order: Maybe<OrderProjection>;
  orderId: Maybe<Scalars["UUID"]>;
  orderNumber: Maybe<Scalars["String"]>;
  orderState: Maybe<Scalars["String"]>;
  paymentStatus: Maybe<Scalars["String"]>;
  paymentType: Maybe<Scalars["String"]>;
  reason: Maybe<Scalars["String"]>;
  repeatAt: Maybe<Scalars["String"]>;
  sequenceIndex: Scalars["Int"];
  state: Maybe<Scalars["String"]>;
  stockNumber: Maybe<Scalars["String"]>;
  taskDefinitionKey: Maybe<Scalars["String"]>;
  taskStatus: Maybe<Scalars["String"]>;
  taskType: Maybe<Scalars["String"]>;
  taskUpdatedAt: Maybe<Scalars["String"]>;
}

export interface ClaimProofSubmittedTaskProjection {
  __typename?: "ClaimProofSubmittedTaskProjection";
  action: Maybe<Scalars["String"]>;
  assignedOn: Maybe<Scalars["String"]>;
  assignedTo: Maybe<UserProjection>;
  assignee: Maybe<UserProjection>;
  claim: Maybe<RetailClaimProjection>;
  claimGroup: Maybe<RetailClaimGroupProjection>;
  closedBy: Maybe<UserProjection>;
  closedOn: Maybe<Scalars["String"]>;
  comment: Maybe<Scalars["String"]>;
  commentsCount: Maybe<Scalars["Int"]>;
  completedBy: Maybe<UserProjection>;
  completedOn: Maybe<Scalars["String"]>;
  createdOn: Maybe<Scalars["String"]>;
  financingStatus: Maybe<Scalars["String"]>;
  flowVersion: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  name: Maybe<Scalars["String"]>;
  order: Maybe<OrderProjection>;
  orderId: Maybe<Scalars["UUID"]>;
  orderNumber: Maybe<Scalars["String"]>;
  orderState: Maybe<Scalars["String"]>;
  paymentStatus: Maybe<Scalars["String"]>;
  paymentType: Maybe<Scalars["String"]>;
  reason: Maybe<Scalars["String"]>;
  repeatAt: Maybe<Scalars["String"]>;
  sequenceIndex: Scalars["Int"];
  state: Maybe<Scalars["String"]>;
  stockNumber: Maybe<Scalars["String"]>;
  taskDefinitionKey: Maybe<Scalars["String"]>;
  taskStatus: Maybe<Scalars["String"]>;
  taskType: Maybe<Scalars["String"]>;
  taskUpdatedAt: Maybe<Scalars["String"]>;
}

export interface ClosedReasonProjectionInput {
  description?: InputMaybe<Scalars["String"]>;
  reason?: InputMaybe<Scalars["String"]>;
}

export type ColorCoding = "GREEN" | "GREY" | "ORANGE" | "RED" | "YELLOW";

export interface ColorDto {
  __typename?: "ColorDTO";
  metallic: Maybe<Scalars["Boolean"]>;
  outside: Maybe<Scalars["Int"]>;
}

export interface CommentTagProjection {
  __typename?: "CommentTagProjection";
  name: Maybe<Scalars["String"]>;
  value: Maybe<Scalars["String"]>;
}

export interface CompileOrderEmailTemplateProjectionInput {
  orderId?: InputMaybe<Scalars["UUID"]>;
  placeholders?: InputMaybe<
    Array<InputMaybe<OrderHistoryEmailPlaceholderInput>>
  >;
  templateId?: InputMaybe<Scalars["UUID"]>;
}

export interface CompiledOrderEmailTemplateProjection {
  __typename?: "CompiledOrderEmailTemplateProjection";
  id: Maybe<Scalars["UUID"]>;
  locale: Maybe<Scalars["String"]>;
  market: Maybe<Scalars["String"]>;
  message: Maybe<Scalars["String"]>;
  project: Maybe<Scalars["String"]>;
  recipient: Maybe<Scalars["String"]>;
  replyRequired: Maybe<Scalars["Boolean"]>;
  subject: Maybe<Scalars["String"]>;
  title: Maybe<Scalars["String"]>;
  type: Maybe<Scalars["String"]>;
}

export interface CompositeImageProjection {
  __typename?: "CompositeImageProjection";
  adId: Maybe<Scalars["UUID"]>;
  createdAt: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  images: Maybe<Array<Maybe<ImageProjection>>>;
  lastModified: Maybe<Scalars["String"]>;
  type: Maybe<Scalars["String"]>;
}

export interface CompositeImageProjectionInput {
  adId?: InputMaybe<Scalars["UUID"]>;
  createdAt?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["UUID"]>;
  images?: InputMaybe<Array<InputMaybe<ImageProjectionInput>>>;
  lastModified?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
}

export interface ConditionParametersProjection {
  __typename?: "ConditionParametersProjection";
  abTestName: Maybe<Scalars["String"]>;
  featureName: Maybe<Scalars["String"]>;
  featureUsageDateSource: Maybe<FeatureUsageDateSource>;
  registrationCountry: Maybe<Scalars["String"]>;
}

export interface ConditionParametersProjectionInput {
  abTestName?: InputMaybe<Scalars["String"]>;
  featureName?: InputMaybe<Scalars["String"]>;
  featureUsageDateSource?: InputMaybe<FeatureUsageDateSource>;
  registrationCountry?: InputMaybe<Scalars["String"]>;
}

export type ConditionType = "AB_TEST" | "FEATURE" | "REGISTRATION_COUNTRY";

export interface CreateDocumentInventoryTypeInput {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
}

export interface CreateDocumentInventoryTypePayload {
  __typename?: "CreateDocumentInventoryTypePayload";
  clientMutationId: Maybe<Scalars["String"]>;
  documentInventoryType: Maybe<DocumentInventoryType>;
  errors: Maybe<CreateDocumentInventoryTypeValidationError>;
  query: Maybe<GqlRetailCarDocumentsService>;
}

export interface CreateDocumentInventoryTypeValidationError {
  __typename?: "CreateDocumentInventoryTypeValidationError";
  message: Maybe<Scalars["String"]>;
  value: Maybe<JsonObject>;
}

export interface CreateDocumentPackageInput {
  deliveryAddress: DocumentInventoryPackageAddressInput;
  documentCarItems: Array<InputMaybe<Scalars["UUID"]>>;
  orderId: Scalars["UUID"];
  shippingProvider: Scalars["Int"];
  trackingCode?: InputMaybe<Scalars["String"]>;
}

export interface CreateDocumentPackageProjection {
  __typename?: "CreateDocumentPackageProjection";
  documentInventoryPackages: ResultConnection_DocumentInventoryPackageProjection;
}

export type CreateDocumentPackageResult =
  | CreateDocumentPackageProjection
  | DocumentItemsInPackageProblem
  | DocumentPackageConflictProblem
  | DocumentPackageEmptyBranchNameProblem
  | DocumentPackageEmptyFirstNameProblem
  | DocumentPackageEmptyLastNameProblem
  | DocumentPackageNoHouseNumAddressProblem
  | DocumentPackageZeroItemsProblem
  | DocumentPackagesGeneralProblem;

export interface CreateExternalFinanceRiskEvaluationProjectionInput {
  contractType?: InputMaybe<Scalars["String"]>;
  employmentStatus?: InputMaybe<Scalars["String"]>;
  monthlyNetIncomeMinorUnits?: InputMaybe<Scalars["Long"]>;
  sameEmployerOverTimePeriod?: InputMaybe<Scalars["Boolean"]>;
}

export interface CreateExternalRiskUserInfoProjectionInput {
  accountHolder?: InputMaybe<Scalars["String"]>;
  additionalIncomeMinorUnits?: InputMaybe<Scalars["Long"]>;
  bankAccountOpenDate?: InputMaybe<Scalars["String"]>;
  contractValidFor?: InputMaybe<Scalars["Boolean"]>;
  employedBy?: InputMaybe<Scalars["String"]>;
  employmentContractType?: InputMaybe<Scalars["String"]>;
  employmentEndDate?: InputMaybe<Scalars["String"]>;
  employmentStartDate?: InputMaybe<Scalars["String"]>;
  employmentStatus?: InputMaybe<Scalars["String"]>;
  employmentType?: InputMaybe<Scalars["String"]>;
  housingConstruction?: InputMaybe<Scalars["String"]>;
  housingType?: InputMaybe<Scalars["String"]>;
  iban?: InputMaybe<Scalars["String"]>;
  incomeFromSubsidiesMinorUnits?: InputMaybe<Scalars["Long"]>;
  incomeSpread?: InputMaybe<Scalars["Int"]>;
  jobTitle?: InputMaybe<Scalars["String"]>;
  maritalStatus?: InputMaybe<Scalars["String"]>;
  mortgageOrRentPaymentMinorUnits?: InputMaybe<Scalars["Long"]>;
  movingDate?: InputMaybe<Scalars["String"]>;
  nationality?: InputMaybe<Scalars["String"]>;
  numberOfDependents?: InputMaybe<Scalars["Int"]>;
  numberOfOtherDependents?: InputMaybe<Scalars["Int"]>;
  numberOfOtherLoans?: InputMaybe<Scalars["Int"]>;
  otherLoanPaymentsMinorUnits?: InputMaybe<Scalars["Long"]>;
  pep?: InputMaybe<Scalars["Boolean"]>;
  placeOfBirth?: InputMaybe<Scalars["String"]>;
  previousAddressMovingDate?: InputMaybe<Scalars["String"]>;
  primaryIncomeMinorUnits?: InputMaybe<Scalars["Long"]>;
  residentSinceDate?: InputMaybe<Scalars["String"]>;
  sameEmployerOverTimePeriod?: InputMaybe<Scalars["Boolean"]>;
  sector?: InputMaybe<Scalars["String"]>;
  seniority?: InputMaybe<Scalars["String"]>;
  separationAllowanceMinorUnits?: InputMaybe<Scalars["Long"]>;
  separationOfGoodsInMarriage?: InputMaybe<Scalars["Boolean"]>;
  type?: InputMaybe<Scalars["String"]>;
  userId: Scalars["UUID"];
}

export interface CreateOrUpdateExternalFinancingCustomerDetailsProjectionInput {
  bankAccountOpenDate?: InputMaybe<Scalars["LocalDate"]>;
  currentJobStartDate?: InputMaybe<Scalars["LocalDate"]>;
  housing?: InputMaybe<Scalars["String"]>;
  iban?: InputMaybe<Scalars["String"]>;
  idCardNumber?: InputMaybe<Scalars["String"]>;
  idExpiryDate?: InputMaybe<Scalars["LocalDate"]>;
  idType?: InputMaybe<Scalars["String"]>;
  incomeCurrency?: InputMaybe<Scalars["String"]>;
  isCompanyRegisteredLongEnough?: InputMaybe<Scalars["Boolean"]>;
  maritalStatus?: InputMaybe<Scalars["String"]>;
  moveIntoCurrentHomeDate?: InputMaybe<Scalars["LocalDate"]>;
  taxCode?: InputMaybe<Scalars["String"]>;
}

export interface CreateOrUpdateExternalFinancingOptionsProjectionInput {
  ahOpeningFees?: InputMaybe<Scalars["BigDecimal"]>;
  damageInsurance?: InputMaybe<Scalars["String"]>;
  externalFinancingRefNumber?: InputMaybe<Scalars["String"]>;
  financingProvider?: InputMaybe<Scalars["String"]>;
  gapInsurance?: InputMaybe<Scalars["String"]>;
  ppi?: InputMaybe<Scalars["String"]>;
}

export interface CreateOrderHistoryEmailEventProjectionInput {
  attachments?: InputMaybe<
    Array<InputMaybe<OrderEmailEventAttachmentCreateProjectionInput>>
  >;
  customerEmail?: InputMaybe<Scalars["String"]>;
  message?: InputMaybe<Scalars["String"]>;
  orderId?: InputMaybe<Scalars["UUID"]>;
  recipientEmail?: InputMaybe<Scalars["String"]>;
  replyRequired?: InputMaybe<Scalars["Boolean"]>;
  subject?: InputMaybe<Scalars["String"]>;
  templateId?: InputMaybe<Scalars["UUID"]>;
  templateName?: InputMaybe<Scalars["String"]>;
}

export interface CreateRetailFeatureProjectionInput {
  condition?: InputMaybe<Scalars["Map_String_ObjectScalar"]>;
  description?: InputMaybe<Scalars["String"]>;
  environment?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
}

export interface CreateRetailOutboundPaymentProjectionInput {
  accountDataId?: InputMaybe<Scalars["UUID"]>;
  accountDetails?: InputMaybe<RetailAccountDetailsCreateProjectionInput>;
  accountingDate?: InputMaybe<Scalars["String"]>;
  amountMinorUnits?: InputMaybe<Scalars["Long"]>;
  description?: InputMaybe<Scalars["String"]>;
  orderId?: InputMaybe<Scalars["UUID"]>;
  paymentIds?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  paymentType?: InputMaybe<Scalars["String"]>;
  purpose?: InputMaybe<RetailOutboundPaymentPurpose>;
  reason?: InputMaybe<RetailOutboundPaymentReasonProjectionInput>;
  status?: InputMaybe<RetailOutboundPaymentStatus>;
}

export interface CreditNoteCreateProjectionInput {
  items?: InputMaybe<Array<InputMaybe<CreditNoteItemCreateProjectionInput>>>;
  orderId?: InputMaybe<Scalars["UUID"]>;
  reason?: InputMaybe<Scalars["String"]>;
}

export interface CreditNoteDistributionProjection {
  __typename?: "CreditNoteDistributionProjection";
  amount: Maybe<RetailFinancePriceProjection>;
  id: Maybe<Scalars["UUID"]>;
  items: Maybe<Array<Maybe<CreditNoteItemDistributionProjection>>>;
  reason: Maybe<Scalars["String"]>;
  tempId: Maybe<Scalars["Int"]>;
}

export interface CreditNoteDistributionProjectionInput {
  amount?: InputMaybe<RetailFinancePriceProjectionInput>;
  id?: InputMaybe<Scalars["UUID"]>;
  items?: InputMaybe<
    Array<InputMaybe<CreditNoteItemDistributionProjectionInput>>
  >;
  reason?: InputMaybe<Scalars["String"]>;
  tempId?: InputMaybe<Scalars["Int"]>;
}

export interface CreditNoteItemCreateProjectionInput {
  amountMinorUnits: Scalars["Long"];
  invoiceId?: InputMaybe<Scalars["UUID"]>;
  orderItemId?: InputMaybe<Scalars["UUID"]>;
}

export interface CreditNoteItemDistributionProjection {
  __typename?: "CreditNoteItemDistributionProjection";
  amountMinorUnits: Maybe<Scalars["Long"]>;
  creditNoteItemId: Maybe<Scalars["UUID"]>;
  invoiceId: Maybe<Scalars["UUID"]>;
  orderItemId: Maybe<Scalars["UUID"]>;
}

export interface CreditNoteItemDistributionProjectionInput {
  amountMinorUnits?: InputMaybe<Scalars["Long"]>;
  creditNoteItemId?: InputMaybe<Scalars["UUID"]>;
  invoiceId?: InputMaybe<Scalars["UUID"]>;
  orderItemId?: InputMaybe<Scalars["UUID"]>;
}

export interface CreditNoteItemProjection {
  __typename?: "CreditNoteItemProjection";
  amount: Maybe<PriceDto>;
  createdBy: Maybe<Scalars["UUID"]>;
  createdOn: Maybe<Scalars["String"]>;
  externalId: Maybe<Scalars["UUID"]>;
  externalType: Maybe<InvoiceItemExternalType>;
  id: Maybe<Scalars["UUID"]>;
  vatKey: Maybe<Scalars["String"]>;
}

export interface CreditNoteNumberPendingTaskProjection {
  __typename?: "CreditNoteNumberPendingTaskProjection";
  action: Maybe<Scalars["String"]>;
  assignedOn: Maybe<Scalars["String"]>;
  assignedTo: Maybe<UserProjection>;
  assignee: Maybe<UserProjection>;
  cancellationReasonComment: Maybe<Scalars["String"]>;
  cashOutReason: Maybe<Scalars["String"]>;
  cashOutRequestDate: Maybe<Scalars["String"]>;
  closedBy: Maybe<UserProjection>;
  closedOn: Maybe<Scalars["String"]>;
  comment: Maybe<Scalars["String"]>;
  commentsCount: Maybe<Scalars["Int"]>;
  completedBy: Maybe<UserProjection>;
  completedOn: Maybe<Scalars["String"]>;
  createdOn: Maybe<Scalars["String"]>;
  financingStatus: Maybe<Scalars["String"]>;
  flowVersion: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  name: Maybe<Scalars["String"]>;
  order: Maybe<OrderProjection>;
  orderId: Maybe<Scalars["UUID"]>;
  orderNumber: Maybe<Scalars["String"]>;
  orderState: Maybe<Scalars["String"]>;
  paymentStatus: Maybe<Scalars["String"]>;
  paymentType: Maybe<Scalars["String"]>;
  reason: Maybe<Scalars["String"]>;
  refundAmount: Maybe<PriceDto>;
  repeatAt: Maybe<Scalars["String"]>;
  sequenceIndex: Scalars["Int"];
  state: Maybe<Scalars["String"]>;
  stockNumber: Maybe<Scalars["String"]>;
  taskDefinitionKey: Maybe<Scalars["String"]>;
  taskStatus: Maybe<Scalars["String"]>;
  taskType: Maybe<Scalars["String"]>;
  taskUpdatedAt: Maybe<Scalars["String"]>;
}

export interface CreditNoteOutboundPaymentRefDistributionProjection {
  __typename?: "CreditNoteOutboundPaymentRefDistributionProjection";
  amount: Maybe<RetailFinancePriceProjection>;
  creditNoteTempId: Maybe<Scalars["Int"]>;
  refundTempId: Maybe<Scalars["Int"]>;
}

export interface CreditNoteOutboundPaymentRefDistributionProjectionInput {
  amount?: InputMaybe<RetailFinancePriceProjectionInput>;
  creditNoteTempId?: InputMaybe<Scalars["Int"]>;
  refundTempId?: InputMaybe<Scalars["Int"]>;
}

export interface CreditNoteOutboundPaymentRefProjection {
  __typename?: "CreditNoteOutboundPaymentRefProjection";
  amountMinorUnits: Maybe<Scalars["Long"]>;
  id: Maybe<Scalars["UUID"]>;
  outboundPayment: Maybe<RetailOutboundPaymentProjection>;
}

export interface CreditNotePendingTaskProjection {
  __typename?: "CreditNotePendingTaskProjection";
  action: Maybe<Scalars["String"]>;
  assignedOn: Maybe<Scalars["String"]>;
  assignedTo: Maybe<UserProjection>;
  assignee: Maybe<UserProjection>;
  cancellationReasonComment: Maybe<Scalars["String"]>;
  cashOutReason: Maybe<Scalars["String"]>;
  cashOutRequestDate: Maybe<Scalars["String"]>;
  closedBy: Maybe<UserProjection>;
  closedOn: Maybe<Scalars["String"]>;
  comment: Maybe<Scalars["String"]>;
  commentsCount: Maybe<Scalars["Int"]>;
  completedBy: Maybe<UserProjection>;
  completedOn: Maybe<Scalars["String"]>;
  createdOn: Maybe<Scalars["String"]>;
  financingStatus: Maybe<Scalars["String"]>;
  flowVersion: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  name: Maybe<Scalars["String"]>;
  order: Maybe<OrderProjection>;
  orderId: Maybe<Scalars["UUID"]>;
  orderNumber: Maybe<Scalars["String"]>;
  orderState: Maybe<Scalars["String"]>;
  paymentStatus: Maybe<Scalars["String"]>;
  paymentType: Maybe<Scalars["String"]>;
  reason: Maybe<Scalars["String"]>;
  refundAmount: Maybe<PriceDto>;
  repeatAt: Maybe<Scalars["String"]>;
  sequenceIndex: Scalars["Int"];
  state: Maybe<Scalars["String"]>;
  stockNumber: Maybe<Scalars["String"]>;
  taskDefinitionKey: Maybe<Scalars["String"]>;
  taskStatus: Maybe<Scalars["String"]>;
  taskType: Maybe<Scalars["String"]>;
  taskUpdatedAt: Maybe<Scalars["String"]>;
}

export interface CreditNoteProjection {
  __typename?: "CreditNoteProjection";
  createdBy: Maybe<Scalars["UUID"]>;
  createdOn: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  invoiceId: Maybe<Scalars["UUID"]>;
  invoicedOn: Maybe<Scalars["String"]>;
  items: Maybe<Array<Maybe<CreditNoteItemProjection>>>;
  number: Maybe<Scalars["String"]>;
  oracleAccountingDate: Maybe<Scalars["String"]>;
  oracleFilePresignedUrl: Maybe<Scalars["String"]>;
  oracleGrossAmount: Maybe<PriceDto>;
  oracleIssueDate: Maybe<Scalars["String"]>;
  oracleTransactionalNumber: Maybe<Scalars["String"]>;
  orderId: Maybe<Scalars["UUID"]>;
  outboundPaymentRefs: Maybe<
    Array<Maybe<CreditNoteOutboundPaymentRefProjection>>
  >;
  reason: Maybe<Scalars["String"]>;
  state: Maybe<InvoiceState>;
  totalAmount: Maybe<PriceDto>;
  updatedBy: Maybe<Scalars["UUID"]>;
  updatedOn: Maybe<Scalars["String"]>;
  vatKey: Maybe<Scalars["String"]>;
}

export interface CreditNoteUpdateProjectionInput {
  invoicedOn?: InputMaybe<Scalars["String"]>;
  number?: InputMaybe<Scalars["String"]>;
}

export interface CreditRiskDataDto {
  __typename?: "CreditRiskDataDTO";
  averageDaysOverdrawn: Maybe<Scalars["Int"]>;
  countryCode: Maybe<Scalars["String"]>;
  creditRiskRate: Maybe<Scalars["Float"]>;
  creditScore: Maybe<Scalars["Int"]>;
  creditScoreProviderType: Maybe<Scalars["String"]>;
  creditScoreRange: Maybe<Scalars["String"]>;
  encashmentPayments: Maybe<AmountInfo>;
  gamblingExpenses: Maybe<AmountInfo>;
  hasCreditCard: Maybe<Scalars["Boolean"]>;
  monthlyNetIncome: Maybe<AmountInfo>;
  unemployedBenefits: Maybe<AmountInfo>;
}

export interface CreditScoreResponseDataProjection {
  __typename?: "CreditScoreResponseDataProjection";
  provider: Maybe<Scalars["String"]>;
  riskRate: Maybe<Scalars["Float"]>;
  scoreRange: Maybe<Scalars["String"]>;
  scoreValue: Maybe<Scalars["Int"]>;
}

export interface CurrencyCodeProjection {
  __typename?: "CurrencyCodeProjection";
  country: Maybe<Array<Maybe<Scalars["String"]>>>;
  description: Maybe<Scalars["String"]>;
}

export interface CustomEquipment {
  __typename?: "CustomEquipment";
  groupId: Maybe<Scalars["Long"]>;
  locale: Maybe<Scalars["String"]>;
  name: Maybe<Scalars["String"]>;
}

export interface CustomEquipmentInput {
  groupId?: InputMaybe<Scalars["Long"]>;
  locale?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
}

export interface CustomerAddressCreateProjectionInput {
  additionalAddress?: InputMaybe<Scalars["String"]>;
  city?: InputMaybe<Scalars["String"]>;
  company?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  floor?: InputMaybe<Scalars["String"]>;
  houseNumber?: InputMaybe<Scalars["String"]>;
  info?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  street?: InputMaybe<Scalars["String"]>;
  zipcode?: InputMaybe<Scalars["String"]>;
}

export interface CustomerAddressProjection {
  __typename?: "CustomerAddressProjection";
  additionalAddress: Maybe<Scalars["String"]>;
  city: Maybe<Scalars["String"]>;
  company: Maybe<Scalars["String"]>;
  country: Maybe<Scalars["String"]>;
  firstName: Maybe<Scalars["String"]>;
  floor: Maybe<Scalars["String"]>;
  houseNumber: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  info: Maybe<Scalars["String"]>;
  lastName: Maybe<Scalars["String"]>;
  phone: Maybe<Scalars["String"]>;
  street: Maybe<Scalars["String"]>;
  userId: Maybe<Scalars["UUID"]>;
  zipcode: Maybe<Scalars["String"]>;
}

export interface CustomerAddressUpdateProjectionInput {
  additionalAddress?: InputMaybe<Scalars["String"]>;
  city?: InputMaybe<Scalars["String"]>;
  company?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  floor?: InputMaybe<Scalars["String"]>;
  houseNumber?: InputMaybe<Scalars["String"]>;
  info?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  street?: InputMaybe<Scalars["String"]>;
  zipcode?: InputMaybe<Scalars["String"]>;
}

export interface CustomerDtoInput {
  hasPowerOfAttorneyForPickUp?: InputMaybe<Scalars["Boolean"]>;
  idNumber?: InputMaybe<Scalars["String"]>;
}

export interface CustomerExpectedMaxEtaData {
  __typename?: "CustomerExpectedMaxETAData";
  customerExpectedMaxETA: Maybe<Scalars["String"]>;
  customerExpectedMaxETACalculatedOn: Maybe<Scalars["String"]>;
  customerExpectedMaxETAChangeCount: Maybe<Scalars["Int"]>;
  initialCustomerExpectedMaxETA: Maybe<Scalars["String"]>;
}

export interface CustomerProjection {
  __typename?: "CustomerProjection";
  billingAddress: Maybe<CustomerAddressProjection>;
  carRegistrationAddress: Maybe<CustomerAddressProjection>;
  country: Maybe<Scalars["String"]>;
  createdBy: Maybe<Scalars["UUID"]>;
  createdOn: Maybe<Scalars["String"]>;
  deliveryAddress: Maybe<CustomerAddressProjection>;
  email: Maybe<Scalars["String"]>;
  enabled: Scalars["Boolean"];
  firstName: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  lastName: Maybe<Scalars["String"]>;
  locale: Maybe<Scalars["String"]>;
  loginTime: Maybe<Scalars["String"]>;
  phone: Maybe<Scalars["String"]>;
  registeredFrom: Maybe<Scalars["Int"]>;
  salutation: Maybe<Scalars["Int"]>;
  userType: Maybe<Scalars["Int"]>;
  verified: Scalars["Boolean"];
}

export interface CustomerSearchProjection {
  __typename?: "CustomerSearchProjection";
  email: Maybe<Scalars["String"]>;
  firstName: Maybe<Scalars["String"]>;
  lastName: Maybe<Scalars["String"]>;
}

export interface CustomerUserCreateProjectionInput {
  country?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  locale?: InputMaybe<Scalars["String"]>;
  password?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  registeredFrom?: InputMaybe<Scalars["Int"]>;
}

export interface CustomerUserUpdateProjectionInput {
  billingAddressId?: InputMaybe<Scalars["UUID"]>;
  carRegistrationAddressId?: InputMaybe<Scalars["UUID"]>;
  country?: InputMaybe<Scalars["String"]>;
  deliveryAddressId?: InputMaybe<Scalars["UUID"]>;
  email?: InputMaybe<Scalars["String"]>;
  enabled?: InputMaybe<Scalars["Boolean"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  locale?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  registeredFrom?: InputMaybe<Scalars["Int"]>;
  salutation?: InputMaybe<Scalars["Int"]>;
  verified?: InputMaybe<Scalars["Boolean"]>;
}

export interface DamageCompensationPendingTaskProjection {
  __typename?: "DamageCompensationPendingTaskProjection";
  action: Maybe<Scalars["String"]>;
  assignedOn: Maybe<Scalars["String"]>;
  assignedTo: Maybe<UserProjection>;
  assignee: Maybe<UserProjection>;
  claim: Maybe<RetailClaimProjection>;
  closedBy: Maybe<UserProjection>;
  closedOn: Maybe<Scalars["String"]>;
  comment: Maybe<Scalars["String"]>;
  commentsCount: Maybe<Scalars["Int"]>;
  completedBy: Maybe<UserProjection>;
  completedOn: Maybe<Scalars["String"]>;
  cost: Maybe<ClaimCostWrapperProjection>;
  createdOn: Maybe<Scalars["String"]>;
  damageCompensationDescription: Maybe<Scalars["String"]>;
  financingStatus: Maybe<Scalars["String"]>;
  flowVersion: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  name: Maybe<Scalars["String"]>;
  order: Maybe<OrderProjection>;
  orderId: Maybe<Scalars["UUID"]>;
  orderNumber: Maybe<Scalars["String"]>;
  orderState: Maybe<Scalars["String"]>;
  paymentStatus: Maybe<Scalars["String"]>;
  paymentType: Maybe<Scalars["String"]>;
  reason: Maybe<Scalars["String"]>;
  repeatAt: Maybe<Scalars["String"]>;
  sequenceIndex: Scalars["Int"];
  state: Maybe<Scalars["String"]>;
  stockNumber: Maybe<Scalars["String"]>;
  taskDefinitionKey: Maybe<Scalars["String"]>;
  taskStatus: Maybe<Scalars["String"]>;
  taskType: Maybe<Scalars["String"]>;
  taskUpdatedAt: Maybe<Scalars["String"]>;
}

export interface DamageDto {
  __typename?: "DamageDTO";
  carPart: Maybe<Scalars["String"]>;
  comment: Maybe<Scalars["String"]>;
  damageType: Maybe<Scalars["String"]>;
  photo: Maybe<Scalars["UUID"]>;
}

export interface DamageDtoInput {
  carPart?: InputMaybe<Scalars["String"]>;
  comment?: InputMaybe<Scalars["String"]>;
  damageType?: InputMaybe<Scalars["String"]>;
  photo?: InputMaybe<Scalars["UUID"]>;
}

export interface DamageImageDto {
  __typename?: "DamageImageDTO";
  fullUrl: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
}

export interface DamageTypeProjection {
  __typename?: "DamageTypeProjection";
  translationKey: Maybe<Scalars["String"]>;
}

export interface DamagesProjection {
  __typename?: "DamagesProjection";
  brakes: Maybe<Array<Maybe<BrakeConditionProjection>>>;
  retailAd: Maybe<Array<Maybe<RetailAdDamageProjection>>>;
}

export interface DataValidationDto {
  __typename?: "DataValidationDTO";
  errors: Maybe<Scalars["Json"]>;
  input: Maybe<Scalars["Json"]>;
  processable: Scalars["Boolean"];
  stockNumber: Maybe<Scalars["String"]>;
}

export interface DeleteDocumentInventoryItemInput {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["UUID"];
}

export interface DeleteDocumentInventoryItemPayload {
  __typename?: "DeleteDocumentInventoryItemPayload";
  ad: Maybe<AdProjection>;
  clientMutationId: Maybe<Scalars["String"]>;
  documentInventoryItem: Maybe<DocumentInventoryItem>;
}

export interface DeleteDocumentInventoryItemPayloadAdArgs {
  adId: Scalars["UUID"];
}

export interface DeliveryEta {
  __typename?: "DeliveryETA";
  customerExpectedMaxETAData: Maybe<CustomerExpectedMaxEtaData>;
  deliveryETAType: Maybe<Scalars["String"]>;
  deliveryEtaRange: Maybe<DeliveryEtaRange>;
}

export interface DeliveryEtaRange {
  __typename?: "DeliveryEtaRange";
  deliveryEtaRangeCalculatedOn: Maybe<Scalars["String"]>;
  deliveryEtaRangeChangeCount: Maybe<Scalars["Int"]>;
  earliest: Maybe<Scalars["String"]>;
  earliestOnOrderVerified: Maybe<Scalars["String"]>;
  etaRangeOnOrderVerifiedCalculatedOn: Maybe<Scalars["String"]>;
  initialEarliestEta: Maybe<Scalars["String"]>;
  initialLatestEta: Maybe<Scalars["String"]>;
  latest: Maybe<Scalars["String"]>;
  latestOnOrderVerified: Maybe<Scalars["String"]>;
}

export interface DeliveryEtaRangeDto {
  __typename?: "DeliveryEtaRangeDTO";
  earliest: Maybe<Scalars["String"]>;
  latest: Maybe<Scalars["String"]>;
}

export interface DeliveryPrice {
  __typename?: "DeliveryPrice";
  amount: Maybe<Scalars["Long"]>;
  currency: Maybe<Scalars["String"]>;
  netAmount: Maybe<Scalars["Long"]>;
  vatAmount: Maybe<Scalars["Long"]>;
  vatRate: Maybe<Scalars["Float"]>;
}

export interface DeliveryRouteProjection {
  __typename?: "DeliveryRouteProjection";
  destinationAddress: Maybe<RetailRouteAddressProjection>;
  items: Maybe<Array<Maybe<RetailRouteItemProjection>>>;
  originAddress: Maybe<RetailRouteAddressProjection>;
}

export interface DigitalCarRegistrationProcessingErrorProjection {
  __typename?: "DigitalCarRegistrationProcessingErrorProjection";
  description: Scalars["String"];
}

export interface DigitalCarRegistrationProjection {
  __typename?: "DigitalCarRegistrationProjection";
  accountData: Maybe<AccountDataDto>;
  assignedTo: Maybe<Scalars["UUID"]>;
  assignedToUser: Maybe<RetailUserDto>;
  bankAccountDataId: Maybe<Scalars["UUID"]>;
  carRegistrationAddress: Maybe<RetailAddressDto>;
  carRegistrationAddressId: Maybe<Scalars["UUID"]>;
  createdOn: Scalars["String"];
  customer: Maybe<RetailCustomerProjection>;
  customerId: Maybe<Scalars["UUID"]>;
  evbNumber: Maybe<Scalars["String"]>;
  history: Maybe<Array<DigitalCarRegistrationStatusHistoryProjection>>;
  holderPlaceOfBirth: Maybe<Scalars["String"]>;
  holderPlaceOfBirthday: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  licensePlate: Maybe<LicensePlateProjection>;
  licensePlateNumber: Maybe<Scalars["String"]>;
  licensePlatePin: Maybe<Scalars["String"]>;
  oldLicensePlate: Maybe<LicensePlateProjection>;
  oldLicensePlateFrontSecurityCode: Maybe<Scalars["String"]>;
  oldLicensePlateRearSecurityCode: Maybe<Scalars["String"]>;
  orderId: Scalars["UUID"];
  registeredLicensePlateNumber: Maybe<Scalars["String"]>;
  registrationCertificate1Code: Maybe<Scalars["String"]>;
  registrationCertificate1CodeSecurityCode: Maybe<Scalars["String"]>;
  registrationCertificate2Number: Maybe<Scalars["String"]>;
  registrationCertificate2NumberSecurityCode: Maybe<Scalars["String"]>;
  registrationDate: Maybe<Scalars["LocalDate"]>;
  registrationType: CarRegistrationType;
  retailOrder: Maybe<RetailOrderDto>;
  status: Scalars["String"];
  stockNumber: Scalars["String"];
  updatedOn: Maybe<Scalars["String"]>;
  vehicleTaxesCollectedPeriod: Maybe<Scalars["String"]>;
  vin: Scalars["String"];
}

export interface DigitalCarRegistrationQaDataProjection {
  __typename?: "DigitalCarRegistrationQADataProjection";
  cityOfBirth: Maybe<Scalars["String"]>;
  createdBy: Maybe<Scalars["UUID"]>;
  createdOn: Maybe<Scalars["String"]>;
  dateOfBirth: Maybe<Scalars["String"]>;
  digitalCarRegistrationId: Maybe<Scalars["UUID"]>;
  doctoralDegree: Maybe<Scalars["String"]>;
  evbNumber: Maybe<Scalars["String"]>;
  firstName: Maybe<Scalars["String"]>;
  fuelType: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  lastName: Maybe<Scalars["String"]>;
  licensePlate: Maybe<Scalars["String"]>;
  licensePlatePin: Maybe<Scalars["String"]>;
  oldLicensePlate: Maybe<Scalars["String"]>;
  oldLicensePlateFrontSecurityCode: Maybe<Scalars["String"]>;
  oldLicensePlateRearSecurityCode: Maybe<Scalars["String"]>;
  registrationAddressCity: Maybe<Scalars["String"]>;
  registrationAddressHouseNumber: Maybe<Scalars["String"]>;
  registrationAddressStreet: Maybe<Scalars["String"]>;
  registrationAddressZipCode: Maybe<Scalars["String"]>;
  registrationCertificate1Code: Maybe<Scalars["String"]>;
  registrationCertificate1CodeSecurityCode: Maybe<Scalars["String"]>;
  registrationCertificate2Number: Maybe<Scalars["String"]>;
  registrationCertificate2NumberSecurityCode: Maybe<Scalars["String"]>;
  salutation: Maybe<Scalars["String"]>;
  updatedBy: Maybe<Scalars["UUID"]>;
  updatedOn: Maybe<Scalars["String"]>;
  vehicleTaxesCollectedPeriod: Maybe<Scalars["String"]>;
  vin: Maybe<Scalars["String"]>;
}

export interface DigitalCarRegistrationQaDataUpdateProjectionInput {
  cityOfBirth?: InputMaybe<Scalars["String"]>;
  dateOfBirth?: InputMaybe<Scalars["String"]>;
  doctoralDegree?: InputMaybe<Scalars["String"]>;
  evbNumber?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  fuelType?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  licensePlate?: InputMaybe<Scalars["String"]>;
  licensePlatePin?: InputMaybe<Scalars["String"]>;
  oldLicensePlate?: InputMaybe<Scalars["String"]>;
  oldLicensePlateFrontSecurityCode?: InputMaybe<Scalars["String"]>;
  oldLicensePlateRearSecurityCode?: InputMaybe<Scalars["String"]>;
  registrationAddressCity?: InputMaybe<Scalars["String"]>;
  registrationAddressHouseNumber?: InputMaybe<Scalars["String"]>;
  registrationAddressStreet?: InputMaybe<Scalars["String"]>;
  registrationAddressZipCode?: InputMaybe<Scalars["String"]>;
  registrationCertificate1Code?: InputMaybe<Scalars["String"]>;
  registrationCertificate1CodeSecurityCode?: InputMaybe<Scalars["String"]>;
  registrationCertificate2Number?: InputMaybe<Scalars["String"]>;
  registrationCertificate2NumberSecurityCode?: InputMaybe<Scalars["String"]>;
  salutation?: InputMaybe<Scalars["String"]>;
  vehicleTaxesCollectedPeriod?: InputMaybe<Scalars["String"]>;
  vin?: InputMaybe<Scalars["String"]>;
}

export interface DigitalCarRegistrationStatusHistoryProjection {
  __typename?: "DigitalCarRegistrationStatusHistoryProjection";
  date: Scalars["String"];
  error: Maybe<DigitalCarRegistrationProcessingErrorProjection>;
  status: Scalars["String"];
  step: Scalars["String"];
}

export interface DigitalCarRegistrationUpdateProjectionInput {
  assignedTo?: InputMaybe<Scalars["UUID"]>;
  carRegistrationAddressId?: InputMaybe<Scalars["UUID"]>;
  evbNumber?: InputMaybe<Scalars["String"]>;
  holderPlaceOfBirth?: InputMaybe<Scalars["String"]>;
  licensePlate?: InputMaybe<LicensePlateProjectionInput>;
  licensePlateNumber?: InputMaybe<Scalars["String"]>;
  licensePlatePin?: InputMaybe<Scalars["String"]>;
  oldLicensePlate?: InputMaybe<LicensePlateProjectionInput>;
  oldLicensePlateFrontSecurityCode?: InputMaybe<Scalars["String"]>;
  oldLicensePlateRearSecurityCode?: InputMaybe<Scalars["String"]>;
  registrationCertificate1Code?: InputMaybe<Scalars["String"]>;
  registrationCertificate1CodeSecurityCode?: InputMaybe<Scalars["String"]>;
  registrationCertificate2Number?: InputMaybe<Scalars["String"]>;
  registrationCertificate2NumberSecurityCode?: InputMaybe<Scalars["String"]>;
  vehicleTaxesCollectedPeriod?: InputMaybe<Scalars["String"]>;
}

export type Direction = "ASC" | "DESC";

export interface DisableDocumentInventoryTypeInput {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["UUID"]>;
}

export interface DisableDocumentInventoryTypePayload {
  __typename?: "DisableDocumentInventoryTypePayload";
  clientMutationId: Maybe<Scalars["String"]>;
  documentInventoryType: Maybe<DocumentInventoryType>;
  query: Maybe<GqlRetailCarDocumentsService>;
}

export interface DiscountCreateProjectionInput {
  amountMinorUnits?: InputMaybe<Scalars["Long"]>;
  invoiceId?: InputMaybe<Scalars["UUID"]>;
  orderId?: InputMaybe<Scalars["UUID"]>;
}

export interface DiscountDto {
  __typename?: "DiscountDTO";
  amount: Maybe<PriceDto>;
  amountMinorUnits: Maybe<Scalars["Long"]>;
  createdBy: Maybe<Scalars["UUID"]>;
  createdOn: Maybe<Scalars["String"]>;
  creditNoteNumber: Maybe<Scalars["String"]>;
  currencyCode: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  invoiceId: Maybe<Scalars["UUID"]>;
  orderId: Maybe<Scalars["UUID"]>;
  updatedOn: Maybe<Scalars["String"]>;
}

export interface DocumentBranchProjection {
  __typename?: "DocumentBranchProjection";
  address2: Maybe<Scalars["String"]>;
  branchName: Maybe<Scalars["String"]>;
  city: Maybe<Scalars["String"]>;
  companyName: Maybe<Scalars["String"]>;
  contactPerson: Maybe<Scalars["String"]>;
  contactPhoneNumber: Maybe<Scalars["String"]>;
  country: Maybe<Scalars["String"]>;
  documentContact: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["Int"]>;
  street: Maybe<Scalars["String"]>;
  zipcode: Maybe<Scalars["String"]>;
}

export interface DocumentInventoryItem {
  __typename?: "DocumentInventoryItem";
  id: Scalars["UUID"];
  location: Maybe<DocumentLocation>;
  name: Scalars["String"];
}

export interface DocumentInventoryItemConnection_DocumentInventoryItem {
  __typename?: "DocumentInventoryItemConnection_DocumentInventoryItem";
  nodes: Maybe<Array<Maybe<DocumentInventoryItem>>>;
  totalCount: Maybe<Scalars["Long"]>;
}

export interface DocumentInventoryItemLocationFilterInput {
  orderId?: InputMaybe<Scalars["UUID"]>;
  statusIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
}

export interface DocumentInventoryItemsFilterInput {
  location?: InputMaybe<DocumentLocationFilterInput>;
  orderId: Scalars["UUID"];
  statusIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
}

export interface DocumentInventoryPackageAddressInput {
  address: Scalars["String"];
  address2?: InputMaybe<Scalars["String"]>;
  branchName?: InputMaybe<Scalars["String"]>;
  city: Scalars["String"];
  contactPerson?: InputMaybe<Scalars["String"]>;
  country: Scalars["String"];
  deliveryLocationId?: InputMaybe<Scalars["Int"]>;
  documentContact?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  houseNum?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  shippingCompanyName?: InputMaybe<Scalars["String"]>;
  zipCode: Scalars["String"];
}

export interface DocumentInventoryPackageAddressProjection {
  __typename?: "DocumentInventoryPackageAddressProjection";
  address: Scalars["String"];
  address2: Maybe<Scalars["String"]>;
  branchName: Maybe<Scalars["String"]>;
  city: Scalars["String"];
  contactPerson: Maybe<Scalars["String"]>;
  country: Scalars["String"];
  documentContact: Maybe<Scalars["String"]>;
  firstName: Maybe<Scalars["String"]>;
  houseNum: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["Int"]>;
  lastName: Maybe<Scalars["String"]>;
  phoneNumber: Maybe<Scalars["String"]>;
  shippingCompanyName: Maybe<Scalars["String"]>;
  zipCode: Scalars["String"];
}

export interface DocumentInventoryPackageFilterInput {
  orderId: Scalars["UUID"];
}

export interface DocumentInventoryPackageProjection {
  __typename?: "DocumentInventoryPackageProjection";
  changelog: PageDto_ChangelogProjection;
  createdAt: Scalars["String"];
  deliveredOn: Maybe<Scalars["String"]>;
  deliveryAddress: DocumentInventoryPackageAddressProjection;
  documentInventoryItems: Maybe<ResultConnection_DocumentInventoryItem>;
  estimatedDeliveryDate: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  name: Scalars["String"];
  originAddress: DocumentInventoryPackageAddressProjection;
  packageNumber: Scalars["Int"];
  pickedUpOn: Maybe<Scalars["String"]>;
  shippingProvider: Maybe<ShippingProviderProjection>;
  status: Scalars["String"];
  trackingCode: Maybe<Scalars["String"]>;
}

export interface DocumentInventoryType {
  __typename?: "DocumentInventoryType";
  id: Maybe<Scalars["UUID"]>;
  name: Maybe<Scalars["String"]>;
  state: Maybe<DocumentInventoryTypeState>;
}

export interface DocumentInventoryTypeConnection_DocumentInventoryType {
  __typename?: "DocumentInventoryTypeConnection_DocumentInventoryType";
  nodes: Maybe<Array<Maybe<DocumentInventoryType>>>;
  totalCount: Maybe<Scalars["Long"]>;
}

export type DocumentInventoryTypeState = "DISABLED" | "ENABLED";

export interface DocumentItemInPackageProblem {
  __typename?: "DocumentItemInPackageProblem";
  documentInventoryPackage: Maybe<DocumentInventoryPackageProjection>;
  message: Maybe<Scalars["String"]>;
}

export interface DocumentItemsInPackageProblem {
  __typename?: "DocumentItemsInPackageProblem";
  documentInventoryPackage: Maybe<DocumentInventoryPackageProjection>;
  message: Maybe<Scalars["String"]>;
}

export interface DocumentLocation {
  __typename?: "DocumentLocation";
  address: Scalars["String"];
  city: Scalars["String"];
  country: Scalars["String"];
  id: Scalars["Int"];
  name: Scalars["String"];
  zipcode: Scalars["String"];
}

export interface DocumentLocationFilterInput {
  locationId?: InputMaybe<Scalars["Int"]>;
  notNull?: InputMaybe<Scalars["Boolean"]>;
}

export interface DocumentPackageAlreadyCanceledProblem {
  __typename?: "DocumentPackageAlreadyCanceledProblem";
  isCanceled: Maybe<Scalars["Boolean"]>;
  message: Maybe<Scalars["String"]>;
}

export interface DocumentPackageAlreadyDeliveredProblem {
  __typename?: "DocumentPackageAlreadyDeliveredProblem";
  isDelivered: Maybe<Scalars["Boolean"]>;
  message: Maybe<Scalars["String"]>;
}

export interface DocumentPackageConflictProblem {
  __typename?: "DocumentPackageConflictProblem";
  documentItems: Maybe<ResultConnection_DocumentInventoryItem>;
  message: Maybe<Scalars["String"]>;
}

export interface DocumentPackageEmptyBranchNameProblem {
  __typename?: "DocumentPackageEmptyBranchNameProblem";
  message: Maybe<Scalars["String"]>;
}

export interface DocumentPackageEmptyDeliveredOnProblem {
  __typename?: "DocumentPackageEmptyDeliveredOnProblem";
  message: Maybe<Scalars["String"]>;
}

export interface DocumentPackageEmptyFirstNameProblem {
  __typename?: "DocumentPackageEmptyFirstNameProblem";
  message: Maybe<Scalars["String"]>;
}

export interface DocumentPackageEmptyLastNameProblem {
  __typename?: "DocumentPackageEmptyLastNameProblem";
  message: Maybe<Scalars["String"]>;
}

export interface DocumentPackageEmptyPickedUpOnProblem {
  __typename?: "DocumentPackageEmptyPickedUpOnProblem";
  message: Maybe<Scalars["String"]>;
}

export interface DocumentPackageNoHouseNumAddressProblem {
  __typename?: "DocumentPackageNoHouseNumAddressProblem";
  message: Maybe<Scalars["String"]>;
}

export interface DocumentPackageNotOpenProblem {
  __typename?: "DocumentPackageNotOpenProblem";
  message: Maybe<Scalars["String"]>;
}

export interface DocumentPackageSameStatusProblem {
  __typename?: "DocumentPackageSameStatusProblem";
  message: Maybe<Scalars["String"]>;
  sameStatus: Maybe<Scalars["String"]>;
}

export interface DocumentPackageZeroItemsProblem {
  __typename?: "DocumentPackageZeroItemsProblem";
  message: Maybe<Scalars["String"]>;
}

export interface DocumentPackagesGeneralProblem {
  __typename?: "DocumentPackagesGeneralProblem";
  errorCode: Maybe<Scalars["String"]>;
  message: Maybe<Scalars["String"]>;
}

export type DocumentSignInProcessType = "DIGITAL_CAR_REGISTRATION";

export interface DocumentsDtoInput {
  hasCOCDocument?: InputMaybe<Scalars["Boolean"]>;
  hasCarRegistrationDocument?: InputMaybe<Scalars["Boolean"]>;
  hasCertifiedChequeReceived?: InputMaybe<Scalars["Boolean"]>;
  hasInstantTransferReceived?: InputMaybe<Scalars["Boolean"]>;
  hasLicensePlate?: InputMaybe<Scalars["Boolean"]>;
  hasOtherDocuments?: InputMaybe<Scalars["Boolean"]>;
  hasOwnershipDocument?: InputMaybe<Scalars["Boolean"]>;
  hasServiceBook?: InputMaybe<Scalars["Boolean"]>;
  hasVehicleInspection?: InputMaybe<Scalars["Boolean"]>;
  otherDocuments?: InputMaybe<Scalars["String"]>;
}

export interface DocumentsReturnedDeregistrationTaskProjection {
  __typename?: "DocumentsReturnedDeregistrationTaskProjection";
  action: Maybe<Scalars["String"]>;
  assignedOn: Maybe<Scalars["String"]>;
  assignedTo: Maybe<UserProjection>;
  assignee: Maybe<UserProjection>;
  closedBy: Maybe<UserProjection>;
  closedOn: Maybe<Scalars["String"]>;
  comment: Maybe<Scalars["String"]>;
  commentsCount: Maybe<Scalars["Int"]>;
  completedBy: Maybe<UserProjection>;
  completedOn: Maybe<Scalars["String"]>;
  createdOn: Maybe<Scalars["String"]>;
  documentsRegistrationReadyDate: Maybe<Scalars["String"]>;
  documentsRequestedDate: Maybe<Scalars["String"]>;
  finalDocumentHandoverDate: Maybe<Scalars["String"]>;
  financingStatus: Maybe<Scalars["String"]>;
  flowVersion: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  name: Maybe<Scalars["String"]>;
  order: Maybe<OrderProjection>;
  orderId: Maybe<Scalars["UUID"]>;
  orderNumber: Maybe<Scalars["String"]>;
  orderState: Maybe<Scalars["String"]>;
  paymentStatus: Maybe<Scalars["String"]>;
  paymentType: Maybe<Scalars["String"]>;
  reason: Maybe<Scalars["String"]>;
  registrationInitiatedDate: Maybe<Scalars["String"]>;
  repeatAt: Maybe<Scalars["String"]>;
  returnDeliveryType: Maybe<Scalars["String"]>;
  sequenceIndex: Scalars["Int"];
  state: Maybe<Scalars["String"]>;
  stockNumber: Maybe<Scalars["String"]>;
  taskDefinitionKey: Maybe<Scalars["String"]>;
  taskStatus: Maybe<Scalars["String"]>;
  taskType: Maybe<Scalars["String"]>;
  taskUpdatedAt: Maybe<Scalars["String"]>;
  vin: Maybe<Scalars["String"]>;
}

export interface DownPaymentItemProjection {
  __typename?: "DownPaymentItemProjection";
  created: Maybe<Scalars["String"]>;
  currencyCode: Maybe<Scalars["String"]>;
  discountGrossMinorUnits: Maybe<Scalars["Long"]>;
  discountMinorUnits: Maybe<Scalars["Long"]>;
  downPayment: Maybe<DownPaymentProjection>;
  externalId: Maybe<Scalars["UUID"]>;
  externalType: Maybe<Scalars["Int"]>;
  id: Maybe<Scalars["UUID"]>;
  invoiceDescription: Maybe<Scalars["String"]>;
  invoiceName: Maybe<Scalars["String"]>;
  orderId: Maybe<Scalars["UUID"]>;
  parentId: Maybe<Scalars["UUID"]>;
  priceDiscountGross: Maybe<PriceDto>;
  priceDiscountNet: Maybe<PriceDto>;
  priceDiscountVat: Maybe<PriceDto>;
  priceGross: Maybe<PriceDto>;
  priceGrossMinorUnits: Maybe<Scalars["Long"]>;
  priceNet: Maybe<PriceDto>;
  priceNetMinorUnits: Maybe<Scalars["Long"]>;
  priceTaxAdvantageAmount: Maybe<PriceDto>;
  priceVatAmount: Maybe<PriceDto>;
  type: Maybe<Scalars["String"]>;
  updated: Maybe<Scalars["String"]>;
  vatAmountMinorUnits: Maybe<Scalars["Long"]>;
  vatKey: Maybe<Scalars["String"]>;
  vatRate: Maybe<Scalars["Float"]>;
}

export interface DownPaymentProjection {
  __typename?: "DownPaymentProjection";
  active: Scalars["Boolean"];
  country: Scalars["String"];
  createdBy: Maybe<Scalars["UUID"]>;
  createdOn: Maybe<Scalars["String"]>;
  currencyCode: Scalars["String"];
  deactivatedOn: Maybe<Scalars["String"]>;
  defaulted: Scalars["Boolean"];
  id: Scalars["UUID"];
  internalReferenceName: Maybe<Scalars["String"]>;
  invoiceDescription: Scalars["String"];
  invoiceName: Maybe<Scalars["String"]>;
  partnerName: Scalars["String"];
  pnlCostPrice: Maybe<PriceDetailsProjection>;
  price: PriceDetailsProjection;
  priceDetails: Maybe<Array<Maybe<PriceDetailsProjection>>>;
  productType: Scalars["String"];
  publishedScope: PublishedScopeType;
  quantity: Scalars["Int"];
  recommended: Maybe<Scalars["Boolean"]>;
  refId: Scalars["String"];
  shippingRequired: Scalars["Boolean"];
  subProductDetails: Maybe<RetailSubProductDetailsProjection>;
  updatedOn: Maybe<Scalars["String"]>;
}

export interface DownPaymentUpdateProjectionInput {
  active?: InputMaybe<Scalars["Boolean"]>;
  country?: InputMaybe<Scalars["String"]>;
  defaulted?: InputMaybe<Scalars["Boolean"]>;
  grossPriceMinorUnits?: InputMaybe<Scalars["Long"]>;
  internalReferenceName?: InputMaybe<Scalars["String"]>;
  invoiceDescription?: InputMaybe<Scalars["String"]>;
  invoiceName?: InputMaybe<Scalars["String"]>;
  partnerName?: InputMaybe<Scalars["String"]>;
  pnlCostGrossPriceMinorUnits?: InputMaybe<Scalars["Long"]>;
  pnlCostVatKey?: InputMaybe<VatKeyType>;
  pnlCostVatPercentage?: InputMaybe<Scalars["Float"]>;
  publishedScope?: InputMaybe<PublishedScopeType>;
  quantity?: InputMaybe<Scalars["Int"]>;
  recommended?: InputMaybe<Scalars["Boolean"]>;
  shippingRequired?: InputMaybe<Scalars["Boolean"]>;
  vatKey?: InputMaybe<VatKeyType>;
  vatPercentage?: InputMaybe<Scalars["Float"]>;
}

export interface ElasticCustomerProjection {
  __typename?: "ElasticCustomerProjection";
  customerType: Maybe<Scalars["String"]>;
  email: Maybe<Scalars["String"]>;
  firstName: Maybe<Scalars["String"]>;
  lastName: Maybe<Scalars["String"]>;
  name: Maybe<Scalars["String"]>;
  salutation: Maybe<Scalars["String"]>;
}

export interface ElasticOrderFinancingProjection {
  __typename?: "ElasticOrderFinancingProjection";
  id: Maybe<Scalars["UUID"]>;
  status: Maybe<Scalars["String"]>;
}

export interface ElasticPriceProjection {
  __typename?: "ElasticPriceProjection";
  amountMinorUnits: Maybe<Scalars["Long"]>;
  currencyCode: Maybe<Scalars["String"]>;
}

export interface EmailReplyPendingTaskProjection {
  __typename?: "EmailReplyPendingTaskProjection";
  SENT_ON_KEY: Maybe<Scalars["String"]>;
  TEMPLATE_NAME_KEY: Maybe<Scalars["String"]>;
  action: Maybe<Scalars["String"]>;
  assignedOn: Maybe<Scalars["String"]>;
  assignedTo: Maybe<UserProjection>;
  assignee: Maybe<UserProjection>;
  carHandoverOnDate: Maybe<Scalars["String"]>;
  closedBy: Maybe<UserProjection>;
  closedOn: Maybe<Scalars["String"]>;
  comment: Maybe<Scalars["String"]>;
  commentsCount: Maybe<Scalars["Int"]>;
  completedBy: Maybe<UserProjection>;
  completedOn: Maybe<Scalars["String"]>;
  createdOn: Maybe<Scalars["String"]>;
  customerName: Maybe<Scalars["String"]>;
  financingStatus: Maybe<Scalars["String"]>;
  flowVersion: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  name: Maybe<Scalars["String"]>;
  order: Maybe<OrderProjection>;
  orderId: Maybe<Scalars["UUID"]>;
  orderNumber: Maybe<Scalars["String"]>;
  orderState: Maybe<Scalars["String"]>;
  paymentStatus: Maybe<Scalars["String"]>;
  paymentType: Maybe<Scalars["String"]>;
  reason: Maybe<Scalars["String"]>;
  repeatAt: Maybe<Scalars["String"]>;
  sentOn: Maybe<Scalars["String"]>;
  sequenceIndex: Scalars["Int"];
  state: Maybe<Scalars["String"]>;
  stockNumber: Maybe<Scalars["String"]>;
  taskDefinitionKey: Maybe<Scalars["String"]>;
  taskStatus: Maybe<Scalars["String"]>;
  taskType: Maybe<Scalars["String"]>;
  taskUpdatedAt: Maybe<Scalars["String"]>;
  templateName: Maybe<Scalars["String"]>;
}

export interface EmailReplyReviewTaskProjection {
  __typename?: "EmailReplyReviewTaskProjection";
  SENT_ON_KEY: Maybe<Scalars["String"]>;
  TEMPLATE_NAME_KEY: Maybe<Scalars["String"]>;
  action: Maybe<Scalars["String"]>;
  assignedOn: Maybe<Scalars["String"]>;
  assignedTo: Maybe<UserProjection>;
  assignee: Maybe<UserProjection>;
  carHandoverOnDate: Maybe<Scalars["String"]>;
  closedBy: Maybe<UserProjection>;
  closedOn: Maybe<Scalars["String"]>;
  comment: Maybe<Scalars["String"]>;
  commentsCount: Maybe<Scalars["Int"]>;
  completedBy: Maybe<UserProjection>;
  completedOn: Maybe<Scalars["String"]>;
  createdOn: Maybe<Scalars["String"]>;
  customerName: Maybe<Scalars["String"]>;
  financingStatus: Maybe<Scalars["String"]>;
  flowVersion: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  name: Maybe<Scalars["String"]>;
  order: Maybe<OrderProjection>;
  orderId: Maybe<Scalars["UUID"]>;
  orderNumber: Maybe<Scalars["String"]>;
  orderState: Maybe<Scalars["String"]>;
  paymentStatus: Maybe<Scalars["String"]>;
  paymentType: Maybe<Scalars["String"]>;
  reason: Maybe<Scalars["String"]>;
  repeatAt: Maybe<Scalars["String"]>;
  sentOn: Maybe<Scalars["String"]>;
  sequenceIndex: Scalars["Int"];
  state: Maybe<Scalars["String"]>;
  stockNumber: Maybe<Scalars["String"]>;
  taskDefinitionKey: Maybe<Scalars["String"]>;
  taskStatus: Maybe<Scalars["String"]>;
  taskType: Maybe<Scalars["String"]>;
  taskUpdatedAt: Maybe<Scalars["String"]>;
  templateName: Maybe<Scalars["String"]>;
}

export interface EnableDocumentInventoryTypeInput {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["UUID"]>;
}

export interface EnableDocumentInventoryTypePayload {
  __typename?: "EnableDocumentInventoryTypePayload";
  clientMutationId: Maybe<Scalars["String"]>;
  documentInventoryType: Maybe<DocumentInventoryType>;
  query: Maybe<GqlRetailCarDocumentsService>;
}

export interface EquipmentItemDto {
  __typename?: "EquipmentItemDTO";
  dataSource: Maybe<Scalars["Int"]>;
  equipmentType: Maybe<EquipmentType>;
  groupId: Maybe<Scalars["Long"]>;
  id: Maybe<Scalars["Long"]>;
  name: Maybe<Scalars["String"]>;
  translationKey: Maybe<Scalars["String"]>;
}

export type EquipmentType = "CUSTOM" | "VIN";

export interface EsOrderProjection {
  __typename?: "EsOrderProjection";
  adId: Maybe<Scalars["UUID"]>;
  cancelDescription: Maybe<Scalars["String"]>;
  cancelPeriod: Maybe<Scalars["String"]>;
  cancelReason: Maybe<Scalars["String"]>;
  cancelRequestSubReason: Maybe<Scalars["String"]>;
  cancelType: Maybe<Scalars["String"]>;
  completedOn: Maybe<Scalars["String"]>;
  contractSignedOn: Maybe<Scalars["String"]>;
  createdAt: Maybe<Scalars["String"]>;
  customer: Maybe<ElasticCustomerProjection>;
  deliveredOn: Maybe<Scalars["String"]>;
  financing: Maybe<ElasticOrderFinancingProjection>;
  flowVersion: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  orderNumber: Maybe<Scalars["String"]>;
  overallOverdue: Maybe<Scalars["Int"]>;
  overdueBranchDelivery: Maybe<Scalars["Long"]>;
  overdueMaxEta: Maybe<Scalars["Long"]>;
  paymentStatus: Maybe<Scalars["String"]>;
  paymentType: Maybe<Scalars["String"]>;
  paymentTypeColorCoding: Maybe<ColorCoding>;
  pendingVerificationOn: Maybe<Scalars["String"]>;
  salesAgentAssignedTo: Maybe<UserProjection>;
  state: Maybe<Scalars["String"]>;
  stateColorCoding: Maybe<ColorCoding>;
  stockNumber: Maybe<Scalars["String"]>;
  subCancelReason: Maybe<Scalars["String"]>;
  totalPriceGross: Maybe<ElasticPriceProjection>;
  verifiedOn: Maybe<Scalars["String"]>;
}

export interface ExitCheckCommentProjection {
  __typename?: "ExitCheckCommentProjection";
  comment: Maybe<Scalars["String"]>;
  createdBy: Maybe<RetailUserDto>;
  createdOn: Maybe<Scalars["LocalDateTime"]>;
  exitCheckId: Maybe<Scalars["UUID"]>;
  id: Maybe<Scalars["UUID"]>;
  updatedOn: Maybe<Scalars["LocalDateTime"]>;
}

export interface ExitCheckCommentsProjection {
  __typename?: "ExitCheckCommentsProjection";
  comments: Maybe<Array<Maybe<ExitCheckCommentProjection>>>;
}

export interface ExitCheckProjection {
  __typename?: "ExitCheckProjection";
  adId: Maybe<Scalars["UUID"]>;
  branchData: Maybe<BranchProjection>;
  completedOn: Maybe<Scalars["LocalDateTime"]>;
  createdOn: Maybe<Scalars["LocalDateTime"]>;
  id: Maybe<Scalars["UUID"]>;
  partnerData: Maybe<RefurbishmentPartnerDto>;
  preparedStatusDate: Maybe<Scalars["LocalDateTime"]>;
  repairEta: Maybe<Scalars["LocalDate"]>;
  state: Maybe<ExitCheckState>;
  stockNumber: Maybe<Scalars["String"]>;
  updatedOn: Maybe<Scalars["LocalDateTime"]>;
  vin: Maybe<Scalars["String"]>;
}

export type ExitCheckState =
  | "CANCELLED"
  | "COMPLETED"
  | "ORDERED"
  | "PREPARED"
  | "REPAIRS_COMPLETED"
  | "REPAIRS_IN_PROGRESS"
  | "REPAIRS_NEEDED";

export interface ExportItem {
  __typename?: "ExportItem";
  daysOnline: Maybe<Scalars["Int"]>;
  dealerId: Maybe<Scalars["String"]>;
  description: Maybe<Scalars["String"]>;
  firstPublishedAt: Maybe<Scalars["String"]>;
  inProgress: Maybe<Scalars["Boolean"]>;
  lastPublishingErrors: Maybe<Array<Maybe<PublishingError>>>;
  links: Maybe<Array<Maybe<LinkProjection>>>;
  marketplace: Maybe<Scalars["String"]>;
  published: Maybe<Scalars["Boolean"]>;
  title: Maybe<Scalars["String"]>;
  updatedAt: Maybe<Scalars["String"]>;
}

export interface ExportSectionProjection {
  __typename?: "ExportSectionProjection";
  exportItems: Maybe<Array<Maybe<ExportItem>>>;
}

export type ExportTaxAdvantageType = "BPM" | "DK" | "NOVA";

export interface ExteriorDto {
  __typename?: "ExteriorDTO";
  backLeftRimType: Maybe<RimType>;
  backLeftTireType: Maybe<TireType>;
  backRightRimType: Maybe<RimType>;
  backRightTireType: Maybe<TireType>;
  exteriorPhotos: Maybe<Array<Maybe<Scalars["UUID"]>>>;
  frontLeftRimType: Maybe<RimType>;
  frontLeftTireType: Maybe<TireType>;
  frontRightRimType: Maybe<RimType>;
  frontRightTireType: Maybe<TireType>;
  hasLicensePlateFront: Maybe<Scalars["Boolean"]>;
  hasLicensePlateRear: Maybe<Scalars["Boolean"]>;
  isAntennaAvailable: Maybe<Scalars["Boolean"]>;
  isExteriorClean: Maybe<Scalars["Boolean"]>;
  overviewFrontPhoto: Maybe<Scalars["UUID"]>;
  overviewRearPhoto: Maybe<Scalars["UUID"]>;
  roofPhoto: Maybe<Scalars["UUID"]>;
  sideLeftPhoto: Maybe<Scalars["UUID"]>;
  sideRightPhoto: Maybe<Scalars["UUID"]>;
}

export interface ExteriorDtoInput {
  backLeftRimType?: InputMaybe<RimType>;
  backLeftTireType?: InputMaybe<TireType>;
  backRightRimType?: InputMaybe<RimType>;
  backRightTireType?: InputMaybe<TireType>;
  exteriorPhotos?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  frontLeftRimType?: InputMaybe<RimType>;
  frontLeftTireType?: InputMaybe<TireType>;
  frontRightRimType?: InputMaybe<RimType>;
  frontRightTireType?: InputMaybe<TireType>;
  hasLicensePlateFront?: InputMaybe<Scalars["Boolean"]>;
  hasLicensePlateRear?: InputMaybe<Scalars["Boolean"]>;
  isAntennaAvailable?: InputMaybe<Scalars["Boolean"]>;
  isExteriorClean?: InputMaybe<Scalars["Boolean"]>;
  overviewFrontPhoto?: InputMaybe<Scalars["UUID"]>;
  overviewRearPhoto?: InputMaybe<Scalars["UUID"]>;
  roofPhoto?: InputMaybe<Scalars["UUID"]>;
  sideLeftPhoto?: InputMaybe<Scalars["UUID"]>;
  sideRightPhoto?: InputMaybe<Scalars["UUID"]>;
}

export interface ExternalFinanceRiskEvaluationProjection {
  __typename?: "ExternalFinanceRiskEvaluationProjection";
  contractType: Maybe<Scalars["String"]>;
  employmentStatus: Maybe<Scalars["String"]>;
  monthlyNetIncomeMinorUnits: Maybe<Scalars["Long"]>;
  orderId: Maybe<Scalars["UUID"]>;
  result: Maybe<Scalars["String"]>;
  sameEmployerOverTimePeriod: Maybe<Scalars["Boolean"]>;
}

export interface ExternalFinancingCustomerDetailsProjection {
  __typename?: "ExternalFinancingCustomerDetailsProjection";
  bankAccountOpenDate: Maybe<Scalars["LocalDate"]>;
  currentJobStartDate: Maybe<Scalars["LocalDate"]>;
  customerId: Maybe<Scalars["UUID"]>;
  housing: Maybe<Scalars["String"]>;
  iban: Maybe<Scalars["String"]>;
  idCardNumber: Maybe<Scalars["String"]>;
  idExpiryDate: Maybe<Scalars["LocalDate"]>;
  idType: Maybe<Scalars["String"]>;
  incomeCurrency: Maybe<Scalars["String"]>;
  isCompanyRegisteredLongEnough: Maybe<Scalars["Boolean"]>;
  maritalStatus: Maybe<Scalars["String"]>;
  moveIntoCurrentHomeDate: Maybe<Scalars["LocalDate"]>;
  orderFinancingId: Maybe<Scalars["UUID"]>;
  taxCode: Maybe<Scalars["String"]>;
}

export interface ExternalFinancingEvaluationV2Projection {
  __typename?: "ExternalFinancingEvaluationV2Projection";
  evaluationId: Maybe<Scalars["UUID"]>;
  rejectReason: Maybe<Scalars["String"]>;
  status: Maybe<Scalars["String"]>;
}

export interface ExternalFinancingOptionsProjection {
  __typename?: "ExternalFinancingOptionsProjection";
  ahOpeningFees: Maybe<Scalars["BigDecimal"]>;
  damageInsurance: Maybe<Scalars["String"]>;
  externalFinancingRefNumber: Maybe<Scalars["String"]>;
  financingProvider: Maybe<Scalars["String"]>;
  gapInsurance: Maybe<Scalars["String"]>;
  ppi: Maybe<Scalars["String"]>;
}

export interface ExternalFinancingOptionsValuesProjection {
  __typename?: "ExternalFinancingOptionsValuesProjection";
  options: Maybe<Scalars["Map_String_Map_String_OptionProjectionScalar"]>;
}

export type ExternalPaymentSystem = "ADYEN" | "ADYEN_PAYMENT_LINK";

export interface ExternalPreEvaluationV2ProjectionInput {
  coBorrower?: InputMaybe<CreateExternalRiskUserInfoProjectionInput>;
  mainBorrower: CreateExternalRiskUserInfoProjectionInput;
}

export interface ExternalRiskUserInfoProjection {
  __typename?: "ExternalRiskUserInfoProjection";
  accountHolder: Maybe<Scalars["String"]>;
  additionalIncomeMinorUnits: Maybe<Scalars["Long"]>;
  bankAccountOpenDate: Maybe<Scalars["String"]>;
  borrowerType: Maybe<Scalars["String"]>;
  contractValidFor: Maybe<Scalars["Boolean"]>;
  employedBy: Maybe<Scalars["String"]>;
  employmentContractType: Maybe<Scalars["String"]>;
  employmentEndDate: Maybe<Scalars["String"]>;
  employmentStartDate: Maybe<Scalars["String"]>;
  employmentStatus: Maybe<Scalars["String"]>;
  employmentType: Maybe<Scalars["String"]>;
  housingConstruction: Maybe<Scalars["String"]>;
  housingType: Maybe<Scalars["String"]>;
  iban: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  incomeFromSubsidiesMinorUnits: Maybe<Scalars["Long"]>;
  incomeSpread: Maybe<Scalars["Int"]>;
  jobTitle: Maybe<Scalars["String"]>;
  maritalStatus: Maybe<Scalars["String"]>;
  mortgageOrRentPaymentMinorUnits: Maybe<Scalars["Long"]>;
  movingDate: Maybe<Scalars["String"]>;
  nationality: Maybe<Scalars["String"]>;
  numberOfDependents: Maybe<Scalars["Int"]>;
  numberOfOtherDependents: Maybe<Scalars["Int"]>;
  numberOfOtherLoans: Maybe<Scalars["Int"]>;
  orderFinancingId: Scalars["UUID"];
  otherLoanPaymentsMinorUnits: Maybe<Scalars["Long"]>;
  pep: Maybe<Scalars["Boolean"]>;
  placeOfBirth: Maybe<Scalars["String"]>;
  previousAddressMovingDate: Maybe<Scalars["String"]>;
  primaryIncomeMinorUnits: Maybe<Scalars["Long"]>;
  residentSinceDate: Maybe<Scalars["String"]>;
  sameEmployerOverTimePeriod: Maybe<Scalars["Boolean"]>;
  sector: Maybe<Scalars["String"]>;
  seniority: Maybe<Scalars["String"]>;
  separationAllowanceMinorUnits: Maybe<Scalars["Long"]>;
  separationOfGoodsInMarriage: Maybe<Scalars["Boolean"]>;
  type: Maybe<Scalars["String"]>;
  userId: Scalars["UUID"];
}

export type Feature =
  | "HAS_ABS"
  | "HAS_ACCELERATION_SKID_CONTROL"
  | "HAS_ADAPTIVE_CRUISE_CONTROLLER"
  | "HAS_ADAPTIVE_HEADLIGHTS"
  | "HAS_AIRBAG"
  | "HAS_AIR_CONDITIONING"
  | "HAS_AIR_SUSPENSION"
  | "HAS_ALARM_SYSTEM"
  | "HAS_ALLOY_RIMS"
  | "HAS_ALL_WHEEL_DRIVE"
  | "HAS_AMBIENT_LIGHTING"
  | "HAS_ANDROID_PHONE_CONNECTION"
  | "HAS_APPLE_CARPLAY"
  | "HAS_AUTOMATIC_AIR_CONDITION"
  | "HAS_AUTOMATIC_HEADLIGHT_CONTROL"
  | "HAS_AUXILIARY_HEATER"
  | "HAS_AXLE_LOAD_INCREASE"
  | "HAS_BACKLIGHT_LED"
  | "HAS_BI_XENON_LIGHTS"
  | "HAS_BLIND_SPOT_ASSIST"
  | "HAS_BLOCK_HEATER"
  | "HAS_BLUETOOTH"
  | "HAS_BRAKE_ASSIST"
  | "HAS_CARGO_SPACE_PARTITION"
  | "HAS_CAR_COMMUNICATION"
  | "HAS_CD_MULTICHANGER"
  | "HAS_CENTER_ARM_REST"
  | "HAS_CENTRAL_LOCKING"
  | "HAS_CHASSIS_CONTROL_SYSTEM"
  | "HAS_CLIMATISATION"
  | "HAS_COC_DOCUMENT"
  | "HAS_COMING_HOME_LEAVING_HOME"
  | "HAS_CORNERING_LIGHTS"
  | "HAS_CO_TWO"
  | "HAS_CROSSWIND_ASSIST"
  | "HAS_CROSS_TRAFFIC_ASSIST"
  | "HAS_CRUISE_CONTROL"
  | "HAS_DAYTIME_RUNNING_LIGHTS"
  | "HAS_DETACHABLE_TOW_HOOK"
  | "HAS_DIESEL_PARTICULATE_FILTER"
  | "HAS_DIGITAL_RADIO"
  | "HAS_DIMMING_INTERIOR_MIRROR"
  | "HAS_DISTANCE_WARNER"
  | "HAS_DRAUGHT_STOP"
  | "HAS_DRIVER_DROWSINESS_DETECTION"
  | "HAS_DRIVING_MODE_SWITCH"
  | "HAS_DYNAMIC_PERFORMANCE_CONTROL"
  | "HAS_ECO_DRIVE_CONTROL"
  | "HAS_ELECTRIC_SEATS"
  | "HAS_ELECTRIC_SIDE_VIEW_MIRRORS"
  | "HAS_ELECTRIC_SLIDING_DOORS"
  | "HAS_ELECTRIC_TAIL_GATE"
  | "HAS_EMERGENCY_BREAK_ASSIST"
  | "HAS_EMERGENCY_CALL_SYSTEM"
  | "HAS_ENTERTAINMENT_SYSTEM"
  | "HAS_ENVIRONMENT_ASSIST_SYSTEM"
  | "HAS_ESP"
  | "HAS_EXTERIOR_MIRROR"
  | "HAS_FOG_LIGHTS"
  | "HAS_FOLD_FLAT_PASSENGER_SEAT"
  | "HAS_FRONT_CAMERA"
  | "HAS_GLARE_FREE_HIGH_BEAM"
  | "HAS_GPS"
  | "HAS_HANDICAPPED_FEATURES"
  | "HAS_HANDS_FREE_SYSTEM"
  | "HAS_HARDTOP"
  | "HAS_HEADLIGHT_CLEANING_SYSTEM"
  | "HAS_HEAD_UP_DISPLAY"
  | "HAS_HEATED_FRONT_WINDOW"
  | "HAS_HEATED_SEATS"
  | "HAS_HEATED_SEATS_FRONT"
  | "HAS_HEATED_SEATS_FRONT_AND_REAR"
  | "HAS_HEATED_SIDE_VIEW_MIRRORS"
  | "HAS_HEATED_STEERING_WHEEL"
  | "HAS_HEATED_WIND_SHIELD"
  | "HAS_HIGH_BEAM_ASSIST"
  | "HAS_HIGH_PERFORMANCE_BRAKES"
  | "HAS_HILL_DESCENT_CONTROL"
  | "HAS_HILL_START_ASSIST"
  | "HAS_INTEGRAL_SAFETY_CONCEPT"
  | "HAS_INTEGRATED_MUSIC_STREAMING"
  | "HAS_ISOFIX_MOUNTING_POINTS"
  | "HAS_KEYLESS_ENTRY"
  | "HAS_LANE_ASSIST"
  | "HAS_LANE_CHANGE_ASSIST"
  | "HAS_LASER_HEADLIGHTS"
  | "HAS_LEATHER_STEERING_WHEEL"
  | "HAS_LED_HEADLIGHTS"
  | "HAS_LEVEL_CONTROL"
  | "HAS_LIMITED_SLIP_DIFFERENTIAL"
  | "HAS_LPG"
  | "HAS_MAINTENANCE_GUIDE"
  | "HAS_MASSAGE_SEATS"
  | "HAS_NAVIGATION_SYSTEM"
  | "HAS_NEW_HU_AU"
  | "HAS_NIGHT_VIEW_ASSIST"
  | "HAS_ON_BOARD_COMPUTER"
  | "HAS_PADDLE_SHIFTERS"
  | "HAS_PANORAMIC_ROOF"
  | "HAS_PARKING_ASSIST"
  | "HAS_PARK_DISTANCE_CONTROL"
  | "HAS_PARK_DISTANCE_CONTROL_FRONT_AND_REAR"
  | "HAS_PARK_DISTANCE_CONTROL_REAR"
  | "HAS_PARK_DISTANCE_CONTROL_WITH_CAMERA"
  | "HAS_PARK_HEATER"
  | "HAS_POWER_TAKE_OFF"
  | "HAS_POWER_WINDOWS"
  | "HAS_POWER_WINDOWS_FRONT"
  | "HAS_POWER_WINDOWS_FRONT_AND_REAR"
  | "HAS_RADIO_CASSETTE"
  | "HAS_RADIO_CD"
  | "HAS_RADIO_DAB"
  | "HAS_RADIO_MP3"
  | "HAS_RADIO_USB"
  | "HAS_RAIN_SENSOR_WIPERS"
  | "HAS_RANGE_EXTENDER"
  | "HAS_REAR_SEAT_PASS_THROUGH"
  | "HAS_REAR_SEAT_SPLIT"
  | "HAS_REAR_WIND_BLOCKER"
  | "HAS_REMOTE_CENTRAL_LOCKING"
  | "HAS_ROOF_RACK"
  | "HAS_SEAT_SETTINGS"
  | "HAS_SEAT_VENTILATION"
  | "HAS_SECURITY_PACKAGE"
  | "HAS_SELF_PARKING_SYSTEM"
  | "HAS_SELF_STEERING_SYSTEM"
  | "HAS_SERVO_STEERING"
  | "HAS_SKI_BAG"
  | "HAS_SMARTPHONE_INDUCTION_CHARGER"
  | "HAS_SMOKERS_PACKAGE"
  | "HAS_SOUND_SYSTEM"
  | "HAS_SPEED_CONTROL"
  | "HAS_SPEED_LIMITER"
  | "HAS_SPORT_PACKAGE"
  | "HAS_SPORT_SEATS"
  | "HAS_SPORT_SUSPENSION"
  | "HAS_SPORT_UNDERCARRIAGE"
  | "HAS_START_STOP_AUTOMATIC"
  | "HAS_START_STOP_SYSTEM_ENGINE"
  | "HAS_STEERING_STABILIZER_ASSIST"
  | "HAS_STEERING_WHEEL_WITH_MULTIFUNCTION"
  | "HAS_SUNROOF"
  | "HAS_SUN_BLIND_SYSTEM"
  | "HAS_THERMAL_GLAZING_INSULATION"
  | "HAS_TIRE_PRESSURE_MONITORING_SYSTEM"
  | "HAS_TOUCH_SCREEN"
  | "HAS_TOW_BAR"
  | "HAS_TRACTION_CONTROL"
  | "HAS_TRAFFIC_SIGN_RECOGNITION"
  | "HAS_TRAILER_COUPLING"
  | "HAS_TUNING"
  | "HAS_TV"
  | "HAS_UNCLASSIFIED_FEATURE_DETAIL"
  | "HAS_UNDERCARRIAGE"
  | "HAS_USB"
  | "HAS_VIRTUAL_COCKPIT"
  | "HAS_VOICE_CONTROL"
  | "HAS_WARRANTY"
  | "HAS_WIFI_HOTSPOT"
  | "HAS_WINTER_PACKAGE"
  | "HAS_WOOD_FLOORING_CARGO_AREA"
  | "HAS_WOOD_PANELING_CARGO_AREA"
  | "HAS_XENON_LIGHTS"
  | "IS_BIODIESEL_SUITABLE"
  | "IS_CONVERTIBLE"
  | "IS_E10_ENABLED"
  | "IS_ELECTRO_HYDRAULIC_CONVERTIBLE"
  | "IS_FINANCED";

export type FeatureUsageDateSource =
  | "ORDER_CONTRACT_SIGNED_ON"
  | "ORDER_CREATED_ON";

export interface FeeItemProjection {
  __typename?: "FeeItemProjection";
  created: Maybe<Scalars["String"]>;
  currencyCode: Maybe<Scalars["String"]>;
  discountGrossMinorUnits: Maybe<Scalars["Long"]>;
  discountMinorUnits: Maybe<Scalars["Long"]>;
  externalId: Maybe<Scalars["UUID"]>;
  externalType: Maybe<Scalars["Int"]>;
  fee: Maybe<RetailFeeProjection>;
  id: Maybe<Scalars["UUID"]>;
  invoiceDescription: Maybe<Scalars["String"]>;
  invoiceName: Maybe<Scalars["String"]>;
  orderId: Maybe<Scalars["UUID"]>;
  parentId: Maybe<Scalars["UUID"]>;
  priceDiscountGross: Maybe<PriceDto>;
  priceDiscountNet: Maybe<PriceDto>;
  priceDiscountVat: Maybe<PriceDto>;
  priceGross: Maybe<PriceDto>;
  priceGrossMinorUnits: Maybe<Scalars["Long"]>;
  priceNet: Maybe<PriceDto>;
  priceNetMinorUnits: Maybe<Scalars["Long"]>;
  priceTaxAdvantageAmount: Maybe<PriceDto>;
  priceVatAmount: Maybe<PriceDto>;
  type: Maybe<Scalars["String"]>;
  updated: Maybe<Scalars["String"]>;
  vatAmountMinorUnits: Maybe<Scalars["Long"]>;
  vatKey: Maybe<Scalars["String"]>;
  vatRate: Maybe<Scalars["Float"]>;
}

export interface FilterProjectionInput {
  property?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
}

export interface FinanceAppProjection {
  __typename?: "FinanceAppProjection";
  id: Maybe<Scalars["UUID"]>;
  orderId: Maybe<Scalars["UUID"]>;
}

export interface FinanceRejectReasonFiltersInput {
  financingType?: InputMaybe<Scalars["String"]>;
}

export interface FinanceRiskEvaluationProjection {
  __typename?: "FinanceRiskEvaluationProjection";
  contractType: Maybe<Scalars["String"]>;
  employedUnderLocalWorkContract: Maybe<Scalars["Boolean"]>;
  employmentStatus: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  orderId: Maybe<Scalars["UUID"]>;
  sameEmployerOverTimePeriod: Maybe<Scalars["Boolean"]>;
}

export interface FinancingApplicabilityProjection {
  __typename?: "FinancingApplicabilityProjection";
  applicability: Maybe<Scalars["String"]>;
  inapplicabilityReason: Maybe<Scalars["String"]>;
}

export interface FinancingApplicationPendingBankDecisionTaskProjection {
  __typename?: "FinancingApplicationPendingBankDecisionTaskProjection";
  action: Maybe<Scalars["String"]>;
  assignedOn: Maybe<Scalars["String"]>;
  assignedTo: Maybe<UserProjection>;
  assignee: Maybe<UserProjection>;
  cashAmount: Maybe<PriceDto>;
  closedBy: Maybe<UserProjection>;
  closedOn: Maybe<Scalars["String"]>;
  comment: Maybe<Scalars["String"]>;
  commentsCount: Maybe<Scalars["Int"]>;
  completedBy: Maybe<UserProjection>;
  completedOn: Maybe<Scalars["String"]>;
  createdOn: Maybe<Scalars["String"]>;
  creditAmount: Maybe<PriceDto>;
  creditPeriod: Maybe<Scalars["Int"]>;
  dateOfApplicationSubmition: Maybe<Scalars["String"]>;
  financingStatus: Maybe<Scalars["String"]>;
  flowVersion: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  name: Maybe<Scalars["String"]>;
  order: Maybe<OrderProjection>;
  orderFinanceCancelReasons: Maybe<
    Array<Maybe<OrderFinanceCancelReasonProjection>>
  >;
  orderId: Maybe<Scalars["UUID"]>;
  orderNumber: Maybe<Scalars["String"]>;
  orderState: Maybe<Scalars["String"]>;
  paymentStatus: Maybe<Scalars["String"]>;
  paymentType: Maybe<Scalars["String"]>;
  reason: Maybe<Scalars["String"]>;
  repeatAt: Maybe<Scalars["String"]>;
  sequenceIndex: Scalars["Int"];
  state: Maybe<Scalars["String"]>;
  stockNumber: Maybe<Scalars["String"]>;
  taskDefinitionKey: Maybe<Scalars["String"]>;
  taskStatus: Maybe<Scalars["String"]>;
  taskType: Maybe<Scalars["String"]>;
  taskUpdatedAt: Maybe<Scalars["String"]>;
}

export interface FinancingApplicationValidationTaskProjection {
  __typename?: "FinancingApplicationValidationTaskProjection";
  action: Maybe<Scalars["String"]>;
  assignedOn: Maybe<Scalars["String"]>;
  assignedTo: Maybe<UserProjection>;
  assignee: Maybe<UserProjection>;
  cashAmount: Maybe<PriceDto>;
  closedBy: Maybe<UserProjection>;
  closedOn: Maybe<Scalars["String"]>;
  comment: Maybe<Scalars["String"]>;
  commentsCount: Maybe<Scalars["Int"]>;
  completedBy: Maybe<UserProjection>;
  completedOn: Maybe<Scalars["String"]>;
  createdOn: Maybe<Scalars["String"]>;
  creditAmount: Maybe<PriceDto>;
  dateOfApplicationValidation: Maybe<Scalars["String"]>;
  externalFinancingOptionsValues: Maybe<Array<Maybe<Scalars["ObjectScalar"]>>>;
  financingBankSubmissions: Maybe<Array<Maybe<Scalars["String"]>>>;
  financingStatus: Maybe<Scalars["String"]>;
  flowVersion: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  name: Maybe<Scalars["String"]>;
  order: Maybe<OrderProjection>;
  orderFinanceCancelReasons: Maybe<
    Array<Maybe<OrderFinanceCancelReasonProjection>>
  >;
  orderFinanceRejectReasons: Maybe<
    Array<Maybe<OrderFinanceRejectReasonProjection>>
  >;
  orderId: Maybe<Scalars["UUID"]>;
  orderNumber: Maybe<Scalars["String"]>;
  orderState: Maybe<Scalars["String"]>;
  paymentStatus: Maybe<Scalars["String"]>;
  paymentType: Maybe<Scalars["String"]>;
  reason: Maybe<Scalars["String"]>;
  repeatAt: Maybe<Scalars["String"]>;
  sequenceIndex: Scalars["Int"];
  state: Maybe<Scalars["String"]>;
  stockNumber: Maybe<Scalars["String"]>;
  taskDefinitionKey: Maybe<Scalars["String"]>;
  taskStatus: Maybe<Scalars["String"]>;
  taskType: Maybe<Scalars["String"]>;
  taskUpdatedAt: Maybe<Scalars["String"]>;
}

export interface FinancingCalculatorResponseProjection {
  __typename?: "FinancingCalculatorResponseProjection";
  calculatorTextFragmentFinancingPlanDetails: Maybe<RetailFinancingPlanProjection>;
  financingConditions: Maybe<FinancingConditionsProjection>;
  internalFinancingFeature: Maybe<RetailFinancingPlanProjection>;
}

export interface FinancingConditionsProjection {
  __typename?: "FinancingConditionsProjection";
  additionalFinancingFee: Maybe<Scalars["Long"]>;
  currencyCode: Maybe<Scalars["String"]>;
  discount: Maybe<Scalars["Long"]>;
  downpayment: Maybe<Scalars["Long"]>;
  effectiveInterestRate: Maybe<Scalars["Float"]>;
  financingAmount: Maybe<Scalars["Long"]>;
  financingPeriod: Maybe<Scalars["Int"]>;
  insuranceEffectiveRate: Maybe<Scalars["Float"]>;
  insuranceMonthlyInstalment: Maybe<Scalars["Long"]>;
  maxFinancingAmount: Maybe<Scalars["Long"]>;
  maxPeriod: Maybe<Scalars["Int"]>;
  minCashAmount: Maybe<Scalars["Long"]>;
  minFinancingAmount: Maybe<Scalars["Long"]>;
  minPeriod: Maybe<Scalars["Int"]>;
  monthlyInstalment: Maybe<Scalars["Long"]>;
  monthlyInstalmentNoRounding: Maybe<Scalars["Long"]>;
  monthlyInstalmentWithInsurance: Maybe<Scalars["Long"]>;
  monthlyInstalmentWithInsuranceNoRounding: Maybe<Scalars["Long"]>;
  nominalInterestRate: Maybe<Scalars["Float"]>;
  periodStep: Maybe<Scalars["Int"]>;
  totalCostOfCredit: Maybe<Scalars["Long"]>;
  totalCostOfInsurance: Maybe<Scalars["Long"]>;
  totalFinancingAmount: Maybe<Scalars["Long"]>;
  totalOwedAmount: Maybe<Scalars["Long"]>;
  totalOwedAmountWithInsurance: Maybe<Scalars["Long"]>;
}

export interface FinancingInternalApprovalTaskProjection {
  __typename?: "FinancingInternalApprovalTaskProjection";
  action: Maybe<Scalars["String"]>;
  assignedOn: Maybe<Scalars["String"]>;
  assignedTo: Maybe<UserProjection>;
  assignee: Maybe<UserProjection>;
  closedBy: Maybe<UserProjection>;
  closedOn: Maybe<Scalars["String"]>;
  comment: Maybe<Scalars["String"]>;
  commentsCount: Maybe<Scalars["Int"]>;
  completedBy: Maybe<UserProjection>;
  completedOn: Maybe<Scalars["String"]>;
  createdOn: Maybe<Scalars["String"]>;
  financingStatus: Maybe<Scalars["String"]>;
  flowVersion: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  name: Maybe<Scalars["String"]>;
  order: Maybe<OrderProjection>;
  orderContractSignedOn: Maybe<Scalars["String"]>;
  orderId: Maybe<Scalars["UUID"]>;
  orderNumber: Maybe<Scalars["String"]>;
  orderState: Maybe<Scalars["String"]>;
  paymentStatus: Maybe<Scalars["String"]>;
  paymentType: Maybe<Scalars["String"]>;
  pendingVerificationOn: Maybe<Scalars["String"]>;
  reason: Maybe<Scalars["String"]>;
  repeatAt: Maybe<Scalars["String"]>;
  sequenceIndex: Scalars["Int"];
  state: Maybe<Scalars["String"]>;
  stockNumber: Maybe<Scalars["String"]>;
  taskDefinitionKey: Maybe<Scalars["String"]>;
  taskStatus: Maybe<Scalars["String"]>;
  taskType: Maybe<Scalars["String"]>;
  taskUpdatedAt: Maybe<Scalars["String"]>;
}

export interface FinancingPaymentDetailsUpdateProjectionInput {
  contractType?: InputMaybe<Scalars["String"]>;
  interestRate?: InputMaybe<Scalars["Float"]>;
}

export interface FinancingSearchProjection {
  __typename?: "FinancingSearchProjection";
  adId: Maybe<Scalars["UUID"]>;
  applicationNumber: Maybe<Scalars["String"]>;
  balloonPayment: Maybe<Scalars["Long"]>;
  cashAmountMinorUnits: Maybe<Scalars["Long"]>;
  createdAt: Maybe<Scalars["String"]>;
  creditAmountMinorUnits: Maybe<Scalars["Long"]>;
  creditPeriod: Maybe<Scalars["Int"]>;
  creditType: Maybe<Scalars["String"]>;
  currencyCode: Maybe<Scalars["String"]>;
  customer: Maybe<CustomerSearchProjection>;
  financingId: Maybe<Scalars["UUID"]>;
  financingType: Maybe<Scalars["String"]>;
  flowType: Maybe<Scalars["String"]>;
  gap: Maybe<Scalars["Boolean"]>;
  interestRate: Maybe<Scalars["Float"]>;
  monthlyPaymentAmountMinorUnits: Maybe<Scalars["Long"]>;
  opportunity: Maybe<OpportunitySearchProjection>;
  order: Maybe<OrderSearchProjection>;
  orderId: Maybe<Scalars["UUID"]>;
  paymentType: Maybe<Scalars["String"]>;
  ppi: Maybe<Scalars["Boolean"]>;
  selectedPlanId: Maybe<Scalars["UUID"]>;
  status: Maybe<Scalars["String"]>;
  totalPaymentAmount: Maybe<Scalars["Long"]>;
}

export interface FrRegionalTaxBasedPriceModifierProjection {
  __typename?: "FrRegionalTaxBasedPriceModifierProjection";
  active: Scalars["Boolean"];
  carFuelType: Scalars["String"];
  createdBy: Scalars["UUID"];
  createdOn: Maybe<Scalars["String"]>;
  handlingFeeMinorUnits: Scalars["Int"];
  id: Scalars["UUID"];
  localTaxRateMinorUnits: Scalars["Int"];
  pnlCostPrice: Maybe<PriceDetailsProjection>;
  postalCodeIndex: Scalars["String"];
  postalFeeMinorUnits: Scalars["Int"];
  price: Maybe<PriceDetailsProjection>;
  priceModifierInstance: Scalars["String"];
  productId: Scalars["UUID"];
  updatedOn: Maybe<Scalars["String"]>;
}

export interface FrRegionalTaxBasedPriceModifierUpdateProjectionInput {
  active?: InputMaybe<Scalars["Boolean"]>;
  grossPriceMinorUnits?: InputMaybe<Scalars["Long"]>;
  handlingFeeMinorUnits?: InputMaybe<Scalars["Int"]>;
  localTaxRateMinorUnits?: InputMaybe<Scalars["Int"]>;
  pnlCostGrossPriceMinorUnits?: InputMaybe<Scalars["Long"]>;
  pnlCostVatKey?: InputMaybe<VatKeyType>;
  pnlCostVatPercentage?: InputMaybe<Scalars["Float"]>;
  postalFeeMinorUnits?: InputMaybe<Scalars["Int"]>;
  vatKey?: InputMaybe<VatKeyType>;
  vatPercentage?: InputMaybe<Scalars["Float"]>;
}

export interface FuelConsumptionDto {
  __typename?: "FuelConsumptionDTO";
  city: Maybe<Scalars["BigDecimal"]>;
  combined: Maybe<Scalars["BigDecimal"]>;
  highway: Maybe<Scalars["BigDecimal"]>;
}

export interface FuelConsumptionDtoInput {
  city?: InputMaybe<Scalars["BigDecimal"]>;
  combined?: InputMaybe<Scalars["BigDecimal"]>;
  highway?: InputMaybe<Scalars["BigDecimal"]>;
}

export interface GdprProjection {
  __typename?: "GDPRProjection";
  report: Maybe<Scalars["Map_String_StringScalar"]>;
}

export type GpsType = "BUILT_IN" | "MOBILE";

export interface GqlRetailCarDocumentsService {
  __typename?: "GQLRetailCarDocumentsService";
  DOCUMENT_ITEM_UPDATE_NOT_PERMITTED: Maybe<Scalars["String"]>;
  DOCUMENT_PACKAGE_EMPTY_BRANCH_NAME_VALIDATION_CODE: Maybe<Scalars["String"]>;
  DOCUMENT_PACKAGE_EMPTY_FIRST_NAME_VALIDATION_CODE: Maybe<Scalars["String"]>;
  DOCUMENT_PACKAGE_EMPTY_LAST_NAME_VALIDATION_CODE: Maybe<Scalars["String"]>;
  DOCUMENT_PACKAGE_NO_HOUSE_NUM_ADDRESS_VALIDATION_CODE: Maybe<
    Scalars["String"]
  >;
  DOCUMENT_PACKAGE_STATUS_NOT_OPEN_VALIDATION_CODE: Maybe<Scalars["String"]>;
  EMPTY_DELIVERED_ON_DATE_VALIDATION_CODE: Maybe<Scalars["String"]>;
  EMPTY_PICKEDUP_ON_DATE_VALIDATION_CODE: Maybe<Scalars["String"]>;
  ITEMS_IN_MULTI_PACKAGES_VALIDATION_CODE: Maybe<Scalars["String"]>;
  ITEMS_LOCATION_CONFLICT_VALIDATION_CODE: Maybe<Scalars["String"]>;
  PACKAGE_STATUS_UPDATE_ALREADY_CANCELED_VALIDATION_CODE: Maybe<
    Scalars["String"]
  >;
  PACKAGE_STATUS_UPDATE_ALREADY_DELIVERED_VALIDATION_CODE: Maybe<
    Scalars["String"]
  >;
  PACKAGE_STATUS_UPDATE_SAME_STATUS_VALIDATION_CODE: Maybe<Scalars["String"]>;
  ZERO_ITEMS_VALIDATION_CODE: Maybe<Scalars["String"]>;
  ad: Maybe<AdProjection>;
  documentInventoryItemLocations: Maybe<ResultConnection_DocumentLocation>;
  documentInventoryItems: Maybe<ResultConnection_DocumentInventoryItem>;
  documentInventoryPackage: Maybe<DocumentInventoryPackageProjection>;
  documentInventoryPackages: Maybe<ResultConnection_DocumentInventoryPackageProjection>;
  documentInventoryTypes: Maybe<DocumentInventoryTypeConnection_DocumentInventoryType>;
  documentShippingProviders: Maybe<ResultConnection_ShippingProviderProjection>;
}

export interface GqlRetailCarDocumentsServiceAdArgs {
  adId: Scalars["UUID"];
}

export interface GqlRetailCarDocumentsServiceDocumentInventoryItemLocationsArgs {
  filter: DocumentInventoryItemLocationFilterInput;
}

export interface GqlRetailCarDocumentsServiceDocumentInventoryItemsArgs {
  filter: DocumentInventoryItemsFilterInput;
}

export interface GqlRetailCarDocumentsServiceDocumentInventoryPackageArgs {
  id: Scalars["UUID"];
}

export interface GqlRetailCarDocumentsServiceDocumentInventoryPackagesArgs {
  filter: InputMaybe<DocumentInventoryPackageFilterInput>;
}

export interface GeneralDto {
  __typename?: "GeneralDTO";
  carStarts: Maybe<Scalars["Boolean"]>;
  cmrPhoto: Maybe<Scalars["UUID"]>;
  hasKey: Maybe<Scalars["Boolean"]>;
  keyBatteryWorks: Maybe<Scalars["Boolean"]>;
}

export interface GeneralDtoInput {
  carStarts?: InputMaybe<Scalars["Boolean"]>;
  cmrPhoto?: InputMaybe<Scalars["UUID"]>;
  hasKey?: InputMaybe<Scalars["Boolean"]>;
  keyBatteryWorks?: InputMaybe<Scalars["Boolean"]>;
}

export interface GpsDtoInput {
  name?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
}

export interface GroupCreateProjectionInput {
  description?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  roleIds?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
}

export interface GroupProjection {
  __typename?: "GroupProjection";
  createdBy: Maybe<RetailUserDto>;
  createdOn: Maybe<Scalars["String"]>;
  description: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  name: Maybe<Scalars["String"]>;
  roles: Maybe<Array<Maybe<RoleProjection>>>;
  updatedOn: Maybe<Scalars["String"]>;
  users: Maybe<Array<Maybe<UserProjection>>>;
}

export interface GroupUpdateProjectionInput {
  description?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
}

export interface HandoverSubmissionResultProjection {
  __typename?: "HandoverSubmissionResultProjection";
  customerEmail: Maybe<Scalars["String"]>;
  pdfDownloadLink: Maybe<Scalars["String"]>;
}

export interface HappinessCallTaskManagementProjection {
  __typename?: "HappinessCallTaskManagementProjection";
  action: Maybe<Scalars["String"]>;
  assignedOn: Maybe<Scalars["String"]>;
  assignedTo: Maybe<UserProjection>;
  assignee: Maybe<UserProjection>;
  carHandoverOnDate: Maybe<Scalars["String"]>;
  closedBy: Maybe<UserProjection>;
  closedOn: Maybe<Scalars["String"]>;
  comment: Maybe<Scalars["String"]>;
  commentsCount: Maybe<Scalars["Int"]>;
  completedBy: Maybe<UserProjection>;
  completedOn: Maybe<Scalars["String"]>;
  createdOn: Maybe<Scalars["String"]>;
  financingStatus: Maybe<Scalars["String"]>;
  flowVersion: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  name: Maybe<Scalars["String"]>;
  order: Maybe<OrderProjection>;
  orderContractSignedOn: Maybe<Scalars["String"]>;
  orderHandoverOn: Maybe<Scalars["String"]>;
  orderId: Maybe<Scalars["UUID"]>;
  orderNumber: Maybe<Scalars["String"]>;
  orderState: Maybe<Scalars["String"]>;
  paymentStatus: Maybe<Scalars["String"]>;
  paymentType: Maybe<Scalars["String"]>;
  reason: Maybe<Scalars["String"]>;
  repeatAt: Maybe<Scalars["String"]>;
  retailCountry: Maybe<Scalars["String"]>;
  sequenceIndex: Scalars["Int"];
  state: Maybe<Scalars["String"]>;
  stockNumber: Maybe<Scalars["String"]>;
  taskDefinitionKey: Maybe<Scalars["String"]>;
  taskStatus: Maybe<Scalars["String"]>;
  taskType: Maybe<Scalars["String"]>;
  taskUpdatedAt: Maybe<Scalars["String"]>;
}

export interface HighlightDto {
  __typename?: "HighlightDTO";
  id: Maybe<Scalars["UUID"]>;
  locale: Maybe<Scalars["String"]>;
  name: Maybe<Scalars["String"]>;
  translationKey: Maybe<Scalars["String"]>;
  type: Maybe<Scalars["String"]>;
}

export interface HighlightProjectionInput {
  id?: InputMaybe<Scalars["UUID"]>;
  name?: InputMaybe<Scalars["String"]>;
  translationKey?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
}

export type HistoryEventType =
  | "ATTENDED_OUTBOUND_CALL"
  | "BRANCH"
  | "CAR_DESCRIPTION_UPDATE"
  | "CAR_DOWNPRICING"
  | "CHAT"
  | "CHAT_REQUEST"
  | "CHECKOUT"
  | "FAILED_OUTBOUND_CALL"
  | "IMPORTED_INBOUND_CALL"
  | "IMPORTED_MISSED_CALL"
  | "INBOUND_EMAIL"
  | "INBOUND_PHONE"
  | "MISSED_CALL"
  | "MISSED_PHONE_CALL"
  | "NOTE"
  | "ORDER_EMAIL_OUTGOING"
  | "ORDER_EMAIL_REPLY"
  | "OTHER"
  | "OUTBOUND_EMAIL"
  | "OUTBOUND_PHONE"
  | "SMS"
  | "SYSTEM_OUTBOUND_EMAIL"
  | "TASK"
  | "TEST_DRIVE_REQUEST"
  | "UNATTENDED_OUTBOUND_CALL"
  | "UNKNOWN";

export interface HomeDeliveryOptionRequestDtoInput {
  destinationCountry?: InputMaybe<Scalars["String"]>;
  destinationZipcode?: InputMaybe<Scalars["String"]>;
}

export interface ItPowerAndZoneBasedPriceModifierProjection {
  __typename?: "ITPowerAndZoneBasedPriceModifierProjection";
  active: Scalars["Boolean"];
  createdBy: Scalars["UUID"];
  createdOn: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  pnlCostPrice: Maybe<PriceDetailsProjection>;
  postalZone: Scalars["String"];
  power: Scalars["Int"];
  price: Maybe<PriceDetailsProjection>;
  priceModifierInstance: Scalars["String"];
  productId: Scalars["UUID"];
  updatedOn: Maybe<Scalars["String"]>;
}

export interface ItPowerAndZoneBasedPriceModifierUpdateProjectionInput {
  active?: InputMaybe<Scalars["Boolean"]>;
  grossPriceMinorUnits?: InputMaybe<Scalars["Long"]>;
  pnlCostGrossPriceMinorUnits?: InputMaybe<Scalars["Long"]>;
  pnlCostVatKey?: InputMaybe<VatKeyType>;
  pnlCostVatPercentage?: InputMaybe<Scalars["Float"]>;
  vatKey?: InputMaybe<VatKeyType>;
  vatPercentage?: InputMaybe<Scalars["Float"]>;
}

export interface ImageDto {
  __typename?: "ImageDTO";
  cdnContainer: Maybe<Scalars["String"]>;
  fullUrl: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  order: Maybe<Scalars["Int"]>;
  part: Maybe<Scalars["String"]>;
  photoId: Maybe<Scalars["Int"]>;
  url: Maybe<Scalars["String"]>;
}

export interface ImageIdDto {
  __typename?: "ImageIdDTO";
  id: Maybe<Scalars["UUID"]>;
  url: Maybe<Scalars["String"]>;
}

export interface ImageProjection {
  __typename?: "ImageProjection";
  cdnContainer: Maybe<Scalars["String"]>;
  fullUrl: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  order: Maybe<Scalars["Int"]>;
  part: Maybe<Scalars["String"]>;
  photoId: Maybe<Scalars["Int"]>;
  url: Maybe<Scalars["String"]>;
}

export interface ImageProjectionInput {
  cdnContainer?: InputMaybe<Scalars["String"]>;
  fullUrl?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["UUID"]>;
  order?: InputMaybe<Scalars["Int"]>;
  part?: InputMaybe<Scalars["String"]>;
  photoId?: InputMaybe<Scalars["Int"]>;
  url?: InputMaybe<Scalars["String"]>;
}

export interface ImagesDtoInput {
  certifiedCheque?: InputMaybe<Scalars["UUID"]>;
  comment?: InputMaybe<Scalars["UUID"]>;
  comments?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  instantTransfer?: InputMaybe<Scalars["UUID"]>;
  odometer?: InputMaybe<Scalars["UUID"]>;
  overviewFront?: InputMaybe<Scalars["UUID"]>;
  overviewRear?: InputMaybe<Scalars["UUID"]>;
  secondaryWheels?: InputMaybe<Scalars["UUID"]>;
  sideLeft?: InputMaybe<Scalars["UUID"]>;
  sideRight?: InputMaybe<Scalars["UUID"]>;
  signature?: InputMaybe<Scalars["UUID"]>;
}

export interface ImportCarleadProjectionInput {
  carHash?: InputMaybe<Scalars["String"]>;
  countryCode?: InputMaybe<Scalars["String"]>;
  isTest?: InputMaybe<Scalars["Boolean"]>;
  skipPublish?: InputMaybe<Scalars["Boolean"]>;
}

export interface InboundOutboundPaymentRefDistributionProjection {
  __typename?: "InboundOutboundPaymentRefDistributionProjection";
  amount: Maybe<RetailFinancePriceProjection>;
  inboundPayment: Maybe<RetailPaymentProjection>;
  refundTempId: Maybe<Scalars["Int"]>;
}

export interface InboundOutboundPaymentRefDistributionProjectionInput {
  amount?: InputMaybe<RetailFinancePriceProjectionInput>;
  inboundPayment?: InputMaybe<RetailPaymentProjectionInput>;
  refundTempId?: InputMaybe<Scalars["Int"]>;
}

export interface InsuranceItemProjection {
  __typename?: "InsuranceItemProjection";
  created: Maybe<Scalars["String"]>;
  currencyCode: Maybe<Scalars["String"]>;
  discountGrossMinorUnits: Maybe<Scalars["Long"]>;
  discountMinorUnits: Maybe<Scalars["Long"]>;
  externalId: Maybe<Scalars["UUID"]>;
  externalType: Maybe<Scalars["Int"]>;
  id: Maybe<Scalars["UUID"]>;
  insurance: Maybe<RetailInsuranceProjection>;
  invoiceDescription: Maybe<Scalars["String"]>;
  invoiceName: Maybe<Scalars["String"]>;
  orderId: Maybe<Scalars["UUID"]>;
  parentId: Maybe<Scalars["UUID"]>;
  priceDiscountGross: Maybe<PriceDto>;
  priceDiscountNet: Maybe<PriceDto>;
  priceDiscountVat: Maybe<PriceDto>;
  priceGross: Maybe<PriceDto>;
  priceGrossMinorUnits: Maybe<Scalars["Long"]>;
  priceNet: Maybe<PriceDto>;
  priceNetMinorUnits: Maybe<Scalars["Long"]>;
  priceTaxAdvantageAmount: Maybe<PriceDto>;
  priceVatAmount: Maybe<PriceDto>;
  type: Maybe<Scalars["String"]>;
  updated: Maybe<Scalars["String"]>;
  vatAmountMinorUnits: Maybe<Scalars["Long"]>;
  vatKey: Maybe<Scalars["String"]>;
  vatRate: Maybe<Scalars["Float"]>;
}

export interface IntegerRangeDto {
  __typename?: "IntegerRangeDTO";
  from: Maybe<Scalars["Int"]>;
  to: Maybe<Scalars["Int"]>;
}

export interface InteriorDto {
  __typename?: "InteriorDTO";
  backSeatsPhoto: Maybe<Scalars["UUID"]>;
  frontSeatsPhoto: Maybe<Scalars["UUID"]>;
  fuelLevel: Maybe<Scalars["String"]>;
  fuelLevelPhoto: Maybe<Scalars["UUID"]>;
  gps: Maybe<Scalars["Boolean"]>;
  gpsManufacturer: Maybe<Scalars["String"]>;
  gpsPhoto: Maybe<Scalars["UUID"]>;
  gpsType: Maybe<GpsType>;
  hasFirstAidKit: Maybe<Scalars["Boolean"]>;
  hasFloorMat: Maybe<Scalars["Boolean"]>;
  hasSecondaryWheels: Maybe<Scalars["Boolean"]>;
  hasSecurityTriangle: Maybe<Scalars["Boolean"]>;
  hasSpareTire: Maybe<Scalars["Boolean"]>;
  hasTireFit: Maybe<Scalars["Boolean"]>;
  hasTrunkCover: Maybe<Scalars["Boolean"]>;
  hasWarningVest: Maybe<Scalars["Boolean"]>;
  interiorPhotos: Maybe<Array<Maybe<Scalars["UUID"]>>>;
  isInteriorClean: Maybe<Scalars["Boolean"]>;
  millage: Maybe<Scalars["Int"]>;
  odometerPhoto: Maybe<Scalars["UUID"]>;
  radio: Maybe<Scalars["Boolean"]>;
  radioManufacturer: Maybe<Scalars["String"]>;
  radioPhoto: Maybe<Scalars["UUID"]>;
  secondaryWheelsPhotos: Maybe<Array<Maybe<Scalars["UUID"]>>>;
  secondaryWheelsRimType: Maybe<RimType>;
  secondaryWheelsTireType: Maybe<TireType>;
  warningLights: Maybe<WarningLightsDto>;
  warningLightsOn: Maybe<Scalars["Boolean"]>;
  warningLightsPhoto: Maybe<Scalars["UUID"]>;
}

export interface InteriorDtoInput {
  backSeatsPhoto?: InputMaybe<Scalars["UUID"]>;
  frontSeatsPhoto?: InputMaybe<Scalars["UUID"]>;
  fuelLevel?: InputMaybe<Scalars["String"]>;
  fuelLevelPhoto?: InputMaybe<Scalars["UUID"]>;
  gps?: InputMaybe<Scalars["Boolean"]>;
  gpsManufacturer?: InputMaybe<Scalars["String"]>;
  gpsPhoto?: InputMaybe<Scalars["UUID"]>;
  gpsType?: InputMaybe<GpsType>;
  hasFirstAidKit?: InputMaybe<Scalars["Boolean"]>;
  hasFloorMat?: InputMaybe<Scalars["Boolean"]>;
  hasSecondaryWheels?: InputMaybe<Scalars["Boolean"]>;
  hasSecurityTriangle?: InputMaybe<Scalars["Boolean"]>;
  hasSpareTire?: InputMaybe<Scalars["Boolean"]>;
  hasTireFit?: InputMaybe<Scalars["Boolean"]>;
  hasTrunkCover?: InputMaybe<Scalars["Boolean"]>;
  hasWarningVest?: InputMaybe<Scalars["Boolean"]>;
  interiorPhotos?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  isInteriorClean?: InputMaybe<Scalars["Boolean"]>;
  millage?: InputMaybe<Scalars["Int"]>;
  odometerPhoto?: InputMaybe<Scalars["UUID"]>;
  radio?: InputMaybe<Scalars["Boolean"]>;
  radioManufacturer?: InputMaybe<Scalars["String"]>;
  radioPhoto?: InputMaybe<Scalars["UUID"]>;
  secondaryWheelsPhotos?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  secondaryWheelsRimType?: InputMaybe<RimType>;
  secondaryWheelsTireType?: InputMaybe<TireType>;
  warningLights?: InputMaybe<WarningLightsDtoInput>;
  warningLightsOn?: InputMaybe<Scalars["Boolean"]>;
  warningLightsPhoto?: InputMaybe<Scalars["UUID"]>;
}

export interface InternalPaymentOptionsProjection {
  __typename?: "InternalPaymentOptionsProjection";
  categories: Maybe<Array<Maybe<Scalars["String"]>>>;
}

export interface InventorySourcingImportCreateProjectionInput {
  fileName?: InputMaybe<Scalars["String"]>;
}

export interface InvoiceCreditNoteCreateProjectionInput {
  creditNoteNumber?: InputMaybe<Scalars["String"]>;
  invoicedOn?: InputMaybe<Scalars["String"]>;
  number?: InputMaybe<Scalars["String"]>;
  orderId?: InputMaybe<Scalars["UUID"]>;
  proformaNumber?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<InvoiceState>;
  totalPriceNetMinorUnits?: InputMaybe<Scalars["Long"]>;
}

export type InvoiceItemExternalType =
  | "AD"
  | "AD_SECONDARY_WHEELS"
  | "DELIVERY"
  | "DOWN_PAYMENT"
  | "PRODUCT"
  | "REGISTRATION"
  | "VEHICLE_PREPARATION"
  | "WARRANTY";

export interface InvoiceItemProjection {
  __typename?: "InvoiceItemProjection";
  createdBy: Maybe<Scalars["UUID"]>;
  createdOn: Maybe<Scalars["String"]>;
  discountGross: Maybe<PriceDto>;
  externalId: Maybe<Scalars["UUID"]>;
  externalType: Maybe<InvoiceItemExternalType>;
  id: Maybe<Scalars["UUID"]>;
  priceGross: Maybe<PriceDto>;
  priceGrossMinorUnits: Maybe<Scalars["Long"]>;
  priceNet: Maybe<PriceDto>;
  priceNetMinorUnits: Maybe<Scalars["Long"]>;
  taxAdvantageAmountMinorUnits: Maybe<Scalars["Long"]>;
  updatedOn: Maybe<Scalars["String"]>;
  vatAmount: Maybe<PriceDto>;
  vatAmountMinorUnits: Maybe<Scalars["Long"]>;
  vatKey: Maybe<Scalars["String"]>;
  vatRate: Maybe<Scalars["Float"]>;
}

export interface InvoiceProjection {
  __typename?: "InvoiceProjection";
  createdBy: Maybe<Scalars["UUID"]>;
  createdOn: Maybe<Scalars["String"]>;
  creditNoteNumber: Maybe<Scalars["String"]>;
  deleted: Scalars["Boolean"];
  id: Maybe<Scalars["UUID"]>;
  invoicedOn: Maybe<Scalars["String"]>;
  items: Maybe<Array<Maybe<InvoiceItemProjection>>>;
  number: Maybe<Scalars["String"]>;
  oracleAccountingDate: Maybe<Scalars["String"]>;
  oracleFilePresignedUrl: Maybe<Scalars["String"]>;
  oracleGrossAmount: Maybe<PriceDto>;
  oracleIssueDate: Maybe<Scalars["String"]>;
  oracleTransactionalNumber: Maybe<Scalars["String"]>;
  orderId: Maybe<Scalars["UUID"]>;
  paidOn: Maybe<Scalars["String"]>;
  proformaNumber: Maybe<Scalars["String"]>;
  state: Maybe<InvoiceState>;
  totalPriceGross: Maybe<PriceDto>;
  totalPriceGrossMinorUnits: Maybe<Scalars["Long"]>;
  totalPriceNet: Maybe<PriceDto>;
  totalPriceNetMinorUnits: Maybe<Scalars["Long"]>;
  totalVatAmount: Maybe<PriceDto>;
  totalVatAmountMinorUnits: Maybe<Scalars["Long"]>;
  type: Maybe<InvoiceType>;
  updatedOn: Maybe<Scalars["String"]>;
  vatKey: Maybe<Scalars["String"]>;
}

export type InvoiceState =
  | "CANCELLED"
  | "FULLY_CREDITED"
  | "OPEN"
  | "PAID"
  | "PARTIALLY_CREDITED"
  | "PARTIALLY_PAID"
  | "REFUND";

export type InvoiceType = "CREDIT_NOTE" | "DOWN_PAYMENT" | "STANDARD";

export interface InvoiceUpdateProjectionInput {
  creditNoteNumber?: InputMaybe<Scalars["String"]>;
  invoicedOn?: InputMaybe<Scalars["String"]>;
  number?: InputMaybe<Scalars["String"]>;
  proformaNumber?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<InvoiceState>;
}

export interface JsonObject {
  __typename?: "JSONObject";
  NULL: Maybe<Scalars["ObjectScalar"]>;
  empty: Scalars["Boolean"];
  null: Scalars["Boolean"];
}

export interface JsonObjectNullArgs {
  arg0: InputMaybe<Scalars["String"]>;
}

export interface KbaRequestHistoryProjection {
  __typename?: "KBARequestHistoryProjection";
  createdOn: Scalars["String"];
  digitalCarRegistrationId: Scalars["UUID"];
  errorDescription: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  orderId: Scalars["UUID"];
  referenzId: Maybe<Scalars["String"]>;
  status: Scalars["String"];
}

export interface LatLngInput {
  lat?: InputMaybe<Scalars["Float"]>;
  lng?: InputMaybe<Scalars["Float"]>;
}

export interface LicensePlateProjection {
  __typename?: "LicensePlateProjection";
  digits: Scalars["String"];
  letters: Scalars["String"];
  region: Scalars["String"];
}

export interface LicensePlateProjectionInput {
  digits: Scalars["String"];
  letters: Scalars["String"];
  region: Scalars["String"];
}

export interface LicensePlateTaskProjection {
  __typename?: "LicensePlateTaskProjection";
  action: Maybe<Scalars["String"]>;
  assignedOn: Maybe<Scalars["String"]>;
  assignedTo: Maybe<UserProjection>;
  assignee: Maybe<UserProjection>;
  closedBy: Maybe<UserProjection>;
  closedOn: Maybe<Scalars["String"]>;
  comment: Maybe<Scalars["String"]>;
  commentsCount: Maybe<Scalars["Int"]>;
  completedBy: Maybe<UserProjection>;
  completedOn: Maybe<Scalars["String"]>;
  createdOn: Maybe<Scalars["String"]>;
  financingStatus: Maybe<Scalars["String"]>;
  flowVersion: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  name: Maybe<Scalars["String"]>;
  order: Maybe<OrderProjection>;
  orderId: Maybe<Scalars["UUID"]>;
  orderNumber: Maybe<Scalars["String"]>;
  orderState: Maybe<Scalars["String"]>;
  paymentStatus: Maybe<Scalars["String"]>;
  paymentType: Maybe<Scalars["String"]>;
  reason: Maybe<Scalars["String"]>;
  repeatAt: Maybe<Scalars["String"]>;
  sequenceIndex: Scalars["Int"];
  state: Maybe<Scalars["String"]>;
  stockNumber: Maybe<Scalars["String"]>;
  taskDefinitionKey: Maybe<Scalars["String"]>;
  taskStatus: Maybe<Scalars["String"]>;
  taskType: Maybe<Scalars["String"]>;
  taskUpdatedAt: Maybe<Scalars["String"]>;
}

export interface LinkProjection {
  __typename?: "LinkProjection";
  title: Maybe<Scalars["String"]>;
  url: Maybe<Scalars["String"]>;
}

export interface LinkedOrdersDto {
  __typename?: "LinkedOrdersDTO";
  id: Maybe<Scalars["UUID"]>;
  type: Maybe<Scalars["String"]>;
}

export interface LinkedOrdersDtoInput {
  id?: InputMaybe<Scalars["UUID"]>;
  type?: InputMaybe<Scalars["String"]>;
}

export interface ListContainerDto_AuxUserDto {
  __typename?: "ListContainerDTO_AuxUserDTO";
  entities: Maybe<Array<Maybe<AuxUserDto>>>;
}

export interface ListContainerDto_GroupProjection {
  __typename?: "ListContainerDTO_GroupProjection";
  entities: Maybe<Array<Maybe<GroupProjection>>>;
}

export interface ListContainerDto_OrderItemsRefundsDto {
  __typename?: "ListContainerDTO_OrderItemsRefundsDTO";
  entities: Maybe<Array<Maybe<OrderItemsRefundsDto>>>;
}

export interface ListContainerDto_String {
  __typename?: "ListContainerDTO_String";
  entities: Maybe<Array<Maybe<Scalars["String"]>>>;
}

export interface Location {
  __typename?: "Location";
  latitude: Maybe<Scalars["Float"]>;
  longitude: Maybe<Scalars["Float"]>;
}

export interface LocationDetailDto {
  __typename?: "LocationDetailDTO";
  country: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["Int"]>;
  name: Maybe<Scalars["String"]>;
}

export interface LocationInfoDto {
  __typename?: "LocationInfoDTO";
  currentLocation: Maybe<LocationDetailDto>;
  documentLocation: Maybe<LocationDetailDto>;
  routeEstimatedArrivalDate: Maybe<Scalars["String"]>;
  routeId: Maybe<Scalars["Long"]>;
  routeStatus: Maybe<RouteStatus>;
  sourceLocation: Maybe<LocationDetailDto>;
}

export interface LocationInput {
  latitude?: InputMaybe<Scalars["Float"]>;
  longitude?: InputMaybe<Scalars["Float"]>;
}

export interface LogProjectionInput {
  details?: InputMaybe<Scalars["Map_String_StringScalar"]>;
  eventSource?: InputMaybe<Scalars["String"]>;
  eventType?: InputMaybe<Scalars["String"]>;
}

export interface MileageDto {
  __typename?: "MileageDTO";
  distance: Maybe<Scalars["Int"]>;
  unit: Maybe<MileageUnit>;
}

export interface MileageDtoInput {
  distance?: InputMaybe<Scalars["Int"]>;
  unit?: InputMaybe<MileageUnit>;
}

export interface MileageProjection {
  __typename?: "MileageProjection";
  distance: Scalars["Int"];
  unit: MileageUnit;
}

export interface MileageProjectionInput {
  distance: Scalars["Int"];
  unit: MileageUnit;
}

export type MileageUnit = "KM" | "MILES";

export interface ModelDto {
  __typename?: "ModelDTO";
  make: Maybe<Scalars["String"]>;
  model: Maybe<Scalars["String"]>;
  subModel: Maybe<Scalars["String"]>;
}

export interface MonthlyNetIncomeProjection {
  __typename?: "MonthlyNetIncomeProjection";
  amountMinorUnits: Maybe<Scalars["Long"]>;
  month: Maybe<Scalars["String"]>;
}

export interface MonthlyNetIncomeProjectionInput {
  amountMinorUnits?: InputMaybe<Scalars["Long"]>;
  month?: InputMaybe<Scalars["String"]>;
}

/** Mutation root */
export interface Mutation {
  __typename?: "Mutation";
  addGroupUsers: Scalars["Boolean"];
  addRetailAdSecondaryWheelsImages: Maybe<RetailAdSecondaryWheelsProjection>;
  addUserGroups: Scalars["Boolean"];
  addUserProperties: Scalars["Boolean"];
  addpublishingblockers: Scalars["Boolean"];
  applyPriceUpdate: Scalars["Boolean"];
  approveOrderFinancing: Scalars["Boolean"];
  assignGroupRoles: Scalars["Boolean"];
  assignGroupUsers: Scalars["Boolean"];
  assignRiskAgent: Maybe<RiskAgentProjection>;
  assignRoleGroups: Scalars["Boolean"];
  assignTaskManagerTask: Scalars["Boolean"];
  assignTestDataAccessParam: Scalars["Boolean"];
  assignUnidentifiedPaymentToOrder: Maybe<RetailPaymentProjection>;
  assignUserCountryAccessParams: Scalars["Boolean"];
  assignUserGroupAccessParams: Scalars["Boolean"];
  cancelOrderFinancing: Scalars["Boolean"];
  cancelRetailPayment: Scalars["Boolean"];
  carRegistrationActions: Scalars["Boolean"];
  changeCustomerType: Maybe<RetailCustomerProjection>;
  changeDocumentPackageStatus: Maybe<ChangeDocumentPackageStatusResult>;
  changeReferralStatus: Maybe<ReferralPaymentProjection>;
  changeRegistrationStatus: Scalars["Boolean"];
  changeRetailPaymentOrder: Maybe<RetailPaymentProjection>;
  claimPartialRefundRequestNewRetailBankDetails: Scalars["Boolean"];
  commentTaskManagerTask: Maybe<TaskManagerCommentProjection>;
  completeTaskManagerTask: Scalars["Boolean"];
  completeTaskManagerTaskV2: Scalars["Boolean"];
  createAbTestingExperiment: Maybe<RetailAbTestingExperimentProjection>;
  createAdditionalOrder: Maybe<OrderProjection>;
  createAdditionalProofRequests: Maybe<
    Array<Maybe<RetailClaimProofRequestProjection>>
  >;
  createAttachmentUploadPresignedUrl: Maybe<AttachmentUploadPresignedUrlProjection>;
  createAvailabilityRule: Maybe<AvailabilityRuleProjection>;
  createBranchEntryCheck: Maybe<BranchEntryCheckSubmissionResultProjection>;
  createBranchEntryCheckImageUploadMetadata: Maybe<AwsImageUploadResponseProjection>;
  createCarRegistrationProduct: Maybe<RetailCarRegistrationProjection>;
  createChatbotQuestion: Maybe<ChatbotQuestionDto>;
  createCreditNotes: Maybe<Array<Maybe<CreditNoteProjection>>>;
  createCustomerAddress: Maybe<CustomerAddressProjection>;
  createCustomerUser: Maybe<CustomerProjection>;
  createCustomerUserPasswordless: Maybe<CustomerProjection>;
  createDebtComment: Maybe<RetailOrderDebtCommentProjection>;
  createDigitalHandoverProtocol: Maybe<HandoverSubmissionResultProjection>;
  createDiscount: Maybe<DiscountDto>;
  createDistributedCreditNotesRefunds: Maybe<Scalars["Boolean"]>;
  createDistributedRefunds: Maybe<Scalars["Boolean"]>;
  createDocument: Maybe<RetailDocumentProjection>;
  createDocumentInventoryType: Maybe<CreateDocumentInventoryTypePayload>;
  createDocumentPackage: Maybe<CreateDocumentPackageResult>;
  createDownPaymentProduct: Maybe<DownPaymentProjection>;
  createExternalFinancingEvaluation: Maybe<ExternalFinanceRiskEvaluationProjection>;
  createExternalRiskUserInfo: Maybe<ExternalRiskUserInfoProjection>;
  createFeeProduct: Maybe<RetailFeeProjection>;
  createGroup: Maybe<GroupProjection>;
  createImageTag: Maybe<RetailImageTagProjection>;
  createInsuranceProduct: Maybe<RetailInsuranceProjection>;
  createInvoiceCreditNote: Maybe<InvoiceProjection>;
  createInvoiceDownPayment: Maybe<InvoiceProjection>;
  createInvoiceStandard: Maybe<InvoiceProjection>;
  createInvoiceStandardWithValidation: Maybe<InvoiceProjection>;
  createOrUpdateExternalFinancingCustomerDetails: Maybe<ExternalFinancingCustomerDetailsProjection>;
  createOrUpdateExternalFinancingOptions: Maybe<ExternalFinancingOptionsProjection>;
  createOracleDownPaymentInvoice: Scalars["Boolean"];
  createOracleStandardInvoice: Scalars["Boolean"];
  createOrder: Maybe<OrderProjection>;
  createOrderAddress: Maybe<OrderAddressProjection>;
  createOrderBranchCarCheckupTask: Scalars["Boolean"];
  createOrderBranchCarCheckupTaskV2: Scalars["Boolean"];
  createOrderCancellationRequestedTask: Scalars["Boolean"];
  createOrderCancellationRequestedTaskV2: Scalars["Boolean"];
  createOrderCancellationTask: Scalars["Boolean"];
  createOrderCancellationTaskV2: Scalars["Boolean"];
  createOrderCarReadyForHomeDeliveryTask: Scalars["Boolean"];
  createOrderCarReadyForHomeDeliveryTaskV2: Scalars["Boolean"];
  createOrderCarReadyForPickupCallTask: Scalars["Boolean"];
  createOrderCarReadyForPickupCallTaskV2: Scalars["Boolean"];
  createOrderCarRegistrationDocumentsReturnedToAHTask: Scalars["Boolean"];
  createOrderCarRegistrationDocumentsReturnedToAHTaskV2: Scalars["Boolean"];
  createOrderCarReturnedToAHTask: Scalars["Boolean"];
  createOrderCarReturnedToAHTaskV2: Scalars["Boolean"];
  createOrderCareCallTask: Scalars["Boolean"];
  createOrderCareCallTaskV2: Scalars["Boolean"];
  createOrderClaimClosedTask: Scalars["Boolean"];
  createOrderClaimClosedTaskV2: Scalars["Boolean"];
  createOrderClaimReceivedTask: Scalars["Boolean"];
  createOrderClaimReceivedTaskV2: Scalars["Boolean"];
  createOrderComment: Maybe<OrderCommentProjection>;
  createOrderCustomerAvailabilityForHomeDeliveryTask: Scalars["Boolean"];
  createOrderCustomerAvailabilityForHomeDeliveryTaskV2: Scalars["Boolean"];
  createOrderCustomerSignedBankReleaseTask: Scalars["Boolean"];
  createOrderCustomerSignedBankReleaseTaskV2: Scalars["Boolean"];
  createOrderDocumentsRegistrationReadyTask: Scalars["Boolean"];
  createOrderDocumentsRegistrationReadyTaskV2: Scalars["Boolean"];
  createOrderDocumentsRequestedTask: Scalars["Boolean"];
  createOrderDocumentsRequestedTaskV2: Scalars["Boolean"];
  createOrderFinalDocumentsReceivedTask: Scalars["Boolean"];
  createOrderFinalDocumentsReceivedTaskV2: Scalars["Boolean"];
  createOrderFinancing: Maybe<OrderFinancingDto>;
  createOrderHandoverAppointmentTask: Scalars["Boolean"];
  createOrderHandoverAppointmentTaskV2: Scalars["Boolean"];
  createOrderHappinessCallTask: Scalars["Boolean"];
  createOrderHappinessCallTaskV2: Scalars["Boolean"];
  createOrderHistoryEmailEvent: Maybe<OrderHistoryEventProjection>;
  createOrderHistoryEvent: Maybe<OrderHistoryEventProjection>;
  createOrderItem: Maybe<RetailOrderItemDto>;
  createOrderRegistrationInitiatedTask: Scalars["Boolean"];
  createOrderRegistrationInitiatedTaskV2: Scalars["Boolean"];
  createOrderShippingAddressBody: Maybe<RetailAddressProjection>;
  createOrderUploadedHandoverDocumentTask: Scalars["Boolean"];
  createOrderUploadedHandoverDocumentTaskV2: Scalars["Boolean"];
  createOrderWarrantyContractSentTask: Scalars["Boolean"];
  createOrderWarrantyContractSentTaskV2: Scalars["Boolean"];
  createOrderWarrantyContractSignedTask: Scalars["Boolean"];
  createOrderWarrantyContractSignedTaskV2: Scalars["Boolean"];
  createOtherOrder: Maybe<OrderProjection>;
  createOutboundPayment: Maybe<RetailOutboundPaymentProjection>;
  createPnlItem: Maybe<PnlItemProjection>;
  createPnlItemType: Maybe<PnlItemTypeProjection>;
  createPnlPartner: Maybe<PnlPartnerProjection>;
  createProduct: Maybe<RetailProductProjection>;
  createRefund: Maybe<RetailRefundProjection>;
  createRefundsWithCreditNotesDistribution: Maybe<Scalars["Boolean"]>;
  createRefurbishment: Maybe<RefurbishmentDto>;
  createRefurbishmentComment: Maybe<RefurbishmentCommentProjection>;
  createRefurbishmentServices: Maybe<Array<Maybe<RefurbishmentServiceDto>>>;
  createRefurbishmentServicesOrder: Maybe<
    Array<Maybe<RefurbishmentServiceDto>>
  >;
  createRetailAccountData: Maybe<AccountDataDto>;
  createRetailAdSecondaryWheels: Maybe<RetailAdSecondaryWheelsProjection>;
  createRetailAddress: Maybe<RetailAddressProjection>;
  createRetailCheckout: Maybe<RetailCheckoutProjection>;
  createRetailClaimCost: Maybe<RetailClaimCostProjection>;
  createRetailClaimCostsComment: Maybe<RetailClaimCostCommentProjection>;
  createRetailClaimV2: Maybe<RetailClaimProjection>;
  createRetailClaimWorkshopDetails: Maybe<RetailClaimWorkshopDetailsProjection>;
  createRetailCustomer: Maybe<RetailCustomerProjection>;
  createRetailFeature: Maybe<RetailFeatureProjection>;
  createRetailLead: Maybe<RetailLeadProjection>;
  createRetailOrderAccountDetails: Maybe<RetailOrderAccountDetailsProjection>;
  createRetailOrderSubClaim: Maybe<RetailSubClaimProjection>;
  createRetailPayment: Maybe<RetailPaymentProjection>;
  createRetailSubClaimComment: Maybe<RetailSubClaimCommentProjection>;
  createRetailSubClaimDocument: Maybe<RetailSubClaimDocumentProjection>;
  createRole: Maybe<RoleProjection>;
  createSellerAddress: Maybe<SellerAddressProjection>;
  createStandardOrder: Maybe<OrderProjection>;
  createSubProduct: Scalars["Boolean"];
  createTradeIn: Maybe<RetailTradeInProjection>;
  createUser: Maybe<AuxUserDto>;
  createVehiclePreparationProduct: Maybe<RetailVehiclePreparationProjection>;
  createVerificationCallSurvey: Maybe<SurveyAggregationProjection>;
  createWarrantyProduct: Maybe<RetailWarrantyProjection>;
  deleteAbTestingExperiment: Scalars["Boolean"];
  deleteAbTestingExperimentCounters: Scalars["Boolean"];
  deleteAbTestingParticipants: Maybe<Scalars["Int"]>;
  deleteAvailabilityRule: Scalars["Boolean"];
  deleteChatbotQuestion: Scalars["Boolean"];
  deleteDocumentInventoryItem: Maybe<DeleteDocumentInventoryItemResult>;
  deleteExternalFinancingCustomerDetails: Scalars["Boolean"];
  deleteExternalRiskUserInfo: Scalars["Boolean"];
  deleteGroupUsers: Scalars["Boolean"];
  deleteImageTag: Scalars["Boolean"];
  deleteOrderAddress: Scalars["Boolean"];
  deleteOrderBranchCarCheckupTask: Scalars["Boolean"];
  deleteOrderBranchCarCheckupTaskV2: Scalars["Boolean"];
  deleteOrderCancellationRequestedTask: Scalars["Boolean"];
  deleteOrderCancellationRequestedTaskV2: Scalars["Boolean"];
  deleteOrderCarReadyForHomeDeliveryTask: Scalars["Boolean"];
  deleteOrderCarReadyForHomeDeliveryTaskV2: Scalars["Boolean"];
  deleteOrderCarReadyForPickupCallTask: Scalars["Boolean"];
  deleteOrderCarReadyForPickupCallTaskV2: Scalars["Boolean"];
  deleteOrderCarRegistrationDocumentsReturnedToAHTask: Scalars["Boolean"];
  deleteOrderCarRegistrationDocumentsReturnedToAHTaskV2: Scalars["Boolean"];
  deleteOrderCarReturnedToAHTask: Scalars["Boolean"];
  deleteOrderCarReturnedToAHTaskV2: Scalars["Boolean"];
  deleteOrderCareCallTask: Scalars["Boolean"];
  deleteOrderCareCallTaskV2: Scalars["Boolean"];
  deleteOrderClaimClosedTask: Scalars["Boolean"];
  deleteOrderClaimClosedTaskV2: Scalars["Boolean"];
  deleteOrderClaimReceivedTask: Scalars["Boolean"];
  deleteOrderClaimReceivedTaskV2: Scalars["Boolean"];
  deleteOrderCustomerAvailabilityForHomeDeliveryTask: Scalars["Boolean"];
  deleteOrderCustomerAvailabilityForHomeDeliveryTaskV2: Scalars["Boolean"];
  deleteOrderCustomerSignedBankReleaseTask: Scalars["Boolean"];
  deleteOrderCustomerSignedBankReleaseTaskV2: Scalars["Boolean"];
  deleteOrderDocumentsRegistrationReadyTask: Scalars["Boolean"];
  deleteOrderDocumentsRegistrationReadyTaskV2: Scalars["Boolean"];
  deleteOrderDocumentsRequestedTask: Scalars["Boolean"];
  deleteOrderDocumentsRequestedTaskV2: Scalars["Boolean"];
  deleteOrderFinalDocumentsReceivedTask: Scalars["Boolean"];
  deleteOrderFinalDocumentsReceivedTaskV2: Scalars["Boolean"];
  deleteOrderHandoverAppointmentTask: Scalars["Boolean"];
  deleteOrderHandoverAppointmentTaskV2: Scalars["Boolean"];
  deleteOrderHappinessCallTask: Scalars["Boolean"];
  deleteOrderHappinessCallTaskV2: Scalars["Boolean"];
  deleteOrderItem: Scalars["Boolean"];
  deleteOrderRegistrationInitiatedTask: Scalars["Boolean"];
  deleteOrderRegistrationInitiatedTaskV2: Scalars["Boolean"];
  deleteOrderUploadedHandoverDocumentTask: Scalars["Boolean"];
  deleteOrderUploadedHandoverDocumentTaskV2: Scalars["Boolean"];
  deleteOrderWarrantyContractSentTask: Scalars["Boolean"];
  deleteOrderWarrantyContractSentTaskV2: Scalars["Boolean"];
  deleteOrderWarrantyContractSignedTask: Scalars["Boolean"];
  deleteOrderWarrantyContractSignedTaskV2: Scalars["Boolean"];
  deletePnlItem: Scalars["Boolean"];
  deletePnlItemType: Scalars["Boolean"];
  deletePnlPartner: Scalars["Boolean"];
  deleteProduct: Scalars["Boolean"];
  deleteRefurbishmentComment: Scalars["Boolean"];
  deleteRefurbishmentServices: Scalars["Boolean"];
  deleteRetailAdSecondaryWheelsImages: Maybe<RetailAdSecondaryWheelsProjection>;
  deleteRetailAddress: Scalars["Boolean"];
  deleteRetailClaimCost: Scalars["Boolean"];
  deleteRetailCustomer: Scalars["Boolean"];
  deleteRetailFeature: Maybe<RetailFeatureProjection>;
  deleteRetailSubClaim: Scalars["Boolean"];
  deleteRetailSubClaimDocument: Scalars["Boolean"];
  deleteSubProduct: Scalars["Boolean"];
  deleteUserGroups: Scalars["Boolean"];
  deleteUserProperties: Scalars["Boolean"];
  disableDocumentInventoryType: Maybe<DisableDocumentInventoryTypePayload>;
  disableOrderAutoCancellation: Scalars["Boolean"];
  distributeRetailClaimCosts: Scalars["Boolean"];
  distributeUnidentifiedPayment: Scalars["Boolean"];
  editExternalRiskUserInfo: Maybe<ExternalRiskUserInfoProjection>;
  enableDocumentInventoryType: Maybe<EnableDocumentInventoryTypePayload>;
  externalFinancingEvaluationV2: Maybe<ExternalFinancingEvaluationV2Projection>;
  externalFinancingEvaluationV2Test: Maybe<ExternalFinancingEvaluationV2Projection>;
  generatePdfDocument: Maybe<RetailDocumentGeneratePdfResponseProjection>;
  importCarRegistrationActionItems: Maybe<Array<Maybe<RetailDataImportDto>>>;
  importCarlead: Maybe<AdProjection>;
  importChatbotQuestions: Maybe<Array<Maybe<RetailDataImportDto>>>;
  importInventorySourcingItems: Maybe<Array<Maybe<RetailDataImportDto>>>;
  importPriceUpdateItems: Maybe<Array<Maybe<RetailDataImportDto>>>;
  importProfitAndLossItems: Maybe<Array<Maybe<RetailDataImportDto>>>;
  importRetailDocumentInventoryItems: Maybe<Array<Maybe<RetailDataImportDto>>>;
  importRetailPaymentItems: Maybe<Array<Maybe<RetailDataImportDto>>>;
  markAllUserNotificationsReadOrUnread: Scalars["Boolean"];
  markNotificationReadOrUnread: Scalars["Boolean"];
  markRetailReady: Scalars["Boolean"];
  markUnidentifiedPaymentAsInternalTransfer: Maybe<RetailInternalTransferPaymentProjection>;
  moveOrderToCanceled: Scalars["Boolean"];
  moveOrderToCompleted: Scalars["Boolean"];
  moveOrderToContractSent: Scalars["Boolean"];
  moveOrderToContractSigned: Scalars["Boolean"];
  moveOrderToDelivered: Scalars["Boolean"];
  moveOrderToVerified: Scalars["Boolean"];
  moveRetailAdToImportedToRetail: Scalars["Boolean"];
  moveRetailAdToReturnToAuto1: Scalars["Boolean"];
  notificationVisualized: Scalars["Boolean"];
  offlineLeadsNewsletterSubscriptionsImport: Maybe<
    Array<Maybe<RetailDataImportDto>>
  >;
  orderDebtsEdit: Scalars["Boolean"];
  patchDocument: Maybe<RetailDocumentProjection>;
  persistOrderFinancingFlowType: Scalars["Boolean"];
  pinOrderComment: Scalars["Boolean"];
  pinOrderHistoryEvent: Scalars["Boolean"];
  preApproveOrderFinancing: Scalars["Boolean"];
  qaChangeRegistrationStatus: Scalars["Boolean"];
  qaChangeYouSignRequestStatus: Scalars["Boolean"];
  qaKbaSabrinaApproved: Scalars["Boolean"];
  qaKbaSabrinaRejected: Scalars["Boolean"];
  qaMarkKBARequestFailed: Scalars["Boolean"];
  qaMarkKBARequestSuccessful: Scalars["Boolean"];
  rejectOrderFinancing: Scalars["Boolean"];
  removeAdPublishingBlockers: Scalars["Boolean"];
  requestBankDetails: Scalars["Boolean"];
  requestBankDetailsForOutboundPayment: Scalars["Boolean"];
  resetOrderHandoverDate: Scalars["Boolean"];
  resetUserPassword: Scalars["Boolean"];
  retailDocumentPackageUpdateImport: Maybe<Array<Maybe<RetailDataImportDto>>>;
  retryRefund: Scalars["Boolean"];
  revertLastOrderTransition: Scalars["Boolean"];
  saveFinanceRiskEvaluation: Maybe<FinanceRiskEvaluationProjection>;
  saveVisitingLog: Scalars["Boolean"];
  selfAssignTaskManagerTask: Scalars["Boolean"];
  sendNewFinanceApplicationToCustomer: Scalars["Boolean"];
  setHighlights: Maybe<Array<Maybe<HighlightDto>>>;
  setRetailClaimCostApprove1: Maybe<RetailClaimCostProjection>;
  setRetailClaimCostApprove2: Maybe<RetailClaimCostProjection>;
  setRetailClaimCostApprove3: Maybe<RetailClaimCostProjection>;
  setRetailClaimCostCustomerDecision: Maybe<RetailClaimCostProjection>;
  startFullManualEvaluation: Scalars["Boolean"];
  startFullManualEvaluationProtected: Scalars["Boolean"];
  startFullManualEvaluationProtectedV2: Scalars["Boolean"];
  startFullManualEvaluationV2: Scalars["Boolean"];
  superEditExternalRiskUserInfo: Maybe<ExternalRiskUserInfoProjection>;
  transitionPaymentUnidentifiedToInternalTransfer: Maybe<RetailInternalTransferPaymentProjection>;
  transitionPaymentUnidentifiedToInternalTransferBatch: Maybe<BatchProjection_RetailInternalTransferPaymentProjection>;
  unAssignTaskManagerTask: Scalars["Boolean"];
  updateAbTestingExperiment: Maybe<RetailAbTestingExperimentProjection>;
  updateAd: Scalars["Boolean"];
  updateAdImages: Scalars["Boolean"];
  updateAdVehicle: Scalars["Boolean"];
  updateAvailabilityRule: Maybe<AvailabilityRuleProjection>;
  updateBranchEntryCheckStatus: Maybe<Scalars["Boolean"]>;
  updateCarRegistrationProduct: Maybe<RetailCarRegistrationProjection>;
  updateCarTypeBasedPriceModifier: Maybe<CarTypeBasedPriceModifierProjection>;
  updateChatbotQuestion: Maybe<ChatbotQuestionDto>;
  updateCheckoutPaymentDetails: Maybe<CheckoutPaymentDetailsProjection>;
  updateClaimCostCompensation: Scalars["Boolean"];
  updateClaimStateComment: Maybe<RetailClaimProjection>;
  updateClaimStateDueDate: Maybe<RetailClaimProjection>;
  updateClaimStateDueDaysConfig: Maybe<
    Array<Maybe<RetailClaimStateDueDaysConfigProjection>>
  >;
  updateCreditNote: Maybe<CreditNoteProjection>;
  updateCustomerAddress: Maybe<CustomerAddressProjection>;
  updateCustomerDisplayOnDamage: Maybe<RetailAdDamageDto>;
  updateCustomerExpectedMaxETA: Maybe<OrderProjection>;
  updateCustomerUser: Maybe<CustomerProjection>;
  updateDigitalCarRegistration: Maybe<DigitalCarRegistrationProjection>;
  updateDigitalCarRegistrationBankAccount: Scalars["Boolean"];
  updateDocumentPackage: Maybe<UpdateDocumentPackageResult>;
  updateDocumentPackagePartial: Maybe<UpdateDocumentPackagePartialResult>;
  updateDownPaymentProduct: Maybe<DownPaymentProjection>;
  updateExternalFinancingEvaluation: Maybe<ExternalFinanceRiskEvaluationProjection>;
  updateFeeProduct: Maybe<RetailFeeProjection>;
  updateFinancingApplicationType: Scalars["Boolean"];
  updateFinancingPaymentDetails: Scalars["Boolean"];
  updateFrRegionalTaxBasedPriceModifier: Maybe<FrRegionalTaxBasedPriceModifierProjection>;
  updateGroup: Maybe<GroupProjection>;
  updateITPowerAndZoneBasedPriceModifier: Maybe<ItPowerAndZoneBasedPriceModifierProjection>;
  updateImageTag: Maybe<RetailImageTagProjection>;
  updateInsuranceProduct: Maybe<RetailInsuranceProjection>;
  updateInvoice: Maybe<InvoiceProjection>;
  updateOrCreateQADataDigitalCarRegistration: Scalars["Boolean"];
  updateOrder: Maybe<OrderProjection>;
  updateOrderAddress: Maybe<OrderAddressProjection>;
  updateOrderBillingAddress: Maybe<OrderAddressProjection>;
  updateOrderBranch: Scalars["Boolean"];
  updateOrderCarRegistration: Maybe<OrderProjection>;
  updateOrderCarRegistrationAddress: Maybe<OrderAddressProjection>;
  updateOrderDeliveryOption: Scalars["Boolean"];
  updateOrderDeliveryPlannedCarHandoverDate: Scalars["Boolean"];
  updateOrderDeliveryType: Scalars["Boolean"];
  updateOrderDocumentHandoverOn: Maybe<OrderProjection>;
  updateOrderDocumentShippingAddress: Maybe<OrderAddressProjection>;
  updateOrderFinancing: Scalars["Boolean"];
  updateOrderIsBranchCheckupSuccessful: Maybe<OrderProjection>;
  updateOrderIsTest: Maybe<OrderProjection>;
  updateOrderItemDiscountGross: Scalars["Boolean"];
  updateOrderItemPriceGross: Scalars["Boolean"];
  updateOrderPaymentDetails: Maybe<OrderProjection>;
  updateOrderShippingAddress: Maybe<OrderAddressProjection>;
  updateOrderShippingAddressForce: Maybe<OrderAddressProjection>;
  updateOrderVehicleDeliveredOn: Maybe<OrderProjection>;
  updateOutboundPayment: Maybe<RetailOutboundPaymentProjection>;
  updatePnlItem: Maybe<PnlItemProjection>;
  updateProduct: Maybe<RetailProductProjection>;
  updateRefund: Maybe<RetailRefundProjection>;
  updateRefundAmount: Scalars["Boolean"];
  updateRefurbishment: Maybe<RefurbishmentDto>;
  updateRefurbishmentComment: Maybe<RefurbishmentCommentProjection>;
  updateRefurbishmentServices: Maybe<Array<Maybe<RefurbishmentServiceDto>>>;
  updateRefurbishmentState: Maybe<RefurbishmentDto>;
  updateRetailAd: Maybe<RetailAdProjection>;
  updateRetailAdAuto1BuyPrice: Maybe<RetailAdProjection>;
  updateRetailAdAuto1ReturnOn: Maybe<RetailAdProjection>;
  updateRetailAdAuto1ReturnReason: Maybe<RetailAdProjection>;
  updateRetailAdAutoheroPurchaseOn: Maybe<RetailAdProjection>;
  updateRetailAdDamageDisplayToCustomerFrontend: Maybe<Scalars["Boolean"]>;
  updateRetailAdFirstImportOn: Maybe<RetailAdProjection>;
  updateRetailAdHidingReason: Maybe<RetailAdProjection>;
  updateRetailAdIsTest: Maybe<RetailAdProjection>;
  updateRetailAdRetailReadyDate: Maybe<RetailAdProjection>;
  updateRetailAdSecondaryWheelsDetails: Maybe<RetailAdSecondaryWheelsProjection>;
  updateRetailAdSecondaryWheelsImages: Maybe<RetailAdSecondaryWheelsProjection>;
  updateRetailAdSecondaryWheelsRimsDetails: Maybe<RetailAdSecondaryWheelsProjection>;
  updateRetailAdSecondaryWheelsTiresDetails: Maybe<RetailAdSecondaryWheelsProjection>;
  updateRetailAddress: Maybe<RetailAddressProjection>;
  updateRetailCheckout: Maybe<RetailCheckoutProjection>;
  updateRetailCheckoutByOrderId: Maybe<RetailCheckoutProjection>;
  updateRetailClaimCompensationType: Scalars["Boolean"];
  updateRetailClaimCost: Maybe<RetailClaimCostProjection>;
  updateRetailClaimWorkshopDetails: Maybe<RetailClaimWorkshopDetailsProjection>;
  updateRetailCustomer: Maybe<RetailCustomerProjection>;
  updateRetailFeature: Maybe<RetailFeatureProjection>;
  updateRetailLead: Maybe<RetailLeadProjection>;
  updateRetailOrderClaimAssigneeUser: Scalars["Boolean"];
  updateRetailOrderClaimGroup: Scalars["Boolean"];
  updateRetailOrderClaimState: Maybe<RetailClaimProjection>;
  updateRetailOrderSubClaim: Maybe<RetailSubClaimProjection>;
  updateRetailPayment: Maybe<RetailPaymentProjection>;
  updateRetailPaymentImportConfig: Maybe<RetailDataImportConfigDto>;
  updateRetailShippingAddressForce: Maybe<RetailAddressProjection>;
  updateRetailTaxNoVA: Scalars["Boolean"];
  updateRetailUnidentifiedPayment: Maybe<RetailUnidentifiedPaymentProjection>;
  updateRole: Maybe<RoleProjection>;
  updateSellerAddress: Maybe<SellerAddressProjection>;
  updateSurveyActivePage: Scalars["Boolean"];
  updateSurveyStatus: Scalars["Boolean"];
  updateTaskManagerTaskAction: Scalars["Boolean"];
  updateTaxAdvantage: Maybe<RetailAdTaxAdvantageProjection>;
  updateTaxBPM: Scalars["Boolean"];
  updateTestOrderDownPayment: Maybe<OrderProjection>;
  updateUser: Maybe<UserProjection>;
  updateVehiclePreparationProduct: Maybe<RetailVehiclePreparationProjection>;
  updateWarrantyCarPricePriceModifier: Maybe<WarrantyCarPricePriceModifierProjection>;
  updateWarrantyCarYearsPriceModifier: Maybe<WarrantyCarYearsPriceModifierProjection>;
  updateWarrantyProduct: Maybe<RetailWarrantyProjection>;
  userObfuscation: Maybe<GdprProjection>;
  verificationCallSurveySubmitAnswers: Scalars["Boolean"];
}

/** Mutation root */
export interface MutationAddGroupUsersArgs {
  id: Scalars["UUID"];
  userIds: Array<InputMaybe<Scalars["UUID"]>>;
}

/** Mutation root */
export interface MutationAddRetailAdSecondaryWheelsImagesArgs {
  id: Scalars["UUID"];
  imageIds: RetailBatchDto_UuidInput;
}

/** Mutation root */
export interface MutationAddUserGroupsArgs {
  groupIds: Array<InputMaybe<Scalars["UUID"]>>;
  id: Scalars["UUID"];
}

/** Mutation root */
export interface MutationAddUserPropertiesArgs {
  id: Scalars["UUID"];
  properties: Array<InputMaybe<UserPropertyProjectionInput>>;
}

/** Mutation root */
export interface MutationAddpublishingblockersArgs {
  publishingBlockers: Array<InputMaybe<PublishingBlockerUpdateProjectionInput>>;
  retailAdId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationApplyPriceUpdateArgs {
  request: ApplyPriceUpdateProjectionInput;
}

/** Mutation root */
export interface MutationApproveOrderFinancingArgs {
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationAssignGroupRolesArgs {
  id: Scalars["UUID"];
  roleIds: Array<InputMaybe<Scalars["UUID"]>>;
}

/** Mutation root */
export interface MutationAssignGroupUsersArgs {
  id: Scalars["UUID"];
  userIds: Array<InputMaybe<Scalars["UUID"]>>;
}

/** Mutation root */
export interface MutationAssignRiskAgentArgs {
  financingId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationAssignRoleGroupsArgs {
  groupIds: Array<InputMaybe<Scalars["UUID"]>>;
  id: Scalars["UUID"];
}

/** Mutation root */
export interface MutationAssignTaskManagerTaskArgs {
  taskId: Scalars["UUID"];
  userId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationAssignTestDataAccessParamArgs {
  enabled: Scalars["Boolean"];
  id: Scalars["UUID"];
}

/** Mutation root */
export interface MutationAssignUnidentifiedPaymentToOrderArgs {
  orderId: InputMaybe<Scalars["UUID"]>;
  unidentifiedPaymentId: InputMaybe<Scalars["UUID"]>;
}

/** Mutation root */
export interface MutationAssignUserCountryAccessParamsArgs {
  countries: Array<InputMaybe<Scalars["String"]>>;
  id: Scalars["UUID"];
}

/** Mutation root */
export interface MutationAssignUserGroupAccessParamsArgs {
  groupIds: Array<InputMaybe<Scalars["String"]>>;
  id: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCancelOrderFinancingArgs {
  orderId: Scalars["UUID"];
  reason: RetailOrderFinanceCancelReason;
}

/** Mutation root */
export interface MutationCancelRetailPaymentArgs {
  id: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCarRegistrationActionsArgs {
  action: Scalars["String"];
  orderUuid: Scalars["UUID"];
}

/** Mutation root */
export interface MutationChangeCustomerTypeArgs {
  customer: RetailCustomerCreateProjectionInput;
  customerId: Scalars["UUID"];
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationChangeDocumentPackageStatusArgs {
  input: ChangeDocumentPackageStatusInput;
}

/** Mutation root */
export interface MutationChangeReferralStatusArgs {
  accountingDate: Scalars["LocalDate"];
  mainOrderId: Scalars["UUID"];
  referralId: Scalars["UUID"];
  status: Scalars["String"];
}

/** Mutation root */
export interface MutationChangeRegistrationStatusArgs {
  registrationId: Scalars["UUID"];
  registrationStatus: Scalars["String"];
}

/** Mutation root */
export interface MutationChangeRetailPaymentOrderArgs {
  id: Scalars["UUID"];
  orderNumber: Scalars["String"];
}

/** Mutation root */
export interface MutationClaimPartialRefundRequestNewRetailBankDetailsArgs {
  claimPartialRefundId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCommentTaskManagerTaskArgs {
  taskManagerCommentCreateProjection: TaskManagerCommentCreateProjectionInput;
}

/** Mutation root */
export interface MutationCompleteTaskManagerTaskArgs {
  description: InputMaybe<Scalars["String"]>;
  taskId: Scalars["UUID"];
  variables: InputMaybe<Scalars["Map_String_StringScalar"]>;
}

/** Mutation root */
export interface MutationCompleteTaskManagerTaskV2Args {
  description: InputMaybe<Scalars["String"]>;
  taskId: Scalars["UUID"];
  variables: InputMaybe<Scalars["Map_String_ObjectScalar"]>;
}

/** Mutation root */
export interface MutationCreateAbTestingExperimentArgs {
  input: AbTestingExperimentInputInput;
}

/** Mutation root */
export interface MutationCreateAdditionalOrderArgs {
  order: OrderCreateProjectionInput;
}

/** Mutation root */
export interface MutationCreateAdditionalProofRequestsArgs {
  claimId: Scalars["UUID"];
  proofRequestsCreateProjection: ProofRequestsCreateProjectionInput;
}

/** Mutation root */
export interface MutationCreateAttachmentUploadPresignedUrlArgs {
  attachmentFilename: Scalars["String"];
  contentType: InputMaybe<Scalars["String"]>;
}

/** Mutation root */
export interface MutationCreateAvailabilityRuleArgs {
  availabilityRule: AvailabilityRuleCreateProjectionInput;
}

/** Mutation root */
export interface MutationCreateBranchEntryCheckArgs {
  stockNumber: Scalars["String"];
  submission: BranchEntrySubmissionDtoInput;
}

/** Mutation root */
export interface MutationCreateBranchEntryCheckImageUploadMetadataArgs {
  uploadRequest: AwsImageUploadRequestProjectionInput;
}

/** Mutation root */
export interface MutationCreateCarRegistrationProductArgs {
  carRegistrationCreateProduct: RetailCarRegistrationCreateProjectionInput;
}

/** Mutation root */
export interface MutationCreateChatbotQuestionArgs {
  question: ChatbotQuestionCreateProjectionInput;
}

/** Mutation root */
export interface MutationCreateCreditNotesArgs {
  creditNote: InputMaybe<CreditNoteCreateProjectionInput>;
}

/** Mutation root */
export interface MutationCreateCustomerAddressArgs {
  address: CustomerAddressCreateProjectionInput;
  userId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCreateCustomerUserArgs {
  user: CustomerUserCreateProjectionInput;
}

/** Mutation root */
export interface MutationCreateCustomerUserPasswordlessArgs {
  user: UserPasswordlessProjectionInput;
}

/** Mutation root */
export interface MutationCreateDebtCommentArgs {
  debtId: Scalars["UUID"];
  text: Scalars["String"];
}

/** Mutation root */
export interface MutationCreateDigitalHandoverProtocolArgs {
  orderId: Scalars["UUID"];
  submission: ProtocolSubmissionDtoInput;
}

/** Mutation root */
export interface MutationCreateDiscountArgs {
  discount: DiscountCreateProjectionInput;
}

/** Mutation root */
export interface MutationCreateDistributedCreditNotesRefundsArgs {
  refundsDistribution: RetailOrderRefundsDistributionProjectionInput;
}

/** Mutation root */
export interface MutationCreateDistributedRefundsArgs {
  refundsDistribution: RetailOrderRefundsDistributionProjectionInput;
}

/** Mutation root */
export interface MutationCreateDocumentArgs {
  retailCreateDocumentProjection: RetailCreateDocumentProjectionInput;
}

/** Mutation root */
export interface MutationCreateDocumentInventoryTypeArgs {
  input: CreateDocumentInventoryTypeInput;
}

/** Mutation root */
export interface MutationCreateDocumentPackageArgs {
  input: CreateDocumentPackageInput;
}

/** Mutation root */
export interface MutationCreateDownPaymentProductArgs {
  downPaymentCreateProduct: RetailDownPaymentCreateProjectionInput;
}

/** Mutation root */
export interface MutationCreateExternalFinancingEvaluationArgs {
  financeRiskEvaluation: CreateExternalFinanceRiskEvaluationProjectionInput;
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCreateExternalRiskUserInfoArgs {
  borrowerType: Scalars["String"];
  createExternalRiskUserInfo: CreateExternalRiskUserInfoProjectionInput;
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCreateFeeProductArgs {
  retailFeeCreateProduct: RetailFeeCreateProjectionInput;
}

/** Mutation root */
export interface MutationCreateGroupArgs {
  group: GroupCreateProjectionInput;
}

/** Mutation root */
export interface MutationCreateImageTagArgs {
  imageTag: RetailImageTagCreateProjectionInput;
}

/** Mutation root */
export interface MutationCreateInsuranceProductArgs {
  retailInsuranceCreateProduct: RetailInsuranceCreateProjectionInput;
}

/** Mutation root */
export interface MutationCreateInvoiceCreditNoteArgs {
  creditNote: InvoiceCreditNoteCreateProjectionInput;
}

/** Mutation root */
export interface MutationCreateInvoiceDownPaymentArgs {
  invoice: RetailInvoiceCreateProjectionInput;
}

/** Mutation root */
export interface MutationCreateInvoiceStandardArgs {
  invoice: RetailInvoiceCreateProjectionInput;
}

/** Mutation root */
export interface MutationCreateInvoiceStandardWithValidationArgs {
  invoice: RetailInvoiceCreateProjectionInput;
  validatePayments?: InputMaybe<Scalars["Boolean"]>;
}

/** Mutation root */
export interface MutationCreateOrUpdateExternalFinancingCustomerDetailsArgs {
  customerId: Scalars["UUID"];
  externalFinancingCustomerDetails: CreateOrUpdateExternalFinancingCustomerDetailsProjectionInput;
  orderFinancingId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCreateOrUpdateExternalFinancingOptionsArgs {
  externalFinancingOptions: CreateOrUpdateExternalFinancingOptionsProjectionInput;
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCreateOracleDownPaymentInvoiceArgs {
  orderId: Scalars["UUID"];
  vatKey: Scalars["String"];
}

/** Mutation root */
export interface MutationCreateOracleStandardInvoiceArgs {
  orderId: Scalars["UUID"];
  vatKey: Scalars["String"];
}

/** Mutation root */
export interface MutationCreateOrderArgs {
  order: OrderCreateProjectionInput;
}

/** Mutation root */
export interface MutationCreateOrderAddressArgs {
  address: OrderAddressCreateProjectionInput;
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCreateOrderBranchCarCheckupTaskArgs {
  branchCheckupOn: Scalars["String"];
  comment: OrderCommentCreateProjectionInput;
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCreateOrderBranchCarCheckupTaskV2Args {
  branchCheckupOn: Scalars["String"];
  comment: OrderHistoryEventCreateProjectionInput;
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCreateOrderCancellationRequestedTaskArgs {
  comment: OrderCommentCreateProjectionInput;
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCreateOrderCancellationRequestedTaskV2Args {
  comment: OrderHistoryEventCreateProjectionInput;
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCreateOrderCancellationTaskArgs {
  comment: OrderHistoryEventCreateProjectionInput;
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCreateOrderCancellationTaskV2Args {
  comment: OrderCancellationTaskProjectionInput;
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCreateOrderCarReadyForHomeDeliveryTaskArgs {
  comment: OrderCommentCreateProjectionInput;
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCreateOrderCarReadyForHomeDeliveryTaskV2Args {
  comment: OrderHistoryEventCreateProjectionInput;
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCreateOrderCarReadyForPickupCallTaskArgs {
  comment: OrderCommentCreateProjectionInput;
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCreateOrderCarReadyForPickupCallTaskV2Args {
  comment: OrderHistoryEventCreateProjectionInput;
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCreateOrderCarRegistrationDocumentsReturnedToAhTaskArgs {
  comment: OrderCommentCreateProjectionInput;
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCreateOrderCarRegistrationDocumentsReturnedToAhTaskV2Args {
  comment: OrderHistoryEventCreateProjectionInput;
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCreateOrderCarReturnedToAhTaskArgs {
  comment: OrderCommentCreateProjectionInput;
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCreateOrderCarReturnedToAhTaskV2Args {
  comment: OrderHistoryEventCreateProjectionInput;
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCreateOrderCareCallTaskArgs {
  comment: OrderCommentCreateProjectionInput;
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCreateOrderCareCallTaskV2Args {
  comment: OrderHistoryEventCreateProjectionInput;
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCreateOrderClaimClosedTaskArgs {
  comment: OrderCommentCreateProjectionInput;
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCreateOrderClaimClosedTaskV2Args {
  comment: OrderHistoryEventCreateProjectionInput;
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCreateOrderClaimReceivedTaskArgs {
  comment: OrderCommentCreateProjectionInput;
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCreateOrderClaimReceivedTaskV2Args {
  comment: OrderHistoryEventCreateProjectionInput;
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCreateOrderCommentArgs {
  comment: OrderCommentCreateProjectionInput;
  id: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCreateOrderCustomerAvailabilityForHomeDeliveryTaskArgs {
  comment: OrderCommentCreateProjectionInput;
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCreateOrderCustomerAvailabilityForHomeDeliveryTaskV2Args {
  comment: OrderHistoryEventCreateProjectionInput;
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCreateOrderCustomerSignedBankReleaseTaskArgs {
  comment: OrderCommentCreateProjectionInput;
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCreateOrderCustomerSignedBankReleaseTaskV2Args {
  comment: OrderHistoryEventCreateProjectionInput;
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCreateOrderDocumentsRegistrationReadyTaskArgs {
  comment: OrderCommentCreateProjectionInput;
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCreateOrderDocumentsRegistrationReadyTaskV2Args {
  comment: OrderHistoryEventCreateProjectionInput;
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCreateOrderDocumentsRequestedTaskArgs {
  comment: OrderCommentCreateProjectionInput;
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCreateOrderDocumentsRequestedTaskV2Args {
  comment: OrderHistoryEventCreateProjectionInput;
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCreateOrderFinalDocumentsReceivedTaskArgs {
  comment: OrderCommentCreateProjectionInput;
  finalDocumentHandoverOn: Scalars["String"];
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCreateOrderFinalDocumentsReceivedTaskV2Args {
  comment: OrderHistoryEventCreateProjectionInput;
  finalDocumentHandoverOn: Scalars["String"];
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCreateOrderFinancingArgs {
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCreateOrderHandoverAppointmentTaskArgs {
  comment: OrderCommentCreateProjectionInput;
  orderId: Scalars["UUID"];
  plannedCarHandoverOn: InputMaybe<Scalars["String"]>;
  plannedHandoverFrom: InputMaybe<Scalars["String"]>;
  plannedHandoverTo: InputMaybe<Scalars["String"]>;
}

/** Mutation root */
export interface MutationCreateOrderHandoverAppointmentTaskV2Args {
  comment: OrderHistoryEventCreateProjectionInput;
  orderId: Scalars["UUID"];
  plannedCarHandoverOn: InputMaybe<Scalars["String"]>;
  plannedHandoverFrom: InputMaybe<Scalars["String"]>;
  plannedHandoverTo: InputMaybe<Scalars["String"]>;
}

/** Mutation root */
export interface MutationCreateOrderHappinessCallTaskArgs {
  comment: OrderCommentCreateProjectionInput;
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCreateOrderHappinessCallTaskV2Args {
  comment: OrderHistoryEventCreateProjectionInput;
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCreateOrderHistoryEmailEventArgs {
  emailEvent: CreateOrderHistoryEmailEventProjectionInput;
  id: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCreateOrderHistoryEventArgs {
  event: OrderHistoryEventCreateProjectionInput;
  id: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCreateOrderItemArgs {
  item: OrderItemCreateProjectionInput;
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCreateOrderRegistrationInitiatedTaskArgs {
  comment: OrderCommentCreateProjectionInput;
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCreateOrderRegistrationInitiatedTaskV2Args {
  comment: OrderHistoryEventCreateProjectionInput;
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCreateOrderShippingAddressBodyArgs {
  address: RetailAddressCreateProjectionInput;
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCreateOrderUploadedHandoverDocumentTaskArgs {
  comment: OrderCommentCreateProjectionInput;
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCreateOrderUploadedHandoverDocumentTaskV2Args {
  comment: OrderHistoryEventCreateProjectionInput;
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCreateOrderWarrantyContractSentTaskArgs {
  comment: OrderCommentCreateProjectionInput;
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCreateOrderWarrantyContractSentTaskV2Args {
  comment: OrderHistoryEventCreateProjectionInput;
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCreateOrderWarrantyContractSignedTaskArgs {
  comment: OrderCommentCreateProjectionInput;
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCreateOrderWarrantyContractSignedTaskV2Args {
  comment: OrderHistoryEventCreateProjectionInput;
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCreateOtherOrderArgs {
  order: OrderCreateProjectionInput;
}

/** Mutation root */
export interface MutationCreateOutboundPaymentArgs {
  payment: CreateRetailOutboundPaymentProjectionInput;
}

/** Mutation root */
export interface MutationCreatePnlItemArgs {
  item: PnlItemCreateProjectionInput;
}

/** Mutation root */
export interface MutationCreatePnlItemTypeArgs {
  pnlItemType: PnlItemTypeCreateProjectionInput;
}

/** Mutation root */
export interface MutationCreatePnlPartnerArgs {
  partner: PnlPartnerCreateProjectionInput;
}

/** Mutation root */
export interface MutationCreateProductArgs {
  product: ProductCreateProjectionInput;
}

/** Mutation root */
export interface MutationCreateRefundArgs {
  refund: RetailRefundCreateProjectionInput;
}

/** Mutation root */
export interface MutationCreateRefundsWithCreditNotesDistributionArgs {
  refundsDistribution: RetailOrderRefundsDistributionProjectionInput;
}

/** Mutation root */
export interface MutationCreateRefurbishmentArgs {
  refurbishment: RefurbishmentCreateProjectionInput;
}

/** Mutation root */
export interface MutationCreateRefurbishmentCommentArgs {
  refurbishmentComment: RefurbishmentCommentCreateProjectionInput;
}

/** Mutation root */
export interface MutationCreateRefurbishmentServicesArgs {
  refurbishmentId: Scalars["UUID"];
  services: Array<InputMaybe<RefurbishmentServiceCreateProjectionInput>>;
}

/** Mutation root */
export interface MutationCreateRefurbishmentServicesOrderArgs {
  adId: Scalars["UUID"];
  noRepairNeededDamages: Array<InputMaybe<Scalars["UUID"]>>;
  refurbishmentId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCreateRetailAccountDataArgs {
  accountDetails: AccountDetailsDtoInput;
}

/** Mutation root */
export interface MutationCreateRetailAdSecondaryWheelsArgs {
  id: Scalars["UUID"];
  retailAdSecondaryWheels: RetailAdSecondaryWheelsCreateProjectionInput;
}

/** Mutation root */
export interface MutationCreateRetailAddressArgs {
  address: RetailAddressCreateProjectionInput;
  validate?: InputMaybe<Scalars["Boolean"]>;
}

/** Mutation root */
export interface MutationCreateRetailCheckoutArgs {
  checkout: RetailCheckoutCreateProjectionInput;
}

/** Mutation root */
export interface MutationCreateRetailClaimCostArgs {
  claimCost: RetailClaimCostCreateProjectionInput;
  claimId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCreateRetailClaimCostsCommentArgs {
  claimCostComment: RetailClaimCostCommentCreateProjectionInput;
  claimCostId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCreateRetailClaimV2Args {
  claimCreate: RetailClaimCreateProjectionV2Input;
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCreateRetailClaimWorkshopDetailsArgs {
  claimId: Scalars["UUID"];
  retailClaimWorkshopDetails: RetailClaimWorkshopDetailsCreateProjectionInput;
}

/** Mutation root */
export interface MutationCreateRetailCustomerArgs {
  customer: RetailCustomerCreateProjectionInput;
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCreateRetailFeatureArgs {
  feature: CreateRetailFeatureProjectionInput;
}

/** Mutation root */
export interface MutationCreateRetailLeadArgs {
  retailLead: RetailLeadCreateProjectionInput;
}

/** Mutation root */
export interface MutationCreateRetailOrderAccountDetailsArgs {
  accountDetails: RetailOrderAccountDetailsCreateProjectionInput;
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCreateRetailOrderSubClaimArgs {
  claimId: Scalars["UUID"];
  retailClaim: RetailSubClaimCreateProjectionInput;
}

/** Mutation root */
export interface MutationCreateRetailPaymentArgs {
  payment: RetailPaymentCreateProjectionInput;
}

/** Mutation root */
export interface MutationCreateRetailSubClaimCommentArgs {
  retailSubClaimComment: RetailSubClaimCommentCreateProjectionInput;
  subClaimId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCreateRetailSubClaimDocumentArgs {
  retailSubClaimDocument: RetailSubClaimCreateDocumentProjectionInput;
  subClaimId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCreateRoleArgs {
  role: RoleCreateProjectionInput;
}

/** Mutation root */
export interface MutationCreateSellerAddressArgs {
  sellerAddress: SellerAddressCreateProjectionInput;
  sellerId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCreateStandardOrderArgs {
  order: OrderCreateProjectionInput;
}

/** Mutation root */
export interface MutationCreateSubProductArgs {
  productId: Scalars["UUID"];
  subProductId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationCreateTradeInArgs {
  create: RetailTradeInCreateProjectionInput;
}

/** Mutation root */
export interface MutationCreateUserArgs {
  user: UserCreateProjectionInput;
}

/** Mutation root */
export interface MutationCreateVehiclePreparationProductArgs {
  retailVehiclePreparationCreateProduct: RetailVehiclePreparationCreateProjectionInput;
}

/** Mutation root */
export interface MutationCreateVerificationCallSurveyArgs {
  locale: Scalars["String"];
  orderId: Scalars["UUID"];
  templateType: Scalars["String"];
}

/** Mutation root */
export interface MutationCreateWarrantyProductArgs {
  retailWarrantyCreateProduct: RetailWarrantyCreateProjectionInput;
}

/** Mutation root */
export interface MutationDeleteAbTestingExperimentArgs {
  environment: InputMaybe<Scalars["String"]>;
  id: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDeleteAbTestingExperimentCountersArgs {
  environment: InputMaybe<Scalars["String"]>;
  id: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDeleteAbTestingParticipantsArgs {
  environment: InputMaybe<Scalars["String"]>;
  experimentName: Scalars["String"];
  orderId: InputMaybe<Scalars["UUID"]>;
  userId: InputMaybe<Scalars["UUID"]>;
  visitorId: InputMaybe<Scalars["String"]>;
}

/** Mutation root */
export interface MutationDeleteAvailabilityRuleArgs {
  id: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDeleteChatbotQuestionArgs {
  id: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDeleteDocumentInventoryItemArgs {
  input: DeleteDocumentInventoryItemInput;
}

/** Mutation root */
export interface MutationDeleteExternalFinancingCustomerDetailsArgs {
  customerId: Scalars["UUID"];
  orderFinancingId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDeleteExternalRiskUserInfoArgs {
  borrowerType: Scalars["String"];
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDeleteGroupUsersArgs {
  id: Scalars["UUID"];
  userIds: Array<InputMaybe<Scalars["UUID"]>>;
}

/** Mutation root */
export interface MutationDeleteImageTagArgs {
  id: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDeleteOrderAddressArgs {
  id: Scalars["UUID"];
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDeleteOrderBranchCarCheckupTaskArgs {
  comment: OrderCommentCreateProjectionInput;
  id: Scalars["UUID"];
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDeleteOrderBranchCarCheckupTaskV2Args {
  comment: OrderHistoryEventCreateProjectionInput;
  id: Scalars["UUID"];
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDeleteOrderCancellationRequestedTaskArgs {
  comment: OrderCommentCreateProjectionInput;
  id: Scalars["UUID"];
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDeleteOrderCancellationRequestedTaskV2Args {
  comment: OrderHistoryEventCreateProjectionInput;
  id: Scalars["UUID"];
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDeleteOrderCarReadyForHomeDeliveryTaskArgs {
  comment: OrderCommentCreateProjectionInput;
  id: Scalars["UUID"];
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDeleteOrderCarReadyForHomeDeliveryTaskV2Args {
  comment: OrderHistoryEventCreateProjectionInput;
  id: Scalars["UUID"];
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDeleteOrderCarReadyForPickupCallTaskArgs {
  comment: OrderCommentCreateProjectionInput;
  id: Scalars["UUID"];
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDeleteOrderCarReadyForPickupCallTaskV2Args {
  comment: OrderHistoryEventCreateProjectionInput;
  id: Scalars["UUID"];
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDeleteOrderCarRegistrationDocumentsReturnedToAhTaskArgs {
  comment: OrderCommentCreateProjectionInput;
  id: Scalars["UUID"];
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDeleteOrderCarRegistrationDocumentsReturnedToAhTaskV2Args {
  comment: OrderHistoryEventCreateProjectionInput;
  id: Scalars["UUID"];
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDeleteOrderCarReturnedToAhTaskArgs {
  comment: OrderCommentCreateProjectionInput;
  id: Scalars["UUID"];
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDeleteOrderCarReturnedToAhTaskV2Args {
  comment: OrderHistoryEventCreateProjectionInput;
  id: Scalars["UUID"];
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDeleteOrderCareCallTaskArgs {
  comment: OrderCommentCreateProjectionInput;
  id: Scalars["UUID"];
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDeleteOrderCareCallTaskV2Args {
  comment: OrderHistoryEventCreateProjectionInput;
  id: Scalars["UUID"];
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDeleteOrderClaimClosedTaskArgs {
  comment: OrderCommentCreateProjectionInput;
  id: Scalars["UUID"];
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDeleteOrderClaimClosedTaskV2Args {
  comment: OrderHistoryEventCreateProjectionInput;
  id: Scalars["UUID"];
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDeleteOrderClaimReceivedTaskArgs {
  comment: OrderCommentCreateProjectionInput;
  id: Scalars["UUID"];
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDeleteOrderClaimReceivedTaskV2Args {
  comment: OrderHistoryEventCreateProjectionInput;
  id: Scalars["UUID"];
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDeleteOrderCustomerAvailabilityForHomeDeliveryTaskArgs {
  comment: OrderCommentCreateProjectionInput;
  id: Scalars["UUID"];
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDeleteOrderCustomerAvailabilityForHomeDeliveryTaskV2Args {
  comment: OrderHistoryEventCreateProjectionInput;
  id: Scalars["UUID"];
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDeleteOrderCustomerSignedBankReleaseTaskArgs {
  comment: OrderCommentCreateProjectionInput;
  id: Scalars["UUID"];
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDeleteOrderCustomerSignedBankReleaseTaskV2Args {
  comment: OrderHistoryEventCreateProjectionInput;
  id: Scalars["UUID"];
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDeleteOrderDocumentsRegistrationReadyTaskArgs {
  comment: OrderCommentCreateProjectionInput;
  id: Scalars["UUID"];
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDeleteOrderDocumentsRegistrationReadyTaskV2Args {
  comment: OrderHistoryEventCreateProjectionInput;
  id: Scalars["UUID"];
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDeleteOrderDocumentsRequestedTaskArgs {
  comment: OrderCommentCreateProjectionInput;
  id: Scalars["UUID"];
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDeleteOrderDocumentsRequestedTaskV2Args {
  comment: OrderHistoryEventCreateProjectionInput;
  id: Scalars["UUID"];
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDeleteOrderFinalDocumentsReceivedTaskArgs {
  comment: OrderCommentCreateProjectionInput;
  id: Scalars["UUID"];
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDeleteOrderFinalDocumentsReceivedTaskV2Args {
  comment: OrderHistoryEventCreateProjectionInput;
  id: Scalars["UUID"];
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDeleteOrderHandoverAppointmentTaskArgs {
  comment: OrderCommentCreateProjectionInput;
  id: Scalars["UUID"];
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDeleteOrderHandoverAppointmentTaskV2Args {
  comment: OrderHistoryEventCreateProjectionInput;
  id: Scalars["UUID"];
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDeleteOrderHappinessCallTaskArgs {
  comment: OrderCommentCreateProjectionInput;
  id: Scalars["UUID"];
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDeleteOrderHappinessCallTaskV2Args {
  comment: OrderHistoryEventCreateProjectionInput;
  id: Scalars["UUID"];
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDeleteOrderItemArgs {
  id: Scalars["UUID"];
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDeleteOrderRegistrationInitiatedTaskArgs {
  comment: OrderCommentCreateProjectionInput;
  id: Scalars["UUID"];
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDeleteOrderRegistrationInitiatedTaskV2Args {
  comment: OrderHistoryEventCreateProjectionInput;
  id: Scalars["UUID"];
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDeleteOrderUploadedHandoverDocumentTaskArgs {
  comment: OrderCommentCreateProjectionInput;
  id: Scalars["UUID"];
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDeleteOrderUploadedHandoverDocumentTaskV2Args {
  comment: OrderHistoryEventCreateProjectionInput;
  id: Scalars["UUID"];
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDeleteOrderWarrantyContractSentTaskArgs {
  comment: OrderCommentCreateProjectionInput;
  id: Scalars["UUID"];
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDeleteOrderWarrantyContractSentTaskV2Args {
  comment: OrderHistoryEventCreateProjectionInput;
  id: Scalars["UUID"];
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDeleteOrderWarrantyContractSignedTaskArgs {
  comment: OrderCommentCreateProjectionInput;
  id: Scalars["UUID"];
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDeleteOrderWarrantyContractSignedTaskV2Args {
  comment: OrderHistoryEventCreateProjectionInput;
  id: Scalars["UUID"];
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDeletePnlItemArgs {
  id: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDeletePnlItemTypeArgs {
  id: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDeletePnlPartnerArgs {
  id: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDeleteProductArgs {
  id: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDeleteRefurbishmentCommentArgs {
  commentId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDeleteRefurbishmentServicesArgs {
  serviceIds: Array<InputMaybe<Scalars["UUID"]>>;
}

/** Mutation root */
export interface MutationDeleteRetailAdSecondaryWheelsImagesArgs {
  id: Scalars["UUID"];
  imageIds: RetailBatchDto_UuidInput;
}

/** Mutation root */
export interface MutationDeleteRetailAddressArgs {
  id: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDeleteRetailClaimCostArgs {
  claimCostId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDeleteRetailCustomerArgs {
  customerId: Scalars["UUID"];
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDeleteRetailFeatureArgs {
  id: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDeleteRetailSubClaimArgs {
  subClaimId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDeleteRetailSubClaimDocumentArgs {
  documentId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDeleteSubProductArgs {
  productId: Scalars["UUID"];
  subProductId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDeleteUserGroupsArgs {
  groupIds: Array<InputMaybe<Scalars["UUID"]>>;
  id: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDeleteUserPropertiesArgs {
  id: Scalars["UUID"];
  propertyNames: Array<InputMaybe<Scalars["String"]>>;
}

/** Mutation root */
export interface MutationDisableDocumentInventoryTypeArgs {
  input: DisableDocumentInventoryTypeInput;
}

/** Mutation root */
export interface MutationDisableOrderAutoCancellationArgs {
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDistributeRetailClaimCostsArgs {
  claimCostDistributions: BatchProjection_ClaimCostDistributionProjectionInput;
  claimId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationDistributeUnidentifiedPaymentArgs {
  distribution: RetailUnidentifiedPaymentDistributionProjectionInput;
}

/** Mutation root */
export interface MutationEditExternalRiskUserInfoArgs {
  borrowerType: Scalars["String"];
  orderId: Scalars["UUID"];
  updateExternalRiskUserInfo: UpdateExternalRiskUserInfoProjectionInput;
}

/** Mutation root */
export interface MutationEnableDocumentInventoryTypeArgs {
  input: EnableDocumentInventoryTypeInput;
}

/** Mutation root */
export interface MutationExternalFinancingEvaluationV2Args {
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationExternalFinancingEvaluationV2TestArgs {
  orderId: Scalars["UUID"];
  testEvaluationRequest: ExternalPreEvaluationV2ProjectionInput;
}

/** Mutation root */
export interface MutationGeneratePdfDocumentArgs {
  documentTypeKey: Scalars["String"];
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationImportCarRegistrationActionItemsArgs {
  request: BatchProjection_CarRegistrationActionImportCreateProjectionInput;
}

/** Mutation root */
export interface MutationImportCarleadArgs {
  carlead: ImportCarleadProjectionInput;
}

/** Mutation root */
export interface MutationImportChatbotQuestionsArgs {
  request: BatchProjection_ChatbotQuestionImportCreateProjectionInput;
}

/** Mutation root */
export interface MutationImportInventorySourcingItemsArgs {
  request: BatchProjection_InventorySourcingImportCreateProjectionInput;
}

/** Mutation root */
export interface MutationImportPriceUpdateItemsArgs {
  request: BatchProjection_PriceUpdateItemImportCreateProjectionInput;
}

/** Mutation root */
export interface MutationImportProfitAndLossItemsArgs {
  request: BatchProjection_ProfitAndLossImportCreateProjectionInput;
}

/** Mutation root */
export interface MutationImportRetailDocumentInventoryItemsArgs {
  request: BatchProjection_RetailDocumentInventoryImportCreateProjectionInput;
}

/** Mutation root */
export interface MutationImportRetailPaymentItemsArgs {
  request: BatchProjection_RetailPaymentImportCreateProjectionInput;
}

/** Mutation root */
export interface MutationMarkAllUserNotificationsReadOrUnreadArgs {
  isRead: Scalars["Boolean"];
}

/** Mutation root */
export interface MutationMarkNotificationReadOrUnreadArgs {
  isRead: Scalars["Boolean"];
  notificationId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationMarkRetailReadyArgs {
  refurbishmentIds: Array<InputMaybe<Scalars["UUID"]>>;
  retailReady: Scalars["Boolean"];
}

/** Mutation root */
export interface MutationMarkUnidentifiedPaymentAsInternalTransferArgs {
  id: Scalars["UUID"];
}

/** Mutation root */
export interface MutationMoveOrderToCanceledArgs {
  adId: Scalars["UUID"];
  canceledDescription: InputMaybe<Scalars["String"]>;
  canceledPeriod?: InputMaybe<Scalars["String"]>;
  canceledReason: Scalars["String"];
  canceledType?: InputMaybe<Scalars["String"]>;
  financingRejectionReason?: InputMaybe<Scalars["String"]>;
  id: Scalars["UUID"];
  subCancelReason: InputMaybe<Scalars["String"]>;
}

/** Mutation root */
export interface MutationMoveOrderToCompletedArgs {
  adId: Scalars["UUID"];
  id: Scalars["UUID"];
}

/** Mutation root */
export interface MutationMoveOrderToContractSentArgs {
  adId: Scalars["UUID"];
  contractSentOn: Scalars["String"];
  id: Scalars["UUID"];
}

/** Mutation root */
export interface MutationMoveOrderToContractSignedArgs {
  adId: Scalars["UUID"];
  contractSignedOn: Scalars["String"];
  id: Scalars["UUID"];
}

/** Mutation root */
export interface MutationMoveOrderToDeliveredArgs {
  adId: Scalars["UUID"];
  id: Scalars["UUID"];
  vehicleDeliveredOn: Scalars["String"];
}

/** Mutation root */
export interface MutationMoveOrderToVerifiedArgs {
  adId: Scalars["UUID"];
  id: Scalars["UUID"];
}

/** Mutation root */
export interface MutationMoveRetailAdToImportedToRetailArgs {
  adId: Scalars["UUID"];
  retailAdId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationMoveRetailAdToReturnToAuto1Args {
  adId: Scalars["UUID"];
  reason: Scalars["String"];
  retailAdId: Scalars["UUID"];
  returnedOn: Scalars["String"];
}

/** Mutation root */
export interface MutationNotificationVisualizedArgs {
  notificationId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationOfflineLeadsNewsletterSubscriptionsImportArgs {
  request: BatchProjection_OfflineLeadsNewsletterSubscriptionImportProjectionInput;
}

/** Mutation root */
export interface MutationOrderDebtsEditArgs {
  debt: RetailOrderDebtUpdateProjectionInput;
  id: Scalars["UUID"];
}

/** Mutation root */
export interface MutationPatchDocumentArgs {
  documentUpdate: RetailDocumentUpdateProjectionInput;
  id: Scalars["UUID"];
}

/** Mutation root */
export interface MutationPersistOrderFinancingFlowTypeArgs {
  flowTypeProjection: OrderFinancingSaveFlowTypeProjectionInput;
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationPinOrderCommentArgs {
  id: Scalars["UUID"];
  pinInteraction: OrderCommentPinInteractionProjectionInput;
}

/** Mutation root */
export interface MutationPinOrderHistoryEventArgs {
  id: Scalars["UUID"];
  pinEvent: OrderHistoryEventPinProjectionInput;
}

/** Mutation root */
export interface MutationPreApproveOrderFinancingArgs {
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationQaChangeRegistrationStatusArgs {
  registrationId: Scalars["UUID"];
  registrationStatus: Scalars["String"];
}

/** Mutation root */
export interface MutationQaChangeYouSignRequestStatusArgs {
  registrationId: Scalars["UUID"];
  youSignRequestStatus: Scalars["String"];
}

/** Mutation root */
export interface MutationQaKbaSabrinaApprovedArgs {
  registrationId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationQaKbaSabrinaRejectedArgs {
  registrationId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationQaMarkKbaRequestFailedArgs {
  registrationId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationQaMarkKbaRequestSuccessfulArgs {
  registrationId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationRejectOrderFinancingArgs {
  orderId: Scalars["UUID"];
  reason: RetailOrderFinanceRejectReason;
}

/** Mutation root */
export interface MutationRemoveAdPublishingBlockersArgs {
  publishingBlockers: Array<InputMaybe<PublishingBlockerRemoveProjectionInput>>;
  retailAdId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationRequestBankDetailsArgs {
  request: RequestBankDetailsProjectionInput;
}

/** Mutation root */
export interface MutationRequestBankDetailsForOutboundPaymentArgs {
  request: RequestBankDetailsForOutboundPaymentProjectionInput;
}

/** Mutation root */
export interface MutationResetOrderHandoverDateArgs {
  adId: Scalars["UUID"];
  id: Scalars["UUID"];
}

/** Mutation root */
export interface MutationResetUserPasswordArgs {
  id: Scalars["UUID"];
}

/** Mutation root */
export interface MutationRetailDocumentPackageUpdateImportArgs {
  request: BatchProjection_RetailDocumentPackageUpdateImportProjectionInput;
}

/** Mutation root */
export interface MutationRetryRefundArgs {
  id: Scalars["UUID"];
  refund: RetailRefundRetryProjectionInput;
}

/** Mutation root */
export interface MutationRevertLastOrderTransitionArgs {
  adId: Scalars["UUID"];
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationSaveFinanceRiskEvaluationArgs {
  financeRiskEvaluation: SaveFinanceRiskEvaluationProjectionInput;
}

/** Mutation root */
export interface MutationSaveVisitingLogArgs {
  model: LogProjectionInput;
}

/** Mutation root */
export interface MutationSelfAssignTaskManagerTaskArgs {
  taskId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationSendNewFinanceApplicationToCustomerArgs {
  orderFinancingId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationSetHighlightsArgs {
  adId: Scalars["UUID"];
  highlights: BatchProjection_HighlightProjectionInput;
  locale: InputMaybe<Scalars["String"]>;
}

/** Mutation root */
export interface MutationSetRetailClaimCostApprove1Args {
  claimCostApprove: Scalars["String"];
  costId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationSetRetailClaimCostApprove2Args {
  claimCostApprove: Scalars["String"];
  costId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationSetRetailClaimCostApprove3Args {
  claimCostApprove: Scalars["String"];
  costId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationSetRetailClaimCostCustomerDecisionArgs {
  costId: Scalars["UUID"];
  customerDecision: Scalars["String"];
}

/** Mutation root */
export interface MutationStartFullManualEvaluationArgs {
  orderId: Scalars["UUID"];
  retailManualFullEvaluation: RetailManualFullEvaluationProjectionInput;
}

/** Mutation root */
export interface MutationStartFullManualEvaluationProtectedArgs {
  orderId: Scalars["UUID"];
  retailManualFullEvaluation: RetailManualFullEvaluationProjectionInput;
}

/** Mutation root */
export interface MutationStartFullManualEvaluationProtectedV2Args {
  orderId: Scalars["UUID"];
  retailManualFullEvaluation: RetailManualFullEvaluationProjectionV2Input;
}

/** Mutation root */
export interface MutationStartFullManualEvaluationV2Args {
  orderId: Scalars["UUID"];
  retailManualFullEvaluation: RetailManualFullEvaluationProjectionV2Input;
}

/** Mutation root */
export interface MutationSuperEditExternalRiskUserInfoArgs {
  borrowerType: Scalars["String"];
  orderId: Scalars["UUID"];
  updateExternalRiskUserInfo: UpdateExternalRiskUserInfoProjectionInput;
}

/** Mutation root */
export interface MutationTransitionPaymentUnidentifiedToInternalTransferArgs {
  category: Scalars["String"];
  id: Scalars["UUID"];
}

/** Mutation root */
export interface MutationTransitionPaymentUnidentifiedToInternalTransferBatchArgs {
  request: BatchProjection_RetailTransitionInternalTransferDtoInput;
}

/** Mutation root */
export interface MutationUnAssignTaskManagerTaskArgs {
  taskId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationUpdateAbTestingExperimentArgs {
  id: Scalars["UUID"];
  input: AbTestingExperimentInputInput;
}

/** Mutation root */
export interface MutationUpdateAdArgs {
  ad: AdUpdateProjectionInput;
  adId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationUpdateAdImagesArgs {
  adId: Scalars["UUID"];
  adImages: AdImageProjectionInput;
}

/** Mutation root */
export interface MutationUpdateAdVehicleArgs {
  adId: Scalars["UUID"];
  vehicle: AdVehicleProjectionInput;
}

/** Mutation root */
export interface MutationUpdateAvailabilityRuleArgs {
  availabilityRule: AvailabilityRuleUpdateProjectionInput;
  id: Scalars["UUID"];
}

/** Mutation root */
export interface MutationUpdateBranchEntryCheckStatusArgs {
  id: Scalars["UUID"];
  status: BranchEntryCheckStatus;
}

/** Mutation root */
export interface MutationUpdateCarRegistrationProductArgs {
  carRegistrationProduct: RetailCarRegistrationUpdateProjectionInput;
  id: Scalars["UUID"];
}

/** Mutation root */
export interface MutationUpdateCarTypeBasedPriceModifierArgs {
  carTypeBasedUpdateProjection: CarTypeBasedPriceModifierUpdateProjectionInput;
  id: Scalars["UUID"];
}

/** Mutation root */
export interface MutationUpdateChatbotQuestionArgs {
  id: Scalars["UUID"];
  question: ChatbotQuestionUpdateProjectionInput;
}

/** Mutation root */
export interface MutationUpdateCheckoutPaymentDetailsArgs {
  checkoutPaymentDetails: CheckoutPaymentDetailsUpdateProjectionInput;
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationUpdateClaimCostCompensationArgs {
  claimCostCompensation: ClaimCostCompensationUpdateProjectionInput;
  compensationId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationUpdateClaimStateCommentArgs {
  claimId: Scalars["UUID"];
  comment: InputMaybe<Scalars["String"]>;
}

/** Mutation root */
export interface MutationUpdateClaimStateDueDateArgs {
  claimId: Scalars["UUID"];
  dueDate: Scalars["String"];
}

/** Mutation root */
export interface MutationUpdateClaimStateDueDaysConfigArgs {
  updates: RetailClaimStateDueDaysConfigBatchUpdateProjectionInput;
}

/** Mutation root */
export interface MutationUpdateCreditNoteArgs {
  creditNote: InputMaybe<CreditNoteUpdateProjectionInput>;
  id: InputMaybe<Scalars["UUID"]>;
}

/** Mutation root */
export interface MutationUpdateCustomerAddressArgs {
  address: CustomerAddressUpdateProjectionInput;
  id: Scalars["UUID"];
  userId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationUpdateCustomerDisplayOnDamageArgs {
  customerDisplay: Scalars["Boolean"];
  id: Scalars["UUID"];
}

/** Mutation root */
export interface MutationUpdateCustomerExpectedMaxEtaArgs {
  customerExpectedMaxETA: Scalars["LocalDate"];
  delayReason: Scalars["String"];
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationUpdateCustomerUserArgs {
  id: Scalars["UUID"];
  user: CustomerUserUpdateProjectionInput;
}

/** Mutation root */
export interface MutationUpdateDigitalCarRegistrationArgs {
  id: Scalars["UUID"];
  registration: DigitalCarRegistrationUpdateProjectionInput;
}

/** Mutation root */
export interface MutationUpdateDigitalCarRegistrationBankAccountArgs {
  bankAccount: RetailAccountDataCreateProjectionInput;
  id: Scalars["UUID"];
}

/** Mutation root */
export interface MutationUpdateDocumentPackageArgs {
  input: UpdateDocumentPackageInput;
}

/** Mutation root */
export interface MutationUpdateDocumentPackagePartialArgs {
  input: UpdateDocumentPackagePartialInput;
}

/** Mutation root */
export interface MutationUpdateDownPaymentProductArgs {
  downPaymentUpdateProduct: DownPaymentUpdateProjectionInput;
  id: Scalars["UUID"];
}

/** Mutation root */
export interface MutationUpdateExternalFinancingEvaluationArgs {
  financeRiskEvaluation: UpdateExternalFinanceRiskEvaluationProjectionInput;
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationUpdateFeeProductArgs {
  id: Scalars["UUID"];
  retailFeeUpdateProduct: RetailFeeUpdateProjectionInput;
}

/** Mutation root */
export interface MutationUpdateFinancingApplicationTypeArgs {
  applicationType: Scalars["String"];
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationUpdateFinancingPaymentDetailsArgs {
  orderId: Scalars["UUID"];
  update: FinancingPaymentDetailsUpdateProjectionInput;
}

/** Mutation root */
export interface MutationUpdateFrRegionalTaxBasedPriceModifierArgs {
  frRegionalTaxBasedUpdateProjection: FrRegionalTaxBasedPriceModifierUpdateProjectionInput;
  id: Scalars["UUID"];
}

/** Mutation root */
export interface MutationUpdateGroupArgs {
  group: GroupUpdateProjectionInput;
  id: Scalars["UUID"];
}

/** Mutation root */
export interface MutationUpdateItPowerAndZoneBasedPriceModifierArgs {
  id: Scalars["UUID"];
  itPowerAndZoneBasedUpdateProjection: ItPowerAndZoneBasedPriceModifierUpdateProjectionInput;
}

/** Mutation root */
export interface MutationUpdateImageTagArgs {
  id: Scalars["UUID"];
  imageTag: RetailImageTagUpdateProjectionInput;
}

/** Mutation root */
export interface MutationUpdateInsuranceProductArgs {
  id: Scalars["UUID"];
  retailInsuranceUpdateProduct: RetailInsuranceUpdateProjectionInput;
}

/** Mutation root */
export interface MutationUpdateInvoiceArgs {
  id: Scalars["UUID"];
  invoice: InvoiceUpdateProjectionInput;
}

/** Mutation root */
export interface MutationUpdateOrCreateQaDataDigitalCarRegistrationArgs {
  qaData: DigitalCarRegistrationQaDataUpdateProjectionInput;
  registrationId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationUpdateOrderArgs {
  id: Scalars["UUID"];
  order: OrderUpdateProjectionInput;
}

/** Mutation root */
export interface MutationUpdateOrderAddressArgs {
  address: OrderAddressUpdateProjectionInput;
  id: Scalars["UUID"];
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationUpdateOrderBillingAddressArgs {
  address: OrderAddressUpdateProjectionInput;
  id: Scalars["UUID"];
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationUpdateOrderBranchArgs {
  branchId: InputMaybe<Scalars["Int"]>;
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationUpdateOrderCarRegistrationArgs {
  carRegistration: OrderCarRegistrationUpdateProjectionInput;
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationUpdateOrderCarRegistrationAddressArgs {
  address: OrderAddressUpdateProjectionInput;
  id: Scalars["UUID"];
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationUpdateOrderDeliveryOptionArgs {
  deliveryOption: Scalars["String"];
  estimationId: InputMaybe<Scalars["UUID"]>;
  hubId: InputMaybe<Scalars["Int"]>;
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationUpdateOrderDeliveryPlannedCarHandoverDateArgs {
  branchPlannedCarHandoverOn: InputMaybe<Scalars["String"]>;
  comment: ReschedulingCommentProjectionInput;
  homePlannedCarHandoverFrom: InputMaybe<Scalars["String"]>;
  homePlannedCarHandoverTo: InputMaybe<Scalars["String"]>;
  id: Scalars["UUID"];
}

/** Mutation root */
export interface MutationUpdateOrderDeliveryTypeArgs {
  deliveryType: Scalars["String"];
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationUpdateOrderDocumentHandoverOnArgs {
  id: Scalars["UUID"];
  value: Scalars["String"];
}

/** Mutation root */
export interface MutationUpdateOrderDocumentShippingAddressArgs {
  address: OrderAddressUpdateProjectionInput;
  id: Scalars["UUID"];
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationUpdateOrderFinancingArgs {
  orderFinancing: OrderFinancingUpdateProjectionInput;
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationUpdateOrderIsBranchCheckupSuccessfulArgs {
  id: Scalars["UUID"];
  value: Scalars["Boolean"];
}

/** Mutation root */
export interface MutationUpdateOrderIsTestArgs {
  id: Scalars["UUID"];
  value: Scalars["Boolean"];
}

/** Mutation root */
export interface MutationUpdateOrderItemDiscountGrossArgs {
  id: Scalars["UUID"];
  orderId: Scalars["UUID"];
  value: Scalars["Long"];
}

/** Mutation root */
export interface MutationUpdateOrderItemPriceGrossArgs {
  id: Scalars["UUID"];
  orderId: Scalars["UUID"];
  value: Scalars["Long"];
}

/** Mutation root */
export interface MutationUpdateOrderPaymentDetailsArgs {
  orderId: Scalars["UUID"];
  paymentDetails: OrderPaymentDetailsUpdateProjectionInput;
}

/** Mutation root */
export interface MutationUpdateOrderShippingAddressArgs {
  address: OrderAddressUpdateProjectionInput;
  id: Scalars["UUID"];
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationUpdateOrderShippingAddressForceArgs {
  address: OrderAddressUpdateProjectionInput;
  id: Scalars["UUID"];
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationUpdateOrderVehicleDeliveredOnArgs {
  id: Scalars["UUID"];
  value: Scalars["String"];
}

/** Mutation root */
export interface MutationUpdateOutboundPaymentArgs {
  id: Scalars["UUID"];
  outboundPaymentUpdate: RetailOutboundPaymentUpdateProjectionInput;
}

/** Mutation root */
export interface MutationUpdatePnlItemArgs {
  id: Scalars["UUID"];
  item: PnlItemUpdateProjectionInput;
}

/** Mutation root */
export interface MutationUpdateProductArgs {
  id: Scalars["UUID"];
  product: ProductUpdateProjectionInput;
}

/** Mutation root */
export interface MutationUpdateRefundArgs {
  id: Scalars["UUID"];
  refund: RetailRefundUpdateProjectionInput;
}

/** Mutation root */
export interface MutationUpdateRefundAmountArgs {
  id: Scalars["UUID"];
  value: Scalars["Long"];
}

/** Mutation root */
export interface MutationUpdateRefurbishmentArgs {
  id: Scalars["UUID"];
  refurbishment: RefurbishmentUpdateProjectionInput;
}

/** Mutation root */
export interface MutationUpdateRefurbishmentCommentArgs {
  comment: Scalars["String"];
  commentId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationUpdateRefurbishmentServicesArgs {
  services: Array<InputMaybe<RefurbishmentUpdateServicesProjectionInput>>;
}

/** Mutation root */
export interface MutationUpdateRefurbishmentStateArgs {
  adId: Scalars["UUID"];
  refurbishmentId: Scalars["UUID"];
  stateChange: RetailRefurbishmentStateChangeDtoInput;
}

/** Mutation root */
export interface MutationUpdateRetailAdArgs {
  id: Scalars["UUID"];
  retailAd: RetailAdUpdateProjectionInput;
}

/** Mutation root */
export interface MutationUpdateRetailAdAuto1BuyPriceArgs {
  id: Scalars["UUID"];
  value: Scalars["Int"];
}

/** Mutation root */
export interface MutationUpdateRetailAdAuto1ReturnOnArgs {
  id: Scalars["UUID"];
  value: InputMaybe<Scalars["String"]>;
}

/** Mutation root */
export interface MutationUpdateRetailAdAuto1ReturnReasonArgs {
  id: Scalars["UUID"];
  value: InputMaybe<Scalars["String"]>;
}

/** Mutation root */
export interface MutationUpdateRetailAdAutoheroPurchaseOnArgs {
  id: Scalars["UUID"];
  value: Scalars["String"];
}

/** Mutation root */
export interface MutationUpdateRetailAdDamageDisplayToCustomerFrontendArgs {
  changes: Array<InputMaybe<ChangeInput>>;
  retailAdId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationUpdateRetailAdFirstImportOnArgs {
  id: Scalars["UUID"];
  value: Scalars["String"];
}

/** Mutation root */
export interface MutationUpdateRetailAdHidingReasonArgs {
  id: Scalars["UUID"];
  value: Scalars["String"];
}

/** Mutation root */
export interface MutationUpdateRetailAdIsTestArgs {
  id: Scalars["UUID"];
  value: Scalars["Boolean"];
}

/** Mutation root */
export interface MutationUpdateRetailAdRetailReadyDateArgs {
  id: Scalars["UUID"];
  value: Scalars["String"];
}

/** Mutation root */
export interface MutationUpdateRetailAdSecondaryWheelsDetailsArgs {
  details: RetailAdSecondaryWheelsDetailsUpdateProjectionInput;
  id: Scalars["UUID"];
}

/** Mutation root */
export interface MutationUpdateRetailAdSecondaryWheelsImagesArgs {
  id: Scalars["UUID"];
  imageIds: RetailBatchDto_UuidInput;
}

/** Mutation root */
export interface MutationUpdateRetailAdSecondaryWheelsRimsDetailsArgs {
  id: Scalars["UUID"];
  rimsDetails: NotEmptyMapUpdateProjection_WheelPlacementType_RimDetailsInput;
}

/** Mutation root */
export interface MutationUpdateRetailAdSecondaryWheelsTiresDetailsArgs {
  id: Scalars["UUID"];
  tiresDetails: NotEmptyMapUpdateProjection_WheelPlacementType_TireDetailsInput;
}

/** Mutation root */
export interface MutationUpdateRetailAddressArgs {
  address: RetailAddressUpdateProjectionInput;
  id: Scalars["UUID"];
  validate?: InputMaybe<Scalars["Boolean"]>;
}

/** Mutation root */
export interface MutationUpdateRetailCheckoutArgs {
  checkout: RetailCheckoutUpdateProjectionInput;
  id: Scalars["UUID"];
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationUpdateRetailCheckoutByOrderIdArgs {
  checkout: RetailCheckoutUpdateProjectionInput;
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationUpdateRetailClaimCompensationTypeArgs {
  claimId: Scalars["UUID"];
  compensation: Scalars["String"];
}

/** Mutation root */
export interface MutationUpdateRetailClaimCostArgs {
  claimCostUpdate: RetailClaimCostUpdateProjectionInput;
  costId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationUpdateRetailClaimWorkshopDetailsArgs {
  claimId: Scalars["UUID"];
  retailClaimWorkshopDetails: RetailClaimWorkshopDetailsUpdateProjectionInput;
  workshopId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationUpdateRetailCustomerArgs {
  customer: RetailCustomerUpdateProjectionInput;
  customerId: Scalars["UUID"];
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationUpdateRetailFeatureArgs {
  feature: UpdateRetailFeatureProjectionInput;
  id: Scalars["UUID"];
}

/** Mutation root */
export interface MutationUpdateRetailLeadArgs {
  id: Scalars["UUID"];
  retailLead: RetailLeadProjectionInput;
}

/** Mutation root */
export interface MutationUpdateRetailOrderClaimAssigneeUserArgs {
  claimId: Scalars["UUID"];
  updateUserAssign: RetailClaimUpdateUserAssignProjectionInput;
}

/** Mutation root */
export interface MutationUpdateRetailOrderClaimGroupArgs {
  claimId: Scalars["UUID"];
  updateGroup: RetailClaimUpdateGroupProjectionInput;
}

/** Mutation root */
export interface MutationUpdateRetailOrderClaimStateArgs {
  claimId: Scalars["UUID"];
  updateState: RetailClaimUpdateStateProjectionInput;
}

/** Mutation root */
export interface MutationUpdateRetailOrderSubClaimArgs {
  retailClaim: RetailSubClaimUpdateProjectionInput;
  subClaimId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationUpdateRetailPaymentArgs {
  id: Scalars["UUID"];
  payment: RetailPaymentUpdateProjectionInput;
}

/** Mutation root */
export interface MutationUpdateRetailPaymentImportConfigArgs {
  config: RetailDataImportConfigUpdateProjectionInput;
}

/** Mutation root */
export interface MutationUpdateRetailShippingAddressForceArgs {
  address: RetailAddressUpdateProjectionInput;
  orderId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationUpdateRetailTaxNoVaArgs {
  id: Scalars["UUID"];
  updateProjection: RetailTaxNoVaUpdateProjectionInput;
}

/** Mutation root */
export interface MutationUpdateRetailUnidentifiedPaymentArgs {
  id: Scalars["UUID"];
  unidentifiedPayment: RetailUnidentifiedPaymentUpdateProjectionInput;
}

/** Mutation root */
export interface MutationUpdateRoleArgs {
  id: Scalars["UUID"];
  role: RoleUpdateProjectionInput;
}

/** Mutation root */
export interface MutationUpdateSellerAddressArgs {
  addressId: Scalars["UUID"];
  sellerAddress: SellerAddressProjectionInput;
  sellerId: Scalars["UUID"];
}

/** Mutation root */
export interface MutationUpdateSurveyActivePageArgs {
  activePage: Scalars["Int"];
  id: Scalars["UUID"];
}

/** Mutation root */
export interface MutationUpdateSurveyStatusArgs {
  id: Scalars["UUID"];
  status: Scalars["String"];
}

/** Mutation root */
export interface MutationUpdateTaskManagerTaskActionArgs {
  description: InputMaybe<Scalars["String"]>;
  taskId: Scalars["UUID"];
  variables: InputMaybe<Scalars["Map_String_ObjectScalar"]>;
}

/** Mutation root */
export interface MutationUpdateTaxAdvantageArgs {
  id: Scalars["UUID"];
  taxAdvantage: RetailAdTaxAdvantageUpdateProjectionInput;
}

/** Mutation root */
export interface MutationUpdateTaxBpmArgs {
  id: Scalars["UUID"];
  updateProjection: RetailTaxBpmUpdateProjectionInput;
}

/** Mutation root */
export interface MutationUpdateTestOrderDownPaymentArgs {
  downPayment: UpdateTestOrderDownPaymentProjectionInput;
  id: Scalars["UUID"];
}

/** Mutation root */
export interface MutationUpdateUserArgs {
  id: Scalars["UUID"];
  user: UserUpdateProjectionInput;
}

/** Mutation root */
export interface MutationUpdateVehiclePreparationProductArgs {
  id: Scalars["UUID"];
  retailVehiclePreparationUpdateProduct: RetailVehiclePreparationUpdateProjectionInput;
}

/** Mutation root */
export interface MutationUpdateWarrantyCarPricePriceModifierArgs {
  id: Scalars["UUID"];
  warrantyCarPriceUpdateProjection: WarrantyCarPricePriceModifierUpdateProjectionInput;
}

/** Mutation root */
export interface MutationUpdateWarrantyCarYearsPriceModifierArgs {
  id: Scalars["UUID"];
  warrantyCarYearsUpdateProjection: WarrantyCarYearsPriceModifierUpdateProjectionInput;
}

/** Mutation root */
export interface MutationUpdateWarrantyProductArgs {
  id: Scalars["UUID"];
  retailWarrantyUpdateProduct: RetailWarrantyUpdateProjectionInput;
}

/** Mutation root */
export interface MutationUserObfuscationArgs {
  id: Scalars["UUID"];
}

/** Mutation root */
export interface MutationVerificationCallSurveySubmitAnswersArgs {
  surveyId: Scalars["UUID"];
  updateSurveyDTOInput: UpdateSurveyDtoInputInput;
}

export interface NewFinancingApplicationDto {
  __typename?: "NewFinancingApplicationDTO";
  expiresOn: Maybe<Scalars["String"]>;
  reason: Maybe<Scalars["String"]>;
  sendToCustomerOn: Maybe<Scalars["String"]>;
  status: Maybe<Scalars["String"]>;
}

export interface NotEmptyMapUpdateProjection_WheelPlacementType_RimDetailsInput {
  map?: InputMaybe<Scalars["Map_WheelPlacementType_RimDetailsScalar"]>;
}

export interface NotEmptyMapUpdateProjection_WheelPlacementType_TireDetailsInput {
  map?: InputMaybe<Scalars["Map_WheelPlacementType_TireDetailsScalar"]>;
}

export interface OfflineLeadsNewsletterSubscriptionImportProjectionInput {
  fileName: Scalars["String"];
}

export interface OffsetBasedResponseProjection_BranchDto {
  __typename?: "OffsetBasedResponseProjection_BranchDTO";
  entities: Maybe<Array<Maybe<BranchDto>>>;
  first: Scalars["Int"];
  offset: Scalars["Int"];
  total: Scalars["Int"];
}

export interface OpportunitySearchProjection {
  __typename?: "OpportunitySearchProjection";
  customerLeadId: Maybe<Scalars["UUID"]>;
  id: Maybe<Scalars["UUID"]>;
}

export type OrderAccountDetailsRequestSource =
  | "CANCELLATION_REFUND"
  | "DAMAGE_COMPENSATION"
  | "OVERPAYMENT"
  | "PARTIAL_REFUND";

export interface OrderAddressCreateProjectionInput {
  addressId?: InputMaybe<Scalars["UUID"]>;
  addressType?: InputMaybe<Scalars["String"]>;
  branchId?: InputMaybe<Scalars["Long"]>;
  customerId?: InputMaybe<Scalars["UUID"]>;
  customerType?: InputMaybe<Scalars["String"]>;
}

export interface OrderAddressProjection {
  __typename?: "OrderAddressProjection";
  address: Maybe<RetailAddressProjection>;
  addressId: Maybe<Scalars["UUID"]>;
  addressType: Maybe<Scalars["String"]>;
  branch: Maybe<BranchProjection>;
  branchId: Maybe<Scalars["Long"]>;
  created: Maybe<Scalars["String"]>;
  customerId: Maybe<Scalars["UUID"]>;
  id: Maybe<Scalars["UUID"]>;
  orderId: Maybe<Scalars["UUID"]>;
  updated: Maybe<Scalars["String"]>;
}

export interface OrderAddressUpdateProjectionInput {
  addressId?: InputMaybe<Scalars["UUID"]>;
  branchId?: InputMaybe<Scalars["Long"]>;
}

export interface OrderAutoCancellationProjection {
  __typename?: "OrderAutoCancellationProjection";
  applicability: Maybe<Scalars["String"]>;
  orderId: Maybe<Scalars["UUID"]>;
  plannedOn: Maybe<Scalars["String"]>;
  state: Maybe<Scalars["String"]>;
}

export interface OrderAutomaticVerificationProjection {
  __typename?: "OrderAutomaticVerificationProjection";
  orderId: Maybe<Scalars["UUID"]>;
  reason: Maybe<AutomaticVerificationReason>;
  source: Maybe<Scalars["UUID"]>;
  sourceOrderNumber: Maybe<Scalars["String"]>;
}

export interface OrderCancelMainReasonProjection {
  __typename?: "OrderCancelMainReasonProjection";
  name: Maybe<Scalars["String"]>;
  subReasons: Maybe<Array<Maybe<Scalars["String"]>>>;
}

export interface OrderCancelPeriodProjection {
  __typename?: "OrderCancelPeriodProjection";
  name: Maybe<Scalars["String"]>;
  reasons: Maybe<Array<Maybe<OrderCancelMainReasonProjection>>>;
}

export interface OrderCancelTypeProjection {
  __typename?: "OrderCancelTypeProjection";
  name: Maybe<Scalars["String"]>;
  periods: Maybe<Array<Maybe<OrderCancelPeriodProjection>>>;
}

export interface OrderCancellationOptionsProjection {
  __typename?: "OrderCancellationOptionsProjection";
  options: Maybe<Array<Maybe<OrderCancelTypeProjection>>>;
}

export interface OrderCancellationRevertibleProjection {
  __typename?: "OrderCancellationRevertibleProjection";
  reasonForNotSupporting: Maybe<Scalars["String"]>;
  stateBeforeCancelling: Maybe<Scalars["String"]>;
  supported: Maybe<Scalars["Boolean"]>;
}

export interface OrderCancellationTaskProjectionInput {
  cancelRequestSubReason?: InputMaybe<Scalars["String"]>;
  notes?: InputMaybe<Scalars["String"]>;
  topic?: InputMaybe<Scalars["String"]>;
}

export interface OrderCarRegistrationUpdateProjectionInput {
  status?: InputMaybe<Scalars["String"]>;
}

export interface OrderCommentCreateProjectionInput {
  cancelRequestSubReason?: InputMaybe<Scalars["String"]>;
  channel?: InputMaybe<Scalars["String"]>;
  notes?: InputMaybe<Scalars["String"]>;
  topic?: InputMaybe<Scalars["String"]>;
}

export interface OrderCommentPinInteractionProjectionInput {
  interactionId?: InputMaybe<Scalars["UUID"]>;
  pin?: InputMaybe<Scalars["String"]>;
}

export interface OrderCommentProjection {
  __typename?: "OrderCommentProjection";
  channel: Maybe<Scalars["String"]>;
  createdBy: Maybe<RetailUserDto>;
  createdOn: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  notes: Maybe<Scalars["String"]>;
  orderId: Maybe<Scalars["UUID"]>;
  tags: Maybe<Array<Maybe<CommentTagProjection>>>;
}

export interface OrderComplianceCheckCaseProjection {
  __typename?: "OrderComplianceCheckCaseProjection";
  customerCheckResult: Maybe<Scalars["String"]>;
  orderId: Maybe<Scalars["UUID"]>;
  verificationDecision: Maybe<Scalars["String"]>;
}

export interface OrderCreateProjectionInput {
  auxUserId?: InputMaybe<Scalars["UUID"]>;
  branchId?: InputMaybe<Scalars["Int"]>;
  checkoutBranchId?: InputMaybe<Scalars["Int"]>;
  conversionType?: InputMaybe<Scalars["String"]>;
  delivery?: InputMaybe<RetailOrderDeliveryDtoInput>;
  financingFlowVersion?: InputMaybe<Scalars["String"]>;
  flowVersion?: InputMaybe<Scalars["String"]>;
  items?: InputMaybe<Array<InputMaybe<OrderItemCreateProjectionInput>>>;
  linkedOrder?: InputMaybe<LinkedOrdersDtoInput>;
  orderAddresses?: InputMaybe<
    Array<InputMaybe<OrderAddressCreateProjectionInput>>
  >;
  retailCountry?: InputMaybe<Scalars["String"]>;
  retailCustomers?: InputMaybe<
    Array<InputMaybe<RetailCustomerCreateProjectionInput>>
  >;
  source?: InputMaybe<Scalars["String"]>;
}

export interface OrderCustomerLeadProjection {
  __typename?: "OrderCustomerLeadProjection";
  lastUpdated: Maybe<Scalars["String"]>;
  notes: Maybe<Scalars["String"]>;
}

export interface OrderEmailEventAttachmentCreateProjectionInput {
  fileName?: InputMaybe<Scalars["String"]>;
  originalFileName?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
}

export interface OrderEmailEventAttachmentProjection {
  __typename?: "OrderEmailEventAttachmentProjection";
  id: Maybe<Scalars["String"]>;
  originalFileName: Maybe<Scalars["String"]>;
  stored: Maybe<Scalars["Boolean"]>;
  title: Maybe<Scalars["String"]>;
  url: Maybe<Scalars["String"]>;
}

export interface OrderFinanceCancelReasonProjection {
  __typename?: "OrderFinanceCancelReasonProjection";
  description: Maybe<Scalars["String"]>;
  key: Maybe<Scalars["String"]>;
  subReasons: Maybe<Array<Maybe<OrderFinanceCancelSubReasonProjection>>>;
}

export interface OrderFinanceCancelSubReasonProjection {
  __typename?: "OrderFinanceCancelSubReasonProjection";
  description: Maybe<Scalars["String"]>;
  key: Maybe<Scalars["String"]>;
}

export interface OrderFinanceRejectReasonProjection {
  __typename?: "OrderFinanceRejectReasonProjection";
  description: Maybe<Scalars["String"]>;
  key: Maybe<Scalars["String"]>;
}

export interface OrderFinancingDto {
  __typename?: "OrderFinancingDto";
  applicationNumber: Maybe<Scalars["String"]>;
  applicationType: Maybe<Scalars["String"]>;
  approvedOn: Maybe<Scalars["String"]>;
  cancelReason: Maybe<Scalars["String"]>;
  cancelSubReason: Maybe<Scalars["String"]>;
  createdBy: Maybe<Scalars["UUID"]>;
  createdOn: Maybe<Scalars["String"]>;
  financingFlowVersion: Maybe<RetailOrderFinancingFlowVersion>;
  financingType: Maybe<OrderFinancingType>;
  flowType: Maybe<RetailOrderFinancingFlowType>;
  id: Maybe<Scalars["UUID"]>;
  monthlyPaymentDay: Maybe<Scalars["Int"]>;
  newFinancingApplication: Maybe<NewFinancingApplicationDto>;
  orderId: Maybe<Scalars["UUID"]>;
  orderPaymentDetails: Maybe<OrderFinancingPaymentDetailsDto>;
  preApprovedOn: Maybe<Scalars["String"]>;
  rejectReason: Maybe<Scalars["String"]>;
  retailCountry: Maybe<Scalars["String"]>;
  status: Maybe<Scalars["String"]>;
  submittedToBankOn: Maybe<Scalars["String"]>;
  updatedBy: Maybe<Scalars["UUID"]>;
  updatedOn: Maybe<Scalars["String"]>;
  userConsent: Scalars["Boolean"];
  userId: Maybe<Scalars["UUID"]>;
  validationOn: Maybe<Scalars["String"]>;
}

export interface OrderFinancingHistoryProjection {
  __typename?: "OrderFinancingHistoryProjection";
  createdBy: Maybe<RetailUserDto>;
  createdOn: Maybe<Scalars["String"]>;
  event: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  orderFinancingId: Maybe<Scalars["UUID"]>;
  orderId: Maybe<Scalars["UUID"]>;
  reason: Maybe<Scalars["String"]>;
  status: Maybe<Scalars["String"]>;
}

export interface OrderFinancingPaymentDetailsDto {
  __typename?: "OrderFinancingPaymentDetailsDTO";
  contractType: Maybe<Scalars["String"]>;
  creditAmountMinorUnits: Maybe<Scalars["Long"]>;
  creditPeriod: Maybe<Scalars["Int"]>;
  currencyCode: Maybe<Scalars["String"]>;
  interestRate: Maybe<Scalars["Float"]>;
  lastUpdateInitiator: Maybe<Scalars["String"]>;
  monthlyPaymentAmountMinorUnits: Maybe<Scalars["Long"]>;
  paymentType: Maybe<Scalars["String"]>;
}

export interface OrderFinancingSaveFlowTypeProjectionInput {
  flowType?: InputMaybe<RetailOrderFinancingFlowType>;
}

export type OrderFinancingType = "EXTERNAL" | "INTERNAL";

export interface OrderFinancingUpdateProjectionInput {
  cancelReason?: InputMaybe<RetailOrderFinanceCancelReason>;
  cancelSubReason?: InputMaybe<RetailOrderFinanceCancelSubReason>;
  rejectReason?: InputMaybe<RetailOrderFinanceRejectReason>;
  status?: InputMaybe<Scalars["String"]>;
}

export interface OrderHistoryEmailPlaceholderInput {
  key?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
}

export interface OrderHistoryEventCreateProjectionInput {
  cancelRequestSubReason?: InputMaybe<Scalars["String"]>;
  message?: InputMaybe<Scalars["String"]>;
  source?: InputMaybe<Scalars["String"]>;
  topic?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
}

export interface OrderHistoryEventPinProjectionInput {
  eventId?: InputMaybe<Scalars["UUID"]>;
  pin?: InputMaybe<Scalars["String"]>;
}

export interface OrderHistoryEventProjection {
  __typename?: "OrderHistoryEventProjection";
  attachments: Maybe<Array<Maybe<OrderEmailEventAttachmentProjection>>>;
  createdBy: Maybe<RetailUserDto>;
  createdOn: Maybe<Scalars["String"]>;
  customerEmail: Maybe<Scalars["String"]>;
  eventType: Maybe<HistoryEventType>;
  id: Maybe<Scalars["UUID"]>;
  message: Maybe<Scalars["String"]>;
  orderId: Maybe<Scalars["UUID"]>;
  pinned: Maybe<Scalars["Boolean"]>;
  properties: Maybe<Scalars["Map_String_StringScalar"]>;
  recipient: Maybe<Scalars["String"]>;
  source: Maybe<Scalars["String"]>;
  topic: Maybe<Scalars["String"]>;
  type: Maybe<Scalars["String"]>;
}

export interface OrderItemCreateProjectionInput {
  discountGrossMinorUnits?: InputMaybe<Scalars["Long"]>;
  discountMinorUnits?: InputMaybe<Scalars["Long"]>;
  externalId?: InputMaybe<Scalars["UUID"]>;
  externalType?: InputMaybe<Scalars["Int"]>;
  priceGrossMinorUnits?: InputMaybe<Scalars["Long"]>;
  type?: InputMaybe<Scalars["String"]>;
  zipcode?: InputMaybe<Scalars["String"]>;
}

export interface OrderItemProjection {
  __typename?: "OrderItemProjection";
  created: Maybe<Scalars["String"]>;
  currencyCode: Maybe<Scalars["String"]>;
  discountGrossMinorUnits: Maybe<Scalars["Long"]>;
  discountMinorUnits: Maybe<Scalars["Long"]>;
  externalId: Maybe<Scalars["UUID"]>;
  externalType: Maybe<Scalars["Int"]>;
  id: Maybe<Scalars["UUID"]>;
  invoiceDescription: Maybe<Scalars["String"]>;
  invoiceName: Maybe<Scalars["String"]>;
  orderId: Maybe<Scalars["UUID"]>;
  parentId: Maybe<Scalars["UUID"]>;
  priceDiscountGross: Maybe<PriceDto>;
  priceDiscountNet: Maybe<PriceDto>;
  priceDiscountVat: Maybe<PriceDto>;
  priceGross: Maybe<PriceDto>;
  priceGrossMinorUnits: Maybe<Scalars["Long"]>;
  priceNet: Maybe<PriceDto>;
  priceNetMinorUnits: Maybe<Scalars["Long"]>;
  priceTaxAdvantageAmount: Maybe<PriceDto>;
  priceVatAmount: Maybe<PriceDto>;
  type: Maybe<Scalars["String"]>;
  updated: Maybe<Scalars["String"]>;
  vatAmountMinorUnits: Maybe<Scalars["Long"]>;
  vatKey: Maybe<Scalars["String"]>;
  vatRate: Maybe<Scalars["Float"]>;
}

export interface OrderItemsOptionsProjection {
  __typename?: "OrderItemsOptionsProjection";
  options: Maybe<Array<Maybe<Scalars["String"]>>>;
}

export interface OrderItemsRefundsDto {
  __typename?: "OrderItemsRefundsDTO";
  amountMinorUnits: Maybe<Scalars["Long"]>;
  externalOrderItemId: Maybe<Scalars["UUID"]>;
}

export interface OrderPaymentDetailsProjection {
  __typename?: "OrderPaymentDetailsProjection";
  amountInWords: Maybe<Scalars["String"]>;
  balanceAmount: Maybe<PriceDto>;
  createdBy: Maybe<Scalars["UUID"]>;
  createdOn: Maybe<Scalars["String"]>;
  creditAmount: Maybe<PriceDto>;
  creditNoteAmount: Maybe<PriceDto>;
  creditPeriod: Maybe<Scalars["Int"]>;
  depositAmount: Maybe<PriceDto>;
  depositDueDate: Maybe<Scalars["String"]>;
  depositPaidOn: Maybe<Scalars["String"]>;
  financingApplicability: Maybe<FinancingApplicabilityProjection>;
  financingType: Maybe<Scalars["String"]>;
  fullPaymentDueDate: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  monthlyPaymentAmountMinorUnits: Maybe<Scalars["Long"]>;
  orderId: Maybe<Scalars["UUID"]>;
  paidAmount: Maybe<PriceDto>;
  paidCashAmount: Maybe<PriceDto>;
  paidFinancingAmount: Maybe<PriceDto>;
  paymentState: Maybe<OrderPaymentState>;
  paymentType: Maybe<Scalars["String"]>;
  refundAmount: Maybe<PriceDto>;
  remainingAmount: Maybe<PriceDto>;
  remainingCashAmount: Maybe<PriceDto>;
  updatedBy: Maybe<Scalars["UUID"]>;
  updatedOn: Maybe<Scalars["String"]>;
}

export interface OrderPaymentDetailsUpdateProjectionInput {
  amountInWords?: InputMaybe<Scalars["String"]>;
  creditAmountMinorUnits?: InputMaybe<Scalars["Long"]>;
  creditPeriod?: InputMaybe<Scalars["Int"]>;
  depositAmountMinorUnits?: InputMaybe<Scalars["Long"]>;
  depositDueDate?: InputMaybe<Scalars["String"]>;
  discountRequested?: InputMaybe<Scalars["Boolean"]>;
  financingType?: InputMaybe<Scalars["String"]>;
  fullPaymentDueDate?: InputMaybe<Scalars["String"]>;
  monthlyPaymentAmountMinorUnits?: InputMaybe<Scalars["Long"]>;
  paymentType?: InputMaybe<Scalars["String"]>;
}

export type OrderPaymentState =
  | "FULLY_PAID"
  | "FULLY_REFUNDED"
  | "OPEN"
  | "OVERPAID"
  | "PARTLY_PAID"
  | "REFUNDED";

export interface OrderProjection {
  __typename?: "OrderProjection";
  adItems: Maybe<Array<Maybe<AdItemProjection>>>;
  adSecondaryWheelsItems: Maybe<Array<Maybe<SecondaryWheelsItemProjection>>>;
  amountInWords: Maybe<Scalars["String"]>;
  branchCheckupOn: Maybe<Scalars["String"]>;
  branchId: Maybe<Scalars["Int"]>;
  cancelDescription: Maybe<Scalars["String"]>;
  cancelPeriod: Maybe<Scalars["String"]>;
  cancelReason: Maybe<Scalars["String"]>;
  cancelType: Maybe<Scalars["String"]>;
  canceledOn: Maybe<Scalars["String"]>;
  carInBranch: Maybe<Scalars["Boolean"]>;
  carRegistration: Maybe<RetailOrderCarRegistrationDto>;
  carRegistrationItems: Maybe<Array<Maybe<CarRegistrationItemProjection>>>;
  carRegistrationStatus: Maybe<CarRegistrationStatusDictionaryDto>;
  carReservedOn: Maybe<Scalars["String"]>;
  checkoutBranchId: Maybe<Scalars["Int"]>;
  completed: Maybe<Scalars["String"]>;
  confirmed: Maybe<Scalars["Boolean"]>;
  contractSentOn: Maybe<Scalars["String"]>;
  contractSignedOn: Maybe<Scalars["String"]>;
  conversionType: Maybe<Scalars["String"]>;
  created: Maybe<Scalars["String"]>;
  creditAmount: Maybe<PriceDto>;
  creditAmountMinorUnits: Maybe<Scalars["Long"]>;
  customer: Maybe<RetailCustomerProjection>;
  customerCountry: Maybe<Scalars["String"]>;
  customerEmail: Maybe<Scalars["String"]>;
  deliveryDetails: Maybe<RetailOrderDeliveryDetailsProjection>;
  deliveryETA: Maybe<RangeDto_Integer>;
  deliveryItems: Maybe<Array<Maybe<OrderItemProjection>>>;
  deliveryType: Maybe<Scalars["String"]>;
  depositAmount: Maybe<PriceDto>;
  depositAmountMinorUnits: Maybe<Scalars["Long"]>;
  depositDueDate: Maybe<Scalars["String"]>;
  documentHandoverOn: Maybe<Scalars["String"]>;
  downPaymentItems: Maybe<Array<Maybe<DownPaymentItemProjection>>>;
  editable: Maybe<Scalars["Boolean"]>;
  feeItems: Maybe<Array<Maybe<FeeItemProjection>>>;
  finalDocumentHandoverOn: Maybe<Scalars["String"]>;
  finalPaymentDueDate: Maybe<Scalars["String"]>;
  financeApplications: Maybe<Array<Maybe<FinanceAppProjection>>>;
  financingFlowVersion: Maybe<Scalars["String"]>;
  flowVersion: Maybe<Scalars["String"]>;
  fullPaymentDueDate: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  insuranceItems: Maybe<Array<Maybe<InsuranceItemProjection>>>;
  isBranchCheckupSuccessful: Maybe<Scalars["Boolean"]>;
  isOracle: Maybe<Scalars["Boolean"]>;
  isTest: Maybe<Scalars["Boolean"]>;
  isTradeIn: Maybe<Scalars["Boolean"]>;
  items: Maybe<Array<Maybe<OrderItemProjection>>>;
  linkedOrders: Maybe<Array<Maybe<LinkedOrdersDto>>>;
  locale: Maybe<Scalars["String"]>;
  orderNumber: Maybe<Scalars["String"]>;
  overallDelayCalendarDays: Maybe<Scalars["Int"]>;
  paymentDetails: Maybe<OrderPaymentDetailsProjection>;
  paymentStatus: Maybe<Scalars["String"]>;
  paymentType: Maybe<Scalars["String"]>;
  /** @deprecated This field is kept for backwards compatibility with front-end. After front-end has stopped using it, it will be removed. Use deliveryDetails.branchPlannedCarHandoverOn instead. */
  plannedCarHandoverOn: Maybe<Scalars["String"]>;
  productItems: Maybe<Array<Maybe<ProductItemProjection>>>;
  reservationEndOn: Maybe<Scalars["String"]>;
  retailCountry: Maybe<Scalars["String"]>;
  salesAgentAssignedTo: Maybe<UserProjection>;
  source: Maybe<Scalars["String"]>;
  state: Maybe<Scalars["String"]>;
  stockNumber: Maybe<Scalars["String"]>;
  subCancelReason: Maybe<Scalars["String"]>;
  taxBPM: Maybe<RetailTaxBpmProjection>;
  taxNoVA: Maybe<RetailTaxNoVaProjection>;
  totalPriceGross: Maybe<PriceDto>;
  totalPriceNet: Maybe<PriceDto>;
  totalTaxAdvantage: Maybe<PriceDto>;
  totalVat: Maybe<PriceDto>;
  tradeInItems: Maybe<Array<Maybe<RetailTradeInItemProjection>>>;
  transportOrdered: Maybe<Scalars["String"]>;
  trialExpiredOn: Maybe<Scalars["String"]>;
  trialReturnOn: Maybe<Scalars["String"]>;
  type: Maybe<Scalars["String"]>;
  updated: Maybe<Scalars["String"]>;
  user: Maybe<CustomerProjection>;
  userId: Maybe<Scalars["UUID"]>;
  vehicleDeliveredOn: Maybe<Scalars["String"]>;
  vehiclePreparationItems: Maybe<
    Array<Maybe<VehiclePreparationItemProjection>>
  >;
  verifiedOn: Maybe<Scalars["String"]>;
  warrantyDetails: Maybe<Array<Maybe<WarrantyDetailsProjection>>>;
  warrantyItems: Maybe<Array<Maybe<WarrantyItemProjection>>>;
}

export interface OrderReadyForVerificationTaskProjection {
  __typename?: "OrderReadyForVerificationTaskProjection";
  action: Maybe<Scalars["String"]>;
  assignedOn: Maybe<Scalars["String"]>;
  assignedTo: Maybe<UserProjection>;
  assignee: Maybe<UserProjection>;
  closedBy: Maybe<UserProjection>;
  closedOn: Maybe<Scalars["String"]>;
  comment: Maybe<Scalars["String"]>;
  commentsCount: Maybe<Scalars["Int"]>;
  completedBy: Maybe<UserProjection>;
  completedOn: Maybe<Scalars["String"]>;
  createdOn: Maybe<Scalars["String"]>;
  financingStatus: Maybe<Scalars["String"]>;
  flowVersion: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  name: Maybe<Scalars["String"]>;
  order: Maybe<OrderProjection>;
  orderContractSignedOn: Maybe<Scalars["String"]>;
  orderId: Maybe<Scalars["UUID"]>;
  orderNumber: Maybe<Scalars["String"]>;
  orderState: Maybe<Scalars["String"]>;
  paymentStatus: Maybe<Scalars["String"]>;
  paymentType: Maybe<Scalars["String"]>;
  pendingVerificationOn: Maybe<Scalars["String"]>;
  reason: Maybe<Scalars["String"]>;
  repeatAt: Maybe<Scalars["String"]>;
  sequenceIndex: Scalars["Int"];
  state: Maybe<Scalars["String"]>;
  stockNumber: Maybe<Scalars["String"]>;
  taskDefinitionKey: Maybe<Scalars["String"]>;
  taskStatus: Maybe<Scalars["String"]>;
  taskType: Maybe<Scalars["String"]>;
  taskUpdatedAt: Maybe<Scalars["String"]>;
}

export interface OrderSearchProjection {
  __typename?: "OrderSearchProjection";
  createdAt: Maybe<Scalars["String"]>;
  financingFlowVersion: Maybe<Scalars["String"]>;
  flowVersion: Maybe<Scalars["String"]>;
  orderNumber: Maybe<Scalars["String"]>;
  retailCountry: Maybe<Scalars["String"]>;
  state: Maybe<Scalars["String"]>;
  stockNumber: Maybe<Scalars["String"]>;
}

export interface OrderShippingDetailsUpdateTaskProjection {
  __typename?: "OrderShippingDetailsUpdateTaskProjection";
  action: Maybe<Scalars["String"]>;
  addressUpdateDetails: Maybe<ShippingAddressDetails>;
  assignedOn: Maybe<Scalars["String"]>;
  assignedTo: Maybe<UserProjection>;
  assignee: Maybe<UserProjection>;
  branchId: Maybe<Scalars["Int"]>;
  closedBy: Maybe<UserProjection>;
  closedOn: Maybe<Scalars["String"]>;
  comment: Maybe<Scalars["String"]>;
  commentsCount: Maybe<Scalars["Int"]>;
  completedBy: Maybe<UserProjection>;
  completedOn: Maybe<Scalars["String"]>;
  createdOn: Maybe<Scalars["String"]>;
  financingStatus: Maybe<Scalars["String"]>;
  flowVersion: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  name: Maybe<Scalars["String"]>;
  order: Maybe<OrderProjection>;
  orderId: Maybe<Scalars["UUID"]>;
  orderNumber: Maybe<Scalars["String"]>;
  orderState: Maybe<Scalars["String"]>;
  paymentStatus: Maybe<Scalars["String"]>;
  paymentType: Maybe<Scalars["String"]>;
  reason: Maybe<Scalars["String"]>;
  repeatAt: Maybe<Scalars["String"]>;
  sequenceIndex: Scalars["Int"];
  state: Maybe<Scalars["String"]>;
  stockNumber: Maybe<Scalars["String"]>;
  taskDefinitionKey: Maybe<Scalars["String"]>;
  taskStatus: Maybe<Scalars["String"]>;
  taskType: Maybe<Scalars["String"]>;
  taskUpdatedAt: Maybe<Scalars["String"]>;
}

export interface OrderTaskProjection {
  __typename?: "OrderTaskProjection";
  createdBy: Maybe<UserProjection>;
  createdOn: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  orderId: Maybe<Scalars["UUID"]>;
  type: Maybe<Scalars["String"]>;
}

export interface OrderUpdateProjectionInput {
  amountInWords?: InputMaybe<Scalars["String"]>;
  branchId?: InputMaybe<Scalars["Int"]>;
  cancelDescription?: InputMaybe<Scalars["String"]>;
  cancelReason?: InputMaybe<Scalars["String"]>;
  canceledOn?: InputMaybe<Scalars["String"]>;
  checkoutBranchId?: InputMaybe<Scalars["Int"]>;
  confirmed?: InputMaybe<Scalars["Boolean"]>;
  contractSentOn?: InputMaybe<Scalars["String"]>;
  contractSigned?: InputMaybe<Scalars["Boolean"]>;
  contractSignedOn?: InputMaybe<Scalars["String"]>;
  conversionType?: InputMaybe<Scalars["String"]>;
  depositAmountMinorUnits?: InputMaybe<Scalars["Long"]>;
  depositDueDate?: InputMaybe<Scalars["String"]>;
  details?: InputMaybe<Scalars["String"]>;
  fullPaymentDueDate?: InputMaybe<Scalars["String"]>;
  isBranchCheckupSuccessful?: InputMaybe<Scalars["Boolean"]>;
  isTest?: InputMaybe<Scalars["Boolean"]>;
  isTradeIn?: InputMaybe<Scalars["Boolean"]>;
  payment?: InputMaybe<Scalars["String"]>;
  paymentType?: InputMaybe<Scalars["String"]>;
  reservationEndOn?: InputMaybe<Scalars["String"]>;
  source?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["Int"]>;
  subCancelReason?: InputMaybe<Scalars["String"]>;
  trialExpiredOn?: InputMaybe<Scalars["String"]>;
  trialReturnOn?: InputMaybe<Scalars["String"]>;
}

export interface OutboundPaymentOracleDetailsProjection {
  __typename?: "OutboundPaymentOracleDetailsProjection";
  accountingDate: Maybe<Scalars["String"]>;
  createdOn: Maybe<Scalars["String"]>;
  oracleFileName: Maybe<Scalars["String"]>;
  oracleFilePresignedUrl: Maybe<Scalars["String"]>;
  paymentNumber: Maybe<Scalars["String"]>;
  status: Maybe<Scalars["String"]>;
}

export interface PageDto_AvailabilityRuleProjection {
  __typename?: "PageDTO_AvailabilityRuleProjection";
  entities: Maybe<Array<Maybe<AvailabilityRuleProjection>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_BranchEntryCheckSearchItemDto {
  __typename?: "PageDTO_BranchEntryCheckSearchItemDTO";
  entities: Maybe<Array<Maybe<BranchEntryCheckSearchItemDto>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_BranchProjection {
  __typename?: "PageDTO_BranchProjection";
  entities: Maybe<Array<Maybe<BranchProjection>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_CarTypeBasedPriceModifierProjection {
  __typename?: "PageDTO_CarTypeBasedPriceModifierProjection";
  entities: Maybe<Array<Maybe<CarTypeBasedPriceModifierProjection>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_ChangelogProjection {
  __typename?: "PageDTO_ChangelogProjection";
  entities: Maybe<Array<Maybe<ChangelogProjection>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_ChatbotQuestionDto {
  __typename?: "PageDTO_ChatbotQuestionDTO";
  entities: Maybe<Array<Maybe<ChatbotQuestionDto>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_ClaimChangelogResponseProjection {
  __typename?: "PageDTO_ClaimChangelogResponseProjection";
  entities: Maybe<Array<Maybe<ClaimChangelogResponseProjection>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_CreditNoteProjection {
  __typename?: "PageDTO_CreditNoteProjection";
  entities: Maybe<Array<Maybe<CreditNoteProjection>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_CustomerProjection {
  __typename?: "PageDTO_CustomerProjection";
  entities: Maybe<Array<Maybe<CustomerProjection>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_DataValidationDto {
  __typename?: "PageDTO_DataValidationDTO";
  entities: Maybe<Array<Maybe<DataValidationDto>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_DigitalCarRegistrationProjection {
  __typename?: "PageDTO_DigitalCarRegistrationProjection";
  entities: Maybe<Array<Maybe<DigitalCarRegistrationProjection>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_DigitalCarRegistrationQaDataProjection {
  __typename?: "PageDTO_DigitalCarRegistrationQADataProjection";
  entities: Maybe<Array<Maybe<DigitalCarRegistrationQaDataProjection>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_DiscountDto {
  __typename?: "PageDTO_DiscountDTO";
  entities: Maybe<Array<Maybe<DiscountDto>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_DownPaymentProjection {
  __typename?: "PageDTO_DownPaymentProjection";
  entities: Maybe<Array<Maybe<DownPaymentProjection>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_EsOrderProjection {
  __typename?: "PageDTO_EsOrderProjection";
  entities: Maybe<Array<Maybe<EsOrderProjection>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_ExitCheckProjection {
  __typename?: "PageDTO_ExitCheckProjection";
  entities: Maybe<Array<Maybe<ExitCheckProjection>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_FinancingSearchProjection {
  __typename?: "PageDTO_FinancingSearchProjection";
  entities: Maybe<Array<Maybe<FinancingSearchProjection>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_FrRegionalTaxBasedPriceModifierProjection {
  __typename?: "PageDTO_FrRegionalTaxBasedPriceModifierProjection";
  entities: Maybe<Array<Maybe<FrRegionalTaxBasedPriceModifierProjection>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_GroupProjection {
  __typename?: "PageDTO_GroupProjection";
  entities: Maybe<Array<Maybe<GroupProjection>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_ItPowerAndZoneBasedPriceModifierProjection {
  __typename?: "PageDTO_ITPowerAndZoneBasedPriceModifierProjection";
  entities: Maybe<Array<Maybe<ItPowerAndZoneBasedPriceModifierProjection>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_InvoiceProjection {
  __typename?: "PageDTO_InvoiceProjection";
  entities: Maybe<Array<Maybe<InvoiceProjection>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_KbaRequestHistoryProjection {
  __typename?: "PageDTO_KBARequestHistoryProjection";
  entities: Maybe<Array<Maybe<KbaRequestHistoryProjection>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_OrderCommentProjection {
  __typename?: "PageDTO_OrderCommentProjection";
  entities: Maybe<Array<Maybe<OrderCommentProjection>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_OrderFinancingHistoryProjection {
  __typename?: "PageDTO_OrderFinancingHistoryProjection";
  entities: Maybe<Array<Maybe<OrderFinancingHistoryProjection>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_OrderHistoryEventProjection {
  __typename?: "PageDTO_OrderHistoryEventProjection";
  entities: Maybe<Array<Maybe<OrderHistoryEventProjection>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_OrderProjection {
  __typename?: "PageDTO_OrderProjection";
  entities: Maybe<Array<Maybe<OrderProjection>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_OrderTaskProjection {
  __typename?: "PageDTO_OrderTaskProjection";
  entities: Maybe<Array<Maybe<OrderTaskProjection>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_PnlItemProjection {
  __typename?: "PageDTO_PnlItemProjection";
  entities: Maybe<Array<Maybe<PnlItemProjection>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_PnlItemTypeProjection {
  __typename?: "PageDTO_PnlItemTypeProjection";
  entities: Maybe<Array<Maybe<PnlItemTypeProjection>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_PnlPartnerProjection {
  __typename?: "PageDTO_PnlPartnerProjection";
  entities: Maybe<Array<Maybe<PnlPartnerProjection>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_RefurbishmentAggregateProjection {
  __typename?: "PageDTO_RefurbishmentAggregateProjection";
  entities: Maybe<Array<Maybe<RefurbishmentAggregateProjection>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_RefurbishmentCommentProjection {
  __typename?: "PageDTO_RefurbishmentCommentProjection";
  entities: Maybe<Array<Maybe<RefurbishmentCommentProjection>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_RefurbishmentDto {
  __typename?: "PageDTO_RefurbishmentDTO";
  entities: Maybe<Array<Maybe<RefurbishmentDto>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_RefurbishmentPartnerDto {
  __typename?: "PageDTO_RefurbishmentPartnerDTO";
  entities: Maybe<Array<Maybe<RefurbishmentPartnerDto>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_RefurbishmentProjection {
  __typename?: "PageDTO_RefurbishmentProjection";
  entities: Maybe<Array<Maybe<RefurbishmentProjection>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_RetailAbTestingExperimentProjection {
  __typename?: "PageDTO_RetailAbTestingExperimentProjection";
  entities: Maybe<Array<Maybe<RetailAbTestingExperimentProjection>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_RetailAbTestingParticipantProjection {
  __typename?: "PageDTO_RetailAbTestingParticipantProjection";
  entities: Maybe<Array<Maybe<RetailAbTestingParticipantProjection>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_RetailAdPnlDataProjection {
  __typename?: "PageDTO_RetailAdPnlDataProjection";
  entities: Maybe<Array<Maybe<RetailAdPnlDataProjection>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_RetailAdPriceChangeDto {
  __typename?: "PageDTO_RetailAdPriceChangeDTO";
  entities: Maybe<Array<Maybe<RetailAdPriceChangeDto>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_RetailAdProjection {
  __typename?: "PageDTO_RetailAdProjection";
  entities: Maybe<Array<Maybe<RetailAdProjection>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_RetailBackofficeNotificationProjection {
  __typename?: "PageDTO_RetailBackofficeNotificationProjection";
  entities: Maybe<Array<Maybe<RetailBackofficeNotificationProjection>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_RetailBaseProductProjection {
  __typename?: "PageDTO_RetailBaseProductProjection";
  entities: Maybe<Array<Maybe<RetailBaseProductProjection>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_RetailCarRegistrationProjection {
  __typename?: "PageDTO_RetailCarRegistrationProjection";
  entities: Maybe<Array<Maybe<RetailCarRegistrationProjection>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_RetailClaimAssigneeDto {
  __typename?: "PageDTO_RetailClaimAssigneeDTO";
  entities: Maybe<Array<Maybe<RetailClaimAssigneeDto>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_RetailClaimProjection {
  __typename?: "PageDTO_RetailClaimProjection";
  entities: Maybe<Array<Maybe<RetailClaimProjection>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_RetailClaimStateDueDaysConfigProjection {
  __typename?: "PageDTO_RetailClaimStateDueDaysConfigProjection";
  entities: Maybe<Array<Maybe<RetailClaimStateDueDaysConfigProjection>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_RetailDataImportProjection {
  __typename?: "PageDTO_RetailDataImportProjection";
  entities: Maybe<Array<Maybe<RetailDataImportProjection>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_RetailDigitalCarRegistrationAssigneeDto {
  __typename?: "PageDTO_RetailDigitalCarRegistrationAssigneeDTO";
  entities: Maybe<Array<Maybe<RetailDigitalCarRegistrationAssigneeDto>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_RetailFeatureProjection {
  __typename?: "PageDTO_RetailFeatureProjection";
  entities: Maybe<Array<Maybe<RetailFeatureProjection>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_RetailFeeProjection {
  __typename?: "PageDTO_RetailFeeProjection";
  entities: Maybe<Array<Maybe<RetailFeeProjection>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_RetailImageTagProjection {
  __typename?: "PageDTO_RetailImageTagProjection";
  entities: Maybe<Array<Maybe<RetailImageTagProjection>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_RetailInsuranceProjection {
  __typename?: "PageDTO_RetailInsuranceProjection";
  entities: Maybe<Array<Maybe<RetailInsuranceProjection>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_RetailInternalTransferPaymentProjection {
  __typename?: "PageDTO_RetailInternalTransferPaymentProjection";
  entities: Maybe<Array<Maybe<RetailInternalTransferPaymentProjection>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_RetailLeadProjection {
  __typename?: "PageDTO_RetailLeadProjection";
  entities: Maybe<Array<Maybe<RetailLeadProjection>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_RetailOrderDebtCsvDataProjection {
  __typename?: "PageDTO_RetailOrderDebtCSVDataProjection";
  entities: Maybe<Array<Maybe<RetailOrderDebtCsvDataProjection>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_RetailOrderDebtProjection {
  __typename?: "PageDTO_RetailOrderDebtProjection";
  entities: Maybe<Array<Maybe<RetailOrderDebtProjection>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_RetailOutboundPaymentProjection {
  __typename?: "PageDTO_RetailOutboundPaymentProjection";
  entities: Maybe<Array<Maybe<RetailOutboundPaymentProjection>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_RetailPaymentProjection {
  __typename?: "PageDTO_RetailPaymentProjection";
  entities: Maybe<Array<Maybe<RetailPaymentProjection>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_RetailProductDto {
  __typename?: "PageDTO_RetailProductDTO";
  entities: Maybe<Array<Maybe<RetailProductDto>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_RetailRefundProjection {
  __typename?: "PageDTO_RetailRefundProjection";
  entities: Maybe<Array<Maybe<RetailRefundProjection>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_RetailUnidentifiedPaymentProjection {
  __typename?: "PageDTO_RetailUnidentifiedPaymentProjection";
  entities: Maybe<Array<Maybe<RetailUnidentifiedPaymentProjection>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_RetailUserDto {
  __typename?: "PageDTO_RetailUserDTO";
  entities: Maybe<Array<Maybe<RetailUserDto>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_RetailVehiclePreparationProjection {
  __typename?: "PageDTO_RetailVehiclePreparationProjection";
  entities: Maybe<Array<Maybe<RetailVehiclePreparationProjection>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_RetailWarrantyProjection {
  __typename?: "PageDTO_RetailWarrantyProjection";
  entities: Maybe<Array<Maybe<RetailWarrantyProjection>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_RetailYouSignSignatureRequestProjection {
  __typename?: "PageDTO_RetailYouSignSignatureRequestProjection";
  entities: Maybe<Array<Maybe<RetailYouSignSignatureRequestProjection>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_RoleProjection {
  __typename?: "PageDTO_RoleProjection";
  entities: Maybe<Array<Maybe<RoleProjection>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_SearchRetailDocumentProjection {
  __typename?: "PageDTO_SearchRetailDocumentProjection";
  entities: Maybe<Array<Maybe<SearchRetailDocumentProjection>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_TaskManagerCommentProjection {
  __typename?: "PageDTO_TaskManagerCommentProjection";
  entities: Maybe<Array<Maybe<TaskManagerCommentProjection>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_WarrantyCarPricePriceModifierProjection {
  __typename?: "PageDTO_WarrantyCarPricePriceModifierProjection";
  entities: Maybe<Array<Maybe<WarrantyCarPricePriceModifierProjection>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_WarrantyCarYearsPriceModifierProjection {
  __typename?: "PageDTO_WarrantyCarYearsPriceModifierProjection";
  entities: Maybe<Array<Maybe<WarrantyCarYearsPriceModifierProjection>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageDto_TaskManagementProjection {
  __typename?: "PageDTO_taskManagementProjection";
  entities: Maybe<Array<Maybe<TaskManagementProjection>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Maybe<Scalars["Long"]>;
  totalPageCount: Scalars["Int"];
}

export interface PageFilterProjectionInput {
  filters?: InputMaybe<Array<InputMaybe<FilterProjectionInput>>>;
}

export interface PageRequestProjectionInput {
  filter?: InputMaybe<PageFilterProjectionInput>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  sort?: InputMaybe<PageSortProjectionInput>;
}

export interface PageSortProjectionInput {
  order?: InputMaybe<Array<InputMaybe<SortOrderProjectionInput>>>;
}

export interface PageableRequestProjection_RefurbishmentAggregateSearchDtoInput {
  criteria?: InputMaybe<RefurbishmentAggregateSearchDtoInput>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
}

export interface PartnerDto {
  __typename?: "PartnerDTO";
  country: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["Long"]>;
  logoPath: Maybe<Scalars["String"]>;
  name: Maybe<Scalars["String"]>;
  status: Maybe<Scalars["Int"]>;
}

export interface PartnerWorkshopConnectionDto {
  __typename?: "PartnerWorkshopConnectionDTO";
  createdBy: Maybe<Scalars["UUID"]>;
  createdOn: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  isActive: Maybe<Scalars["Boolean"]>;
  isImportedFromBackoffice: Maybe<Scalars["Boolean"]>;
  mainWorkshop: Maybe<WorkshopDto>;
  repairPartner: Maybe<RepairPartnerDto>;
  repairWorkshop: Maybe<WorkshopDto>;
  updatedOn: Maybe<Scalars["String"]>;
}

export interface PdfDto {
  __typename?: "PdfDTO";
  fullUrl: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  type: Maybe<Scalars["String"]>;
}

export interface PdfDtoInput {
  fullUrl?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["UUID"]>;
  type?: InputMaybe<Scalars["String"]>;
}

export interface PermissionDto {
  __typename?: "PermissionDTO";
  id: Maybe<Scalars["UUID"]>;
  key: Maybe<Scalars["String"]>;
}

export interface PermissionProjection {
  __typename?: "PermissionProjection";
  createdBy: Maybe<Scalars["UUID"]>;
  createdOn: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  name: Maybe<Scalars["String"]>;
}

export interface PhoneNumber {
  __typename?: "PhoneNumber";
  internationalCallingCode: Maybe<Scalars["String"]>;
  phone: Maybe<Scalars["String"]>;
}

export interface PhoneNumberInput {
  internationalCallingCode?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
}

export type PnlInvoiceType =
  | "CREDIT_NOTE"
  | "DAMAGE_COMPENSATION"
  | "ESTIMATE"
  | "INVOICE"
  | "INVOICE_CORRECTION"
  | "SALES_CREDIT_NOTE";

export interface PnlItemCreateProjectionInput {
  currencyCode?: InputMaybe<Scalars["String"]>;
  entityChargedName?: InputMaybe<Scalars["String"]>;
  grossAmountMinorUnits?: InputMaybe<Scalars["Long"]>;
  invoiceDate?: InputMaybe<Scalars["String"]>;
  invoiceNumber?: InputMaybe<Scalars["String"]>;
  netAmountMinorUnits?: InputMaybe<Scalars["Long"]>;
  partnerId?: InputMaybe<Scalars["UUID"]>;
  pnlInvoiceType?: InputMaybe<PnlInvoiceType>;
  pnlItemTypeId?: InputMaybe<Scalars["UUID"]>;
  restoreIfDeleted?: InputMaybe<Scalars["Boolean"]>;
  retailAdId?: InputMaybe<Scalars["UUID"]>;
  retailCountry?: InputMaybe<Scalars["String"]>;
  serviceEndDate?: InputMaybe<Scalars["String"]>;
  serviceId?: InputMaybe<Scalars["String"]>;
  stockNumber?: InputMaybe<Scalars["String"]>;
  vatAmountMinorUnits?: InputMaybe<Scalars["Long"]>;
  vatRate?: InputMaybe<Scalars["Float"]>;
  vin?: InputMaybe<Scalars["String"]>;
}

export interface PnlItemProjection {
  __typename?: "PnlItemProjection";
  createdBy: Maybe<Scalars["UUID"]>;
  createdOn: Maybe<Scalars["String"]>;
  currencyCode: Maybe<Scalars["String"]>;
  deleted: Scalars["Boolean"];
  entityChargedName: Maybe<Scalars["String"]>;
  grossAmount: Maybe<PriceDto>;
  grossAmountMinorUnits: Maybe<Scalars["Long"]>;
  id: Maybe<Scalars["UUID"]>;
  invoiceDate: Maybe<Scalars["String"]>;
  invoiceNumber: Maybe<Scalars["String"]>;
  netAmount: Maybe<PriceDto>;
  netAmountMinorUnits: Maybe<Scalars["Long"]>;
  partner: Maybe<PnlPartnerProjection>;
  partnerId: Maybe<Scalars["UUID"]>;
  pnlInvoiceType: Maybe<PnlInvoiceType>;
  pnlItemTypeId: Maybe<Scalars["UUID"]>;
  retailAdId: Maybe<Scalars["UUID"]>;
  retailCountry: Maybe<Scalars["String"]>;
  serviceEndDate: Maybe<Scalars["String"]>;
  serviceId: Maybe<Scalars["String"]>;
  stockNumber: Maybe<Scalars["String"]>;
  type: Maybe<PnlItemTypeProjection>;
  updatedOn: Maybe<Scalars["String"]>;
  vatAmount: Maybe<PriceDto>;
  vatAmountMinorUnits: Maybe<Scalars["Long"]>;
  vatRate: Maybe<Scalars["Float"]>;
  vin: Maybe<Scalars["String"]>;
}

export interface PnlItemTypeCreateProjectionInput {
  category?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
}

export interface PnlItemTypeProjection {
  __typename?: "PnlItemTypeProjection";
  category: Maybe<Scalars["String"]>;
  createdBy: Maybe<Scalars["UUID"]>;
  createdOn: Maybe<Scalars["String"]>;
  deleted: Scalars["Boolean"];
  description: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  name: Maybe<Scalars["String"]>;
  type: Maybe<Scalars["String"]>;
  updatedOn: Maybe<Scalars["String"]>;
}

export interface PnlItemUpdateProjectionInput {
  currencyCode?: InputMaybe<Scalars["String"]>;
  entityChargedName?: InputMaybe<Scalars["String"]>;
  grossAmountMinorUnits?: InputMaybe<Scalars["Long"]>;
  invoiceDate?: InputMaybe<Scalars["String"]>;
  invoiceNumber?: InputMaybe<Scalars["String"]>;
  netAmountMinorUnits?: InputMaybe<Scalars["Long"]>;
  partnerId?: InputMaybe<Scalars["UUID"]>;
  pnlInvoiceType?: InputMaybe<PnlInvoiceType>;
  pnlItemTypeId?: InputMaybe<Scalars["UUID"]>;
  serviceEndDate?: InputMaybe<Scalars["String"]>;
  serviceId?: InputMaybe<Scalars["String"]>;
  vatAmountMinorUnits?: InputMaybe<Scalars["Long"]>;
  vatRate?: InputMaybe<Scalars["Float"]>;
}

export interface PnlPartnerCreateProjectionInput {
  adminCarrierId?: InputMaybe<Scalars["Long"]>;
  auto1Entity?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  invoiceCurrency?: InputMaybe<Scalars["String"]>;
  partnerAddress?: InputMaybe<Scalars["String"]>;
  partnerCity?: InputMaybe<Scalars["String"]>;
  partnerCountry?: InputMaybe<Scalars["String"]>;
  partnerId?: InputMaybe<Scalars["String"]>;
  partnerName?: InputMaybe<Scalars["String"]>;
  partnerVatId?: InputMaybe<Scalars["String"]>;
  partnerZip?: InputMaybe<Scalars["String"]>;
}

export interface PnlPartnerProjection {
  __typename?: "PnlPartnerProjection";
  adminCarrierId: Maybe<Scalars["Long"]>;
  auto1Entity: Maybe<Scalars["String"]>;
  country: Maybe<Scalars["String"]>;
  createdBy: Maybe<Scalars["UUID"]>;
  createdOn: Maybe<Scalars["String"]>;
  deleted: Scalars["Boolean"];
  id: Maybe<Scalars["UUID"]>;
  invoiceCurrency: Maybe<Scalars["String"]>;
  partnerAddress: Maybe<Scalars["String"]>;
  partnerCity: Maybe<Scalars["String"]>;
  partnerCountry: Maybe<Scalars["String"]>;
  partnerId: Maybe<Scalars["String"]>;
  partnerName: Maybe<Scalars["String"]>;
  partnerVatId: Maybe<Scalars["String"]>;
  partnerZip: Maybe<Scalars["String"]>;
  updatedOn: Maybe<Scalars["String"]>;
}

export interface PollValidationStatusProjectionInput {
  dataImportId?: InputMaybe<Scalars["UUID"]>;
}

export interface PositionDto {
  __typename?: "PositionDTO";
  leftPercentage: Maybe<Scalars["Float"]>;
  topPercentage: Maybe<Scalars["Float"]>;
}

export interface PositionDtoInput {
  leftPercentage?: InputMaybe<Scalars["Float"]>;
  topPercentage?: InputMaybe<Scalars["Float"]>;
}

export interface Price {
  __typename?: "Price";
  amountMinorUnits: Maybe<Scalars["Long"]>;
  conversionMajor: Maybe<Scalars["Int"]>;
  currency: Maybe<Scalars["String"]>;
}

export interface PriceDto {
  __typename?: "PriceDTO";
  amountMinorUnits: Maybe<Scalars["Long"]>;
  currencyCode: Maybe<Scalars["String"]>;
}

export interface PriceDtoInput {
  amountMinorUnits?: InputMaybe<Scalars["Long"]>;
  currencyCode?: InputMaybe<Scalars["String"]>;
}

export type PriceDetailType = "INVOICE" | "PNL";

export interface PriceDetailsDto {
  __typename?: "PriceDetailsDTO";
  grossPriceMinorUnits: Maybe<Scalars["Int"]>;
  netPriceMinorUnits: Maybe<Scalars["Int"]>;
  priceDetailType: Maybe<Scalars["String"]>;
  vatAmountMinorUnits: Maybe<Scalars["Int"]>;
  vatKey: Maybe<Scalars["String"]>;
  vatPercentage: Maybe<Scalars["Float"]>;
}

export interface PriceDetailsProjection {
  __typename?: "PriceDetailsProjection";
  grossPriceMinorUnits: Maybe<Scalars["Int"]>;
  netPriceMinorUnits: Maybe<Scalars["Int"]>;
  priceDetailType: Maybe<PriceDetailType>;
  vatAmountMinorUnits: Maybe<Scalars["Int"]>;
  vatKey: Maybe<VatKeyType>;
  vatPercentage: Maybe<Scalars["Float"]>;
}

export interface PriceInput {
  amountMinorUnits?: InputMaybe<Scalars["Long"]>;
  conversionMajor?: InputMaybe<Scalars["Int"]>;
  currency?: InputMaybe<Scalars["String"]>;
}

export type PriceType = "FIXED" | "NEGOTIABLE";

export interface PriceUpdateItemImportCreateProjectionInput {
  fileName?: InputMaybe<Scalars["String"]>;
}

export type PriceUpdateType =
  | "PROCEED_EXCLUDING_PRICE_ERROR"
  | "PROCEED_WITH_PRICE_ERROR";

export interface ProductCreateProjectionInput {
  active: Scalars["Boolean"];
  category?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  currencyCode?: InputMaybe<Scalars["String"]>;
  defaulted: Scalars["Boolean"];
  depositApplicable: Scalars["Boolean"];
  depositPercentage?: InputMaybe<Scalars["Float"]>;
  excludedFromInvoice?: InputMaybe<Scalars["Boolean"]>;
  invoiceDescription?: InputMaybe<Scalars["String"]>;
  invoiceItemNetMinorUnits?: InputMaybe<Scalars["Long"]>;
  paidService: Scalars["Boolean"];
  partner?: InputMaybe<Scalars["String"]>;
  pnlCostNetMinorUnits?: InputMaybe<Scalars["Long"]>;
  pnlVatApplicable: Scalars["Boolean"];
  pnlVatRate?: InputMaybe<Scalars["Float"]>;
  properties?: InputMaybe<Scalars["String"]>;
  quantity?: InputMaybe<Scalars["Int"]>;
  shippingRequired: Scalars["Boolean"];
  sku?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
  vatKey?: InputMaybe<Scalars["String"]>;
  vatRate?: InputMaybe<Scalars["Float"]>;
}

export interface ProductItemProjection {
  __typename?: "ProductItemProjection";
  created: Maybe<Scalars["String"]>;
  currencyCode: Maybe<Scalars["String"]>;
  discountGrossMinorUnits: Maybe<Scalars["Long"]>;
  discountMinorUnits: Maybe<Scalars["Long"]>;
  externalId: Maybe<Scalars["UUID"]>;
  externalType: Maybe<Scalars["Int"]>;
  id: Maybe<Scalars["UUID"]>;
  invoiceDescription: Maybe<Scalars["String"]>;
  invoiceName: Maybe<Scalars["String"]>;
  orderId: Maybe<Scalars["UUID"]>;
  parentId: Maybe<Scalars["UUID"]>;
  priceDiscountGross: Maybe<PriceDto>;
  priceDiscountNet: Maybe<PriceDto>;
  priceDiscountVat: Maybe<PriceDto>;
  priceGross: Maybe<PriceDto>;
  priceGrossMinorUnits: Maybe<Scalars["Long"]>;
  priceNet: Maybe<PriceDto>;
  priceNetMinorUnits: Maybe<Scalars["Long"]>;
  priceTaxAdvantageAmount: Maybe<PriceDto>;
  priceVatAmount: Maybe<PriceDto>;
  product: Maybe<ProductProjection>;
  type: Maybe<Scalars["String"]>;
  updated: Maybe<Scalars["String"]>;
  vatAmountMinorUnits: Maybe<Scalars["Long"]>;
  vatKey: Maybe<Scalars["String"]>;
  vatRate: Maybe<Scalars["Float"]>;
}

export interface ProductPartnersProjection {
  __typename?: "ProductPartnersProjection";
  entities: Maybe<Array<Maybe<Scalars["String"]>>>;
}

export interface ProductPriceDto {
  __typename?: "ProductPriceDTO";
  amountMinorUnits: Maybe<Scalars["Long"]>;
  currencyCode: Maybe<Scalars["String"]>;
}

export interface ProductProjection {
  __typename?: "ProductProjection";
  active: Scalars["Boolean"];
  category: Maybe<RetailProductCategory>;
  country: Maybe<Scalars["String"]>;
  createdOn: Maybe<Scalars["String"]>;
  defaulted: Scalars["Boolean"];
  depositApplicable: Scalars["Boolean"];
  depositPercentage: Maybe<Scalars["Float"]>;
  excludedFromInvoice: Scalars["Boolean"];
  id: Maybe<Scalars["UUID"]>;
  invoiceDescription: Maybe<Scalars["String"]>;
  invoiceItemGross: Maybe<PriceDto>;
  invoiceItemGrossMinorUnits: Maybe<Scalars["Long"]>;
  invoiceItemNet: Maybe<PriceDto>;
  invoiceItemNetMinorUnits: Maybe<Scalars["Long"]>;
  invoiceItemVatAmount: Maybe<PriceDto>;
  invoiceItemVatAmountMinorUnits: Maybe<Scalars["Long"]>;
  paidService: Scalars["Boolean"];
  partner: Maybe<Scalars["String"]>;
  pnlCostGross: Maybe<PriceDto>;
  pnlCostGrossMinorUnits: Maybe<Scalars["Long"]>;
  pnlCostNet: Maybe<PriceDto>;
  pnlCostNetMinorUnits: Maybe<Scalars["Long"]>;
  pnlCostVatAmount: Maybe<PriceDto>;
  pnlCostVatAmountMinorUnits: Maybe<Scalars["Long"]>;
  pnlVatApplicable: Scalars["Boolean"];
  pnlVatRate: Maybe<Scalars["Float"]>;
  properties: Maybe<Scalars["String"]>;
  quantity: Maybe<Scalars["Int"]>;
  shippingRequired: Scalars["Boolean"];
  sku: Maybe<Scalars["String"]>;
  title: Maybe<Scalars["String"]>;
  type: Maybe<Scalars["String"]>;
  updatedOn: Maybe<Scalars["String"]>;
  vatKey: Maybe<VatKeyType>;
  vatRate: Maybe<Scalars["Float"]>;
}

export interface ProductTypesProjection {
  __typename?: "ProductTypesProjection";
  entities: Maybe<Array<Maybe<Scalars["String"]>>>;
}

export interface ProductUpdateProjectionInput {
  active?: InputMaybe<Scalars["Boolean"]>;
  category?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  currencyCode?: InputMaybe<Scalars["String"]>;
  defaulted?: InputMaybe<Scalars["Boolean"]>;
  depositApplicable?: InputMaybe<Scalars["Boolean"]>;
  depositPercentage?: InputMaybe<Scalars["Float"]>;
  excludedFromInvoice?: InputMaybe<Scalars["Boolean"]>;
  invoiceDescription?: InputMaybe<Scalars["String"]>;
  invoiceItemNetMinorUnits?: InputMaybe<Scalars["Long"]>;
  paidService?: InputMaybe<Scalars["Boolean"]>;
  partner?: InputMaybe<Scalars["String"]>;
  pnlCostNetMinorUnits?: InputMaybe<Scalars["Long"]>;
  pnlVatApplicable?: InputMaybe<Scalars["Boolean"]>;
  pnlVatRate?: InputMaybe<Scalars["Float"]>;
  properties?: InputMaybe<Scalars["String"]>;
  quantity?: InputMaybe<Scalars["Int"]>;
  shippingRequired?: InputMaybe<Scalars["Boolean"]>;
  sku?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
  vatKey?: InputMaybe<Scalars["String"]>;
  vatRate?: InputMaybe<Scalars["Float"]>;
}

export interface Profile {
  __typename?: "Profile";
  depth: Maybe<Scalars["Int"]>;
  unit: Maybe<ProfileUnit>;
}

export type ProfileUnit = "MM";

export interface ProfitAndLossImportCreateProjectionInput {
  fileName?: InputMaybe<Scalars["String"]>;
}

export interface ProofRequestItemCreateProjectionInput {
  comment?: InputMaybe<Scalars["String"]>;
  complaintId?: InputMaybe<Scalars["UUID"]>;
  type?: InputMaybe<Scalars["String"]>;
}

export interface ProofRequestsCreateProjectionInput {
  requests?: InputMaybe<
    Array<InputMaybe<ProofRequestItemCreateProjectionInput>>
  >;
}

export interface ProtocolComplementaryProjection {
  __typename?: "ProtocolComplementaryProjection";
  chequeVerificationRequired: Maybe<Scalars["Boolean"]>;
  country: Maybe<Scalars["String"]>;
  customerAddress: Maybe<Scalars["String"]>;
  customerDateOfBirth: Maybe<Scalars["LocalDate"]>;
  customerName: Maybe<Scalars["String"]>;
  handoverDate: Maybe<Scalars["LocalDate"]>;
  handoverLocation: Maybe<Scalars["String"]>;
  isHomeDelivery: Maybe<Scalars["Boolean"]>;
  manufacturer: Maybe<Scalars["String"]>;
  modelDescription: Maybe<Scalars["String"]>;
  onDeliveryPaymentType: Maybe<Scalars["String"]>;
  orderId: Maybe<Scalars["UUID"]>;
  orderNumber: Maybe<Scalars["String"]>;
  paymentShouldBeDoneOnDelivery: Maybe<Scalars["Boolean"]>;
  paymentStatus: Maybe<Scalars["String"]>;
  remainingBalanceMinorUnits: Maybe<Scalars["Long"]>;
  shippingAddress: Maybe<Scalars["String"]>;
  stockNumber: Maybe<Scalars["String"]>;
  vin: Maybe<Scalars["String"]>;
}

export interface ProtocolSubmissionDtoInput {
  car?: InputMaybe<CarDtoInput>;
  customer?: InputMaybe<CustomerDtoInput>;
  customerComment?: InputMaybe<Scalars["String"]>;
  documents?: InputMaybe<DocumentsDtoInput>;
  images?: InputMaybe<ImagesDtoInput>;
  vin?: InputMaybe<Scalars["String"]>;
}

export type PublishedScopeType = "GLOBAL" | "ONLINE" | "SHOWROOM";

export interface PublishingBlockerProjection {
  __typename?: "PublishingBlockerProjection";
  comment: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  publishingBlockerReasonId: Maybe<Scalars["UUID"]>;
  publishingBlockerSubReasonId: Maybe<Scalars["UUID"]>;
}

export interface PublishingBlockerReasonDto {
  __typename?: "PublishingBlockerReasonDTO";
  description: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  subReasons: Maybe<Array<Maybe<PublishingBlockerSubReasonDto>>>;
}

export interface PublishingBlockerRemoveProjectionInput {
  publishingBlockerSubReasonId?: InputMaybe<Scalars["UUID"]>;
}

export interface PublishingBlockerSubReasonDto {
  __typename?: "PublishingBlockerSubReasonDTO";
  description: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
}

export interface PublishingBlockerUpdateProjectionInput {
  comment?: InputMaybe<Scalars["String"]>;
  publishingBlockerSubReasonId?: InputMaybe<Scalars["UUID"]>;
}

export interface PublishingError {
  __typename?: "PublishingError";
  createdOn: Maybe<Scalars["String"]>;
  message: Maybe<Scalars["String"]>;
}

/** Query root */
export interface Query {
  __typename?: "Query";
  abTestingAvailableConditionVariables: Maybe<
    Array<Maybe<RetailAbTestingConditionVariableProjection>>
  >;
  abTestingExperiment: Maybe<RetailAbTestingExperimentProjection>;
  abTestingExperimentCounters: Maybe<RetailAbTestingExperimentCountersProjection>;
  abTestingExperiments: Maybe<
    Array<Maybe<RetailAbTestingExperimentProjection>>
  >;
  ad: Maybe<AdProjection>;
  adByStockNumber: Maybe<AdProjection>;
  adDetailByRetailAdId: Maybe<AdDetailsProjection>;
  adEquipment: Maybe<Array<Maybe<CategorizedEquipmentsDto>>>;
  adVideoPresignedUrls: Maybe<Array<Maybe<AwsS3AdVideoPresignedUrlDto>>>;
  allowedClaimStates: Maybe<RetailClaimAllowedStatesProjection>;
  assignedRiskAgents: Maybe<Array<Maybe<AssignedRiskAgentProjection>>>;
  attachableTradeIns: Maybe<Array<Maybe<RetailTradeInProjection>>>;
  availabilityRule: Maybe<AvailabilityRuleProjection>;
  baseProduct: Maybe<RetailBaseProductProjection>;
  batchPriceUpdateValidationSummary: Maybe<RetailBatchPriceUpdateValidationSummaryDto>;
  buildImageUploadCredentials: Maybe<AwsUploadCredentialsProjection>;
  carRegistrationActions: Maybe<Array<Maybe<CarRegistrationActionProjection>>>;
  carRegistrationProduct: Maybe<RetailCarRegistrationProjection>;
  checkoutPaymentDetails: Maybe<CheckoutPaymentDetailsProjection>;
  claimOrderDetails: Maybe<OrderProjection>;
  claimStateDueDaysConfig: Maybe<PageDto_RetailClaimStateDueDaysConfigProjection>;
  compiledEmailTemplate: Maybe<CompiledOrderEmailTemplateProjection>;
  creditScoreDataByOrderFinancing: Maybe<RetailCreditScoreDataProjection>;
  creditScoreDataByOrderFinancingV2: Maybe<RetailCreditScoreDataProjectionV2>;
  customerAddress: Maybe<CustomerAddressProjection>;
  deliveryRoute: Maybe<DeliveryRouteProjection>;
  digitalCarRegistration: Maybe<DigitalCarRegistrationProjection>;
  digitalCarRegistrationByOrderId: Maybe<DigitalCarRegistrationProjection>;
  digitalCarRegistrationTestDataSource: Maybe<PageDto_DigitalCarRegistrationQaDataProjection>;
  digitalHandoverComplementary: Maybe<ProtocolComplementaryProjection>;
  distributeCreditNotesRefunds: Maybe<RetailOrderRefundsDistributionProjection>;
  distributeRefunds: Maybe<RetailOrderRefundsDistributionProjection>;
  distributeRefundsByOrderItems: Maybe<ListContainerDto_OrderItemsRefundsDto>;
  /** Returns all the categories with their respective document types */
  documentCategories: Maybe<RetailDocumentTopCategoryProjection>;
  documentInventoryItemLocations: Maybe<ResultConnection_DocumentLocation>;
  documentInventoryItems: Maybe<ResultConnection_DocumentInventoryItem>;
  documentInventoryPackage: Maybe<DocumentInventoryPackageProjection>;
  documentInventoryPackages: Maybe<ResultConnection_DocumentInventoryPackageProjection>;
  documentInventoryTypes: Maybe<DocumentInventoryTypeConnection_DocumentInventoryType>;
  documentShippingDeliveryAddresses: Maybe<BranchSearchingResult_DocumentBranchProjection>;
  documentShippingProviders: Maybe<ResultConnection_ShippingProviderProjection>;
  downPayment: Maybe<DownPaymentProjection>;
  esSearchOrders: Maybe<PageDto_EsOrderProjection>;
  exitCheckComments: Maybe<ExitCheckCommentsProjection>;
  experianDataByOrderFinancing: Maybe<RetailExperianDataProjection>;
  exportSection: Maybe<ExportSectionProjection>;
  extendedTradeInsForUser: Maybe<Array<Maybe<RetailTradeInExtendedProjection>>>;
  externalFinancingCustomerDetails: Maybe<ExternalFinancingCustomerDetailsProjection>;
  externalFinancingEvaluation: Maybe<ExternalFinanceRiskEvaluationProjection>;
  externalFinancingOptions: Maybe<ExternalFinancingOptionsProjection>;
  externalFinancingOptionsValues: Maybe<ExternalFinancingOptionsValuesProjection>;
  externalRiskUserInfo: Maybe<ExternalRiskUserInfoProjection>;
  financeRiskEvaluation: Maybe<FinanceRiskEvaluationProjection>;
  financeRiskEvaluationByOrder: Maybe<FinanceRiskEvaluationProjection>;
  financeRiskEvaluationByOrderFinancing: Maybe<FinanceRiskEvaluationProjection>;
  financingCalculator: Maybe<FinancingCalculatorResponseProjection>;
  financingPlanPdfData: Maybe<
    Array<Maybe<RetailFinancingPlanBreakdownProjection>>
  >;
  fullFtsDataByOrderFinancing: Maybe<RetailFullFtsDataProjection>;
  getAllValidations: Maybe<PageDto_DataValidationDto>;
  getAutomaticVerification: Maybe<OrderAutomaticVerificationProjection>;
  getBranchEntryCheckCarSupported: Maybe<BranchEntryCheckCarSupportedProjection>;
  getBranchEntryCheckComplementary: Maybe<BranchEntryCheckComplementaryProjection>;
  getBranchEntryCheckDetails: Maybe<BranchEntryCheckDetailsProjection>;
  getClaimsChangelog: Maybe<PageDto_ClaimChangelogResponseProjection>;
  getCustomerCreditScore: Maybe<CreditScoreResponseDataProjection>;
  getHandover: Maybe<RetailOrderHandoverProjection>;
  getOrderAutoCancellation: Maybe<OrderAutoCancellationProjection>;
  getOutboundPaymentOptions: Maybe<
    Scalars["Map_String_RetailOutboundPaymentOptionProjectionScalar"]
  >;
  getReferralStatuses: Maybe<Array<Maybe<ReferralProjection>>>;
  getRefurbishmentTypes: Maybe<Array<Maybe<Scalars["String"]>>>;
  getReschedulingReasons: Maybe<
    Array<Maybe<RetailOrderDeliveryReschedulingReasonDto>>
  >;
  getRetailClaimById: Maybe<RetailClaimProjection>;
  getSalesAgentsList: Maybe<Array<Maybe<SalesAgentProjection>>>;
  getSubProducts: Maybe<Array<Maybe<RetailBaseProductProjection>>>;
  getVatRateByCountry: Maybe<VatRateProjection>;
  getVerificationSurveyAnsweredQuestions: Maybe<
    Array<Maybe<SurveyAnsweredQuestionProjection>>
  >;
  group: Maybe<GroupProjection>;
  highlights: Maybe<Array<Maybe<HighlightDto>>>;
  highlightsSuggestions: Maybe<Array<Maybe<HighlightDto>>>;
  importedAd: Maybe<Scalars["Map_String_ObjectScalar"]>;
  internalPaymentOptions: Maybe<InternalPaymentOptionsProjection>;
  internalTransferPaymentById: Maybe<RetailInternalTransferPaymentProjection>;
  isAppointmentBookingApplicable: Scalars["Boolean"];
  isProductDeRegTempRequired: Scalars["Boolean"];
  isRevertOrderCancellationSupported: Maybe<OrderCancellationRevertibleProjection>;
  kbaRequestDumpURL: Maybe<Scalars["String"]>;
  licensePlateByRetailAdId: Maybe<RetailOrderContractRetailVehicleLicensePlateProjection>;
  me: Maybe<RetailUserDto>;
  order: Maybe<OrderProjection>;
  orderAddresses: Maybe<Array<Maybe<OrderAddressProjection>>>;
  orderCancelTypes: Maybe<Array<Maybe<OrderCancelTypeProjection>>>;
  orderCancellationOptions: Maybe<OrderCancellationOptionsProjection>;
  orderComplianceCheckCase: Maybe<OrderComplianceCheckCaseProjection>;
  orderContract: Maybe<RetailOrderContractProjection>;
  orderCountsByExternalIds: Maybe<Scalars["Map_UUID_LongScalar"]>;
  orderCustomerNotes: Maybe<OrderCustomerLeadProjection>;
  orderDebtsCsvData: Maybe<PageDto_RetailOrderDebtCsvDataProjection>;
  orderDelivery: Maybe<RetailOrderDeliveryProjection>;
  orderEmailTemplates: Maybe<Array<Maybe<TemplateProjection>>>;
  orderFinanceCancelReasons: Maybe<
    Array<Maybe<OrderFinanceCancelReasonProjection>>
  >;
  orderFinanceRejectReasons: Maybe<
    Array<Maybe<OrderFinanceRejectReasonProjection>>
  >;
  orderFinancing: Maybe<OrderFinancingDto>;
  orderFinancingPlansHistory: Maybe<
    Array<Maybe<RetailFinancingPlanCalculationProjection>>
  >;
  orderIncludedInABTestingExperiment: Scalars["Boolean"];
  orderItemsOptions: Maybe<OrderItemsOptionsProjection>;
  orderSalesAgentAssignees: Maybe<Array<Maybe<UserProjection>>>;
  orderStatistics: Maybe<RetailOrderStatisticsDto>;
  ordersByAdId: Maybe<Array<Maybe<OrderProjection>>>;
  plainSearchBaseProducts: Maybe<PageDto_RetailBaseProductProjection>;
  plainSearchCarRegistrationProducts: Maybe<PageDto_RetailCarRegistrationProjection>;
  plainSearchDownPaymentProducts: Maybe<PageDto_DownPaymentProjection>;
  plainSearchFeeProducts: Maybe<PageDto_RetailFeeProjection>;
  plainSearchInsuranceProducts: Maybe<PageDto_RetailInsuranceProjection>;
  plainSearchVehiclePreparationProducts: Maybe<PageDto_RetailVehiclePreparationProjection>;
  plainSearchWarrantyProducts: Maybe<PageDto_RetailWarrantyProjection>;
  pnlItem: Maybe<PnlItemProjection>;
  pnlItemChangelog: Maybe<PageDto_ChangelogProjection>;
  pnlItemType: Maybe<PnlItemTypeProjection>;
  pnlItemTypeCategories: Maybe<Array<Maybe<Scalars["String"]>>>;
  pnlPartner: Maybe<PnlPartnerProjection>;
  pollValidationStatus: Maybe<RetailDataImportDto>;
  presignedUrls: Maybe<Array<Maybe<AwsS3PresignedUrlDto>>>;
  product: Maybe<RetailProductProjection>;
  productPartners: Maybe<ProductPartnersProjection>;
  productTypes: Maybe<ProductTypesProjection>;
  recentOrderFinancing: Maybe<OrderFinancingDto>;
  refundsWithCreditNotesDistribute: Maybe<RetailOrderRefundsDistributionProjection>;
  refurbishment: Maybe<RefurbishmentDto>;
  refurbishmentByRefId: Maybe<RefurbishmentDto>;
  refurbishmentCommentsByRefurbishmentId: Maybe<PageDto_RefurbishmentCommentProjection>;
  refurbishmentPartners: Maybe<PageDto_RefurbishmentPartnerDto>;
  refurbishmentsByRetailAdId: Maybe<PageDto_RefurbishmentProjection>;
  refurbishmentsOverview: Maybe<PageDto_RefurbishmentAggregateProjection>;
  retailAccountDetailsForPayment: Maybe<RetailAccountDetailsForPaymentProjection>;
  retailAd: Maybe<RetailAdProjection>;
  retailAdByAdId: Maybe<RetailAdProjection>;
  retailAdDamage: Maybe<Array<Maybe<RetailAdDamageImageDto>>>;
  retailAdDeliveryOptions: Maybe<BatchProjection_RetailAdDeliveryOptionProjection>;
  retailAdPnlData: Maybe<RetailAdPnlDataProjection>;
  retailAdPriceHistory: Maybe<PageDto_RetailAdPriceChangeDto>;
  retailAdPublishingBlockers: Maybe<RetailAdPublishingBlockerProjection>;
  retailAdSecondaryWheels: Maybe<RetailAdSecondaryWheelsProjection>;
  retailAdSecondaryWheelsOptions: Maybe<SecondaryWheelsOptionsProjection>;
  retailAddress: Maybe<RetailAddressProjection>;
  retailCarDetailsPlainByStockNumber: Maybe<CarDetailsStoreAdPlainProjection>;
  retailCheckout: Maybe<RetailCheckoutProjection>;
  retailCheckoutStepTypes: Maybe<RetailCheckoutAllStepTypesProjection>;
  retailClaimCostCompensationsByOrderId: Maybe<
    Array<Maybe<ClaimCostCompensationProjection>>
  >;
  retailClaimGroupsOptions: Maybe<Array<Maybe<RetailClaimGroupProjection>>>;
  retailCustomer: Maybe<Array<Maybe<RetailCustomerProjection>>>;
  retailCustomersByOrderIds: Maybe<
    Array<Maybe<RetailCustomersByOrderProjection>>
  >;
  retailFeature: Maybe<RetailFeatureProjection>;
  /** evaluate status one feature by name */
  retailFeatureStatusV2: Maybe<RetailFeatureStatusProjectionV2>;
  /**
   * returns status of all features
   * @deprecated request is heavy and returns unnecessary data
   */
  retailFeatures: Maybe<Array<Maybe<RetailFeatureStatusDto>>>;
  /** evaluate status of requested features by names */
  retailFeaturesStatusV2: Maybe<Array<Maybe<RetailFeatureStatusProjectionV2>>>;
  retailFee: Maybe<RetailFeeProjection>;
  retailFeeOptions: Maybe<RetailFeeOptionsProjection>;
  retailFtsRiskDataByOrderFinancing: Maybe<RetailFtsRiskDataProjection>;
  retailInsurance: Maybe<RetailInsuranceProjection>;
  retailLead: Maybe<RetailLeadProjection>;
  retailOrderReturnAppointment: Maybe<RetailOrderReturnAppointmentProjection>;
  retailPayment: Maybe<RetailPaymentProjection>;
  retailPaymentImportConfig: Maybe<Array<Maybe<RetailDataImportConfigDto>>>;
  retailPublishingBlockerReasons: Maybe<
    Array<Maybe<PublishingBlockerReasonDto>>
  >;
  retailVehiclePreparation: Maybe<RetailVehiclePreparationProjection>;
  role: Maybe<RoleProjection>;
  roleGroups: Maybe<ListContainerDto_GroupProjection>;
  roleUsers: Maybe<ListContainerDto_AuxUserDto>;
  searchAbTestingExperiments: Maybe<PageDto_RetailAbTestingExperimentProjection>;
  searchAbTestingParticipants: Maybe<PageDto_RetailAbTestingParticipantProjection>;
  searchAdsByChannelAndSourceIds: Maybe<Array<Maybe<AdProjection>>>;
  searchAdsByIds: Maybe<Array<Maybe<AdProjection>>>;
  searchAvailabilityRules: Maybe<PageDto_AvailabilityRuleProjection>;
  searchBranchEntryChecks: Maybe<PageDto_BranchEntryCheckSearchItemDto>;
  searchBranches: Maybe<PageDto_BranchProjection>;
  searchBranchesForSelection: Maybe<OffsetBasedResponseProjection_BranchDto>;
  searchCarRegistration: Maybe<PageDto_RetailCarRegistrationProjection>;
  /** @deprecated Use searchCarRegistration */
  searchCarRegistrationProduct: Maybe<PageDto_RetailCarRegistrationProjection>;
  searchCarTypeBasedPriceModifier: Maybe<PageDto_CarTypeBasedPriceModifierProjection>;
  searchChatbotQuestions: Maybe<PageDto_ChatbotQuestionDto>;
  searchClaimAssigneeUsers: Maybe<PageDto_RetailClaimAssigneeDto>;
  searchCreditNotes: Maybe<PageDto_CreditNoteProjection>;
  searchCustomerUsers: Maybe<PageDto_CustomerProjection>;
  searchDigitalCarRegistrationAssigneeUsers: Maybe<PageDto_RetailDigitalCarRegistrationAssigneeDto>;
  searchDigitalCarRegistrationQAData: Maybe<PageDto_DigitalCarRegistrationQaDataProjection>;
  searchDigitalCarRegistrations: Maybe<PageDto_DigitalCarRegistrationProjection>;
  searchDiscounts: Maybe<PageDto_DiscountDto>;
  searchDocuments: Maybe<PageDto_SearchRetailDocumentProjection>;
  searchFeeProducts: Maybe<PageDto_RetailFeeProjection>;
  searchFinancings: Maybe<PageDto_FinancingSearchProjection>;
  searchFrRegionalTaxBasedPriceModifier: Maybe<PageDto_FrRegionalTaxBasedPriceModifierProjection>;
  searchGroups: Maybe<PageDto_GroupProjection>;
  searchITPowerAndZoneBasedPriceModifier: Maybe<PageDto_ItPowerAndZoneBasedPriceModifierProjection>;
  searchImageTags: Maybe<PageDto_RetailImageTagProjection>;
  searchInsurance: Maybe<PageDto_RetailInsuranceProjection>;
  searchInternalTransferPayments: Maybe<PageDto_RetailInternalTransferPaymentProjection>;
  searchInvoices: Maybe<PageDto_InvoiceProjection>;
  searchKBARequestHistory: Maybe<PageDto_KbaRequestHistoryProjection>;
  searchOrderChangelogs: Maybe<PageDto_ChangelogProjection>;
  searchOrderComments: Maybe<PageDto_OrderCommentProjection>;
  searchOrderDebts: Maybe<PageDto_RetailOrderDebtProjection>;
  searchOrderFinancingByOrder: Maybe<Array<Maybe<OrderFinancingDto>>>;
  searchOrderFinancingHistory: Maybe<PageDto_OrderFinancingHistoryProjection>;
  searchOrderHistoryEvents: Maybe<PageDto_OrderHistoryEventProjection>;
  searchOrderTasks: Maybe<PageDto_OrderTaskProjection>;
  searchOrders: Maybe<PageDto_OrderProjection>;
  searchOutboundPayment: Maybe<PageDto_RetailOutboundPaymentProjection>;
  searchPnlItemTypes: Maybe<PageDto_PnlItemTypeProjection>;
  searchPnlItems: Maybe<PageDto_PnlItemProjection>;
  searchPnlPartners: Maybe<PageDto_PnlPartnerProjection>;
  searchProductChangelogs: Maybe<PageDto_ChangelogProjection>;
  searchProductPriceModifierInstances: Maybe<ListContainerDto_String>;
  searchProducts: Maybe<PageDto_RetailProductDto>;
  searchRefunds: Maybe<PageDto_RetailRefundProjection>;
  searchRefurbishmentETAsByRetailAdId: Maybe<RefurbishmentEtaListProjection>;
  searchRefurbishmentExitChecks: Maybe<PageDto_ExitCheckProjection>;
  searchRefurbishments: Maybe<PageDto_RefurbishmentDto>;
  searchRetailAdPnlData: Maybe<PageDto_RetailAdPnlDataProjection>;
  searchRetailAds: Maybe<PageDto_RetailAdProjection>;
  searchRetailClaims: Maybe<PageDto_RetailClaimProjection>;
  searchRetailDataImport: Maybe<PageDto_RetailDataImportProjection>;
  searchRetailDataImports: Maybe<PageDto_RetailDataImportProjection>;
  searchRetailFeatureChangelogs: Maybe<PageDto_ChangelogProjection>;
  searchRetailFeatures: Maybe<PageDto_RetailFeatureProjection>;
  searchRetailLead: Maybe<PageDto_RetailLeadProjection>;
  searchRetailPayments: Maybe<PageDto_RetailPaymentProjection>;
  searchRetailUnidentifiedPayments: Maybe<PageDto_RetailUnidentifiedPaymentProjection>;
  searchRoles: Maybe<PageDto_RoleProjection>;
  searchTaskManagerTaskComments: Maybe<PageDto_TaskManagerCommentProjection>;
  searchTaskManagerTasks: Maybe<PageDto_TaskManagementProjection>;
  searchTradeInByStockNumber: Maybe<RetailTradeInCreateProjection>;
  searchUsers: Maybe<PageDto_RetailUserDto>;
  searchVehiclePreparation: Maybe<PageDto_RetailVehiclePreparationProjection>;
  /** @deprecated Use searchVehiclePreparation */
  searchVehiclePreparationProduct: Maybe<PageDto_RetailVehiclePreparationProjection>;
  searchVerificationCallTask: Maybe<VerificationCallTaskProjection>;
  searchWarranty: Maybe<PageDto_RetailWarrantyProjection>;
  searchWarrantyCarPricePriceModifier: Maybe<PageDto_WarrantyCarPricePriceModifierProjection>;
  searchWarrantyCarYearsPriceModifier: Maybe<PageDto_WarrantyCarYearsPriceModifierProjection>;
  /** @deprecated Use new query searchWarranty */
  searchWarrantyProducts: Maybe<PageDto_RetailWarrantyProjection>;
  searchYouSignSignatureRequests: Maybe<PageDto_RetailYouSignSignatureRequestProjection>;
  sellerAddress: Maybe<SellerAddressProjection>;
  servicesPerType: Maybe<
    Scalars["Map_RefurbishmentServiceType_List_RefurbishmentServiceNameScalar"]
  >;
  surveyScore: Maybe<RetailSurveyScoreProjection>;
  surveyScores: Maybe<Array<Maybe<RetailSurveyScoreProjection>>>;
  tasksManagerSearchOptions: Maybe<TasksManagerSearchOptionsProjection>;
  tasksSearchOptions: Maybe<TasksSearchOptionsProjection>;
  taxAdvantage: Maybe<RetailAdTaxAdvantageProjection>;
  trustpilotInvitation: Maybe<RetailTrustpilotInvitationProjection>;
  trustpilotReview: Maybe<RetailTrustpilotCustomerReviewProjection>;
  unidentifiedPaymentById: Maybe<RetailUnidentifiedPaymentProjection>;
  user: Maybe<UserProjection>;
  userNotifications: Maybe<PageDto_RetailBackofficeNotificationProjection>;
  userOrdersInfoForVerification: Maybe<UserOrdersInfoForVerificationProjection>;
  vehicleByVin: Maybe<VehicleInfoProjection>;
  verificationCallSurvey: Maybe<SurveyAggregationProjection>;
  verificationCallSurveyAnswers: Maybe<SurveyAnsweredQuestionsProjection>;
  warranty: Maybe<RetailWarrantyProjection>;
}

/** Query root */
export interface QueryAbTestingExperimentArgs {
  environment: InputMaybe<Scalars["String"]>;
  id: Scalars["UUID"];
}

/** Query root */
export interface QueryAbTestingExperimentCountersArgs {
  environment: InputMaybe<Scalars["String"]>;
  experimentId: Scalars["UUID"];
}

/** Query root */
export interface QueryAbTestingExperimentsArgs {
  environment: InputMaybe<Scalars["String"]>;
  name: InputMaybe<Scalars["String"]>;
}

/** Query root */
export interface QueryAdArgs {
  id: Scalars["UUID"];
}

/** Query root */
export interface QueryAdByStockNumberArgs {
  stockNumber: Scalars["String"];
}

/** Query root */
export interface QueryAdDetailByRetailAdIdArgs {
  id: Scalars["UUID"];
}

/** Query root */
export interface QueryAdEquipmentArgs {
  adId: Scalars["UUID"];
  locale: Scalars["String"];
}

/** Query root */
export interface QueryAdVideoPresignedUrlsArgs {
  request: BatchProjection_AwsS3AdVideoPresignedUrlRequestDtoInput;
}

/** Query root */
export interface QueryAllowedClaimStatesArgs {
  claimId: Scalars["UUID"];
}

/** Query root */
export interface QueryAttachableTradeInsArgs {
  auxUserId: Scalars["UUID"];
}

/** Query root */
export interface QueryAvailabilityRuleArgs {
  productId: InputMaybe<Scalars["UUID"]>;
}

/** Query root */
export interface QueryBaseProductArgs {
  id: InputMaybe<Scalars["UUID"]>;
}

/** Query root */
export interface QueryBatchPriceUpdateValidationSummaryArgs {
  dataImportId: InputMaybe<Scalars["UUID"]>;
}

/** Query root */
export interface QueryBuildImageUploadCredentialsArgs {
  awsUploadCredentialsRequestProjection: AwsUploadCredentialsRequestProjectionInput;
}

/** Query root */
export interface QueryCarRegistrationActionsArgs {
  orderUuid: Scalars["UUID"];
}

/** Query root */
export interface QueryCarRegistrationProductArgs {
  id: InputMaybe<Scalars["UUID"]>;
}

/** Query root */
export interface QueryCheckoutPaymentDetailsArgs {
  orderId: Scalars["UUID"];
}

/** Query root */
export interface QueryClaimOrderDetailsArgs {
  orderId: Scalars["UUID"];
}

/** Query root */
export interface QueryCompiledEmailTemplateArgs {
  compileOrderEmailTemplateProjection: InputMaybe<CompileOrderEmailTemplateProjectionInput>;
}

/** Query root */
export interface QueryCreditScoreDataByOrderFinancingArgs {
  orderFinancingId: Scalars["UUID"];
}

/** Query root */
export interface QueryCreditScoreDataByOrderFinancingV2Args {
  orderFinancingId: Scalars["UUID"];
}

/** Query root */
export interface QueryCustomerAddressArgs {
  id: Scalars["UUID"];
  userId: Scalars["UUID"];
}

/** Query root */
export interface QueryDeliveryRouteArgs {
  orderId: Scalars["UUID"];
}

/** Query root */
export interface QueryDigitalCarRegistrationArgs {
  id: Scalars["UUID"];
}

/** Query root */
export interface QueryDigitalCarRegistrationByOrderIdArgs {
  orderId: Scalars["UUID"];
}

/** Query root */
export interface QueryDigitalHandoverComplementaryArgs {
  orderId: Scalars["UUID"];
  vin: Scalars["String"];
}

/** Query root */
export interface QueryDistributeCreditNotesRefundsArgs {
  refundsDistribute: RetailOrderRefundsDistributeProjectionInput;
}

/** Query root */
export interface QueryDistributeRefundsArgs {
  refundsDistribute: RetailOrderRefundsDistributeProjectionInput;
}

/** Query root */
export interface QueryDistributeRefundsByOrderItemsArgs {
  orderId: Scalars["UUID"];
}

/** Query root */
export interface QueryDocumentCategoriesArgs {
  country: Scalars["String"];
  topCategory: Scalars["String"];
}

/** Query root */
export interface QueryDocumentInventoryItemLocationsArgs {
  filter: DocumentInventoryItemLocationFilterInput;
}

/** Query root */
export interface QueryDocumentInventoryItemsArgs {
  filter: DocumentInventoryItemsFilterInput;
}

/** Query root */
export interface QueryDocumentInventoryPackageArgs {
  id: Scalars["UUID"];
}

/** Query root */
export interface QueryDocumentInventoryPackagesArgs {
  filter: InputMaybe<DocumentInventoryPackageFilterInput>;
}

/** Query root */
export interface QueryDocumentShippingDeliveryAddressesArgs {
  orderId: Scalars["UUID"];
}

/** Query root */
export interface QueryDownPaymentArgs {
  id: InputMaybe<Scalars["UUID"]>;
}

/** Query root */
export interface QueryEsSearchOrdersArgs {
  search: SearchProjection2Input;
}

/** Query root */
export interface QueryExitCheckCommentsArgs {
  orderNumber: Scalars["String"];
}

/** Query root */
export interface QueryExperianDataByOrderFinancingArgs {
  orderFinancingId: Scalars["UUID"];
}

/** Query root */
export interface QueryExportSectionArgs {
  adId: Scalars["UUID"];
}

/** Query root */
export interface QueryExtendedTradeInsForUserArgs {
  auxUserId: Scalars["UUID"];
  country: InputMaybe<Scalars["String"]>;
}

/** Query root */
export interface QueryExternalFinancingCustomerDetailsArgs {
  customerId: Scalars["UUID"];
  orderFinancingId: Scalars["UUID"];
}

/** Query root */
export interface QueryExternalFinancingEvaluationArgs {
  orderId: Scalars["UUID"];
}

/** Query root */
export interface QueryExternalFinancingOptionsArgs {
  orderId: Scalars["UUID"];
}

/** Query root */
export interface QueryExternalRiskUserInfoArgs {
  borrowerType: Scalars["String"];
  orderFinancingId: Scalars["UUID"];
}

/** Query root */
export interface QueryFinanceRiskEvaluationArgs {
  id: Scalars["UUID"];
}

/** Query root */
export interface QueryFinanceRiskEvaluationByOrderArgs {
  orderId: Scalars["UUID"];
}

/** Query root */
export interface QueryFinanceRiskEvaluationByOrderFinancingArgs {
  orderFinancingId: Scalars["UUID"];
}

/** Query root */
export interface QueryFinancingCalculatorArgs {
  adId: Scalars["UUID"];
  financingAmount: InputMaybe<Scalars["Long"]>;
  financingPeriod: InputMaybe<Scalars["Int"]>;
  orderId: InputMaybe<Scalars["UUID"]>;
}

/** Query root */
export interface QueryFinancingPlanPdfDataArgs {
  orderId: Scalars["UUID"];
}

/** Query root */
export interface QueryFullFtsDataByOrderFinancingArgs {
  orderFinancingId: Scalars["UUID"];
}

/** Query root */
export interface QueryGetAllValidationsArgs {
  request: SearchProjection2Input;
}

/** Query root */
export interface QueryGetAutomaticVerificationArgs {
  orderId: Scalars["UUID"];
}

/** Query root */
export interface QueryGetBranchEntryCheckCarSupportedArgs {
  orderId: Scalars["UUID"];
}

/** Query root */
export interface QueryGetBranchEntryCheckComplementaryArgs {
  stockNumber: Scalars["String"];
  vin: Scalars["String"];
}

/** Query root */
export interface QueryGetBranchEntryCheckDetailsArgs {
  id: Scalars["UUID"];
}

/** Query root */
export interface QueryGetClaimsChangelogArgs {
  search: SearchProjection2Input;
}

/** Query root */
export interface QueryGetCustomerCreditScoreArgs {
  orderId: Scalars["UUID"];
}

/** Query root */
export interface QueryGetHandoverArgs {
  orderId: Scalars["UUID"];
}

/** Query root */
export interface QueryGetOrderAutoCancellationArgs {
  orderId: Scalars["UUID"];
}

/** Query root */
export interface QueryGetOutboundPaymentOptionsArgs {
  orderId: Scalars["UUID"];
}

/** Query root */
export interface QueryGetReferralStatusesArgs {
  orderId: Scalars["UUID"];
}

/** Query root */
export interface QueryGetRefurbishmentTypesArgs {
  retailAdId: Scalars["UUID"];
}

/** Query root */
export interface QueryGetRetailClaimByIdArgs {
  id: Scalars["UUID"];
}

/** Query root */
export interface QueryGetSubProductsArgs {
  productId: Scalars["UUID"];
}

/** Query root */
export interface QueryGetVatRateByCountryArgs {
  countryCode: Scalars["String"];
}

/** Query root */
export interface QueryGetVerificationSurveyAnsweredQuestionsArgs {
  locale: Scalars["String"];
  orderId: Scalars["UUID"];
}

/** Query root */
export interface QueryGroupArgs {
  id: Scalars["UUID"];
}

/** Query root */
export interface QueryHighlightsArgs {
  adId: Scalars["UUID"];
  locale: InputMaybe<Scalars["String"]>;
}

/** Query root */
export interface QueryHighlightsSuggestionsArgs {
  amount: InputMaybe<Scalars["Int"]>;
  locale: InputMaybe<Scalars["String"]>;
  text: InputMaybe<Scalars["String"]>;
}

/** Query root */
export interface QueryImportedAdArgs {
  countryCode: InputMaybe<Scalars["String"]>;
  stockNumber: Scalars["String"];
}

/** Query root */
export interface QueryInternalTransferPaymentByIdArgs {
  id: InputMaybe<Scalars["UUID"]>;
}

/** Query root */
export interface QueryIsAppointmentBookingApplicableArgs {
  appointmentType: Scalars["String"];
  orderId: Scalars["UUID"];
}

/** Query root */
export interface QueryIsProductDeRegTempRequiredArgs {
  orderId: Scalars["UUID"];
}

/** Query root */
export interface QueryIsRevertOrderCancellationSupportedArgs {
  orderId: Scalars["UUID"];
}

/** Query root */
export interface QueryKbaRequestDumpUrlArgs {
  id: Scalars["UUID"];
}

/** Query root */
export interface QueryLicensePlateByRetailAdIdArgs {
  adId: Scalars["UUID"];
}

/** Query root */
export interface QueryOrderArgs {
  id: Scalars["UUID"];
}

/** Query root */
export interface QueryOrderAddressesArgs {
  orderId: Scalars["UUID"];
}

/** Query root */
export interface QueryOrderComplianceCheckCaseArgs {
  orderId: Scalars["UUID"];
}

/** Query root */
export interface QueryOrderContractArgs {
  orderId: Scalars["UUID"];
}

/** Query root */
export interface QueryOrderCountsByExternalIdsArgs {
  id: Array<InputMaybe<Scalars["UUID"]>>;
}

/** Query root */
export interface QueryOrderCustomerNotesArgs {
  orderId: Scalars["UUID"];
}

/** Query root */
export interface QueryOrderDebtsCsvDataArgs {
  search: SearchProjection2Input;
}

/** Query root */
export interface QueryOrderDeliveryArgs {
  orderId: Scalars["UUID"];
}

/** Query root */
export interface QueryOrderEmailTemplatesArgs {
  market: Scalars["String"];
  project: Scalars["String"];
}

/** Query root */
export interface QueryOrderFinanceRejectReasonsArgs {
  filters: InputMaybe<FinanceRejectReasonFiltersInput>;
}

/** Query root */
export interface QueryOrderFinancingArgs {
  orderId: Scalars["UUID"];
}

/** Query root */
export interface QueryOrderFinancingPlansHistoryArgs {
  orderFinancingId: Scalars["UUID"];
}

/** Query root */
export interface QueryOrderIncludedInAbTestingExperimentArgs {
  environment: InputMaybe<Scalars["String"]>;
  experimentName: Scalars["String"];
  orderId: Scalars["UUID"];
}

/** Query root */
export interface QueryOrderItemsOptionsArgs {
  orderId: Scalars["UUID"];
}

/** Query root */
export interface QueryOrdersByAdIdArgs {
  id: Scalars["UUID"];
}

/** Query root */
export interface QueryPlainSearchBaseProductsArgs {
  search: SearchProjection2Input;
}

/** Query root */
export interface QueryPlainSearchCarRegistrationProductsArgs {
  search: SearchProjection2Input;
}

/** Query root */
export interface QueryPlainSearchDownPaymentProductsArgs {
  search: SearchProjection2Input;
}

/** Query root */
export interface QueryPlainSearchFeeProductsArgs {
  search: SearchProjection2Input;
}

/** Query root */
export interface QueryPlainSearchInsuranceProductsArgs {
  search: SearchProjection2Input;
}

/** Query root */
export interface QueryPlainSearchVehiclePreparationProductsArgs {
  search: SearchProjection2Input;
}

/** Query root */
export interface QueryPlainSearchWarrantyProductsArgs {
  search: SearchProjection2Input;
}

/** Query root */
export interface QueryPnlItemArgs {
  id: Scalars["UUID"];
}

/** Query root */
export interface QueryPnlItemChangelogArgs {
  retailAdId: Scalars["UUID"];
  search: SearchProjection2Input;
}

/** Query root */
export interface QueryPnlItemTypeArgs {
  id: Scalars["UUID"];
}

/** Query root */
export interface QueryPnlPartnerArgs {
  id: Scalars["UUID"];
}

/** Query root */
export interface QueryPollValidationStatusArgs {
  request: PollValidationStatusProjectionInput;
}

/** Query root */
export interface QueryPresignedUrlsArgs {
  request: BatchProjection_AwsS3PresignedUrlRequestDtoInput;
}

/** Query root */
export interface QueryProductArgs {
  id: InputMaybe<Scalars["UUID"]>;
}

/** Query root */
export interface QueryProductPartnersArgs {
  category: Scalars["String"];
  country: Scalars["String"];
  type: Scalars["String"];
}

/** Query root */
export interface QueryProductTypesArgs {
  category: Scalars["String"];
  country: Scalars["String"];
}

/** Query root */
export interface QueryRecentOrderFinancingArgs {
  orderId: Scalars["UUID"];
}

/** Query root */
export interface QueryRefundsWithCreditNotesDistributeArgs {
  refundsDistribute: RetailOrderRefundsDistributeProjectionInput;
}

/** Query root */
export interface QueryRefurbishmentArgs {
  id: Scalars["UUID"];
}

/** Query root */
export interface QueryRefurbishmentByRefIdArgs {
  refId: Scalars["String"];
}

/** Query root */
export interface QueryRefurbishmentCommentsByRefurbishmentIdArgs {
  refurbishmentId: Scalars["UUID"];
}

/** Query root */
export interface QueryRefurbishmentPartnersArgs {
  search: PageRequestProjectionInput;
}

/** Query root */
export interface QueryRefurbishmentsByRetailAdIdArgs {
  retailAdId: Scalars["UUID"];
}

/** Query root */
export interface QueryRefurbishmentsOverviewArgs {
  search: PageableRequestProjection_RefurbishmentAggregateSearchDtoInput;
}

/** Query root */
export interface QueryRetailAccountDetailsForPaymentArgs {
  country: Scalars["String"];
  orderId: Scalars["UUID"];
  paymentId: InputMaybe<Scalars["UUID"]>;
  paymentType: Scalars["String"];
}

/** Query root */
export interface QueryRetailAdArgs {
  id: Scalars["UUID"];
}

/** Query root */
export interface QueryRetailAdByAdIdArgs {
  adId: Scalars["UUID"];
}

/** Query root */
export interface QueryRetailAdDamageArgs {
  adId: InputMaybe<Scalars["UUID"]>;
}

/** Query root */
export interface QueryRetailAdDeliveryOptionsArgs {
  auxUserId: InputMaybe<Scalars["UUID"]>;
  branchDeliveryOptionRequest: InputMaybe<BranchDeliveryOptionRequestDtoInput>;
  homeDeliveryOptionRequest: InputMaybe<HomeDeliveryOptionRequestDtoInput>;
  orderId: InputMaybe<Scalars["UUID"]>;
  retailAdId: Scalars["UUID"];
  retailCountry: InputMaybe<Scalars["String"]>;
  tradeInCompatibleOnly: InputMaybe<Scalars["Boolean"]>;
}

/** Query root */
export interface QueryRetailAdPnlDataArgs {
  id: Scalars["UUID"];
}

/** Query root */
export interface QueryRetailAdPriceHistoryArgs {
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  retailAdId: InputMaybe<Scalars["UUID"]>;
}

/** Query root */
export interface QueryRetailAdPublishingBlockersArgs {
  retailAdId: Scalars["UUID"];
}

/** Query root */
export interface QueryRetailAdSecondaryWheelsArgs {
  id: Scalars["UUID"];
}

/** Query root */
export interface QueryRetailAddressArgs {
  id: Scalars["UUID"];
}

/** Query root */
export interface QueryRetailCarDetailsPlainByStockNumberArgs {
  stockNumber: Scalars["String"];
}

/** Query root */
export interface QueryRetailCheckoutArgs {
  orderId: Scalars["UUID"];
}

/** Query root */
export interface QueryRetailClaimCostCompensationsByOrderIdArgs {
  orderId: Scalars["UUID"];
}

/** Query root */
export interface QueryRetailCustomerArgs {
  orderId: Scalars["UUID"];
  type: InputMaybe<Scalars["String"]>;
}

/** Query root */
export interface QueryRetailCustomersByOrderIdsArgs {
  orderIds: Array<InputMaybe<Scalars["UUID"]>>;
}

/** Query root */
export interface QueryRetailFeatureArgs {
  id: Scalars["UUID"];
}

/** Query root */
export interface QueryRetailFeatureStatusV2Args {
  context: RetailFeatureStatusContextDtoInput;
  name: Scalars["String"];
}

/** Query root */
export interface QueryRetailFeaturesArgs {
  context: RetailFeatureStatusContextDtoInput;
}

/** Query root */
export interface QueryRetailFeaturesStatusV2Args {
  context: RetailFeatureStatusContextDtoInput;
  names: Array<InputMaybe<Scalars["String"]>>;
}

/** Query root */
export interface QueryRetailFeeArgs {
  feeId: Scalars["UUID"];
}

/** Query root */
export interface QueryRetailFeeOptionsArgs {
  request: RetailFeeOptionsRequestProjectionInput;
}

/** Query root */
export interface QueryRetailFtsRiskDataByOrderFinancingArgs {
  orderFinancingId: Scalars["UUID"];
}

/** Query root */
export interface QueryRetailInsuranceArgs {
  insuranceId: Scalars["UUID"];
}

/** Query root */
export interface QueryRetailLeadArgs {
  id: Scalars["UUID"];
}

/** Query root */
export interface QueryRetailOrderReturnAppointmentArgs {
  orderId: Scalars["UUID"];
}

/** Query root */
export interface QueryRetailPaymentArgs {
  id: Scalars["UUID"];
}

/** Query root */
export interface QueryRetailVehiclePreparationArgs {
  vehiclePreparationId: Scalars["UUID"];
}

/** Query root */
export interface QueryRoleArgs {
  id: Scalars["UUID"];
}

/** Query root */
export interface QueryRoleGroupsArgs {
  id: Scalars["UUID"];
}

/** Query root */
export interface QueryRoleUsersArgs {
  id: Scalars["UUID"];
}

/** Query root */
export interface QuerySearchAbTestingExperimentsArgs {
  search: SearchProjection2Input;
}

/** Query root */
export interface QuerySearchAbTestingParticipantsArgs {
  search: SearchProjection2Input;
}

/** Query root */
export interface QuerySearchAdsByChannelAndSourceIdsArgs {
  channel: Scalars["String"];
  sourceIds: Array<InputMaybe<Scalars["String"]>>;
}

/** Query root */
export interface QuerySearchAdsByIdsArgs {
  ids: Array<InputMaybe<Scalars["UUID"]>>;
}

/** Query root */
export interface QuerySearchAvailabilityRulesArgs {
  search: SearchProjection2Input;
}

/** Query root */
export interface QuerySearchBranchEntryChecksArgs {
  search: SearchProjection2Input;
}

/** Query root */
export interface QuerySearchBranchesArgs {
  search: PageRequestProjectionInput;
}

/** Query root */
export interface QuerySearchBranchesForSelectionArgs {
  search: BranchForSelectionSearchProjectionInput;
}

/** Query root */
export interface QuerySearchCarRegistrationArgs {
  search: RetailCarRegistrationSearchProjectionInput;
}

/** Query root */
export interface QuerySearchCarRegistrationProductArgs {
  priceModifiers: InputMaybe<Scalars["Map_String_PriceModifierPayloadScalar"]>;
  search: SearchProjection2Input;
}

/** Query root */
export interface QuerySearchCarTypeBasedPriceModifierArgs {
  search: SearchProjection2Input;
}

/** Query root */
export interface QuerySearchChatbotQuestionsArgs {
  search: SearchProjection2Input;
}

/** Query root */
export interface QuerySearchClaimAssigneeUsersArgs {
  countries: Array<InputMaybe<Scalars["String"]>>;
}

/** Query root */
export interface QuerySearchCreditNotesArgs {
  search: SearchProjection2Input;
}

/** Query root */
export interface QuerySearchCustomerUsersArgs {
  search: SearchProjection2Input;
}

/** Query root */
export interface QuerySearchDigitalCarRegistrationQaDataArgs {
  search: SearchProjection2Input;
}

/** Query root */
export interface QuerySearchDigitalCarRegistrationsArgs {
  search: SearchProjection2Input;
}

/** Query root */
export interface QuerySearchDiscountsArgs {
  search: SearchProjection2Input;
}

/** Query root */
export interface QuerySearchDocumentsArgs {
  search: SearchProjection2Input;
}

/** Query root */
export interface QuerySearchFeeProductsArgs {
  search: RetailFeeSearchProjectionInput;
}

/** Query root */
export interface QuerySearchFinancingsArgs {
  search: SearchProjection2Input;
}

/** Query root */
export interface QuerySearchFrRegionalTaxBasedPriceModifierArgs {
  search: SearchProjection2Input;
}

/** Query root */
export interface QuerySearchGroupsArgs {
  search: SearchProjection2Input;
}

/** Query root */
export interface QuerySearchItPowerAndZoneBasedPriceModifierArgs {
  search: SearchProjection2Input;
}

/** Query root */
export interface QuerySearchImageTagsArgs {
  search: SearchProjection2Input;
}

/** Query root */
export interface QuerySearchInsuranceArgs {
  search: RetailInsuranceSearchProjectionInput;
}

/** Query root */
export interface QuerySearchInternalTransferPaymentsArgs {
  search: SearchProjection2Input;
}

/** Query root */
export interface QuerySearchInvoicesArgs {
  search: SearchProjection2Input;
}

/** Query root */
export interface QuerySearchKbaRequestHistoryArgs {
  search: SearchProjection2Input;
}

/** Query root */
export interface QuerySearchOrderChangelogsArgs {
  search: SearchProjection2Input;
}

/** Query root */
export interface QuerySearchOrderCommentsArgs {
  search: SearchProjection2Input;
}

/** Query root */
export interface QuerySearchOrderDebtsArgs {
  search: SearchProjection2Input;
}

/** Query root */
export interface QuerySearchOrderFinancingByOrderArgs {
  orderId: Scalars["UUID"];
}

/** Query root */
export interface QuerySearchOrderFinancingHistoryArgs {
  search: SearchProjection2Input;
}

/** Query root */
export interface QuerySearchOrderHistoryEventsArgs {
  search: SearchProjection2Input;
}

/** Query root */
export interface QuerySearchOrderTasksArgs {
  search: SearchProjection2Input;
}

/** Query root */
export interface QuerySearchOrdersArgs {
  search: SearchProjection2Input;
}

/** Query root */
export interface QuerySearchOutboundPaymentArgs {
  search: SearchProjection2Input;
}

/** Query root */
export interface QuerySearchPnlItemTypesArgs {
  search: SearchProjection2Input;
}

/** Query root */
export interface QuerySearchPnlItemsArgs {
  currencyCode: InputMaybe<Scalars["String"]>;
  search: SearchProjection2Input;
}

/** Query root */
export interface QuerySearchPnlPartnersArgs {
  search: SearchProjection2Input;
}

/** Query root */
export interface QuerySearchProductChangelogsArgs {
  search: SearchProjection2Input;
}

/** Query root */
export interface QuerySearchProductPriceModifierInstancesArgs {
  productId: Scalars["UUID"];
}

/** Query root */
export interface QuerySearchProductsArgs {
  search: SearchProjection2Input;
}

/** Query root */
export interface QuerySearchRefundsArgs {
  search: SearchProjection2Input;
}

/** Query root */
export interface QuerySearchRefurbishmentEtAsByRetailAdIdArgs {
  retailAdId: Scalars["UUID"];
}

/** Query root */
export interface QuerySearchRefurbishmentExitChecksArgs {
  search: SearchProjection2Input;
}

/** Query root */
export interface QuerySearchRefurbishmentsArgs {
  search: SearchProjection2Input;
}

/** Query root */
export interface QuerySearchRetailAdPnlDataArgs {
  search: SearchProjection2Input;
}

/** Query root */
export interface QuerySearchRetailAdsArgs {
  search: SearchProjection2Input;
}

/** Query root */
export interface QuerySearchRetailClaimsArgs {
  search: SearchProjection2Input;
}

/** Query root */
export interface QuerySearchRetailDataImportArgs {
  search: SearchProjection2Input;
}

/** Query root */
export interface QuerySearchRetailDataImportsArgs {
  search: SearchProjection2Input;
}

/** Query root */
export interface QuerySearchRetailFeatureChangelogsArgs {
  id: Scalars["UUID"];
  search: SearchProjection2Input;
}

/** Query root */
export interface QuerySearchRetailFeaturesArgs {
  search: SearchProjection2Input;
}

/** Query root */
export interface QuerySearchRetailLeadArgs {
  search: SearchProjection2Input;
}

/** Query root */
export interface QuerySearchRetailPaymentsArgs {
  search: SearchProjection2Input;
}

/** Query root */
export interface QuerySearchRetailUnidentifiedPaymentsArgs {
  search: SearchProjection2Input;
}

/** Query root */
export interface QuerySearchRolesArgs {
  search: SearchProjection2Input;
}

/** Query root */
export interface QuerySearchTaskManagerTaskCommentsArgs {
  search: SearchProjection2Input;
}

/** Query root */
export interface QuerySearchTaskManagerTasksArgs {
  search: CamundaSearchProjection2Input;
}

/** Query root */
export interface QuerySearchTradeInByStockNumberArgs {
  countryCode?: InputMaybe<Scalars["String"]>;
  stockNumber: Scalars["String"];
}

/** Query root */
export interface QuerySearchUsersArgs {
  search: SearchProjection2Input;
}

/** Query root */
export interface QuerySearchVehiclePreparationArgs {
  search: RetailVehiclePreparationSearchProjectionInput;
}

/** Query root */
export interface QuerySearchVehiclePreparationProductArgs {
  priceModifiers: InputMaybe<Scalars["Map_String_PriceModifierPayloadScalar"]>;
  search: InputMaybe<SearchProjection2Input>;
}

/** Query root */
export interface QuerySearchVerificationCallTaskArgs {
  orderId: Scalars["UUID"];
}

/** Query root */
export interface QuerySearchWarrantyArgs {
  search: RetailWarrantySearchProjectionInput;
}

/** Query root */
export interface QuerySearchWarrantyCarPricePriceModifierArgs {
  search: SearchProjection2Input;
}

/** Query root */
export interface QuerySearchWarrantyCarYearsPriceModifierArgs {
  search: SearchProjection2Input;
}

/** Query root */
export interface QuerySearchWarrantyProductsArgs {
  ad_id?: InputMaybe<Scalars["String"]>;
  order_id?: InputMaybe<Scalars["String"]>;
  search: SearchProjection2Input;
}

/** Query root */
export interface QuerySearchYouSignSignatureRequestsArgs {
  search: SearchProjection2Input;
}

/** Query root */
export interface QuerySellerAddressArgs {
  addressId: Scalars["UUID"];
  sellerId: Scalars["UUID"];
}

/** Query root */
export interface QuerySurveyScoreArgs {
  extId: Scalars["UUID"];
  extType: Scalars["String"];
  surveyType: Scalars["String"];
}

/** Query root */
export interface QuerySurveyScoresArgs {
  extId: Scalars["UUID"];
  extType: Scalars["String"];
  surveyTypes: Array<InputMaybe<Scalars["String"]>>;
}

/** Query root */
export interface QueryTaxAdvantageArgs {
  retailAdId: Scalars["UUID"];
}

/** Query root */
export interface QueryTrustpilotInvitationArgs {
  orderId: Scalars["UUID"];
}

/** Query root */
export interface QueryTrustpilotReviewArgs {
  orderId: Scalars["UUID"];
}

/** Query root */
export interface QueryUnidentifiedPaymentByIdArgs {
  id: InputMaybe<Scalars["UUID"]>;
}

/** Query root */
export interface QueryUserArgs {
  id: Scalars["UUID"];
}

/** Query root */
export interface QueryUserNotificationsArgs {
  locale: Scalars["String"];
  search: SearchProjection2Input;
}

/** Query root */
export interface QueryUserOrdersInfoForVerificationArgs {
  auxUserId: Scalars["UUID"];
}

/** Query root */
export interface QueryVehicleByVinArgs {
  vin: Scalars["String"];
}

/** Query root */
export interface QueryVerificationCallSurveyArgs {
  locale: Scalars["String"];
  orderId: Scalars["UUID"];
  templateType: Scalars["String"];
}

/** Query root */
export interface QueryVerificationCallSurveyAnswersArgs {
  locale: Scalars["String"];
  orderId: Scalars["UUID"];
  templateType: Scalars["String"];
}

/** Query root */
export interface QueryWarrantyArgs {
  id: InputMaybe<Scalars["UUID"]>;
}

export interface RadioDtoInput {
  name?: InputMaybe<Scalars["String"]>;
}

export interface RangeDto_Integer {
  __typename?: "RangeDTO_Integer";
  max: Maybe<Scalars["Int"]>;
  min: Maybe<Scalars["Int"]>;
}

export interface RangeDto_IntegerInput {
  max?: InputMaybe<Scalars["Int"]>;
  min?: InputMaybe<Scalars["Int"]>;
}

export interface ReferralPaymentProjection {
  __typename?: "ReferralPaymentProjection";
  orderId: Maybe<Scalars["UUID"]>;
  referralType: Maybe<Scalars["String"]>;
}

export interface ReferralProjection {
  __typename?: "ReferralProjection";
  accountingDate: Maybe<Scalars["LocalDate"]>;
  auxUserId: Maybe<Scalars["UUID"]>;
  canceled: Maybe<Scalars["Boolean"]>;
  createdAt: Maybe<Scalars["String"]>;
  currency: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  orderId: Maybe<Scalars["UUID"]>;
  paid: Maybe<Scalars["Boolean"]>;
  reward: Maybe<Scalars["Int"]>;
}

export interface RefundDistributionProjection {
  __typename?: "RefundDistributionProjection";
  accountDetails: Maybe<RetailAccountDetailsCreateProjection>;
  amount: Maybe<RetailFinancePriceProjection>;
  tempId: Maybe<Scalars["Int"]>;
}

export interface RefundDistributionProjectionInput {
  accountDetails?: InputMaybe<RetailAccountDetailsCreateProjectionInput>;
  amount?: InputMaybe<RetailFinancePriceProjectionInput>;
  tempId?: InputMaybe<Scalars["Int"]>;
}

export interface RefundPendingTaskProjection {
  __typename?: "RefundPendingTaskProjection";
  action: Maybe<Scalars["String"]>;
  assignedOn: Maybe<Scalars["String"]>;
  assignedTo: Maybe<UserProjection>;
  assignee: Maybe<UserProjection>;
  cancellationReasonComment: Maybe<Scalars["String"]>;
  cashOutReason: Maybe<Scalars["String"]>;
  cashOutRequestDate: Maybe<Scalars["String"]>;
  closedBy: Maybe<UserProjection>;
  closedOn: Maybe<Scalars["String"]>;
  comment: Maybe<Scalars["String"]>;
  commentsCount: Maybe<Scalars["Int"]>;
  completedBy: Maybe<UserProjection>;
  completedOn: Maybe<Scalars["String"]>;
  createdOn: Maybe<Scalars["String"]>;
  financingStatus: Maybe<Scalars["String"]>;
  flowVersion: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  name: Maybe<Scalars["String"]>;
  order: Maybe<OrderProjection>;
  orderId: Maybe<Scalars["UUID"]>;
  orderNumber: Maybe<Scalars["String"]>;
  orderState: Maybe<Scalars["String"]>;
  paymentStatus: Maybe<Scalars["String"]>;
  paymentType: Maybe<Scalars["String"]>;
  reason: Maybe<Scalars["String"]>;
  refundAmount: Maybe<PriceDto>;
  repeatAt: Maybe<Scalars["String"]>;
  sequenceIndex: Scalars["Int"];
  state: Maybe<Scalars["String"]>;
  stockNumber: Maybe<Scalars["String"]>;
  taskDefinitionKey: Maybe<Scalars["String"]>;
  taskStatus: Maybe<Scalars["String"]>;
  taskType: Maybe<Scalars["String"]>;
  taskUpdatedAt: Maybe<Scalars["String"]>;
}

export interface RefurbishmentAggregateProjection {
  __typename?: "RefurbishmentAggregateProjection";
  adId: Maybe<Scalars["UUID"]>;
  beautyShot: Maybe<Scalars["String"]>;
  beautyShotsTakenOn: Maybe<Scalars["String"]>;
  branchId: Maybe<Scalars["Int"]>;
  branchName: Maybe<Scalars["String"]>;
  cancelDescription: Maybe<Scalars["String"]>;
  cancelReason: Maybe<Scalars["String"]>;
  completedReason: Maybe<Scalars["String"]>;
  country: Maybe<Scalars["String"]>;
  createdBy: Maybe<Scalars["UUID"]>;
  createdOn: Maybe<Scalars["String"]>;
  currencyCode: Maybe<Scalars["String"]>;
  daysInCurrentStatus: Maybe<Scalars["Int"]>;
  daysSinceCarArrivedInWorkshop: Maybe<Scalars["String"]>;
  daysSincePreparationStarted: Maybe<Scalars["String"]>;
  estimatedCompleteDate: Maybe<Scalars["String"]>;
  flowVersion: Maybe<RefurbishmentFlowVersion>;
  id: Maybe<Scalars["UUID"]>;
  lastStatusUpdatedOn: Maybe<Scalars["String"]>;
  maximumBudgetMinorUnits: Maybe<Scalars["Long"]>;
  modelInfo: Maybe<ModelDto>;
  notificationCount: Maybe<
    Scalars["Map_RetailNotificationEntityType_IntegerScalar"]
  >;
  partnerId: Maybe<Scalars["Int"]>;
  partnerName: Maybe<Scalars["String"]>;
  picturesReceivedOn: Maybe<Scalars["String"]>;
  picturesUploadedOn: Maybe<Scalars["String"]>;
  providerEstimatedCompleteDate: Maybe<Scalars["String"]>;
  refId: Maybe<Scalars["String"]>;
  refurbishmentLocation: Maybe<Scalars["String"]>;
  refurbishmentPartner: Maybe<Scalars["String"]>;
  refurbishmentType: Maybe<Scalars["String"]>;
  retailAdId: Maybe<Scalars["UUID"]>;
  retailReady: Maybe<Scalars["Boolean"]>;
  retailStockCountry: Maybe<Scalars["String"]>;
  sourcingCountry: Maybe<Scalars["String"]>;
  state: Maybe<Scalars["String"]>;
  stockNumber: Maybe<Scalars["String"]>;
  totalCostBudgetMinorUnits: Maybe<Scalars["Long"]>;
  updatedOn: Maybe<Scalars["String"]>;
  videoUploadedOn: Maybe<Scalars["String"]>;
  vinNumber: Maybe<Scalars["String"]>;
  wheelSetDeleted: Maybe<Scalars["Boolean"]>;
  wheelSetDeletedReason: Maybe<WheelSetDeleteReason>;
}

export interface RefurbishmentAggregateSearchDtoInput {
  branchIds?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  countries?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  isTest?: InputMaybe<Scalars["Boolean"]>;
  refId?: InputMaybe<Scalars["String"]>;
  refurbishmentCancelReasons?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  refurbishmentCompletedReasons?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  refurbishmentStates?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  refurbishmentTypes?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  retailReady?: InputMaybe<Scalars["Boolean"]>;
  stockNumber?: InputMaybe<Scalars["String"]>;
  vinNumber?: InputMaybe<Scalars["String"]>;
}

export interface RefurbishmentCommentCreateProjectionInput {
  comment?: InputMaybe<Scalars["String"]>;
  refurbishmentId?: InputMaybe<Scalars["UUID"]>;
}

export interface RefurbishmentCommentProjection {
  __typename?: "RefurbishmentCommentProjection";
  comment: Maybe<Scalars["String"]>;
  createdBy: Maybe<UserProjection>;
  createdOn: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  notificationId: Maybe<Scalars["UUID"]>;
  refurbishmentId: Maybe<Scalars["UUID"]>;
  refurbishmentTransitionId: Maybe<Scalars["UUID"]>;
  updatedOn: Maybe<Scalars["String"]>;
}

export interface RefurbishmentCreateProjectionInput {
  beautyShotsTakenOn?: InputMaybe<Scalars["String"]>;
  branchId?: InputMaybe<Scalars["Int"]>;
  country?: InputMaybe<Scalars["String"]>;
  partnerId?: InputMaybe<Scalars["Int"]>;
  picturesReceivedOn?: InputMaybe<Scalars["String"]>;
  picturesUploadedOn?: InputMaybe<Scalars["String"]>;
  refurbishmentType?: InputMaybe<Scalars["String"]>;
  retailAdId?: InputMaybe<Scalars["UUID"]>;
}

export interface RefurbishmentDto {
  __typename?: "RefurbishmentDTO";
  adDetails: Maybe<AdDetailsProjection>;
  beautyShot: Maybe<Scalars["String"]>;
  beautyShotsTakenOn: Maybe<Scalars["String"]>;
  branchId: Maybe<Scalars["Int"]>;
  branchName: Maybe<Scalars["String"]>;
  branches: Maybe<Array<Maybe<BranchProjection>>>;
  cancelDescription: Maybe<Scalars["String"]>;
  cancelReason: Maybe<Scalars["String"]>;
  completedReason: Maybe<Scalars["String"]>;
  country: Maybe<Scalars["String"]>;
  createdBy: Maybe<Scalars["UUID"]>;
  createdOn: Maybe<Scalars["String"]>;
  currencyCode: Maybe<Scalars["String"]>;
  currentPartnerWorkshopConnectionId: Maybe<Scalars["UUID"]>;
  daysInCurrentStatus: Maybe<Scalars["Int"]>;
  daysSinceCarArrivedInWorkshop: Maybe<Scalars["String"]>;
  daysSincePreparationStarted: Maybe<Scalars["String"]>;
  defaultPartnerWorkshopConnectionId: Maybe<Scalars["UUID"]>;
  documents: Maybe<Array<Maybe<RetailDocumentProjection>>>;
  estimatedCompleteDate: Maybe<Scalars["String"]>;
  flowVersion: Maybe<RefurbishmentFlowVersion>;
  id: Maybe<Scalars["UUID"]>;
  keysCount: Maybe<Scalars["Int"]>;
  lastStatusUpdatedOn: Maybe<Scalars["String"]>;
  maximumBudgetMinorUnits: Maybe<Scalars["Long"]>;
  partnerId: Maybe<Scalars["Int"]>;
  partnerName: Maybe<Scalars["String"]>;
  partnerWorkshopConnections: Maybe<Array<Maybe<PartnerWorkshopConnectionDto>>>;
  partners: Maybe<Array<Maybe<RefurbishmentPartnerDto>>>;
  picturesReceivedOn: Maybe<Scalars["String"]>;
  picturesUploadedOn: Maybe<Scalars["String"]>;
  providerEstimatedCompleteDate: Maybe<Scalars["String"]>;
  refId: Maybe<Scalars["String"]>;
  refurbishmentServices: Maybe<Array<Maybe<RefurbishmentServiceDto>>>;
  refurbishmentSparePartStatus: Maybe<Scalars["String"]>;
  refurbishmentType: Maybe<Scalars["String"]>;
  retailAdId: Maybe<Scalars["UUID"]>;
  retailReady: Maybe<Scalars["Boolean"]>;
  serviceBookPresent: Maybe<Scalars["Boolean"]>;
  serviceBookType: Maybe<Scalars["String"]>;
  state: Maybe<Scalars["String"]>;
  tags: Maybe<Array<Maybe<Scalars["String"]>>>;
  totalCostBudgetMinorUnits: Maybe<Scalars["Long"]>;
  transitions: Maybe<Array<Maybe<RefurbishmentTransitionDto>>>;
  updatedOn: Maybe<Scalars["String"]>;
  videoUploadedOn: Maybe<Scalars["String"]>;
  vinNumber: Maybe<Scalars["String"]>;
  wheelSetDeleted: Maybe<Scalars["Boolean"]>;
  wheelSetDeletedReason: Maybe<WheelSetDeleteReason>;
}

export interface RefurbishmentEtaListProjection {
  __typename?: "RefurbishmentEtaListProjection";
  etaList: Maybe<Array<Maybe<RefurbishmentEtaProjection>>>;
}

export interface RefurbishmentEtaProjection {
  __typename?: "RefurbishmentEtaProjection";
  comment: Maybe<Scalars["String"]>;
  createdBy: Maybe<RetailUserDto>;
  createdOn: Maybe<Scalars["String"]>;
  eta: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  refurbishmentId: Maybe<Scalars["UUID"]>;
}

export type RefurbishmentFlowVersion = "V1" | "V2";

export interface RefurbishmentPartnerDto {
  __typename?: "RefurbishmentPartnerDTO";
  address: Maybe<Scalars["String"]>;
  city: Maybe<Scalars["String"]>;
  contactName: Maybe<Scalars["String"]>;
  country: Maybe<Scalars["String"]>;
  createdBy: Maybe<Scalars["Int"]>;
  createdOn: Maybe<Scalars["String"]>;
  email1: Maybe<Scalars["String"]>;
  email2: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["Int"]>;
  name: Maybe<Scalars["String"]>;
  partnerEmail: Maybe<Scalars["String"]>;
  phone: Maybe<Scalars["String"]>;
  plz: Maybe<Scalars["String"]>;
  status: Maybe<Scalars["Int"]>;
}

export interface RefurbishmentProjection {
  __typename?: "RefurbishmentProjection";
  beautyShotsTakenOn: Maybe<Scalars["String"]>;
  branchData: Maybe<BranchProjection>;
  cancelDescription: Maybe<Scalars["String"]>;
  cancelReason: Maybe<Scalars["String"]>;
  completedReason: Maybe<Scalars["String"]>;
  country: Maybe<Scalars["String"]>;
  createdBy: Maybe<Scalars["UUID"]>;
  createdOn: Maybe<Scalars["String"]>;
  currencyCode: Maybe<Scalars["String"]>;
  daysInCurrentStatus: Maybe<Scalars["Int"]>;
  daysSinceCarArrivedInWorkshop: Maybe<Scalars["String"]>;
  daysSincePreparationStarted: Maybe<Scalars["String"]>;
  estimatedCompleteDate: Maybe<Scalars["String"]>;
  flowVersion: Maybe<RefurbishmentFlowVersion>;
  id: Maybe<Scalars["UUID"]>;
  lastStatusUpdatedOn: Maybe<Scalars["String"]>;
  maximumBudget: Maybe<PriceDto>;
  maximumBudgetMinorUnits: Maybe<Scalars["Long"]>;
  partnerData: Maybe<RefurbishmentPartnerDto>;
  picturesReceivedOn: Maybe<Scalars["String"]>;
  picturesUploadedOn: Maybe<Scalars["String"]>;
  providerEstimatedCompleteDate: Maybe<Scalars["String"]>;
  refId: Maybe<Scalars["String"]>;
  refurbishmentType: Maybe<Scalars["String"]>;
  retailAdId: Maybe<Scalars["UUID"]>;
  retailReady: Maybe<Scalars["Boolean"]>;
  state: Maybe<Scalars["String"]>;
  totalCostBudget: Maybe<PriceDto>;
  totalCostBudgetMinorUnits: Maybe<Scalars["Long"]>;
}

export interface RefurbishmentServiceCreateProjectionInput {
  budgetMinorUnits?: InputMaybe<Scalars["Long"]>;
  comment?: InputMaybe<Scalars["String"]>;
  currencyCode?: InputMaybe<Scalars["String"]>;
  retailAdDamageId?: InputMaybe<Scalars["UUID"]>;
  serviceName?: InputMaybe<Scalars["String"]>;
  serviceType?: InputMaybe<Scalars["String"]>;
  sourceType?: InputMaybe<Scalars["String"]>;
}

export interface RefurbishmentServiceDto {
  __typename?: "RefurbishmentServiceDTO";
  budgetMinorUnits: Maybe<Scalars["Long"]>;
  comment: Maybe<Scalars["String"]>;
  consumableCostMinorUnits: Maybe<Scalars["Long"]>;
  createdOn: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  isAutoCreate: Maybe<Scalars["Boolean"]>;
  laborRateMinorUnits: Maybe<Scalars["Long"]>;
  laborTimeHours: Maybe<Scalars["Float"]>;
  partnerWorkshopConnectionId: Maybe<Scalars["UUID"]>;
  refurbishmentId: Maybe<Scalars["UUID"]>;
  refurbishmentTransitionId: Maybe<Scalars["UUID"]>;
  relatedServiceId: Maybe<Scalars["UUID"]>;
  retailAdDamageId: Maybe<Scalars["UUID"]>;
  serviceName: Maybe<Scalars["String"]>;
  serviceType: Maybe<Scalars["String"]>;
  sourceType: Maybe<Scalars["String"]>;
  sparePart: Maybe<SparePartDto>;
  sparePartCostMinorUnits: Maybe<Scalars["Long"]>;
  state: Maybe<Scalars["String"]>;
  stateComment: Maybe<Scalars["String"]>;
  updatedOn: Maybe<Scalars["String"]>;
}

export interface RefurbishmentServiceUpdateProjectionInput {
  budgetMinorUnits?: InputMaybe<Scalars["Long"]>;
  comment?: InputMaybe<Scalars["String"]>;
  serviceName?: InputMaybe<Scalars["String"]>;
  sourceType?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
}

export interface RefurbishmentTransitionDto {
  __typename?: "RefurbishmentTransitionDTO";
  createdBy: Maybe<Scalars["UUID"]>;
  createdOn: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  refurbishmentId: Maybe<Scalars["UUID"]>;
  sourceType: Maybe<Scalars["String"]>;
  stateFrom: Maybe<Scalars["String"]>;
  stateTo: Maybe<Scalars["String"]>;
  transitionDate: Maybe<Scalars["String"]>;
}

export interface RefurbishmentUpdateProjectionInput {
  beautyShotsTakenOn?: InputMaybe<Scalars["String"]>;
  branchId?: InputMaybe<Scalars["Int"]>;
  country?: InputMaybe<Scalars["String"]>;
  currencyCode?: InputMaybe<Scalars["String"]>;
  estimatedCompleteDate?: InputMaybe<Scalars["String"]>;
  maximumBudgetMinorUnits?: InputMaybe<Scalars["Long"]>;
  partnerId?: InputMaybe<Scalars["Int"]>;
  picturesReceivedOn?: InputMaybe<Scalars["String"]>;
  picturesUploadedOn?: InputMaybe<Scalars["String"]>;
  retailReady?: InputMaybe<Scalars["Boolean"]>;
  videoUploadedOn?: InputMaybe<Scalars["String"]>;
}

export interface RefurbishmentUpdateServicesProjectionInput {
  refurbishmentServiceUpdateProjection?: InputMaybe<RefurbishmentServiceUpdateProjectionInput>;
  serviceId?: InputMaybe<Scalars["UUID"]>;
}

export type RegistrationUserSource =
  | "AH_BACKOFFICE_CHECKOUT"
  | "AH_CHECKOUT"
  | "AH_COMING_SOON_AD_SUBSCRIBE"
  | "AH_FAVOURITES"
  | "AH_FINANCE_FLOW"
  | "AH_GENERAL"
  | "AH_MAKRETING_REALMADRID_BOOTCAMP_RAFFLE"
  | "AH_MAKRETING_REALMADRID_RAFFLES"
  | "AH_MARKETING_DTM"
  | "AH_MARKETING_HERTHA"
  | "AH_MARKETING_PSG_RAFFLE"
  | "AH_ONLINE_CHECKOUT"
  | "AH_PRE_ACCOUNT_FAVORITES"
  | "AH_PRE_ACCOUNT_SAVED_SEARCH"
  | "AH_RESERVED_AD_SUBSCRIBE"
  | "AH_SAVED_SEARCH"
  | "AH_SEARCH"
  | "AH_SELL_ONLY"
  | "AH_SELL_ONLY_FUNNEL"
  | "AH_TRADE_IN";

export interface RemainingRefundAmountProjection {
  __typename?: "RemainingRefundAmountProjection";
  amountMinorUnits: Maybe<Scalars["Long"]>;
}

export interface RepairPartnerDto {
  __typename?: "RepairPartnerDTO";
  address: Maybe<Scalars["String"]>;
  city: Maybe<Scalars["String"]>;
  contactName: Maybe<Scalars["String"]>;
  country: Maybe<Scalars["String"]>;
  createdBy: Maybe<Scalars["UUID"]>;
  createdOn: Maybe<Scalars["String"]>;
  email1: Maybe<Scalars["String"]>;
  email2: Maybe<Scalars["String"]>;
  enabled: Maybe<Scalars["Boolean"]>;
  id: Maybe<Scalars["UUID"]>;
  name: Maybe<Scalars["String"]>;
  partnerEmail: Maybe<Scalars["String"]>;
  phone: Maybe<Scalars["String"]>;
  postcode: Maybe<Scalars["String"]>;
  refurbishmentPartnerId: Maybe<Scalars["Int"]>;
}

export interface RepeatHappinessCallTaskManagementProjection {
  __typename?: "RepeatHappinessCallTaskManagementProjection";
  action: Maybe<Scalars["String"]>;
  assignedOn: Maybe<Scalars["String"]>;
  assignedTo: Maybe<UserProjection>;
  assignee: Maybe<UserProjection>;
  carHandoverOnDate: Maybe<Scalars["String"]>;
  closedBy: Maybe<UserProjection>;
  closedOn: Maybe<Scalars["String"]>;
  comment: Maybe<Scalars["String"]>;
  commentsCount: Maybe<Scalars["Int"]>;
  completedBy: Maybe<UserProjection>;
  completedOn: Maybe<Scalars["String"]>;
  createdOn: Maybe<Scalars["String"]>;
  financingStatus: Maybe<Scalars["String"]>;
  flowVersion: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  name: Maybe<Scalars["String"]>;
  order: Maybe<OrderProjection>;
  orderContractSignedOn: Maybe<Scalars["String"]>;
  orderHandoverOn: Maybe<Scalars["String"]>;
  orderId: Maybe<Scalars["UUID"]>;
  orderNumber: Maybe<Scalars["String"]>;
  orderState: Maybe<Scalars["String"]>;
  paymentStatus: Maybe<Scalars["String"]>;
  paymentType: Maybe<Scalars["String"]>;
  reason: Maybe<Scalars["String"]>;
  repeatAt: Maybe<Scalars["String"]>;
  retailCountry: Maybe<Scalars["String"]>;
  sequenceIndex: Scalars["Int"];
  state: Maybe<Scalars["String"]>;
  stockNumber: Maybe<Scalars["String"]>;
  taskDefinitionKey: Maybe<Scalars["String"]>;
  taskStatus: Maybe<Scalars["String"]>;
  taskType: Maybe<Scalars["String"]>;
  taskUpdatedAt: Maybe<Scalars["String"]>;
}

export interface RequestBankDetailsForOutboundPaymentProjectionInput {
  amountMinorUnits?: InputMaybe<Scalars["Long"]>;
  orderId?: InputMaybe<Scalars["UUID"]>;
  paymentType?: InputMaybe<Scalars["String"]>;
  purpose?: InputMaybe<RetailOutboundPaymentPurpose>;
}

export interface RequestBankDetailsProjectionInput {
  orderId?: InputMaybe<Scalars["UUID"]>;
  refundAmount?: InputMaybe<PriceDtoInput>;
  source?: InputMaybe<OrderAccountDetailsRequestSource>;
}

export interface ReschedulingCommentProjectionInput {
  channel?: InputMaybe<Scalars["String"]>;
  note?: InputMaybe<Scalars["String"]>;
  reason?: InputMaybe<Scalars["String"]>;
}

export interface ResultConnection_DocumentInventoryItem {
  __typename?: "ResultConnection_DocumentInventoryItem";
  nodes: Array<Maybe<DocumentInventoryItem>>;
  totalCount: Scalars["Long"];
}

export interface ResultConnection_DocumentInventoryPackageProjection {
  __typename?: "ResultConnection_DocumentInventoryPackageProjection";
  nodes: Array<Maybe<DocumentInventoryPackageProjection>>;
  totalCount: Scalars["Long"];
}

export interface ResultConnection_DocumentLocation {
  __typename?: "ResultConnection_DocumentLocation";
  nodes: Array<Maybe<DocumentLocation>>;
  totalCount: Scalars["Long"];
}

export interface ResultConnection_ShippingProviderProjection {
  __typename?: "ResultConnection_ShippingProviderProjection";
  nodes: Array<Maybe<ShippingProviderProjection>>;
  totalCount: Scalars["Long"];
}

export interface RetailAbTestingConditionVariableProjection {
  __typename?: "RetailAbTestingConditionVariableProjection";
  type: Maybe<Scalars["String"]>;
  values: Maybe<Array<Maybe<Scalars["String"]>>>;
  variable: Maybe<Scalars["String"]>;
}

export interface RetailAbTestingExperimentCountersProjection {
  __typename?: "RetailAbTestingExperimentCountersProjection";
  counters: Array<Maybe<Scalars["Long"]>>;
  totalHits: Scalars["Long"];
}

export interface RetailAbTestingExperimentProjection {
  __typename?: "RetailAbTestingExperimentProjection";
  condition: Maybe<Scalars["String"]>;
  createdBy: Maybe<AbTestingExperimentAuthor>;
  createdOn: Maybe<Scalars["String"]>;
  enabled: Scalars["Boolean"];
  environment: Maybe<Scalars["String"]>;
  expireAt: Maybe<Scalars["String"]>;
  gaExperimentId: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  multiStep: Scalars["Boolean"];
  name: Scalars["String"];
  nextStepVariants: Maybe<
    Array<Maybe<RetailAbTestingNextStepVariantProjection>>
  >;
  startAt: Scalars["String"];
  trafficPercents: Scalars["Int"];
  updatedBy: Maybe<AbTestingExperimentAuthor>;
  updatedOn: Maybe<Scalars["String"]>;
  variants: Maybe<Array<Maybe<RetailAbTestingVariantProjection>>>;
  viewNames: Array<Maybe<Scalars["String"]>>;
}

export interface RetailAbTestingNextStepVariantProjection {
  __typename?: "RetailAbTestingNextStepVariantProjection";
  gaExperimentId: Maybe<Scalars["String"]>;
  gaVariationId: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  nextStep: Maybe<Scalars["String"]>;
  skipSteps: Maybe<Array<Maybe<Scalars["String"]>>>;
  weightPercents: Maybe<Scalars["Int"]>;
}

export interface RetailAbTestingNextStepVariantProjectionInput {
  gaExperimentId?: InputMaybe<Scalars["String"]>;
  gaVariationId?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["UUID"]>;
  nextStep?: InputMaybe<Scalars["String"]>;
  skipSteps?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  weightPercents?: InputMaybe<Scalars["Int"]>;
}

export interface RetailAbTestingParticipantProjection {
  __typename?: "RetailAbTestingParticipantProjection";
  adId: Maybe<Scalars["UUID"]>;
  checkoutId: Maybe<Scalars["UUID"]>;
  experimentId: Scalars["UUID"];
  experimentName: Scalars["String"];
  gaExperimentId: Scalars["String"];
  gaVariationId: Scalars["String"];
  orderId: Maybe<Scalars["UUID"]>;
  participatedOn: Scalars["String"];
  userId: Maybe<Scalars["UUID"]>;
  visitorId: Scalars["String"];
}

export interface RetailAbTestingVariantProjection {
  __typename?: "RetailAbTestingVariantProjection";
  gaExperimentId: Maybe<Scalars["String"]>;
  gaVariationId: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  viewParams: Maybe<Scalars["String"]>;
  viewVariant: Maybe<Scalars["String"]>;
  weightPercents: Maybe<Scalars["Int"]>;
}

export interface RetailAbTestingVariantProjectionInput {
  gaExperimentId?: InputMaybe<Scalars["String"]>;
  gaVariationId?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["UUID"]>;
  viewParams?: InputMaybe<Scalars["String"]>;
  viewVariant?: InputMaybe<Scalars["String"]>;
  weightPercents?: InputMaybe<Scalars["Int"]>;
}

export interface RetailAccessParamDto {
  __typename?: "RetailAccessParamDTO";
  key: Maybe<Scalars["String"]>;
  value: Maybe<Scalars["String"]>;
}

export interface RetailAccountDataCreateProjectionInput {
  bankName?: InputMaybe<Scalars["String"]>;
  bic?: InputMaybe<Scalars["String"]>;
  blz?: InputMaybe<Scalars["String"]>;
  holder?: InputMaybe<Scalars["String"]>;
  iban?: InputMaybe<Scalars["String"]>;
  number?: InputMaybe<Scalars["String"]>;
}

export interface RetailAccountDetailsCreateProjection {
  __typename?: "RetailAccountDetailsCreateProjection";
  accountDataId: Maybe<Scalars["UUID"]>;
  bic: Maybe<Scalars["String"]>;
  holder: Maybe<Scalars["String"]>;
  iban: Maybe<Scalars["String"]>;
}

export interface RetailAccountDetailsCreateProjectionInput {
  accountDataId?: InputMaybe<Scalars["UUID"]>;
  bic?: InputMaybe<Scalars["String"]>;
  holder?: InputMaybe<Scalars["String"]>;
  iban?: InputMaybe<Scalars["String"]>;
}

export interface RetailAccountDetailsForPaymentProjection {
  __typename?: "RetailAccountDetailsForPaymentProjection";
  accountType: Maybe<Scalars["String"]>;
  accounts: Maybe<Array<Maybe<RetailAccountDetailsWithPaymentsProjection>>>;
}

export interface RetailAccountDetailsWithPaymentsProjection {
  __typename?: "RetailAccountDetailsWithPaymentsProjection";
  payments: Maybe<Array<Maybe<RetailPaymentWrapperProjection>>>;
  retailAccount: Maybe<BankAccountDetailsWithDocumentsProjection>;
  source: Maybe<Scalars["String"]>;
  uploadSource: Maybe<Scalars["String"]>;
}

export type RetailAdDamageCancellationReason =
  | "INCORRECT_VEHICLE_PART"
  | "NOT_FOUND";

export interface RetailAdDamageDto {
  __typename?: "RetailAdDamageDTO";
  area: Maybe<Scalars["String"]>;
  cancelComment: Maybe<Scalars["String"]>;
  cancelReason: Maybe<RetailAdDamageCancellationReason>;
  comment: Maybe<Scalars["String"]>;
  createdOn: Maybe<Scalars["String"]>;
  customerDisplay: Scalars["Boolean"];
  damageExtendedId: Maybe<Scalars["Long"]>;
  displayToCustomerFrontend: Maybe<Scalars["Boolean"]>;
  id: Maybe<Scalars["UUID"]>;
  isSecondaryWheel: Maybe<Scalars["Boolean"]>;
  part: Maybe<Scalars["String"]>;
  photoId: Maybe<Scalars["Int"]>;
  retailAdId: Maybe<Scalars["UUID"]>;
  severities: Maybe<Array<Maybe<Scalars["String"]>>>;
  sourceType: Maybe<Scalars["String"]>;
  state: Maybe<Scalars["String"]>;
  subArea: Maybe<Scalars["String"]>;
  type: Maybe<Scalars["String"]>;
  updatedOn: Maybe<Scalars["String"]>;
}

export interface RetailAdDamageImageDto {
  __typename?: "RetailAdDamageImageDTO";
  displayToCustomerFrontend: Scalars["Boolean"];
  id: Maybe<Scalars["UUID"]>;
  image: Maybe<DamageImageDto>;
  part: Maybe<Scalars["String"]>;
  type: Maybe<Scalars["String"]>;
}

export interface RetailAdDamageProjection {
  __typename?: "RetailAdDamageProjection";
  area: Maybe<Scalars["String"]>;
  areaTranslation: Maybe<Scalars["String"]>;
  customerDisplay: Maybe<Scalars["Boolean"]>;
  damageService: Maybe<RefurbishmentServiceDto>;
  id: Maybe<Scalars["UUID"]>;
  image: Maybe<ImageProjection>;
  isSecondaryWheel: Maybe<Scalars["Boolean"]>;
  part: Maybe<Scalars["String"]>;
  partTranslation: Maybe<Scalars["String"]>;
  retailAdId: Maybe<Scalars["UUID"]>;
  service: Maybe<RefurbishmentServiceDto>;
  severities: Maybe<Array<Maybe<Scalars["String"]>>>;
  severityTranslations: Maybe<Array<Maybe<SeverityProjection>>>;
  sourceType: Maybe<Scalars["String"]>;
  state: Maybe<Scalars["String"]>;
  subArea: Maybe<Scalars["String"]>;
  subAreaTranslation: Maybe<Scalars["String"]>;
  type: Maybe<Scalars["String"]>;
  typeTranslation: Maybe<Scalars["String"]>;
}

export interface RetailAdDeliveryAddressDetailsProjection {
  __typename?: "RetailAdDeliveryAddressDetailsProjection";
  DELIVERY_RANGE_ETA: Maybe<Scalars["String"]>;
  customerExpectedEta: Maybe<RetailCustomerExpectedEtaDto>;
  deliveryOption: Maybe<Scalars["String"]>;
  deliveryPrice: Maybe<DeliveryPrice>;
  deliveryType: Maybe<Scalars["String"]>;
  estimationId: Maybe<Scalars["UUID"]>;
  homeDeliveryExtraMileage: Maybe<Scalars["Int"]>;
  hubId: Maybe<Scalars["Int"]>;
}

export interface RetailAdDeliveryBranchDetailsDto {
  __typename?: "RetailAdDeliveryBranchDetailsDTO";
  address1: Maybe<Scalars["String"]>;
  address2: Maybe<Scalars["String"]>;
  city: Maybe<Scalars["String"]>;
  country: Maybe<Scalars["String"]>;
  customerExpectedEta: Maybe<RetailCustomerExpectedEtaDto>;
  deliveryETA: Maybe<IntegerRangeDto>;
  deliveryETADate: Maybe<Scalars["String"]>;
  deliveryEtaRange: Maybe<DeliveryEtaRangeDto>;
  deliveryEtaType: Maybe<Scalars["String"]>;
  deliveryOption: Maybe<Scalars["String"]>;
  deliveryPrice: Maybe<DeliveryPrice>;
  deliveryType: Maybe<Scalars["String"]>;
  distance: Maybe<Scalars["Float"]>;
  estimationId: Maybe<Scalars["UUID"]>;
  id: Maybe<Scalars["Int"]>;
  logisticsEtaInCalendarDays: Maybe<Scalars["Int"]>;
  name: Maybe<Scalars["String"]>;
  properties: Maybe<Array<Maybe<Scalars["String"]>>>;
  street: Maybe<Scalars["String"]>;
  type: Maybe<Scalars["Int"]>;
  zipCode: Maybe<Scalars["String"]>;
}

export interface RetailAdDeliveryBranchDetailsProjection {
  __typename?: "RetailAdDeliveryBranchDetailsProjection";
  address1: Maybe<Scalars["String"]>;
  address2: Maybe<Scalars["String"]>;
  city: Maybe<Scalars["String"]>;
  country: Maybe<Scalars["String"]>;
  customerExpectedEta: Maybe<RetailCustomerExpectedEtaDto>;
  deliveryETA: Maybe<IntegerRangeDto>;
  deliveryETADate: Maybe<Scalars["LocalDate"]>;
  deliveryPrice: Maybe<DeliveryPrice>;
  distance: Maybe<Scalars["Float"]>;
  estimationId: Maybe<Scalars["UUID"]>;
  id: Maybe<Scalars["Int"]>;
  name: Maybe<Scalars["String"]>;
  properties: Maybe<Array<Maybe<Scalars["String"]>>>;
  street: Maybe<Scalars["String"]>;
  type: Maybe<Scalars["Int"]>;
  zipCode: Maybe<Scalars["String"]>;
}

export interface RetailAdDeliveryBranchesDetailsProjection {
  __typename?: "RetailAdDeliveryBranchesDetailsProjection";
  branches: Maybe<Array<Maybe<RetailAdDeliveryBranchDetailsDto>>>;
  distanceWhereBranchesFound: Maybe<Scalars["Int"]>;
  estimationId: Maybe<Scalars["UUID"]>;
  first: Maybe<Scalars["Int"]>;
  offset: Maybe<Scalars["Int"]>;
  total: Maybe<Scalars["Int"]>;
}

export interface RetailAdDeliveryOptionProjection {
  __typename?: "RetailAdDeliveryOptionProjection";
  deliveryOption: Maybe<Scalars["String"]>;
  deliveryType: Maybe<Scalars["String"]>;
  retailAdDeliveryOptionDetails: Maybe<RetailAdDeliveryOptionDetails>;
  retailAdId: Maybe<Scalars["UUID"]>;
}

export interface RetailAdPnlDataProjection {
  __typename?: "RetailAdPnlDataProjection";
  classifiedAd: Maybe<AdProjection>;
  costItems: Maybe<Array<Maybe<PnlItemProjection>>>;
  order: Maybe<OrderProjection>;
  retailAd: Maybe<RetailAdProjection>;
  revenueItems: Maybe<Array<Maybe<PnlItemProjection>>>;
}

export interface RetailAdPriceChangeDto {
  __typename?: "RetailAdPriceChangeDTO";
  actionType: Maybe<Scalars["String"]>;
  changeReason: Maybe<Scalars["String"]>;
  createdOn: Maybe<Scalars["String"]>;
  failureReason: Maybe<Scalars["String"]>;
  newPrice: Maybe<Scalars["String"]>;
  previousPrice: Maybe<Scalars["String"]>;
  priceChangeType: Maybe<Scalars["String"]>;
  status: Maybe<Scalars["String"]>;
  userName: Maybe<Scalars["String"]>;
}

export interface RetailAdProjection {
  __typename?: "RetailAdProjection";
  adId: Maybe<Scalars["UUID"]>;
  auto1BuyPrice: Maybe<Scalars["Int"]>;
  auto1BuyPriceDto: Maybe<PriceDto>;
  auto1ReturnOn: Maybe<Scalars["String"]>;
  auto1ReturnReason: Maybe<Scalars["String"]>;
  auto1Status: Maybe<Scalars["Int"]>;
  autoheroPurchaseOn: Maybe<Scalars["String"]>;
  contractDetails: Maybe<Array<Maybe<Scalars["ObjectScalar"]>>>;
  country: Maybe<Scalars["String"]>;
  createdOn: Maybe<Scalars["String"]>;
  documentLocation: Maybe<Scalars["String"]>;
  expectedSalesPriceGrossMinorUnits: Maybe<Scalars["Long"]>;
  financedPrice: Maybe<PriceDto>;
  firstImportOn: Maybe<Scalars["String"]>;
  firstPurchasedOn: Maybe<Scalars["String"]>;
  grossPrice: Maybe<PriceDto>;
  grossPriceMinorUnits: Maybe<Scalars["Long"]>;
  hidingOn: Maybe<Scalars["String"]>;
  hidingReason: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  lastKnownLocation: Maybe<Scalars["String"]>;
  locationInfo: Maybe<LocationInfoDto>;
  netPrice: Maybe<PriceDto>;
  netPriceMinorUnits: Maybe<Scalars["Long"]>;
  retailReady: Scalars["Boolean"];
  retailReadyDate: Maybe<Scalars["String"]>;
  secondaryWheels: Maybe<RetailAdSecondaryWheelsProjection>;
  state: Maybe<Scalars["String"]>;
  stockNumber: Maybe<Scalars["String"]>;
  taxAdvantage: Maybe<RetailAdTaxAdvantageProjection>;
  test: Scalars["Boolean"];
  updatedOn: Maybe<Scalars["String"]>;
  vatAmount: Maybe<PriceDto>;
  vatAmountMinorUnits: Maybe<Scalars["Long"]>;
  vatType: Maybe<Scalars["Int"]>;
}

export interface RetailAdPublishingBlockerProjection {
  __typename?: "RetailAdPublishingBlockerProjection";
  blockers: Maybe<Array<Maybe<PublishingBlockerProjection>>>;
  retailAdId: Maybe<Scalars["UUID"]>;
}

export interface RetailAdSecondaryWheelsCreateProjectionInput {
  adId?: InputMaybe<Scalars["UUID"]>;
  costPnlVatKey?: InputMaybe<Scalars["String"]>;
  countryCode?: InputMaybe<Scalars["String"]>;
  damageCondition?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  images?: InputMaybe<Array<InputMaybe<ImageProjectionInput>>>;
  internalRefName?: InputMaybe<Scalars["String"]>;
  invoiceGrossPrice?: InputMaybe<RetailFinancePriceProjectionInput>;
  invoiceNetPrice?: InputMaybe<RetailFinancePriceProjectionInput>;
  invoicePriceVatKey?: InputMaybe<Scalars["String"]>;
  invoicePriceVatRate?: InputMaybe<Scalars["Float"]>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  isShippingRequired?: InputMaybe<Scalars["Boolean"]>;
  location?: InputMaybe<SecondaryWheelsLocation>;
  locationDescription?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  partnerName?: InputMaybe<Scalars["String"]>;
  pnlCostGrossPrice?: InputMaybe<RetailFinancePriceProjectionInput>;
  pnlCostNetPrice?: InputMaybe<RetailFinancePriceProjectionInput>;
  pnlCostVatRate?: InputMaybe<Scalars["Float"]>;
  publishedScope?: InputMaybe<Scalars["String"]>;
  rimsDetails?: InputMaybe<
    Scalars["Map_WheelPlacementType_RimDetailsProjectionScalar"]
  >;
  sku?: InputMaybe<Scalars["String"]>;
  tiresDetails?: InputMaybe<
    Scalars["Map_WheelPlacementType_TireDetailsProjectionScalar"]
  >;
}

export interface RetailAdSecondaryWheelsDetailsUpdateProjectionInput {
  damageCondition?: InputMaybe<Scalars["String"]>;
  invoiceGrossPriceMinorUnits?: InputMaybe<Scalars["Long"]>;
  invoicePriceVatRate?: InputMaybe<Scalars["Float"]>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  isShippingRequired?: InputMaybe<Scalars["Boolean"]>;
}

export interface RetailAdSecondaryWheelsProjection {
  __typename?: "RetailAdSecondaryWheelsProjection";
  adId: Maybe<Scalars["UUID"]>;
  costPnlVatKey: Maybe<Scalars["String"]>;
  countryCode: Maybe<Scalars["String"]>;
  createdOn: Maybe<Scalars["String"]>;
  damageCondition: Maybe<Scalars["String"]>;
  description: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  images: Maybe<Array<Maybe<ImageProjection>>>;
  internalRefName: Maybe<Scalars["String"]>;
  invoiceGrossPrice: Maybe<RetailFinancePriceProjection>;
  invoiceNetPrice: Maybe<RetailFinancePriceProjection>;
  invoicePriceVatKey: Maybe<Scalars["String"]>;
  invoicePriceVatRate: Maybe<Scalars["Float"]>;
  isActive: Maybe<Scalars["Boolean"]>;
  isShippingRequired: Maybe<Scalars["Boolean"]>;
  location: Maybe<SecondaryWheelsLocation>;
  locationDescription: Maybe<Scalars["String"]>;
  name: Maybe<Scalars["String"]>;
  partnerName: Maybe<Scalars["String"]>;
  pnlCostGrossPrice: Maybe<RetailFinancePriceProjection>;
  pnlCostNetPrice: Maybe<RetailFinancePriceProjection>;
  pnlCostVatRate: Maybe<Scalars["Float"]>;
  publishedScope: Maybe<Scalars["String"]>;
  rimsDetails: Maybe<
    Scalars["Map_WheelPlacementType_RimDetailsProjectionScalar"]
  >;
  sku: Maybe<Scalars["String"]>;
  tiresDetails: Maybe<
    Scalars["Map_WheelPlacementType_TireDetailsProjectionScalar"]
  >;
  updatedOn: Maybe<Scalars["String"]>;
}

export interface RetailAdTaxAdvantageProjection {
  __typename?: "RetailAdTaxAdvantageProjection";
  actualAmount: Maybe<PriceDto>;
  actualAmountMinorUnits: Maybe<Scalars["Int"]>;
  costAmount: Maybe<PriceDto>;
  costAmountMinorUnits: Maybe<Scalars["Int"]>;
  currentCountryFirstRegDate: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  internationalFirstRegDate: Maybe<Scalars["String"]>;
  lastActualAmountCalcDate: Maybe<Scalars["String"]>;
  lastCostAmountCalcDate: Maybe<Scalars["String"]>;
  originalAmount: Maybe<PriceDto>;
  originalAmountMinorUnits: Maybe<Scalars["Int"]>;
  retailAdId: Maybe<Scalars["UUID"]>;
  taxAdvantageSource: Maybe<TaxAdvantageSource>;
  taxAdvantageType: Maybe<ExportTaxAdvantageType>;
  warning: Maybe<Scalars["String"]>;
  warningLevel: Maybe<Scalars["String"]>;
}

export interface RetailAdTaxAdvantageUpdateProjectionInput {
  actualAmountMinorUnits?: InputMaybe<Scalars["Int"]>;
  costAmountMinorUnits?: InputMaybe<Scalars["Int"]>;
  warning?: InputMaybe<Scalars["String"]>;
  warningLevel?: InputMaybe<Scalars["String"]>;
}

export interface RetailAdUpdateProjectionInput {
  auto1BuyPrice?: InputMaybe<Scalars["Int"]>;
  auto1Status?: InputMaybe<Scalars["Int"]>;
  contractDetails?: InputMaybe<Scalars["String"]>;
  grossPriceMinorUnits?: InputMaybe<Scalars["Long"]>;
  isTest?: InputMaybe<Scalars["Boolean"]>;
  netPriceMinorUnits?: InputMaybe<Scalars["Long"]>;
  retailCountry?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
  stockNumber?: InputMaybe<Scalars["String"]>;
  vatAmountMinorUnits?: InputMaybe<Scalars["Long"]>;
  vatType?: InputMaybe<Scalars["Int"]>;
}

export interface RetailAddressCreateProjectionInput {
  city?: InputMaybe<Scalars["String"]>;
  companyName?: InputMaybe<Scalars["String"]>;
  contact?: InputMaybe<Scalars["String"]>;
  contactName?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  hasPrivateParking?: InputMaybe<Scalars["Boolean"]>;
  houseNumber?: InputMaybe<Scalars["String"]>;
  houseNumberAdditive?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  latitude?: InputMaybe<Scalars["Float"]>;
  longitude?: InputMaybe<Scalars["Float"]>;
  phone?: InputMaybe<Scalars["String"]>;
  street?: InputMaybe<Scalars["String"]>;
  zipcode?: InputMaybe<Scalars["String"]>;
}

export interface RetailAddressDto {
  __typename?: "RetailAddressDto";
  city: Maybe<Scalars["String"]>;
  companyName: Maybe<Scalars["String"]>;
  contact: Maybe<Scalars["String"]>;
  contactName: Maybe<Scalars["String"]>;
  country: Maybe<Scalars["String"]>;
  createdOn: Maybe<Scalars["String"]>;
  firstName: Maybe<Scalars["String"]>;
  hasPrivateParking: Maybe<Scalars["Boolean"]>;
  houseNumber: Maybe<Scalars["String"]>;
  houseNumberAdditive: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  lastName: Maybe<Scalars["String"]>;
  latitude: Maybe<Scalars["Float"]>;
  longitude: Maybe<Scalars["Float"]>;
  phone: Maybe<Scalars["String"]>;
  street: Maybe<Scalars["String"]>;
  updatedOn: Maybe<Scalars["String"]>;
  zipcode: Maybe<Scalars["String"]>;
}

export interface RetailAddressProjection {
  __typename?: "RetailAddressProjection";
  city: Maybe<Scalars["String"]>;
  companyName: Maybe<Scalars["String"]>;
  contact: Maybe<Scalars["String"]>;
  contactName: Maybe<Scalars["String"]>;
  country: Maybe<Scalars["String"]>;
  createdOn: Maybe<Scalars["String"]>;
  firstName: Maybe<Scalars["String"]>;
  hasPrivateParking: Maybe<Scalars["Boolean"]>;
  houseNumber: Maybe<Scalars["String"]>;
  houseNumberAdditive: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  lastName: Maybe<Scalars["String"]>;
  latitude: Maybe<Scalars["Float"]>;
  longitude: Maybe<Scalars["Float"]>;
  phone: Maybe<Scalars["String"]>;
  street: Maybe<Scalars["String"]>;
  updatedOn: Maybe<Scalars["String"]>;
  zipcode: Maybe<Scalars["String"]>;
}

export interface RetailAddressUpdateProjectionInput {
  city?: InputMaybe<Scalars["String"]>;
  companyName?: InputMaybe<Scalars["String"]>;
  contact?: InputMaybe<Scalars["String"]>;
  contactName?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  hasPrivateParking?: InputMaybe<Scalars["Boolean"]>;
  houseNumber?: InputMaybe<Scalars["String"]>;
  houseNumberAdditive?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  latitude?: InputMaybe<Scalars["Float"]>;
  longitude?: InputMaybe<Scalars["Float"]>;
  phone?: InputMaybe<Scalars["String"]>;
  street?: InputMaybe<Scalars["String"]>;
  zipcode?: InputMaybe<Scalars["String"]>;
}

export interface RetailBackofficeNotificationProjection {
  __typename?: "RetailBackofficeNotificationProjection";
  content: Maybe<Scalars["String"]>;
  createdOn: Maybe<Scalars["String"]>;
  domain: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  isRead: Maybe<Scalars["Boolean"]>;
  type: Maybe<Scalars["String"]>;
  updatedOn: Maybe<Scalars["String"]>;
  userId: Maybe<Scalars["UUID"]>;
}

export interface RetailBaseProductDto {
  __typename?: "RetailBaseProductDTO";
  active: Maybe<Scalars["Boolean"]>;
  country: Maybe<Scalars["String"]>;
  createdBy: Maybe<Scalars["UUID"]>;
  createdOn: Maybe<Scalars["String"]>;
  currencyCode: Maybe<Scalars["String"]>;
  deactivatedOn: Maybe<Scalars["String"]>;
  defaulted: Maybe<Scalars["Boolean"]>;
  id: Maybe<Scalars["UUID"]>;
  internalReferenceName: Maybe<Scalars["String"]>;
  invoiceDescription: Maybe<Scalars["String"]>;
  invoiceName: Maybe<Scalars["String"]>;
  partnerName: Maybe<Scalars["String"]>;
  pnlCostPrice: Maybe<PriceDetailsDto>;
  price: Maybe<PriceDetailsDto>;
  productType: Maybe<Scalars["String"]>;
  publishedScope: Maybe<Scalars["String"]>;
  quantity: Maybe<Scalars["Int"]>;
  recommended: Maybe<Scalars["Boolean"]>;
  refId: Maybe<Scalars["String"]>;
  shippingRequired: Maybe<Scalars["Boolean"]>;
  subProductDetails: Maybe<RetailBaseProductDtoSubProductDetails>;
  updatedOn: Maybe<Scalars["String"]>;
}

export interface RetailBaseProductDtoSubProductDetails {
  __typename?: "RetailBaseProductDTOSubProductDetails";
  childProducts: Maybe<Array<Maybe<RetailBaseProductDto>>>;
  primaryProduct: Maybe<RetailBaseProductDto>;
}

export interface RetailBaseProductProjection {
  __typename?: "RetailBaseProductProjection";
  active: Scalars["Boolean"];
  country: Scalars["String"];
  createdBy: Maybe<Scalars["UUID"]>;
  createdOn: Maybe<Scalars["String"]>;
  currencyCode: Scalars["String"];
  deactivatedOn: Maybe<Scalars["String"]>;
  defaulted: Scalars["Boolean"];
  id: Scalars["UUID"];
  internalReferenceName: Maybe<Scalars["String"]>;
  invoiceDescription: Scalars["String"];
  invoiceName: Maybe<Scalars["String"]>;
  partnerName: Scalars["String"];
  pnlCostPrice: Maybe<PriceDetailsProjection>;
  price: PriceDetailsProjection;
  priceDetails: Maybe<Array<Maybe<PriceDetailsProjection>>>;
  productType: Scalars["String"];
  publishedScope: PublishedScopeType;
  quantity: Scalars["Int"];
  recommended: Maybe<Scalars["Boolean"]>;
  refId: Scalars["String"];
  shippingRequired: Scalars["Boolean"];
  subProductDetails: Maybe<RetailSubProductDetailsProjection>;
  updatedOn: Maybe<Scalars["String"]>;
}

export interface RetailBatchDto_UuidInput {
  entities?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
}

export interface RetailBatchPriceUpdateValidationSummaryDto {
  __typename?: "RetailBatchPriceUpdateValidationSummaryDTO";
  processableEntityCount: Maybe<Scalars["Int"]>;
  processableErrorlessEntityCount: Maybe<Scalars["Int"]>;
}

export interface RetailCarRegistrationCreateProjectionInput {
  active: Scalars["Boolean"];
  country: Scalars["String"];
  currencyCode: Scalars["String"];
  defaulted: Scalars["Boolean"];
  grossPriceMinorUnits: Scalars["Int"];
  internalReferenceName?: InputMaybe<Scalars["String"]>;
  invoiceDescription: Scalars["String"];
  invoiceName?: InputMaybe<Scalars["String"]>;
  partnerName: Scalars["String"];
  pnlCostGrossPriceMinorUnits?: InputMaybe<Scalars["Int"]>;
  pnlCostVatKey?: InputMaybe<VatKeyType>;
  pnlCostVatPercentage?: InputMaybe<Scalars["Float"]>;
  publishedScope: PublishedScopeType;
  quantity: Scalars["Int"];
  recommended?: InputMaybe<Scalars["Boolean"]>;
  refId: Scalars["String"];
  registrationInAnyCountry?: InputMaybe<Scalars["Boolean"]>;
  registrationInRetailCountry?: InputMaybe<Scalars["Boolean"]>;
  registrationType: CarRegistrationType;
  shippingRequired: Scalars["Boolean"];
  vatKey: VatKeyType;
  vatPercentage: Scalars["Float"];
}

export interface RetailCarRegistrationProjection {
  __typename?: "RetailCarRegistrationProjection";
  active: Scalars["Boolean"];
  country: Scalars["String"];
  createdBy: Maybe<Scalars["UUID"]>;
  createdOn: Maybe<Scalars["String"]>;
  currencyCode: Scalars["String"];
  deactivatedOn: Maybe<Scalars["String"]>;
  defaulted: Scalars["Boolean"];
  id: Scalars["UUID"];
  internalReferenceName: Maybe<Scalars["String"]>;
  invoiceDescription: Scalars["String"];
  invoiceName: Maybe<Scalars["String"]>;
  partnerName: Scalars["String"];
  pnlCostPrice: Maybe<PriceDetailsProjection>;
  price: PriceDetailsProjection;
  priceDetails: Maybe<Array<Maybe<PriceDetailsProjection>>>;
  productType: Scalars["String"];
  publishedScope: PublishedScopeType;
  quantity: Scalars["Int"];
  recommended: Maybe<Scalars["Boolean"]>;
  refId: Scalars["String"];
  registrationInAnyCountry: Maybe<Scalars["Boolean"]>;
  registrationInRetailCountry: Maybe<Scalars["Boolean"]>;
  registrationType: CarRegistrationType;
  shippingRequired: Scalars["Boolean"];
  subProductDetails: Maybe<RetailSubProductDetailsProjection>;
  updatedOn: Maybe<Scalars["String"]>;
}

export interface RetailCarRegistrationSearchProjectionInput {
  adId?: InputMaybe<Scalars["UUID"]>;
  country?: InputMaybe<Scalars["String"]>;
  customerType?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<Scalars["Map_String_ObjectScalar"]>;
  onlyAvailable?: InputMaybe<Scalars["Boolean"]>;
  orderId?: InputMaybe<Scalars["UUID"]>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  priceModifiers?: InputMaybe<Scalars["Map_String_PriceModifierPayloadScalar"]>;
  registrationCountry?: InputMaybe<Scalars["String"]>;
  sorts?: InputMaybe<Array<InputMaybe<SortOrderProjectionInput>>>;
  zipCode?: InputMaybe<Scalars["String"]>;
}

export interface RetailCarRegistrationUpdateProjectionInput {
  active?: InputMaybe<Scalars["Boolean"]>;
  country?: InputMaybe<Scalars["String"]>;
  defaulted?: InputMaybe<Scalars["Boolean"]>;
  grossPriceMinorUnits?: InputMaybe<Scalars["Long"]>;
  internalReferenceName?: InputMaybe<Scalars["String"]>;
  invoiceDescription?: InputMaybe<Scalars["String"]>;
  invoiceName?: InputMaybe<Scalars["String"]>;
  partnerName?: InputMaybe<Scalars["String"]>;
  pnlCostGrossPriceMinorUnits?: InputMaybe<Scalars["Long"]>;
  pnlCostVatKey?: InputMaybe<VatKeyType>;
  pnlCostVatPercentage?: InputMaybe<Scalars["Float"]>;
  publishedScope?: InputMaybe<PublishedScopeType>;
  quantity?: InputMaybe<Scalars["Int"]>;
  recommended?: InputMaybe<Scalars["Boolean"]>;
  registrationInAnyCountry?: InputMaybe<Scalars["Boolean"]>;
  registrationInRetailCountry?: InputMaybe<Scalars["Boolean"]>;
  registrationType?: InputMaybe<CarRegistrationType>;
  shippingRequired?: InputMaybe<Scalars["Boolean"]>;
  vatKey?: InputMaybe<VatKeyType>;
  vatPercentage?: InputMaybe<Scalars["Float"]>;
}

export interface RetailCheckoutAllStepTypesProjection {
  __typename?: "RetailCheckoutAllStepTypesProjection";
  steps: Maybe<Array<Maybe<RetailCheckoutStepTypeProjection>>>;
}

export interface RetailCheckoutCreateProjectionInput {
  completedSteps?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  orderId?: InputMaybe<Scalars["UUID"]>;
  source?: InputMaybe<CheckoutSource>;
}

export interface RetailCheckoutProjection {
  __typename?: "RetailCheckoutProjection";
  completedSteps: Maybe<Array<Maybe<RetailCheckoutStepProjection>>>;
  id: Maybe<Scalars["UUID"]>;
  orderId: Maybe<Scalars["UUID"]>;
  source: Maybe<CheckoutSource>;
}

export interface RetailCheckoutStepProjection {
  __typename?: "RetailCheckoutStepProjection";
  step: Maybe<Scalars["String"]>;
}

export interface RetailCheckoutStepTypeProjection {
  __typename?: "RetailCheckoutStepTypeProjection";
  stepName: Maybe<Scalars["String"]>;
  viewName: Maybe<Scalars["String"]>;
}

export interface RetailCheckoutUpdateProjectionInput {
  completedSteps?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  source?: InputMaybe<CheckoutSource>;
}

export interface RetailClaimAllowedStatesProjection {
  __typename?: "RetailClaimAllowedStatesProjection";
  allowedNextStates: Maybe<Array<Maybe<Scalars["String"]>>>;
  currentState: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
}

export interface RetailClaimAreaProjection {
  __typename?: "RetailClaimAreaProjection";
  carParts: Maybe<Array<Maybe<RetailClaimCarPartProjection>>>;
  deleted: Maybe<Scalars["Boolean"]>;
  id: Maybe<Scalars["UUID"]>;
  name: Maybe<Scalars["String"]>;
}

export interface RetailClaimAssigneeDto {
  __typename?: "RetailClaimAssigneeDTO";
  country: Maybe<Scalars["String"]>;
  email: Maybe<Scalars["String"]>;
  firstName: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  lastName: Maybe<Scalars["String"]>;
}

export interface RetailClaimCarPartProjection {
  __typename?: "RetailClaimCarPartProjection";
  damages: Maybe<Array<Maybe<RetailClaimDamageProjection>>>;
  deleted: Maybe<Scalars["Boolean"]>;
  id: Maybe<Scalars["UUID"]>;
  name: Maybe<Scalars["String"]>;
}

export interface RetailClaimComplaintCommentProjection {
  __typename?: "RetailClaimComplaintCommentProjection";
  comment: Maybe<Scalars["String"]>;
  complaintId: Maybe<Scalars["UUID"]>;
  createdBy: Maybe<UserProjection>;
  createdOn: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
}

export interface RetailClaimComplaintDocumentProjection {
  __typename?: "RetailClaimComplaintDocumentProjection";
  complaintId: Maybe<Scalars["UUID"]>;
  createdBy: Maybe<UserProjection>;
  createdOn: Maybe<Scalars["String"]>;
  fileName: Maybe<Scalars["String"]>;
  fileSizeKb: Maybe<Scalars["Int"]>;
  fullUrl: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  type: Maybe<Scalars["String"]>;
}

export interface RetailClaimComplaintProjection {
  __typename?: "RetailClaimComplaintProjection";
  area: Maybe<Scalars["String"]>;
  claimId: Maybe<Scalars["UUID"]>;
  comments: Maybe<Array<Maybe<RetailClaimComplaintCommentProjection>>>;
  complaintArea: Maybe<Scalars["String"]>;
  complaintDamage: Maybe<Scalars["String"]>;
  complaintNumber: Maybe<Scalars["Int"]>;
  complaintPart: Maybe<Scalars["String"]>;
  createdBy: Maybe<UserProjection>;
  createdOn: Maybe<Scalars["String"]>;
  damage: Maybe<Scalars["String"]>;
  damageSeverity: Maybe<Scalars["String"]>;
  documents: Maybe<Array<Maybe<RetailClaimComplaintDocumentProjection>>>;
  id: Maybe<Scalars["UUID"]>;
  namingVersion: Maybe<Scalars["Int"]>;
}

export interface RetailClaimCostCommentCreateProjectionInput {
  approvalLevel1Comment?: InputMaybe<Scalars["String"]>;
  approvalLevel2Comment?: InputMaybe<Scalars["String"]>;
  approvalLevel3Comment?: InputMaybe<Scalars["String"]>;
  customerDecisionComment?: InputMaybe<Scalars["String"]>;
}

export interface RetailClaimCostCommentProjection {
  __typename?: "RetailClaimCostCommentProjection";
  approvalLevel1Comment: Maybe<Scalars["String"]>;
  approvalLevel2Comment: Maybe<Scalars["String"]>;
  approvalLevel3Comment: Maybe<Scalars["String"]>;
  claimCostId: Maybe<Scalars["UUID"]>;
  createdBy: Maybe<UserProjection>;
  createdOn: Maybe<Scalars["String"]>;
  customerDecisionComment: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  updatedOn: Maybe<Scalars["String"]>;
}

export interface RetailClaimCostCreateProjectionInput {
  cost?: InputMaybe<Scalars["Long"]>;
  costType?: InputMaybe<Scalars["String"]>;
  orderItemId?: InputMaybe<Scalars["UUID"]>;
  warranty?: InputMaybe<Scalars["String"]>;
}

export interface RetailClaimCostPaymentProjection {
  __typename?: "RetailClaimCostPaymentProjection";
  claimCostId: Maybe<Scalars["UUID"]>;
  createdBy: Maybe<UserProjection>;
  createdOn: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  paymentId: Maybe<Scalars["UUID"]>;
  paymentStatus: Maybe<Scalars["String"]>;
  paymentType: Maybe<Scalars["String"]>;
  pnlId: Maybe<Scalars["UUID"]>;
  updatedOn: Maybe<Scalars["String"]>;
}

export interface RetailClaimCostProjection {
  __typename?: "RetailClaimCostProjection";
  approvalLevel1: Maybe<Scalars["String"]>;
  approvalLevel1SetBy: Maybe<Scalars["UUID"]>;
  approvalLevel1SetByProjection: Maybe<UserProjection>;
  approvalLevel1SetOn: Maybe<Scalars["String"]>;
  approvalLevel2: Maybe<Scalars["String"]>;
  approvalLevel2SetBy: Maybe<Scalars["UUID"]>;
  approvalLevel2SetByProjection: Maybe<UserProjection>;
  approvalLevel2SetOn: Maybe<Scalars["String"]>;
  approvalLevel3: Maybe<Scalars["String"]>;
  approvalLevel3SetBy: Maybe<Scalars["UUID"]>;
  approvalLevel3SetByProjection: Maybe<UserProjection>;
  approvalLevel3SetOn: Maybe<Scalars["String"]>;
  claimId: Maybe<Scalars["UUID"]>;
  comments: Maybe<Array<Maybe<RetailClaimCostCommentProjection>>>;
  costCurrencyCode: Maybe<Scalars["String"]>;
  costMinorUnits: Maybe<Scalars["Long"]>;
  costType: Maybe<Scalars["String"]>;
  costTypeCategory: Maybe<Scalars["String"]>;
  createdBy: Maybe<UserProjection>;
  createdOn: Maybe<Scalars["String"]>;
  customerDecision: Maybe<Scalars["String"]>;
  customerDecisionSetBy: Maybe<Scalars["UUID"]>;
  customerDecisionSetByProjection: Maybe<UserProjection>;
  customerDecisionSetOn: Maybe<Scalars["String"]>;
  editState: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  number: Maybe<Scalars["String"]>;
  orderItemId: Maybe<Scalars["UUID"]>;
  partialRefund: Maybe<ClaimPartialRefundProjection>;
  payment: Maybe<RetailClaimCostPaymentProjection>;
  status: Maybe<Scalars["String"]>;
  updatedOn: Maybe<Scalars["String"]>;
  warrantyCoverageType: Maybe<Scalars["String"]>;
}

export interface RetailClaimCostUpdateProjectionInput {
  cost?: InputMaybe<Scalars["Long"]>;
  costType?: InputMaybe<Scalars["String"]>;
  orderItemId?: InputMaybe<Scalars["UUID"]>;
  warranty?: InputMaybe<Scalars["String"]>;
}

export interface RetailClaimCreateProjectionV2Input {
  assignTo?: InputMaybe<Scalars["UUID"]>;
  claimGroupId?: InputMaybe<Scalars["UUID"]>;
  subClaim?: InputMaybe<RetailSubClaimCreateProjectionInput>;
}

export interface RetailClaimDamageProjection {
  __typename?: "RetailClaimDamageProjection";
  deleted: Scalars["Boolean"];
  id: Maybe<Scalars["UUID"]>;
  name: Maybe<Scalars["String"]>;
}

export interface RetailClaimGroupProjection {
  __typename?: "RetailClaimGroupProjection";
  areas: Maybe<Array<Maybe<RetailClaimAreaProjection>>>;
  id: Maybe<Scalars["UUID"]>;
  labels: Maybe<Array<Maybe<Scalars["String"]>>>;
  liableParties: Maybe<Array<Maybe<RetailClaimLiablePartyProjection>>>;
  name: Maybe<Scalars["String"]>;
}

export interface RetailClaimLiablePartyProjection {
  __typename?: "RetailClaimLiablePartyProjection";
  name: Maybe<Scalars["String"]>;
  value: Maybe<Scalars["String"]>;
}

export interface RetailClaimProjection {
  __typename?: "RetailClaimProjection";
  ad: Maybe<AdProjection>;
  assignTo: Maybe<Scalars["UUID"]>;
  claimGroupId: Maybe<Scalars["UUID"]>;
  closedDescription: Maybe<Scalars["String"]>;
  closedOn: Maybe<Scalars["String"]>;
  closedReason: Maybe<Scalars["String"]>;
  compensationType: Maybe<Scalars["String"]>;
  complaints: Maybe<Array<Maybe<RetailClaimComplaintProjection>>>;
  completedOn: Maybe<Scalars["String"]>;
  costs: Maybe<Array<Maybe<RetailClaimCostProjection>>>;
  country: Maybe<Scalars["String"]>;
  createdBy: Maybe<UserProjection>;
  createdOn: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  number: Maybe<Scalars["String"]>;
  order: Maybe<OrderProjection>;
  proofRequests: Maybe<Array<Maybe<RetailClaimProofRequestProjection>>>;
  sourceType: Maybe<Scalars["String"]>;
  state: Maybe<Scalars["String"]>;
  stateComment: Maybe<Scalars["String"]>;
  stateDueDate: Maybe<Scalars["String"]>;
  subClaims: Maybe<Array<Maybe<RetailSubClaimProjection>>>;
  updatedOn: Maybe<Scalars["String"]>;
  workshopDetails: Maybe<RetailClaimWorkshopDetailsProjection>;
}

export interface RetailClaimProofRequestProjection {
  __typename?: "RetailClaimProofRequestProjection";
  claimId: Maybe<Scalars["UUID"]>;
  comment: Maybe<Scalars["String"]>;
  complaintId: Maybe<Scalars["UUID"]>;
  createdBy: Maybe<UserProjection>;
  createdOn: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  state: Maybe<Scalars["String"]>;
  type: Maybe<Scalars["String"]>;
}

export interface RetailClaimStateDueDaysConfigBatchUpdateProjectionInput {
  configUpdates?: InputMaybe<
    Array<InputMaybe<RetailClaimStateDueDaysConfigUpdateProjectionInput>>
  >;
}

export interface RetailClaimStateDueDaysConfigProjection {
  __typename?: "RetailClaimStateDueDaysConfigProjection";
  claimState: Maybe<Scalars["String"]>;
  createdBy: Maybe<UserProjection>;
  createdOn: Maybe<Scalars["String"]>;
  dueDays: Maybe<Scalars["Int"]>;
  id: Maybe<Scalars["UUID"]>;
  updatedBy: Maybe<UserProjection>;
  updatedOn: Maybe<Scalars["String"]>;
}

export interface RetailClaimStateDueDaysConfigUpdateProjectionInput {
  dueDays?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["UUID"]>;
}

export interface RetailClaimUpdateGroupProjectionInput {
  claimGroupId?: InputMaybe<Scalars["UUID"]>;
}

export interface RetailClaimUpdateStateProjectionInput {
  closedReason?: InputMaybe<ClosedReasonProjectionInput>;
  state?: InputMaybe<Scalars["String"]>;
}

export interface RetailClaimUpdateUserAssignProjectionInput {
  assignTo?: InputMaybe<Scalars["UUID"]>;
}

export interface RetailClaimWorkshopDetailsCreateProjectionInput {
  address?: InputMaybe<Scalars["String"]>;
  carPickedUp?: InputMaybe<Scalars["Boolean"]>;
  carPickedUpOn?: InputMaybe<Scalars["String"]>;
}

export interface RetailClaimWorkshopDetailsProjection {
  __typename?: "RetailClaimWorkshopDetailsProjection";
  address: Maybe<Scalars["String"]>;
  carPickedUp: Maybe<Scalars["Boolean"]>;
  carPickedUpOn: Maybe<Scalars["String"]>;
  createdBy: Maybe<UserProjection>;
  createdOn: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  updatedOn: Maybe<Scalars["String"]>;
}

export interface RetailClaimWorkshopDetailsUpdateProjectionInput {
  address?: InputMaybe<Scalars["String"]>;
  carPickedUp?: InputMaybe<Scalars["Boolean"]>;
  carPickedUpOn?: InputMaybe<Scalars["String"]>;
}

export interface RetailCreateDocumentProjectionInput {
  retailDocumentCreateDTO?: InputMaybe<RetailDocumentCreateDtoInput>;
}

export interface RetailCreditScoreDataProjection {
  __typename?: "RetailCreditScoreDataProjection";
  calculatedOn: Maybe<Scalars["String"]>;
  createdBy: Maybe<Scalars["UUID"]>;
  creditRiskRate: Maybe<Scalars["Float"]>;
  creditScore: Maybe<Scalars["Int"]>;
  creditScoreProviderType: Maybe<Scalars["String"]>;
  creditScoreRange: Maybe<Scalars["String"]>;
  monthlyNetIncomeMinorUnits: Maybe<Scalars["Long"]>;
}

export interface RetailCreditScoreDataProjectionV2 {
  __typename?: "RetailCreditScoreDataProjectionV2";
  calculatedOn: Maybe<Scalars["String"]>;
  createdBy: Maybe<Scalars["UUID"]>;
  creditRiskRate: Maybe<Scalars["Float"]>;
  creditScore: Maybe<Scalars["Int"]>;
  creditScoreProviderType: Maybe<Scalars["String"]>;
  creditScoreRange: Maybe<Scalars["String"]>;
  monthlyAvgNetIncomeMinorUnits: Maybe<Scalars["Long"]>;
  monthlyNetIncomePerMonth: Maybe<Array<Maybe<MonthlyNetIncomeProjection>>>;
}

export interface RetailCustomerCreateProjectionInput {
  cityOfBirth?: InputMaybe<Scalars["String"]>;
  customerId?: InputMaybe<Scalars["String"]>;
  customerType?: InputMaybe<Scalars["String"]>;
  dateOfBirth?: InputMaybe<Scalars["String"]>;
  departmentOfBirth?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  locale?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  number?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  relation?: InputMaybe<Scalars["String"]>;
  salutation?: InputMaybe<Scalars["String"]>;
  ssn?: InputMaybe<Scalars["String"]>;
  taxCode?: InputMaybe<Scalars["String"]>;
  taxCodeValidationState?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
  vatId?: InputMaybe<Scalars["String"]>;
}

export interface RetailCustomerExpectedEtaDto {
  __typename?: "RetailCustomerExpectedEtaDTO";
  customerExpectedMaxEta: Maybe<Scalars["String"]>;
  deliveryEtaRange: Maybe<DeliveryEtaRangeDto>;
  deliveryEtaType: Maybe<Scalars["String"]>;
  estimationId: Maybe<Scalars["UUID"]>;
  logisticsEtaInCalendarDays: Maybe<Scalars["Int"]>;
}

export interface RetailCustomerProjection {
  __typename?: "RetailCustomerProjection";
  cityOfBirth: Maybe<Scalars["String"]>;
  created: Maybe<Scalars["String"]>;
  customerId: Maybe<Scalars["String"]>;
  customerType: Maybe<Scalars["String"]>;
  dateOfBirth: Maybe<Scalars["String"]>;
  departmentOfBirth: Maybe<Scalars["String"]>;
  email: Maybe<Scalars["String"]>;
  firstName: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  lastName: Maybe<Scalars["String"]>;
  locale: Maybe<Scalars["String"]>;
  name: Maybe<Scalars["String"]>;
  number: Maybe<Scalars["String"]>;
  orderId: Maybe<Scalars["UUID"]>;
  phone: Maybe<Scalars["String"]>;
  relation: Maybe<Scalars["String"]>;
  salutation: Maybe<Scalars["String"]>;
  ssn: Maybe<Scalars["String"]>;
  taxCode: Maybe<Scalars["String"]>;
  taxCodeValidationState: Maybe<Scalars["String"]>;
  type: Maybe<Scalars["String"]>;
  updated: Maybe<Scalars["String"]>;
  vatId: Maybe<Scalars["String"]>;
  verification: Maybe<RetailCustomerVerificationProjection>;
}

export interface RetailCustomerUpdateProjectionInput {
  cityOfBirth?: InputMaybe<Scalars["String"]>;
  customerId?: InputMaybe<Scalars["String"]>;
  customerType?: InputMaybe<Scalars["String"]>;
  dateOfBirth?: InputMaybe<Scalars["String"]>;
  departmentOfBirth?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  locale?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  relation?: InputMaybe<Scalars["String"]>;
  salutation?: InputMaybe<Scalars["String"]>;
  ssn?: InputMaybe<Scalars["String"]>;
  taxCode?: InputMaybe<Scalars["String"]>;
  taxCodeValidationState?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
  vatId?: InputMaybe<Scalars["String"]>;
}

export interface RetailCustomerVerificationProjection {
  __typename?: "RetailCustomerVerificationProjection";
  customerId: Maybe<Scalars["UUID"]>;
  id: Maybe<Scalars["UUID"]>;
  orderId: Maybe<Scalars["UUID"]>;
  reusesVerificationFromOrderId: Maybe<Scalars["UUID"]>;
  reusesVerificationFromOrderNumber: Maybe<Scalars["String"]>;
  status: Maybe<Scalars["String"]>;
  verificationSteps: Maybe<
    Array<Maybe<RetailCustomerVerificationStepProjection>>
  >;
}

export interface RetailCustomerVerificationStepProjection {
  __typename?: "RetailCustomerVerificationStepProjection";
  reuseType: Maybe<Scalars["String"]>;
  status: Maybe<Scalars["String"]>;
  type: Maybe<Scalars["String"]>;
}

export interface RetailCustomersByOrderProjection {
  __typename?: "RetailCustomersByOrderProjection";
  customers: Maybe<Array<Maybe<RetailCustomerProjection>>>;
  orderId: Maybe<Scalars["UUID"]>;
}

export interface RetailDataImportConfigDto {
  __typename?: "RetailDataImportConfigDTO";
  config: Maybe<Scalars["String"]>;
  description: Maybe<Scalars["String"]>;
  key: Maybe<Scalars["String"]>;
}

export interface RetailDataImportConfigUpdateProjectionInput {
  config?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  key?: InputMaybe<Scalars["String"]>;
}

export interface RetailDataImportDto {
  __typename?: "RetailDataImportDTO";
  createdBy: Maybe<Scalars["UUID"]>;
  createdOn: Maybe<Scalars["String"]>;
  creationSource: Maybe<Scalars["String"]>;
  dataImportType: Maybe<RetailDataImportType>;
  errorRows: Maybe<Scalars["Int"]>;
  errorsResourceLocation: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  provider: Maybe<RetailDataImportProvider>;
  resourceLocation: Maybe<Scalars["String"]>;
  state: Maybe<RetailDataImportState>;
  successRows: Maybe<Scalars["Int"]>;
  totalRows: Maybe<Scalars["Int"]>;
  updatedOn: Maybe<Scalars["String"]>;
}

export interface RetailDataImportProjection {
  __typename?: "RetailDataImportProjection";
  createdBy: Maybe<RetailUserDto>;
  createdOn: Maybe<Scalars["String"]>;
  creationSource: Maybe<Scalars["String"]>;
  errorRows: Maybe<Scalars["Int"]>;
  errorsResourceLocation: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  provider: Maybe<RetailDataImportProvider>;
  resourceLocation: Maybe<Scalars["String"]>;
  state: Maybe<RetailDataImportState>;
  successRows: Maybe<Scalars["Int"]>;
  totalRows: Maybe<Scalars["Int"]>;
  updatedOn: Maybe<Scalars["String"]>;
}

export type RetailDataImportProvider = "AWS_S3";

export type RetailDataImportState =
  | "COMPLETED"
  | "CREATED"
  | "FAILED"
  | "IN_PROGRESS"
  | "VALIDATED"
  | "VALIDATING";

export type RetailDataImportType =
  | "BULK_PRICE_UPDATE"
  | "CAR_REGISTRATION_ACTION"
  | "CHATBOT_QUESTION"
  | "DAD_CAR_REGISTRATION"
  | "INVENTORY_SOURCING"
  | "PNL"
  | "RETAIL_DOCUMENT_INVENTORY"
  | "RETAIL_DOCUMENT_PACKAGE"
  | "RETAIL_OFFLINE_LEADS_NEWSLETTER_SUBSCRIPTION"
  | "RETAIL_PAYMENT"
  | "RETURN_TO_AUTO1";

export interface RetailDebtPriceDto {
  __typename?: "RetailDebtPriceDTO";
  amountMinorUnits: Maybe<Scalars["Long"]>;
  currencyCode: Maybe<Scalars["String"]>;
}

export interface RetailDeliveryRouteItemDto {
  __typename?: "RetailDeliveryRouteItemDTO";
  city: Maybe<Scalars["String"]>;
  completedOn: Maybe<Scalars["String"]>;
  itemType: Maybe<RetailDeliveryRouteItemType>;
  state: Maybe<RetailDeliveryRouteState>;
}

export type RetailDeliveryRouteItemType =
  | "DELIVERY_TO_LOGISTICS_CENTER"
  | "DELIVERY_TO_TARGET_BRANCH"
  | "PICKUP_APPOINTMENT_SCHEDULING"
  | "PREPARATION_FOR_SHIPMENT"
  | "SHIPMENT";

export type RetailDeliveryRouteState = "COMPLETED" | "NOT_STARTED" | "STARTED";

export interface RetailDigitalCarRegistrationAssigneeDto {
  __typename?: "RetailDigitalCarRegistrationAssigneeDTO";
  firstName: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  lastName: Maybe<Scalars["String"]>;
}

export interface RetailDisputeProjection {
  __typename?: "RetailDisputeProjection";
  amount: Maybe<RetailFinancePriceProjection>;
  disputeReason: Maybe<Scalars["String"]>;
  disputeStatus: Maybe<Scalars["String"]>;
  eventDate: Maybe<Scalars["String"]>;
  eventSuccess: Maybe<Scalars["Boolean"]>;
}

export interface RetailDisputeProjectionInput {
  amount?: InputMaybe<RetailFinancePriceProjectionInput>;
  disputeReason?: InputMaybe<Scalars["String"]>;
  disputeStatus?: InputMaybe<Scalars["String"]>;
  eventDate?: InputMaybe<Scalars["String"]>;
  eventSuccess?: InputMaybe<Scalars["Boolean"]>;
}

export interface RetailDocumentCategoryDto {
  __typename?: "RetailDocumentCategoryDTO";
  id: Maybe<Scalars["UUID"]>;
  name: Maybe<Scalars["String"]>;
  topCategory: Maybe<Scalars["String"]>;
}

export interface RetailDocumentCategoryProjection {
  __typename?: "RetailDocumentCategoryProjection";
  documentTypes: Maybe<Array<Maybe<RetailDocumentTypeProjection>>>;
  name: Maybe<Scalars["String"]>;
}

export interface RetailDocumentCreateDtoInput {
  attachedTo?: InputMaybe<Scalars["UUID"]>;
  description?: InputMaybe<Scalars["String"]>;
  documentTypeId?: InputMaybe<Scalars["UUID"]>;
  documentTypeKey?: InputMaybe<Scalars["String"]>;
  documentUploadSourceType?: InputMaybe<Scalars["String"]>;
  fileName?: InputMaybe<Scalars["String"]>;
  fullUrl?: InputMaybe<Scalars["String"]>;
}

export interface RetailDocumentGeneratePdfResponseProjection {
  __typename?: "RetailDocumentGeneratePdfResponseProjection";
  presignedUrl: Maybe<Scalars["String"]>;
}

export interface RetailDocumentInventoryImportCreateProjectionInput {
  fileName?: InputMaybe<Scalars["String"]>;
}

export interface RetailDocumentPackageUpdateImportProjectionInput {
  fileName: Scalars["String"];
}

export interface RetailDocumentProjection {
  __typename?: "RetailDocumentProjection";
  attachedTo: Maybe<Scalars["UUID"]>;
  createdBy: Maybe<UserProjection>;
  createdOn: Maybe<Scalars["String"]>;
  deletedBy: Maybe<Scalars["UUID"]>;
  deletedByUser: Maybe<UserProjection>;
  deletedOn: Maybe<Scalars["String"]>;
  description: Maybe<Scalars["String"]>;
  documentType: Maybe<RetailDocumentTypeDto>;
  documentUploadSourceType: Maybe<Scalars["String"]>;
  fileName: Maybe<Scalars["String"]>;
  fullUrl: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  notificationId: Maybe<Scalars["UUID"]>;
  signatureExternalId: Maybe<Scalars["UUID"]>;
  signatureProvider: Maybe<Scalars["String"]>;
  signed: Maybe<Scalars["Boolean"]>;
  signedBy: Maybe<Scalars["UUID"]>;
  signedOn: Maybe<Scalars["String"]>;
  state: Maybe<Scalars["String"]>;
  verified: Maybe<Scalars["Boolean"]>;
  verifiedBy: Maybe<Scalars["UUID"]>;
  verifiedByUser: Maybe<UserProjection>;
  verifiedOn: Maybe<Scalars["String"]>;
}

export interface RetailDocumentTopCategoryProjection {
  __typename?: "RetailDocumentTopCategoryProjection";
  categories: Maybe<Array<Maybe<RetailDocumentCategoryProjection>>>;
  topCategory: Maybe<Scalars["String"]>;
}

export interface RetailDocumentTypeDto {
  __typename?: "RetailDocumentTypeDTO";
  description: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  key: Maybe<Scalars["String"]>;
  name: Maybe<Scalars["String"]>;
  retailDocumentCategoryDTO: Maybe<RetailDocumentCategoryDto>;
}

export interface RetailDocumentTypeProjection {
  __typename?: "RetailDocumentTypeProjection";
  description: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  key: Maybe<Scalars["String"]>;
  name: Maybe<Scalars["String"]>;
}

export interface RetailDocumentUpdateProjectionInput {
  description?: InputMaybe<Scalars["String"]>;
  documentUploadSourceType?: InputMaybe<Scalars["String"]>;
  fileName?: InputMaybe<Scalars["String"]>;
  fullUrl?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
  verified?: InputMaybe<Scalars["Boolean"]>;
}

export interface RetailDownPaymentCreateProjectionInput {
  active: Scalars["Boolean"];
  country: Scalars["String"];
  currencyCode: Scalars["String"];
  defaulted: Scalars["Boolean"];
  grossPriceMinorUnits: Scalars["Int"];
  internalReferenceName?: InputMaybe<Scalars["String"]>;
  invoiceDescription: Scalars["String"];
  invoiceName?: InputMaybe<Scalars["String"]>;
  partnerName: Scalars["String"];
  pnlCostGrossPriceMinorUnits?: InputMaybe<Scalars["Int"]>;
  pnlCostVatKey?: InputMaybe<VatKeyType>;
  pnlCostVatPercentage?: InputMaybe<Scalars["Float"]>;
  publishedScope: PublishedScopeType;
  quantity: Scalars["Int"];
  recommended?: InputMaybe<Scalars["Boolean"]>;
  refId: Scalars["String"];
  shippingRequired: Scalars["Boolean"];
  vatKey: VatKeyType;
  vatPercentage: Scalars["Float"];
}

export interface RetailExperianDataProjection {
  __typename?: "RetailExperianDataProjection";
  calculatedOn: Maybe<Scalars["String"]>;
  experianCreditScore: Maybe<Scalars["Int"]>;
  monthlyNetIncomeMinorUnits: Maybe<Scalars["Long"]>;
}

export interface RetailFeatureProjection {
  __typename?: "RetailFeatureProjection";
  condition: Maybe<Scalars["Map_String_ObjectScalar"]>;
  createdBy: Maybe<Scalars["UUID"]>;
  createdByUser: Maybe<RetailUserDto>;
  createdOn: Maybe<Scalars["String"]>;
  description: Maybe<Scalars["String"]>;
  enabled: Scalars["Boolean"];
  environment: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  name: Maybe<Scalars["String"]>;
  updatedBy: Maybe<Scalars["UUID"]>;
  updatedByUser: Maybe<RetailUserDto>;
  updatedOn: Maybe<Scalars["String"]>;
}

export interface RetailFeatureStatusContextDtoInput {
  country?: InputMaybe<Scalars["String"]>;
  environment?: InputMaybe<Scalars["String"]>;
  featureUsageDate?: InputMaybe<Scalars["String"]>;
  locale?: InputMaybe<Scalars["String"]>;
}

export interface RetailFeatureStatusDto {
  __typename?: "RetailFeatureStatusDTO";
  name: Maybe<Scalars["String"]>;
  value: Maybe<Scalars["String"]>;
}

export interface RetailFeatureStatusProjectionV2 {
  __typename?: "RetailFeatureStatusProjectionV2";
  enabled: Scalars["Boolean"];
  name: Maybe<Scalars["String"]>;
}

export interface RetailFeeCreateProjectionInput {
  active: Scalars["Boolean"];
  country: Scalars["String"];
  currencyCode: Scalars["String"];
  defaulted: Scalars["Boolean"];
  feeType?: InputMaybe<Scalars["String"]>;
  grossPriceMinorUnits: Scalars["Int"];
  internalReferenceName?: InputMaybe<Scalars["String"]>;
  invoiceDescription: Scalars["String"];
  invoiceName?: InputMaybe<Scalars["String"]>;
  partnerName: Scalars["String"];
  pnlCostGrossPriceMinorUnits?: InputMaybe<Scalars["Int"]>;
  pnlCostVatKey?: InputMaybe<VatKeyType>;
  pnlCostVatPercentage?: InputMaybe<Scalars["Float"]>;
  publishedScope: PublishedScopeType;
  quantity: Scalars["Int"];
  recommended?: InputMaybe<Scalars["Boolean"]>;
  refId: Scalars["String"];
  shippingRequired: Scalars["Boolean"];
  title?: InputMaybe<Scalars["String"]>;
  vatKey: VatKeyType;
  vatPercentage: Scalars["Float"];
}

export interface RetailFeeOptionsProjection {
  __typename?: "RetailFeeOptionsProjection";
  types: Maybe<Array<Maybe<Scalars["String"]>>>;
}

export interface RetailFeeOptionsRequestProjectionInput {
  country?: InputMaybe<Scalars["String"]>;
}

export interface RetailFeeProjection {
  __typename?: "RetailFeeProjection";
  active: Scalars["Boolean"];
  country: Scalars["String"];
  createdBy: Maybe<Scalars["UUID"]>;
  createdOn: Maybe<Scalars["String"]>;
  currencyCode: Scalars["String"];
  deactivatedOn: Maybe<Scalars["String"]>;
  defaulted: Scalars["Boolean"];
  feeType: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  internalReferenceName: Maybe<Scalars["String"]>;
  invoiceDescription: Scalars["String"];
  invoiceName: Maybe<Scalars["String"]>;
  partnerName: Scalars["String"];
  pnlCostPrice: Maybe<PriceDetailsProjection>;
  price: PriceDetailsProjection;
  priceDetails: Maybe<Array<Maybe<PriceDetailsProjection>>>;
  productType: Scalars["String"];
  publishedScope: PublishedScopeType;
  quantity: Scalars["Int"];
  recommended: Maybe<Scalars["Boolean"]>;
  refId: Scalars["String"];
  shippingRequired: Scalars["Boolean"];
  subProductDetails: Maybe<RetailSubProductDetailsProjection>;
  title: Maybe<Scalars["String"]>;
  updatedOn: Maybe<Scalars["String"]>;
}

export interface RetailFeeSearchProjectionInput {
  adId?: InputMaybe<Scalars["UUID"]>;
  country?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<Scalars["Map_String_ObjectScalar"]>;
  onlyAvailable?: InputMaybe<Scalars["Boolean"]>;
  orderId?: InputMaybe<Scalars["UUID"]>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  priceModifiers?: InputMaybe<Scalars["Map_String_PriceModifierPayloadScalar"]>;
  sorts?: InputMaybe<Array<InputMaybe<SortOrderProjectionInput>>>;
  zipCode?: InputMaybe<Scalars["String"]>;
}

export interface RetailFeeUpdateProjectionInput {
  active?: InputMaybe<Scalars["Boolean"]>;
  country?: InputMaybe<Scalars["String"]>;
  defaulted?: InputMaybe<Scalars["Boolean"]>;
  grossPriceMinorUnits?: InputMaybe<Scalars["Long"]>;
  internalReferenceName?: InputMaybe<Scalars["String"]>;
  invoiceDescription?: InputMaybe<Scalars["String"]>;
  invoiceName?: InputMaybe<Scalars["String"]>;
  partnerName?: InputMaybe<Scalars["String"]>;
  pnlCostGrossPriceMinorUnits?: InputMaybe<Scalars["Long"]>;
  pnlCostVatKey?: InputMaybe<VatKeyType>;
  pnlCostVatPercentage?: InputMaybe<Scalars["Float"]>;
  publishedScope?: InputMaybe<PublishedScopeType>;
  quantity?: InputMaybe<Scalars["Int"]>;
  recommended?: InputMaybe<Scalars["Boolean"]>;
  shippingRequired?: InputMaybe<Scalars["Boolean"]>;
  title?: InputMaybe<Scalars["String"]>;
  vatKey?: InputMaybe<VatKeyType>;
  vatPercentage?: InputMaybe<Scalars["Float"]>;
}

export interface RetailFinancePriceProjection {
  __typename?: "RetailFinancePriceProjection";
  amountMinorUnits: Maybe<Scalars["Long"]>;
  currencyCode: Maybe<Scalars["String"]>;
}

export interface RetailFinancePriceProjectionInput {
  amountMinorUnits?: InputMaybe<Scalars["Long"]>;
  currencyCode?: InputMaybe<Scalars["String"]>;
}

export interface RetailFinancingPlanBreakdownProjection {
  __typename?: "RetailFinancingPlanBreakdownProjection";
  balloonPayment: Maybe<Scalars["Long"]>;
  balloonPaymentNoRounding: Maybe<Scalars["Long"]>;
  installmentsSum: Maybe<Scalars["Long"]>;
  interestSum: Maybe<Scalars["Long"]>;
  monthlyPayments: Maybe<
    Array<Maybe<RetailFinancingPlanMonthlyPaymentProjection>>
  >;
  monthlyRate: Maybe<Scalars["Long"]>;
  totalRepayment: Maybe<Scalars["Long"]>;
}

export interface RetailFinancingPlanCalculationProjection {
  __typename?: "RetailFinancingPlanCalculationProjection";
  createdOn: Maybe<Scalars["String"]>;
  financingAmountMinorUnits: Maybe<Scalars["Long"]>;
  id: Maybe<Scalars["UUID"]>;
  isSelectedForRetailRiskCalculation: Maybe<Scalars["Boolean"]>;
  maturity: Maybe<Scalars["Int"]>;
  monthlyPaymentAmountMinorUnits: Maybe<Scalars["Long"]>;
  retailFinancingTiers: Maybe<Array<Maybe<RetailFinancingTierProjection>>>;
}

export interface RetailFinancingPlanMonthlyPaymentProjection {
  __typename?: "RetailFinancingPlanMonthlyPaymentProjection";
  dueDate: Maybe<Scalars["String"]>;
  installmentRepaymentAmount: Maybe<Scalars["Long"]>;
  interestRateAmount: Maybe<Scalars["Long"]>;
  number: Maybe<Scalars["Int"]>;
  rateAmount: Maybe<Scalars["Long"]>;
  remainingDebtAmount: Maybe<Scalars["Long"]>;
}

export interface RetailFinancingPlanProjection {
  __typename?: "RetailFinancingPlanProjection";
  debitInterest: Maybe<Scalars["BigDecimal"]>;
  effectiveAnnualInterest: Maybe<Scalars["BigDecimal"]>;
  monthlyInstalmentRate: Maybe<Scalars["Long"]>;
  totalLoanAmountWithInterest: Maybe<Scalars["Long"]>;
}

export interface RetailFinancingTierProductProjection {
  __typename?: "RetailFinancingTierProductProjection";
  id: Scalars["UUID"];
  monthlyPayment: Scalars["Long"];
  price: Scalars["Long"];
  priceInterestFree: Scalars["Long"];
  tierId: Scalars["UUID"];
  type: Scalars["String"];
}

export interface RetailFinancingTierProjection {
  __typename?: "RetailFinancingTierProjection";
  additionalCash: Maybe<Scalars["Long"]>;
  additionalCashFromInsurance: Maybe<Scalars["Long"]>;
  apr: Maybe<Scalars["Float"]>;
  balloonPayment: Maybe<Scalars["Long"]>;
  balloonPercent: Maybe<Scalars["Int"]>;
  financingAmountMinorUnits: Maybe<Scalars["Long"]>;
  id: Maybe<Scalars["UUID"]>;
  initialLoanAmount: Maybe<Scalars["Long"]>;
  interestRate: Maybe<Scalars["Float"]>;
  isEligible: Maybe<Scalars["Boolean"]>;
  isSelected: Maybe<Scalars["Boolean"]>;
  maturity: Maybe<Scalars["Int"]>;
  maxLoanAmount: Maybe<Scalars["Long"]>;
  monthlyPayment: Maybe<Scalars["Long"]>;
  name: Maybe<Scalars["String"]>;
  number: Maybe<Scalars["Int"]>;
  parentTierId: Maybe<Scalars["UUID"]>;
  presentValue: Maybe<Scalars["Long"]>;
  products: Array<Maybe<RetailFinancingTierProductProjection>>;
  riskScore: Maybe<Scalars["Float"]>;
  totalPaymentAmount: Maybe<Scalars["Long"]>;
}

export interface RetailFtsRiskDataProjection {
  __typename?: "RetailFtsRiskDataProjection";
  accountHolder: Maybe<Scalars["String"]>;
  bankName: Maybe<Scalars["String"]>;
  bic: Maybe<Scalars["String"]>;
  finishedOn: Maybe<Scalars["String"]>;
  iban: Maybe<Scalars["String"]>;
  riskData: Maybe<RiskData>;
  transactionId: Maybe<Scalars["String"]>;
}

export interface RetailFullFtsDataProjection {
  __typename?: "RetailFullFtsDataProjection";
  accountHolder: Maybe<Scalars["String"]>;
  bankName: Maybe<Scalars["String"]>;
  bic: Maybe<Scalars["String"]>;
  creditRiskDataDTO: Maybe<CreditRiskDataDto>;
  finishedOn: Maybe<Scalars["String"]>;
  iban: Maybe<Scalars["String"]>;
  transactionId: Maybe<Scalars["String"]>;
}

export interface RetailGroupDto {
  __typename?: "RetailGroupDTO";
  createdBy: Maybe<Scalars["UUID"]>;
  createdOn: Maybe<Scalars["String"]>;
  description: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  name: Maybe<Scalars["String"]>;
  roles: Maybe<Array<Maybe<RetailRoleDto>>>;
  updatedOn: Maybe<Scalars["String"]>;
  users: Maybe<Array<Maybe<RetailUserDto>>>;
}

export interface RetailImageTagCreateProjectionInput {
  adId?: InputMaybe<Scalars["UUID"]>;
  imageId?: InputMaybe<Scalars["UUID"]>;
  parentImageId?: InputMaybe<Scalars["UUID"]>;
  position?: InputMaybe<PositionDtoInput>;
  type?: InputMaybe<TagType>;
}

export interface RetailImageTagProjection {
  __typename?: "RetailImageTagProjection";
  adId: Maybe<Scalars["UUID"]>;
  createdOn: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  imageId: Maybe<Scalars["UUID"]>;
  parentImageId: Maybe<Scalars["UUID"]>;
  position: Maybe<PositionDto>;
  type: Maybe<TagType>;
  updatedOn: Maybe<Scalars["String"]>;
}

export interface RetailImageTagUpdateProjectionInput {
  adId?: InputMaybe<Scalars["UUID"]>;
  imageId?: InputMaybe<Scalars["UUID"]>;
  parentImageId?: InputMaybe<Scalars["UUID"]>;
  position?: InputMaybe<PositionDtoInput>;
  type?: InputMaybe<TagType>;
}

export interface RetailInsuranceCreateProjectionInput {
  active: Scalars["Boolean"];
  country: Scalars["String"];
  currencyCode: Scalars["String"];
  defaulted: Scalars["Boolean"];
  grossPriceMinorUnits: Scalars["Int"];
  internalReferenceName?: InputMaybe<Scalars["String"]>;
  invoiceDescription: Scalars["String"];
  invoiceName?: InputMaybe<Scalars["String"]>;
  partnerName: Scalars["String"];
  pnlCostGrossPriceMinorUnits?: InputMaybe<Scalars["Int"]>;
  pnlCostVatKey?: InputMaybe<VatKeyType>;
  pnlCostVatPercentage?: InputMaybe<Scalars["Float"]>;
  publishedScope: PublishedScopeType;
  quantity: Scalars["Int"];
  recommended?: InputMaybe<Scalars["Boolean"]>;
  refId: Scalars["String"];
  shippingRequired: Scalars["Boolean"];
  vatKey: VatKeyType;
  vatPercentage: Scalars["Float"];
}

export interface RetailInsuranceProjection {
  __typename?: "RetailInsuranceProjection";
  active: Scalars["Boolean"];
  country: Scalars["String"];
  createdBy: Maybe<Scalars["UUID"]>;
  createdOn: Maybe<Scalars["String"]>;
  currencyCode: Scalars["String"];
  deactivatedOn: Maybe<Scalars["String"]>;
  defaulted: Scalars["Boolean"];
  externalProviderPrice: Maybe<Scalars["Long"]>;
  id: Scalars["UUID"];
  internalReferenceName: Maybe<Scalars["String"]>;
  invoiceDescription: Scalars["String"];
  invoiceName: Maybe<Scalars["String"]>;
  partnerName: Scalars["String"];
  pnlCostPrice: Maybe<PriceDetailsProjection>;
  price: PriceDetailsProjection;
  priceDetails: Maybe<Array<Maybe<PriceDetailsProjection>>>;
  productType: Scalars["String"];
  publishedScope: PublishedScopeType;
  quantity: Scalars["Int"];
  recommended: Maybe<Scalars["Boolean"]>;
  refId: Scalars["String"];
  shippingRequired: Scalars["Boolean"];
  subProductDetails: Maybe<RetailSubProductDetailsProjection>;
  updatedOn: Maybe<Scalars["String"]>;
}

export interface RetailInsuranceSearchProjectionInput {
  adId?: InputMaybe<Scalars["UUID"]>;
  country?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<Scalars["Map_String_ObjectScalar"]>;
  onlyAvailable?: InputMaybe<Scalars["Boolean"]>;
  orderId?: InputMaybe<Scalars["UUID"]>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  priceModifiers?: InputMaybe<Scalars["Map_String_PriceModifierPayloadScalar"]>;
  sorts?: InputMaybe<Array<InputMaybe<SortOrderProjectionInput>>>;
  zipCode?: InputMaybe<Scalars["String"]>;
}

export interface RetailInsuranceUpdateProjectionInput {
  active?: InputMaybe<Scalars["Boolean"]>;
  country?: InputMaybe<Scalars["String"]>;
  defaulted?: InputMaybe<Scalars["Boolean"]>;
  grossPriceMinorUnits?: InputMaybe<Scalars["Long"]>;
  internalReferenceName?: InputMaybe<Scalars["String"]>;
  invoiceDescription?: InputMaybe<Scalars["String"]>;
  invoiceName?: InputMaybe<Scalars["String"]>;
  partnerName?: InputMaybe<Scalars["String"]>;
  pnlCostGrossPriceMinorUnits?: InputMaybe<Scalars["Long"]>;
  pnlCostVatKey?: InputMaybe<VatKeyType>;
  pnlCostVatPercentage?: InputMaybe<Scalars["Float"]>;
  publishedScope?: InputMaybe<PublishedScopeType>;
  quantity?: InputMaybe<Scalars["Int"]>;
  recommended?: InputMaybe<Scalars["Boolean"]>;
  shippingRequired?: InputMaybe<Scalars["Boolean"]>;
  vatKey?: InputMaybe<VatKeyType>;
  vatPercentage?: InputMaybe<Scalars["Float"]>;
}

export type RetailInternalTransferPaymentCategory =
  | "CHARGEBACK"
  | "DUPLICATE_FINANCING_AMOUNT"
  | "FINANCING_KICKBACK"
  | "INTERNAL"
  | "LEGAL"
  | "LOGISTIC"
  | "NOT_APPLICABLE_PAYMENT"
  | "ON_HOLD"
  | "OUTGOING_REFUND"
  | "OVERPAYMENT"
  | "RATENKAUF_FINANCING"
  | "REFUND"
  | "REJECTED_OUTBOUND_PAYMENT";

export interface RetailInternalTransferPaymentProjection {
  __typename?: "RetailInternalTransferPaymentProjection";
  accountData: Maybe<BankAccountDetailsProjection>;
  accountingDate: Maybe<Scalars["String"]>;
  amount: Maybe<RetailFinancePriceProjection>;
  category: Maybe<RetailInternalTransferPaymentCategory>;
  countryCode: Maybe<Scalars["String"]>;
  createdBy: Maybe<Scalars["UUID"]>;
  createdOn: Maybe<Scalars["String"]>;
  creationSource: Maybe<Scalars["String"]>;
  externalId: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  importDetails: Maybe<RetailPaymentImportDetailsProjection>;
  retailTransactionId: Maybe<Scalars["UUID"]>;
  status: Maybe<RetailPaymentStatus>;
  type: Maybe<Scalars["String"]>;
  updatedBy: Maybe<Scalars["UUID"]>;
  updatedOn: Maybe<Scalars["String"]>;
}

export interface RetailInvoiceCreateProjectionInput {
  currencyCode?: InputMaybe<Scalars["String"]>;
  invoicedOn?: InputMaybe<Scalars["String"]>;
  number?: InputMaybe<Scalars["String"]>;
  orderId?: InputMaybe<Scalars["UUID"]>;
  vatKey?: InputMaybe<Scalars["String"]>;
}

export interface RetailLeadCreateProjectionInput {
  adId?: InputMaybe<Scalars["UUID"]>;
  auxUserId?: InputMaybe<Scalars["UUID"]>;
  contactType?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  firstContactAt?: InputMaybe<Scalars["String"]>;
  leadType?: InputMaybe<Scalars["String"]>;
  message?: InputMaybe<Scalars["String"]>;
  ownerId?: InputMaybe<Scalars["UUID"]>;
  phoneNumber?: InputMaybe<PhoneNumberInput>;
  platform?: InputMaybe<Scalars["String"]>;
  rejectType?: InputMaybe<Scalars["String"]>;
  requestedTime?: InputMaybe<Scalars["String"]>;
  salesNote?: InputMaybe<Scalars["String"]>;
  source?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
}

export interface RetailLeadProjection {
  __typename?: "RetailLeadProjection";
  adId: Maybe<Scalars["UUID"]>;
  auxUserId: Maybe<Scalars["UUID"]>;
  contactType: Maybe<Scalars["String"]>;
  country: Maybe<Scalars["String"]>;
  created: Maybe<Scalars["String"]>;
  email: Maybe<Scalars["String"]>;
  firstContactAt: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  leadType: Maybe<Scalars["String"]>;
  message: Maybe<Scalars["String"]>;
  ownerId: Maybe<Scalars["UUID"]>;
  phoneNumber: Maybe<PhoneNumber>;
  platform: Maybe<Scalars["String"]>;
  rejectType: Maybe<Scalars["String"]>;
  requestedTime: Maybe<Scalars["String"]>;
  salesNote: Maybe<Scalars["String"]>;
  source: Maybe<Scalars["String"]>;
  status: Maybe<Scalars["String"]>;
}

export interface RetailLeadProjectionInput {
  adId?: InputMaybe<Scalars["UUID"]>;
  auxUserId?: InputMaybe<Scalars["UUID"]>;
  contactType?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  created?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  firstContactAt?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["UUID"]>;
  leadType?: InputMaybe<Scalars["String"]>;
  message?: InputMaybe<Scalars["String"]>;
  ownerId?: InputMaybe<Scalars["UUID"]>;
  phoneNumber?: InputMaybe<PhoneNumberInput>;
  platform?: InputMaybe<Scalars["String"]>;
  rejectType?: InputMaybe<Scalars["String"]>;
  requestedTime?: InputMaybe<Scalars["String"]>;
  salesNote?: InputMaybe<Scalars["String"]>;
  source?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
}

export interface RetailManualFullEvaluationProjectionInput {
  amount?: InputMaybe<AmountInfoInput>;
  hasCreditCard: Scalars["Boolean"];
  riskProvider?: InputMaybe<Scalars["String"]>;
  riskScore?: InputMaybe<Scalars["String"]>;
}

export interface RetailManualFullEvaluationProjectionV2Input {
  currency?: InputMaybe<Scalars["String"]>;
  hasCreditCard: Scalars["Boolean"];
  monthlyNetIncomePerMonth?: InputMaybe<
    Array<InputMaybe<MonthlyNetIncomeProjectionInput>>
  >;
  riskProvider?: InputMaybe<Scalars["String"]>;
  riskScore?: InputMaybe<Scalars["String"]>;
}

export interface RetailOrderAccountDetailsCreateProjectionInput {
  account?: InputMaybe<RetailAccountDetailsCreateProjectionInput>;
  description?: InputMaybe<Scalars["String"]>;
  documentIds?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
}

export interface RetailOrderAccountDetailsProjection {
  __typename?: "RetailOrderAccountDetailsProjection";
  account: Maybe<BankAccountDetailsProjection>;
  description: Maybe<Scalars["String"]>;
  documentIds: Maybe<Array<Maybe<Scalars["UUID"]>>>;
  id: Maybe<Scalars["UUID"]>;
  orderId: Maybe<Scalars["UUID"]>;
  uploadSource: Maybe<Scalars["String"]>;
}

export type RetailOrderBasedTaskAction =
  | "ADDED_BY_CUSTOMER_IN_DASHBOARD"
  | "ADDITIONAL_INFORMATION_IS_NEEDED"
  | "APPLICATION_APPROVED"
  | "APPLICATION_CANCELLED"
  | "APPLICATION_PRE_APPROVED"
  | "APPLICATION_REJECTED"
  | "APPLICATION_SENT_TO_THE_BANK"
  | "APPROVED_PAYMENT_SHIFTING"
  | "APPROVED_REFUND"
  | "BANK_DETAILS_ADDED"
  | "BANK_DETAILS_NO_LONGER_NEEDED"
  | "CANCELED"
  | "CANCELED_AUTO_CLOSE_BY_SYSTEM"
  | "CAR_DROPPED_OFF_BY_CUSTOMER"
  | "CAR_PICKED_UP_BY_AH"
  | "CLAIM_COST_NO_LONGER_COMPLETED"
  | "CLAIM_PARTIAL_REFUND_COMPLETED"
  | "CLOSE"
  | "CLOSED_DUE_TO_ORDER_CANCELLATION_REVERSAL"
  | "COMPLETED"
  | "CONFIRM_FIN_CONTRACT"
  | "CUSTOMER_ADDED_PROOF"
  | "CUSTOMER_DID_NOT_ADD_PROOF"
  | "DAMAGE_COMPENSATION_APPROVED"
  | "DEREGISTRATION_DONE"
  | "DOCS_SUBMITTED"
  | "DOCUMENTS_RETURNED"
  | "INITIAL_FIN_CONTRACT_SENT"
  | "NEW_BANK_DETAILS_REQUIRED"
  | "OPEN"
  | "PROOF_WAS_REVIEWED"
  | "REOPEN"
  | "REQUEST_DOCS"
  | "SUCCESS"
  | "SUCCESS_AUTO_CLOSE_BY_SYSTEM"
  | "UPDATED_FIN_CONTRACT_SENT";

export interface RetailOrderCarRegistrationDto {
  __typename?: "RetailOrderCarRegistrationDTO";
  id: Maybe<Scalars["UUID"]>;
  status: Maybe<Scalars["String"]>;
}

export interface RetailOrderContractAdProjection {
  __typename?: "RetailOrderContractAdProjection";
  id: Maybe<Scalars["UUID"]>;
  price: Maybe<Price>;
  source: Maybe<Source>;
  vatRate: Maybe<Scalars["Float"]>;
  vehicle: Maybe<RetailOrderContractVehicleProjection>;
}

export interface RetailOrderContractFeatureStatusProjection {
  __typename?: "RetailOrderContractFeatureStatusProjection";
  contractDsaRemovalAutohero: Maybe<Scalars["Boolean"]>;
  displayInsuranceTaxNotice: Maybe<Scalars["Boolean"]>;
  millageInLocalUnitsAH: Maybe<Scalars["Boolean"]>;
  showVatAutohero: Maybe<Scalars["Boolean"]>;
  showVatBackOffice: Maybe<Scalars["Boolean"]>;
}

export interface RetailOrderContractOrderItemProjection {
  __typename?: "RetailOrderContractOrderItemProjection";
  currencyCode: Maybe<Scalars["String"]>;
  discountGrossMinorUnits: Maybe<Scalars["Long"]>;
  externalId: Maybe<Scalars["UUID"]>;
  externalType: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  priceDiscountGross: Maybe<PriceDto>;
  priceDiscountNet: Maybe<PriceDto>;
  priceDiscountVat: Maybe<PriceDto>;
  priceGross: Maybe<PriceDto>;
  priceGrossMinorUnits: Maybe<Scalars["Long"]>;
  priceNet: Maybe<PriceDto>;
  priceNetMinorUnits: Maybe<Scalars["Long"]>;
  priceTaxAdvantageAmount: Maybe<PriceDto>;
  priceVatAmount: Maybe<PriceDto>;
  vatAmountMinorUnits: Maybe<Scalars["Long"]>;
  vatKey: Maybe<Scalars["String"]>;
  vatRate: Maybe<Scalars["Float"]>;
}

export interface RetailOrderContractProductProjection {
  __typename?: "RetailOrderContractProductProjection";
  id: Maybe<Scalars["UUID"]>;
  insuranceTax: Maybe<Scalars["String"]>;
  invoiceDescription: Maybe<Scalars["String"]>;
  invoiceName: Maybe<Scalars["String"]>;
  itemDiscountNet: Maybe<PriceDto>;
  itemPriceGross: Maybe<PriceDto>;
  itemPriceNet: Maybe<PriceDto>;
  level: Maybe<Scalars["String"]>;
  monthsCovered: Maybe<Scalars["Int"]>;
  productType: Maybe<Scalars["String"]>;
  quantity: Scalars["Int"];
  refId: Maybe<Scalars["String"]>;
  source: Maybe<Scalars["String"]>;
  title: Maybe<Scalars["String"]>;
}

export interface RetailOrderContractProjection {
  __typename?: "RetailOrderContractProjection";
  ad: Maybe<RetailOrderContractAdProjection>;
  billingAddress: Maybe<RetailOrderContractRetailAddressProjection>;
  deliveryHandover: Maybe<RetailOrderContractRetailOrderHandoverProjection>;
  documentShippingAddress: Maybe<RetailOrderContractRetailAddressProjection>;
  features: Maybe<RetailOrderContractFeatureStatusProjection>;
  financingDataPDF: Maybe<
    Array<Maybe<RetailOrderContractRetailFinancingPlanBreakdownProjection>>
  >;
  financingPlan: Maybe<RetailOrderContractRetailFinancingPlanProjection>;
  order: Maybe<RetailOrderContractRetailOrderProjection>;
  orderCustomer: Maybe<RetailOrderContractRetailCustomerProjection>;
  products: Maybe<Array<Maybe<RetailOrderContractProductProjection>>>;
  retailAd: Maybe<RetailOrderContractRetailAdProjection>;
  shippingAddress: Maybe<RetailOrderContractRetailAddressProjection>;
  tradeInItem: Maybe<RetailOrderContractOrderItemProjection>;
}

export interface RetailOrderContractRetailAdProjection {
  __typename?: "RetailOrderContractRetailAdProjection";
  contractDetails: Maybe<Scalars["String"]>;
  country: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  retailCountryLicencePlate: Maybe<Scalars["String"]>;
  secondaryWheels: Maybe<RetailOrderContractSecondaryWheelsProjection>;
}

export interface RetailOrderContractRetailAddressProjection {
  __typename?: "RetailOrderContractRetailAddressProjection";
  branchName: Maybe<Scalars["String"]>;
  city: Maybe<Scalars["String"]>;
  country: Maybe<Scalars["String"]>;
  firstName: Maybe<Scalars["String"]>;
  houseNumber: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  lastName: Maybe<Scalars["String"]>;
  street: Maybe<Scalars["String"]>;
  zipcode: Maybe<Scalars["String"]>;
}

export interface RetailOrderContractRetailCustomerProjection {
  __typename?: "RetailOrderContractRetailCustomerProjection";
  customerId: Maybe<Scalars["String"]>;
  customerType: Maybe<Scalars["String"]>;
  dateOfBirth: Maybe<Scalars["String"]>;
  email: Maybe<Scalars["String"]>;
  firstName: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  lastName: Maybe<Scalars["String"]>;
  name: Maybe<Scalars["String"]>;
  number: Maybe<Scalars["String"]>;
  phone: Maybe<Scalars["String"]>;
  salutation: Maybe<Scalars["String"]>;
  ssn: Maybe<Scalars["String"]>;
  taxCode: Maybe<Scalars["String"]>;
  type: Maybe<Scalars["String"]>;
  vatId: Maybe<Scalars["String"]>;
}

export interface RetailOrderContractRetailFinancingPlanBreakdownProjection {
  __typename?: "RetailOrderContractRetailFinancingPlanBreakdownProjection";
  installmentsSum: Maybe<Scalars["Long"]>;
  interestSum: Maybe<Scalars["Long"]>;
  monthlyRate: Maybe<Scalars["Long"]>;
  totalRepayment: Maybe<Scalars["Long"]>;
}

export interface RetailOrderContractRetailFinancingPlanProjection {
  __typename?: "RetailOrderContractRetailFinancingPlanProjection";
  financingProducts: Maybe<
    Array<Maybe<RetailOrderContractRetailFinancingTierProductProjection>>
  >;
  id: Maybe<Scalars["UUID"]>;
}

export interface RetailOrderContractRetailFinancingTierProductProjection {
  __typename?: "RetailOrderContractRetailFinancingTierProductProjection";
  id: Scalars["UUID"];
  monthlyPayment: Scalars["Long"];
  price: Scalars["Long"];
  priceInterestFree: Scalars["Long"];
  tierId: Scalars["UUID"];
  type: Scalars["String"];
}

export interface RetailOrderContractRetailOrderHandoverProjection {
  __typename?: "RetailOrderContractRetailOrderHandoverProjection";
  id: Maybe<Scalars["UUID"]>;
  onDeliveryPaymentType: Maybe<Scalars["String"]>;
  orderId: Maybe<Scalars["UUID"]>;
}

export interface RetailOrderContractRetailOrderProjection {
  __typename?: "RetailOrderContractRetailOrderProjection";
  amountInWords: Maybe<Scalars["String"]>;
  contractSignedOn: Maybe<Scalars["String"]>;
  created: Maybe<Scalars["String"]>;
  creditAmount: Maybe<PriceDto>;
  depositAmount: Maybe<PriceDto>;
  depositDueDate: Maybe<Scalars["String"]>;
  editable: Maybe<Scalars["Boolean"]>;
  financingType: Maybe<Scalars["String"]>;
  flowVersion: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  items: Maybe<Array<Maybe<RetailOrderContractOrderItemProjection>>>;
  locale: Maybe<Scalars["String"]>;
  orderNumber: Maybe<Scalars["String"]>;
  paymentType: Maybe<Scalars["String"]>;
  remainingCashAmount: Maybe<PriceDto>;
  retailCountry: Maybe<Scalars["String"]>;
  state: Maybe<Scalars["String"]>;
  stockNumber: Maybe<Scalars["String"]>;
  totalPriceGross: Maybe<PriceDto>;
  totalPriceNet: Maybe<PriceDto>;
  totalTaxAdvantage: Maybe<PriceDto>;
  totalVat: Maybe<PriceDto>;
  verifiedOn: Maybe<Scalars["String"]>;
}

export interface RetailOrderContractRetailVehicleLicensePlateProjection {
  __typename?: "RetailOrderContractRetailVehicleLicensePlateProjection";
  licensePlate: Maybe<Scalars["String"]>;
}

export interface RetailOrderContractSecondaryWheelsProjection {
  __typename?: "RetailOrderContractSecondaryWheelsProjection";
  damageCondition: Maybe<SecondaryWheelsDamageCondition>;
  id: Maybe<Scalars["UUID"]>;
  rimsDetails: Maybe<Scalars["Map_WheelPlacementType_RimDetailsScalar"]>;
  tiresDetails: Maybe<Scalars["Map_WheelPlacementType_TireDetailsScalar"]>;
}

export interface RetailOrderContractVehicleProjection {
  __typename?: "RetailOrderContractVehicleProjection";
  accidentCar: Maybe<Scalars["Boolean"]>;
  builtYear: Maybe<Scalars["Int"]>;
  carPreownerCount: Maybe<Scalars["Int"]>;
  countryOfOrigin: Maybe<Scalars["String"]>;
  emissionStandardCode: Maybe<Scalars["Int"]>;
  fuelType: Maybe<Scalars["String"]>;
  fuelTypeCode: Maybe<Scalars["Int"]>;
  handoverKeyCount: Maybe<Scalars["Int"]>;
  horsePower: Maybe<Scalars["Int"]>;
  inspectionExpiryDate: Maybe<Scalars["String"]>;
  kw: Maybe<Scalars["BigDecimal"]>;
  lastServiceOn: Maybe<Scalars["String"]>;
  licensePlate: Maybe<Scalars["String"]>;
  make: Maybe<Scalars["String"]>;
  mileageDTO: Maybe<MileageDto>;
  model: Maybe<Scalars["String"]>;
  modelApi: Maybe<ModelDto>;
  originalEngine: Maybe<Scalars["Boolean"]>;
  registration: Maybe<Scalars["String"]>;
  stockNumber: Maybe<Scalars["String"]>;
  vatType: Maybe<Scalars["Int"]>;
  vin: Maybe<Scalars["String"]>;
  wasInCommercialUse: Maybe<Scalars["Boolean"]>;
}

export interface RetailOrderDto {
  __typename?: "RetailOrderDTO";
  amountInWords: Maybe<Scalars["String"]>;
  auxUserId: Maybe<Scalars["UUID"]>;
  branchCheckupOn: Maybe<Scalars["String"]>;
  branchId: Maybe<Scalars["Int"]>;
  cancelDescription: Maybe<Scalars["String"]>;
  cancelPeriod: Maybe<Scalars["String"]>;
  cancelReason: Maybe<Scalars["String"]>;
  cancelRequest: Maybe<CancelRequestDto>;
  cancelRequestSubreason: Maybe<Scalars["String"]>;
  cancelType: Maybe<Scalars["String"]>;
  canceledOn: Maybe<Scalars["String"]>;
  carRegistration: Maybe<RetailOrderCarRegistrationDto>;
  carRegistrationStatus: Maybe<CarRegistrationStatusDictionaryDto>;
  carReservedOn: Maybe<Scalars["String"]>;
  checkoutBranchId: Maybe<Scalars["Int"]>;
  checkoutPaymentDetails: Maybe<Scalars["ObjectScalar"]>;
  completed: Maybe<Scalars["String"]>;
  confirmed: Maybe<Scalars["Boolean"]>;
  contractSentOn: Maybe<Scalars["String"]>;
  contractSigned: Maybe<Scalars["Boolean"]>;
  contractSignedOn: Maybe<Scalars["String"]>;
  conversionType: Maybe<Scalars["String"]>;
  created: Maybe<Scalars["String"]>;
  createdBy: Maybe<Scalars["UUID"]>;
  creditAmount: Maybe<PriceDto>;
  creditAmountMinorUnits: Maybe<Scalars["Long"]>;
  customerCountry: Maybe<Scalars["String"]>;
  customerEmail: Maybe<Scalars["String"]>;
  delivery: Maybe<RetailOrderDeliveryDto>;
  deliveryETA: Maybe<RangeDto_Integer>;
  deliveryRoute: Maybe<Array<Maybe<RetailDeliveryRouteItemDto>>>;
  depositAmount: Maybe<PriceDto>;
  depositAmountMinorUnits: Maybe<Scalars["Long"]>;
  depositDueDate: Maybe<Scalars["String"]>;
  depositPaidOn: Maybe<Scalars["String"]>;
  details: Maybe<Scalars["String"]>;
  documentHandoverOn: Maybe<Scalars["String"]>;
  draftOn: Maybe<Scalars["String"]>;
  finalDocumentHandoverOn: Maybe<Scalars["String"]>;
  finalPaymentDueDate: Maybe<Scalars["String"]>;
  financing: Maybe<OrderFinancingDto>;
  financingFlowVersion: Maybe<Scalars["String"]>;
  financingType: Maybe<Scalars["String"]>;
  flowVersion: Maybe<Scalars["String"]>;
  fullPaymentDueDate: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  invoiceNumber: Maybe<Scalars["String"]>;
  isBranchCheckupSuccessful: Maybe<Scalars["Boolean"]>;
  isCarInBranch: Maybe<Scalars["Boolean"]>;
  isEditable: Maybe<Scalars["Boolean"]>;
  isOracle: Maybe<Scalars["Boolean"]>;
  isTest: Maybe<Scalars["Boolean"]>;
  isTradeIn: Maybe<Scalars["Boolean"]>;
  items: Maybe<Array<Maybe<RetailOrderItemDto>>>;
  linkedOrders: Maybe<Array<Maybe<LinkedOrdersDto>>>;
  locale: Maybe<Scalars["String"]>;
  orderNumber: Maybe<Scalars["String"]>;
  overallDelayCalendarDays: Maybe<Scalars["Int"]>;
  ownerId: Maybe<Scalars["UUID"]>;
  paidCashAmount: Maybe<PriceDto>;
  paidOn: Maybe<Scalars["String"]>;
  payment: Maybe<Scalars["String"]>;
  paymentStatus: Maybe<Scalars["String"]>;
  paymentType: Maybe<Scalars["String"]>;
  pendingVerificationOn: Maybe<Scalars["String"]>;
  plannedCarHandoverLastDefinedOn: Maybe<Scalars["String"]>;
  plannedCarHandoverOn: Maybe<Scalars["String"]>;
  remainingAmount: Maybe<PriceDto>;
  remainingCashAmount: Maybe<PriceDto>;
  reservationEndOn: Maybe<Scalars["String"]>;
  retailCountry: Maybe<Scalars["String"]>;
  salesAgentAssignedTo: Maybe<Scalars["UUID"]>;
  source: Maybe<Scalars["String"]>;
  state: Maybe<Scalars["String"]>;
  stockNumber: Maybe<Scalars["String"]>;
  subCancelReason: Maybe<Scalars["String"]>;
  totalPriceGross: Maybe<PriceDto>;
  totalPriceNet: Maybe<PriceDto>;
  totalTaxAdvantage: Maybe<PriceDto>;
  totalVat: Maybe<PriceDto>;
  transportOrdered: Maybe<Scalars["String"]>;
  trialExpiredOn: Maybe<Scalars["String"]>;
  trialReturnOn: Maybe<Scalars["String"]>;
  type: Maybe<Scalars["String"]>;
  updated: Maybe<Scalars["String"]>;
  vehicleDeliveredOn: Maybe<Scalars["String"]>;
  verifiedOn: Maybe<Scalars["String"]>;
  visibleForCustomerWhenCancelled: Maybe<Scalars["Boolean"]>;
  yellowPlatesRequired: Maybe<Scalars["Boolean"]>;
}

export interface RetailOrderDebtCsvDataProjection {
  __typename?: "RetailOrderDebtCSVDataProjection";
  createdOn: Maybe<Scalars["String"]>;
  customerBillingAddress: Maybe<Scalars["String"]>;
  customerBillingAddressCity: Maybe<Scalars["String"]>;
  customerBillingAddressZipCode: Maybe<Scalars["String"]>;
  customerEmail: Maybe<Scalars["String"]>;
  customerName: Maybe<Scalars["String"]>;
  customerName2: Maybe<Scalars["String"]>;
  customerPhoneNumber: Maybe<Scalars["String"]>;
  customerSalutation: Maybe<Scalars["String"]>;
  debtAmount: Maybe<RetailDebtPriceDto>;
  debtType: Maybe<Scalars["String"]>;
  documentType: Maybe<Scalars["String"]>;
  dunningState: Maybe<Scalars["Int"]>;
  orderAmount: Maybe<RetailDebtPriceDto>;
  orderCreatedOn: Maybe<Scalars["String"]>;
  orderId: Maybe<Scalars["UUID"]>;
  orderNumber: Maybe<Scalars["String"]>;
}

export interface RetailOrderDebtCommentProjection {
  __typename?: "RetailOrderDebtCommentProjection";
  createdBy: Maybe<UserProjection>;
  createdOn: Maybe<Scalars["String"]>;
  debtId: Maybe<Scalars["UUID"]>;
  id: Maybe<Scalars["UUID"]>;
  text: Maybe<Scalars["String"]>;
}

export interface RetailOrderDebtProjection {
  __typename?: "RetailOrderDebtProjection";
  comments: Maybe<Array<Maybe<RetailOrderDebtCommentProjection>>>;
  createdOn: Maybe<Scalars["String"]>;
  debtAmount: Maybe<RetailDebtPriceDto>;
  eligibleDays: Maybe<Scalars["Int"]>;
  eligibleOn: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  orderAmount: Maybe<RetailDebtPriceDto>;
  orderCountry: Maybe<Scalars["String"]>;
  orderCreatedOn: Maybe<Scalars["String"]>;
  orderFinancingFlow: Maybe<Scalars["String"]>;
  orderId: Maybe<Scalars["UUID"]>;
  orderNumber: Maybe<Scalars["String"]>;
  orderPaymentFlow: Maybe<Scalars["String"]>;
  orderVehicleDeliveredOn: Maybe<Scalars["String"]>;
  type: Maybe<Scalars["String"]>;
}

export interface RetailOrderDebtUpdateProjectionInput {
  paidOffOn?: InputMaybe<Scalars["String"]>;
}

export interface RetailOrderDeliveryDto {
  __typename?: "RetailOrderDeliveryDTO";
  branchPlannedCarHandoverLastDefinedOn: Maybe<Scalars["String"]>;
  branchPlannedCarHandoverOn: Maybe<Scalars["String"]>;
  customerExpectedMaxETA: Maybe<Scalars["String"]>;
  customerExpectedMaxETACalculatedOn: Maybe<Scalars["String"]>;
  customerExpectedMaxETAChangeCount: Maybe<Scalars["Int"]>;
  delayReason: Maybe<Scalars["String"]>;
  deliveryAddressChangeApplied: Maybe<Scalars["Boolean"]>;
  deliveryDateType: Maybe<Scalars["String"]>;
  deliveryETA: Maybe<RangeDto_Integer>;
  deliveryOption: Maybe<Scalars["String"]>;
  deliveryType: Maybe<Scalars["String"]>;
  initialCustomerExpectedMaxETA: Maybe<Scalars["String"]>;
  plannedHandoverFrom: Maybe<Scalars["String"]>;
  plannedHandoverLastDefinedOn: Maybe<Scalars["String"]>;
  plannedHandoverTo: Maybe<Scalars["String"]>;
  tradeInCompatible: Maybe<Scalars["Boolean"]>;
}

export interface RetailOrderDeliveryDtoInput {
  branchPlannedCarHandoverLastDefinedOn?: InputMaybe<Scalars["String"]>;
  branchPlannedCarHandoverOn?: InputMaybe<Scalars["String"]>;
  customerExpectedMaxETA?: InputMaybe<Scalars["String"]>;
  customerExpectedMaxETACalculatedOn?: InputMaybe<Scalars["String"]>;
  customerExpectedMaxETAChangeCount?: InputMaybe<Scalars["Int"]>;
  delayReason?: InputMaybe<Scalars["String"]>;
  deliveryAddressChangeApplied?: InputMaybe<Scalars["Boolean"]>;
  deliveryDateType?: InputMaybe<Scalars["String"]>;
  deliveryETA?: InputMaybe<RangeDto_IntegerInput>;
  deliveryOption?: InputMaybe<Scalars["String"]>;
  deliveryType?: InputMaybe<Scalars["String"]>;
  initialCustomerExpectedMaxETA?: InputMaybe<Scalars["String"]>;
  plannedHandoverFrom?: InputMaybe<Scalars["String"]>;
  plannedHandoverLastDefinedOn?: InputMaybe<Scalars["String"]>;
  plannedHandoverTo?: InputMaybe<Scalars["String"]>;
  tradeInCompatible?: InputMaybe<Scalars["Boolean"]>;
}

export interface RetailOrderDeliveryDetailsProjection {
  __typename?: "RetailOrderDeliveryDetailsProjection";
  activeRouteBasedETA: Maybe<Scalars["String"]>;
  branchDeliverySelectionCriteria: Maybe<Scalars["String"]>;
  branchPlannedCarHandoverOn: Maybe<Scalars["String"]>;
  customerExpectedMaxETA: Maybe<Scalars["String"]>;
  customerExpectedMaxETAChangeCount: Maybe<Scalars["Int"]>;
  deliveryAddressChangeApplied: Maybe<Scalars["Boolean"]>;
  deliveryETAType: Maybe<Scalars["String"]>;
  deliveryLocationType: Maybe<Scalars["String"]>;
  deliveryType: Maybe<Scalars["String"]>;
  handoverDateSource: Maybe<Scalars["String"]>;
  homeDeliveryExtraMileage: Maybe<Scalars["Int"]>;
  initialCustomerExpectedMaxETA: Maybe<Scalars["String"]>;
  plannedHandoverFrom: Maybe<Scalars["String"]>;
  plannedHandoverTo: Maybe<Scalars["String"]>;
  retailDeliveryOption: Maybe<Scalars["String"]>;
  tradeInCompatible: Maybe<Scalars["Boolean"]>;
}

export interface RetailOrderDeliveryProjection {
  __typename?: "RetailOrderDeliveryProjection";
  branchDeliverySelectionCriteria: Maybe<Scalars["String"]>;
  branchPlannedCarHandoverOn: Maybe<Scalars["String"]>;
  deliveryAddressChangeApplied: Maybe<Scalars["Boolean"]>;
  deliveryETA: Maybe<DeliveryEta>;
  deliveryLocationType: Maybe<Scalars["String"]>;
  homeDeliveryExtraMileage: Maybe<Scalars["Int"]>;
  plannedHandoverFrom: Maybe<Scalars["String"]>;
  plannedHandoverTo: Maybe<Scalars["String"]>;
  retailDeliveryOption: Maybe<Scalars["String"]>;
  tradeInCompatible: Maybe<Scalars["Boolean"]>;
}

export interface RetailOrderDeliveryReschedulingReasonDto {
  __typename?: "RetailOrderDeliveryReschedulingReasonDTO";
  type: Maybe<Scalars["String"]>;
  value: Maybe<Scalars["String"]>;
}

export type RetailOrderFinanceCancelReason =
  | "ACTIVE_FIN_APPLICATION_ALREADY_EXISTS"
  | "CHARGEBACK"
  | "CUSTOMER_PAYED_WITH_CASH"
  | "EXPIRED"
  | "FINANCE_APPLICATION_REVOKED"
  | "NEW_FINANCE_APPLICATION"
  | "NO_DOCUMENTS_PROVIDED"
  | "ORDER_REVOKED"
  | "RECREATED"
  | "VEHICLE_NO_LONGER_AVAILABLE";

export type RetailOrderFinanceCancelSubReason =
  | "NFA_ADD_OR_REMOVE_DISCOUNT"
  | "NFA_ADD_OR_REMOVE_PRODUCT"
  | "NFA_CHANGE_ADDRESS_DOB_NAME"
  | "NFA_CHANGE_CREDIT_PERIOD"
  | "NFA_CHANGE_DOWNPAYMENT"
  | "NFA_CHANGE_EMPLOYMENT_DETAILS"
  | "NFA_PAYSLIPS_NOT_MATCH_NET_SALARY";

export type RetailOrderFinanceRejectReason =
  | "ACCOUNT_OVERDRAWN"
  | "CREDIT_SCORE_TOO_LOW"
  | "CUSTOMER_NAME_MISMATCH"
  | "CUSTOMER_UNEMPLOYED"
  | "DUPLICATE_IBAN"
  | "EXPERIAN_NO_RESULT"
  | "EXPERIAN_TECHNICAL_PROBLEM"
  | "FRAUD"
  | "FRAUD_IBAN"
  | "GAMBLING_EXPENSES"
  | "HIGH_DEBT"
  | "HIGH_FINANCING_AMOUNT"
  | "INCOME_MONTH"
  | "LENGTH_OF_EMPLOYMENT"
  | "LOW_DEPOSIT"
  | "LOW_INCOME"
  | "LOW_SALARY"
  | "NO_ELIGIBLE_PLAN"
  | "REJECTED_BY_BANK"
  | "SCHUFA_CUSTOMER_IS_NOT_KNOWN"
  | "SCHUFA_ELECTRONIC_REPORT_NOT_POSSIBLE"
  | "SCHUFA_INVALID_USER_DATA"
  | "SCHUFA_MANUAL_PROCESSING_NOT_WANTED"
  | "SCHUFA_MANUAL_PROCESSING_REQUIRED"
  | "SCHUFA_NO_INFORMATION_FOR_KNOWN_CUSTOMER"
  | "SCHUFA_NO_RESULT"
  | "SCHUFA_SCORE_ERRORS"
  | "SCHUFA_TECHNICAL_PROBLEM"
  | "SCHUFA_UNKNOWN_ERROR"
  | "TEMPORARY_CONTRACT"
  | "UNEMPLOYED"
  | "VEHICLE_TOO_OLD";

export type RetailOrderFinancingFlowType =
  | "AH_DIGITAL"
  | "AH_MANUAL"
  | "MANUAL";

export type RetailOrderFinancingFlowVersion = "V1" | "V2";

export interface RetailOrderHandoverProjection {
  __typename?: "RetailOrderHandoverProjection";
  allowedWithoutFullPayment: Maybe<Scalars["Boolean"]>;
  id: Maybe<Scalars["UUID"]>;
  onDeliveryPaymentType: Maybe<Scalars["String"]>;
  orderId: Maybe<Scalars["UUID"]>;
  paymentShouldBeDoneOnDelivery: Maybe<Scalars["Boolean"]>;
}

export interface RetailOrderItemDto {
  __typename?: "RetailOrderItemDTO";
  cartId: Maybe<Scalars["UUID"]>;
  created: Maybe<Scalars["String"]>;
  currencyCode: Maybe<Scalars["String"]>;
  discountGrossMinorUnits: Maybe<Scalars["Long"]>;
  externalId: Maybe<Scalars["UUID"]>;
  externalType: Maybe<Scalars["Int"]>;
  id: Maybe<Scalars["UUID"]>;
  invoiceDescription: Maybe<Scalars["String"]>;
  invoiceName: Maybe<Scalars["String"]>;
  orderId: Maybe<Scalars["UUID"]>;
  parentId: Maybe<Scalars["UUID"]>;
  pnlCostPriceGross: Maybe<PriceDto>;
  pnlCostPriceNet: Maybe<PriceDto>;
  pnlCostPriceVatAmount: Maybe<PriceDto>;
  pnlCostVatRate: Maybe<Scalars["Float"]>;
  priceDiscountGross: Maybe<PriceDto>;
  priceDiscountNet: Maybe<PriceDto>;
  priceDiscountVat: Maybe<PriceDto>;
  priceGross: Maybe<PriceDto>;
  priceGrossMinorUnits: Maybe<Scalars["Long"]>;
  priceNet: Maybe<PriceDto>;
  priceNetMinorUnits: Maybe<Scalars["Long"]>;
  priceTaxAdvantageAmount: Maybe<PriceDto>;
  priceVatAmount: Maybe<PriceDto>;
  taxAdvantageAmountMinorUnits: Maybe<Scalars["Long"]>;
  type: Maybe<Scalars["String"]>;
  updated: Maybe<Scalars["String"]>;
  vatAmountMinorUnits: Maybe<Scalars["Long"]>;
  vatKey: Maybe<Scalars["String"]>;
  vatRate: Maybe<Scalars["Float"]>;
  zipcode: Maybe<Scalars["String"]>;
}

export interface RetailOrderRefundsDistributeProjectionInput {
  items?: InputMaybe<
    Array<InputMaybe<CreditNoteItemDistributionProjectionInput>>
  >;
  orderId?: InputMaybe<Scalars["UUID"]>;
  reason?: InputMaybe<Scalars["String"]>;
}

export interface RetailOrderRefundsDistributionProjection {
  __typename?: "RetailOrderRefundsDistributionProjection";
  amount: Maybe<RetailFinancePriceProjection>;
  claimDetails: Maybe<ClaimDetailsProjection>;
  creditNoteOutboundPaymentRefs: Maybe<
    Array<Maybe<CreditNoteOutboundPaymentRefDistributionProjection>>
  >;
  creditNotes: Maybe<Array<Maybe<CreditNoteDistributionProjection>>>;
  inboundOutboundPaymentRefs: Maybe<
    Array<Maybe<InboundOutboundPaymentRefDistributionProjection>>
  >;
  orderId: Maybe<Scalars["UUID"]>;
  refunds: Maybe<Array<Maybe<RefundDistributionProjection>>>;
}

export interface RetailOrderRefundsDistributionProjectionInput {
  amount?: InputMaybe<RetailFinancePriceProjectionInput>;
  claimDetails?: InputMaybe<ClaimDetailsProjectionInput>;
  creditNoteOutboundPaymentRefs?: InputMaybe<
    Array<InputMaybe<CreditNoteOutboundPaymentRefDistributionProjectionInput>>
  >;
  creditNotes?: InputMaybe<
    Array<InputMaybe<CreditNoteDistributionProjectionInput>>
  >;
  inboundOutboundPaymentRefs?: InputMaybe<
    Array<InputMaybe<InboundOutboundPaymentRefDistributionProjectionInput>>
  >;
  orderId?: InputMaybe<Scalars["UUID"]>;
  refunds?: InputMaybe<Array<InputMaybe<RefundDistributionProjectionInput>>>;
}

export interface RetailOrderReturnAppointmentProjection {
  __typename?: "RetailOrderReturnAppointmentProjection";
  createdAt: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  orderDeliveryId: Maybe<Scalars["UUID"]>;
  orderId: Maybe<Scalars["UUID"]>;
  plannedCarReturnDate: Maybe<Scalars["String"]>;
  returnDeliveryType: Maybe<Scalars["String"]>;
}

export interface RetailOrderStatisticsDto {
  __typename?: "RetailOrderStatisticsDTO";
  ordersCTR: Maybe<Scalars["Float"]>;
  ordersPerDay: Maybe<Scalars["Float"]>;
  ordersPerWeek: Maybe<Scalars["Float"]>;
  totalOrders: Maybe<Scalars["Long"]>;
  totalOrdersCanceled: Maybe<Scalars["Long"]>;
  totalOrdersConfirmed: Maybe<Scalars["Long"]>;
}

export interface RetailOutboundPaymentProjection {
  __typename?: "RetailOutboundPaymentProjection";
  accountDetails: Maybe<BankAccountDetailsProjection>;
  accountingDate: Maybe<Scalars["String"]>;
  amount: Maybe<PriceDto>;
  createdBy: Maybe<Scalars["UUID"]>;
  createdOn: Maybe<Scalars["String"]>;
  creditNoteRefs: Maybe<Array<Maybe<CreditNoteProjection>>>;
  description: Maybe<Scalars["String"]>;
  externalId: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  inboundPaymentRefs: Maybe<Array<Maybe<RetailPaymentProjection>>>;
  oraclePaymentDetails: Maybe<OutboundPaymentOracleDetailsProjection>;
  orderId: Maybe<Scalars["UUID"]>;
  paymentType: Maybe<Scalars["String"]>;
  purpose: Maybe<RetailOutboundPaymentPurpose>;
  reason: Maybe<RetailOutboundPaymentReasonProjection>;
  status: Maybe<RetailOutboundPaymentStatus>;
  updatedBy: Maybe<Scalars["UUID"]>;
  updatedOn: Maybe<Scalars["String"]>;
}

export type RetailOutboundPaymentPurpose =
  | "CHARGEBACK"
  | "CREDIT_NOTE_REFUND"
  | "DAMAGE_COMPENSATION"
  | "OVERPAYMENT"
  | "TRADE_IN_OVERPAYMENT";

export type RetailOutboundPaymentReason =
  | "ADYEN_CHARGEBACK"
  | "COMPENSATION_DELAY"
  | "COMPENSATION_GENERAL"
  | "CUSTOMER_CHANGED_BANK_ACCOUNT"
  | "DAMAGE_COMPENSATION"
  | "LAWYER_REQUEST"
  | "OTHER"
  | "TRADE_IN_WITHOUT_BANK_DETAILS";

export interface RetailOutboundPaymentReasonProjection {
  __typename?: "RetailOutboundPaymentReasonProjection";
  description: Maybe<Scalars["String"]>;
  type: Maybe<RetailOutboundPaymentReason>;
}

export interface RetailOutboundPaymentReasonProjectionInput {
  description?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<RetailOutboundPaymentReason>;
}

export type RetailOutboundPaymentStatus = "FAILURE" | "PENDING" | "SUCCESS";

export interface RetailOutboundPaymentUpdateProjectionInput {
  accountingDate?: InputMaybe<Scalars["String"]>;
}

export interface RetailPaymentCreateProjectionInput {
  accountDetails?: InputMaybe<RetailAccountDetailsCreateProjectionInput>;
  accountingDate?: InputMaybe<Scalars["String"]>;
  amountMinorUnits?: InputMaybe<Scalars["Long"]>;
  currencyCode?: InputMaybe<Scalars["String"]>;
  externalId?: InputMaybe<Scalars["String"]>;
  externalToken?: InputMaybe<Scalars["String"]>;
  orderId?: InputMaybe<Scalars["UUID"]>;
  type?: InputMaybe<Scalars["String"]>;
}

export type RetailPaymentCreationSource =
  | "AUTOMATED_BATCH_IMPORT"
  | "DEUTSCHE_BANK_API"
  | "EXTERNAL_PAYMENT_SYSTEM"
  | "MANUALLY_CREATED"
  | "MANUAL_BATCH_IMPORTER";

export interface RetailPaymentDataProjection {
  __typename?: "RetailPaymentDataProjection";
  accountHolder: Maybe<Scalars["String"]>;
  accountingDate: Maybe<Scalars["String"]>;
  amount: Maybe<Scalars["Float"]>;
  bankAccount: Maybe<Scalars["String"]>;
  bankReferenceNumber: Maybe<Scalars["String"]>;
  bic: Maybe<Scalars["String"]>;
  countryCode: Maybe<Scalars["String"]>;
  currencyCode: Maybe<CurrencyCodeProjection>;
  customerPaymentReference: Maybe<Scalars["String"]>;
  endToEndReference: Maybe<Scalars["String"]>;
  iban: Maybe<Scalars["String"]>;
  orderNumbers: Maybe<Array<Maybe<Scalars["String"]>>>;
  paymentDate: Maybe<Scalars["String"]>;
  paymentType: Maybe<Scalars["String"]>;
  transactionCode: Maybe<Scalars["Long"]>;
}

export interface RetailPaymentImportCreateProjectionInput {
  fileName?: InputMaybe<Scalars["String"]>;
}

export interface RetailPaymentImportDetailsProjection {
  __typename?: "RetailPaymentImportDetailsProjection";
  customerPaymentReference: Maybe<Scalars["String"]>;
  endToEndReference: Maybe<Scalars["String"]>;
  paymentDate: Maybe<Scalars["String"]>;
  recipientBankAccount: Maybe<Scalars["String"]>;
  transactionCode: Maybe<Scalars["String"]>;
}

export interface RetailPaymentImportDetailsProjectionInput {
  customerPaymentReference?: InputMaybe<Scalars["String"]>;
  endToEndReference?: InputMaybe<Scalars["String"]>;
  paymentDate?: InputMaybe<Scalars["String"]>;
  recipientBankAccount?: InputMaybe<Scalars["String"]>;
  transactionCode?: InputMaybe<Scalars["String"]>;
}

export interface RetailPaymentOracleDetailsWrapperProjection {
  __typename?: "RetailPaymentOracleDetailsWrapperProjection";
  accountingDate: Maybe<Scalars["String"]>;
  paymentNumber: Maybe<Scalars["String"]>;
  status: Maybe<Scalars["String"]>;
}

export interface RetailPaymentProjection {
  __typename?: "RetailPaymentProjection";
  accountDetails: Maybe<BankAccountDetailsProjection>;
  accountingDate: Maybe<Scalars["String"]>;
  amount: Maybe<RetailFinancePriceProjection>;
  countryCode: Maybe<Scalars["String"]>;
  createdBy: Maybe<Scalars["UUID"]>;
  createdOn: Maybe<Scalars["String"]>;
  creationSource: Maybe<RetailPaymentCreationSource>;
  disputes: Maybe<Array<Maybe<RetailDisputeProjection>>>;
  externalId: Maybe<Scalars["String"]>;
  externalPaymentSystem: Maybe<ExternalPaymentSystem>;
  externalToken: Maybe<Scalars["String"]>;
  failureReason: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  importDetails: Maybe<RetailPaymentImportDetailsProjection>;
  orderId: Maybe<Scalars["UUID"]>;
  paymentDate: Maybe<Scalars["String"]>;
  remainingRefundAmount: Maybe<RemainingRefundAmountProjection>;
  retailTransactionId: Maybe<Scalars["UUID"]>;
  retailTransactionStatus: Maybe<Scalars["String"]>;
  status: Maybe<RetailPaymentStatus>;
  successDuplicateByAdyen: Maybe<Scalars["Boolean"]>;
  type: Maybe<Scalars["String"]>;
  updatedBy: Maybe<Scalars["UUID"]>;
  updatedOn: Maybe<Scalars["String"]>;
}

export interface RetailPaymentProjectionInput {
  accountDetails?: InputMaybe<BankAccountDetailsProjectionInput>;
  accountingDate?: InputMaybe<Scalars["String"]>;
  amount?: InputMaybe<RetailFinancePriceProjectionInput>;
  countryCode?: InputMaybe<Scalars["String"]>;
  createdBy?: InputMaybe<Scalars["UUID"]>;
  createdOn?: InputMaybe<Scalars["String"]>;
  creationSource?: InputMaybe<RetailPaymentCreationSource>;
  disputes?: InputMaybe<Array<InputMaybe<RetailDisputeProjectionInput>>>;
  externalId?: InputMaybe<Scalars["String"]>;
  externalPaymentSystem?: InputMaybe<ExternalPaymentSystem>;
  externalToken?: InputMaybe<Scalars["String"]>;
  failureReason?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["UUID"]>;
  importDetails?: InputMaybe<RetailPaymentImportDetailsProjectionInput>;
  orderId?: InputMaybe<Scalars["UUID"]>;
  paymentDate?: InputMaybe<Scalars["String"]>;
  retailTransactionId?: InputMaybe<Scalars["UUID"]>;
  retailTransactionStatus?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<RetailPaymentStatus>;
  successDuplicateByAdyen?: InputMaybe<Scalars["Boolean"]>;
  type?: InputMaybe<Scalars["String"]>;
  updatedBy?: InputMaybe<Scalars["UUID"]>;
  updatedOn?: InputMaybe<Scalars["String"]>;
}

export type RetailPaymentStatus = "FAILURE" | "PENDING" | "SUCCESS";

export interface RetailPaymentUpdateProjectionInput {
  accountingDate?: InputMaybe<Scalars["String"]>;
  externalId?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
}

export interface RetailPaymentWrapperProjection {
  __typename?: "RetailPaymentWrapperProjection";
  accountingDate: Maybe<Scalars["String"]>;
  paymentDate: Maybe<Scalars["String"]>;
  paymentOracleDetails: Maybe<RetailPaymentOracleDetailsWrapperProjection>;
  paymentType: Maybe<Scalars["String"]>;
  status: Maybe<Scalars["String"]>;
  type: Maybe<Scalars["String"]>;
}

export interface RetailPermissionDto {
  __typename?: "RetailPermissionDTO";
  createdBy: Maybe<Scalars["UUID"]>;
  createdOn: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  name: Maybe<Scalars["String"]>;
}

export interface RetailPricingCostDto {
  __typename?: "RetailPricingCostDTO";
  amountMinorUnits: Maybe<Scalars["Int"]>;
  currencyCode: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  note: Maybe<Scalars["String"]>;
  retailAdId: Maybe<Scalars["UUID"]>;
  retailPricingCostType: Maybe<RetailPricingCostType>;
}

export type RetailPricingCostType =
  | "ESTIMATED_REPAIR_MAINTENANCE"
  | "ESTIMATED_REPAIR_MECHANICAL"
  | "ESTIMATED_REPAIR_OPTICAL"
  | "ESTIMATED_REPAIR_OTHER";

export type RetailProductCategory =
  | "CAR"
  | "CUSTOM_LICENSE_PLATE"
  | "PRODUCT"
  | "REFURBISHMENT"
  | "SERVICE"
  | "TAX"
  | "TRANSPORT"
  | "WARRANTY";

export interface RetailProductDto {
  __typename?: "RetailProductDTO";
  active: Maybe<Scalars["Boolean"]>;
  category: Maybe<Scalars["String"]>;
  country: Maybe<Scalars["String"]>;
  createdOn: Maybe<Scalars["String"]>;
  currencyCode: Maybe<Scalars["String"]>;
  defaulted: Maybe<Scalars["Boolean"]>;
  depositApplicable: Maybe<Scalars["Boolean"]>;
  depositPercentage: Maybe<Scalars["Float"]>;
  excludedFromInvoice: Maybe<Scalars["Boolean"]>;
  id: Maybe<Scalars["UUID"]>;
  invoiceDescription: Maybe<Scalars["String"]>;
  invoiceItemGross: Maybe<ProductPriceDto>;
  invoiceItemGrossMinorUnits: Maybe<Scalars["Long"]>;
  invoiceItemNet: Maybe<ProductPriceDto>;
  invoiceItemNetMinorUnits: Maybe<Scalars["Long"]>;
  invoiceItemVatAmount: Maybe<ProductPriceDto>;
  invoiceItemVatAmountMinorUnits: Maybe<Scalars["Long"]>;
  paidService: Maybe<Scalars["Boolean"]>;
  partner: Maybe<Scalars["String"]>;
  pnlCostGross: Maybe<ProductPriceDto>;
  pnlCostGrossMinorUnits: Maybe<Scalars["Long"]>;
  pnlCostNet: Maybe<ProductPriceDto>;
  pnlCostNetMinorUnits: Maybe<Scalars["Long"]>;
  pnlCostVatAmount: Maybe<ProductPriceDto>;
  pnlCostVatAmountMinorUnits: Maybe<Scalars["Long"]>;
  pnlVatApplicable: Maybe<Scalars["Boolean"]>;
  pnlVatRate: Maybe<Scalars["Float"]>;
  properties: Maybe<Scalars["String"]>;
  quantity: Maybe<Scalars["Int"]>;
  shippingRequired: Maybe<Scalars["Boolean"]>;
  sku: Maybe<Scalars["String"]>;
  title: Maybe<Scalars["String"]>;
  type: Maybe<Scalars["String"]>;
  updatedOn: Maybe<Scalars["String"]>;
  vatKey: Maybe<Scalars["String"]>;
  vatRate: Maybe<Scalars["Float"]>;
}

export interface RetailProductProjection {
  __typename?: "RetailProductProjection";
  active: Scalars["Boolean"];
  category: Maybe<Scalars["String"]>;
  country: Maybe<Scalars["String"]>;
  createdOn: Maybe<Scalars["String"]>;
  currencyCode: Maybe<Scalars["String"]>;
  defaulted: Scalars["Boolean"];
  depositApplicable: Scalars["Boolean"];
  depositPercentage: Maybe<Scalars["Float"]>;
  excludedFromInvoice: Maybe<Scalars["Boolean"]>;
  id: Maybe<Scalars["UUID"]>;
  invoiceDescription: Maybe<Scalars["String"]>;
  invoiceItemGross: Maybe<PriceDto>;
  invoiceItemGrossMinorUnits: Maybe<Scalars["Long"]>;
  invoiceItemNet: Maybe<PriceDto>;
  invoiceItemNetMinorUnits: Maybe<Scalars["Long"]>;
  invoiceItemVatAmount: Maybe<PriceDto>;
  invoiceItemVatAmountMinorUnits: Maybe<Scalars["Long"]>;
  paidService: Scalars["Boolean"];
  partner: Maybe<Scalars["String"]>;
  pnlCostGross: Maybe<PriceDto>;
  pnlCostGrossMinorUnits: Maybe<Scalars["Long"]>;
  pnlCostNet: Maybe<PriceDto>;
  pnlCostNetMinorUnits: Maybe<Scalars["Long"]>;
  pnlCostVatAmount: Maybe<PriceDto>;
  pnlCostVatAmountMinorUnits: Maybe<Scalars["Long"]>;
  pnlVatApplicable: Scalars["Boolean"];
  pnlVatRate: Maybe<Scalars["Float"]>;
  properties: Maybe<Scalars["String"]>;
  quantity: Maybe<Scalars["Int"]>;
  shippingRequired: Scalars["Boolean"];
  sku: Maybe<Scalars["String"]>;
  title: Maybe<Scalars["String"]>;
  type: Maybe<Scalars["String"]>;
  updatedOn: Maybe<Scalars["String"]>;
  vatKey: Maybe<VatKeyType>;
  vatRate: Maybe<Scalars["Float"]>;
}

export interface RetailRefundCreateProjectionInput {
  accountDetails?: InputMaybe<RetailAccountDetailsCreateProjectionInput>;
  accountingDate?: InputMaybe<Scalars["String"]>;
  amountMinorUnits: Scalars["Long"];
  claimDetails?: InputMaybe<ClaimDetailsProjectionInput>;
  creditNoteId?: InputMaybe<Scalars["UUID"]>;
  description?: InputMaybe<Scalars["String"]>;
  externalId?: InputMaybe<Scalars["String"]>;
  orderId?: InputMaybe<Scalars["UUID"]>;
  paymentId?: InputMaybe<Scalars["UUID"]>;
  type?: InputMaybe<Scalars["String"]>;
}

export interface RetailRefundProjection {
  __typename?: "RetailRefundProjection";
  accountDetails: Maybe<BankAccountDetailsProjection>;
  accountingDate: Maybe<Scalars["String"]>;
  amount: Maybe<PriceDto>;
  createdBy: Maybe<Scalars["UUID"]>;
  createdOn: Maybe<Scalars["String"]>;
  creditNoteId: Maybe<Scalars["UUID"]>;
  description: Maybe<Scalars["String"]>;
  externalId: Maybe<Scalars["String"]>;
  failureReason: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  orderId: Maybe<Scalars["UUID"]>;
  paymentId: Maybe<Scalars["UUID"]>;
  status: Maybe<RetailRefundStatus>;
  type: Maybe<Scalars["String"]>;
  updatedBy: Maybe<Scalars["UUID"]>;
  updatedOn: Maybe<Scalars["String"]>;
}

export interface RetailRefundRetryProjectionInput {
  amountMinorUnits: Scalars["Long"];
}

export type RetailRefundStatus = "FAILURE" | "PENDING" | "SUCCESS";

export interface RetailRefundUpdateProjectionInput {
  accountingDate?: InputMaybe<Scalars["String"]>;
  externalId?: InputMaybe<Scalars["String"]>;
}

export interface RetailRefurbishmentStateChangeDtoInput {
  cancelDescription?: InputMaybe<Scalars["String"]>;
  cancelReason?: InputMaybe<Scalars["String"]>;
  completedReason?: InputMaybe<Scalars["String"]>;
  lastTransitionId?: InputMaybe<Scalars["UUID"]>;
  sourceType?: InputMaybe<Scalars["String"]>;
  stateTo?: InputMaybe<Scalars["String"]>;
  transitionDate?: InputMaybe<Scalars["String"]>;
}

export interface RetailRoleDto {
  __typename?: "RetailRoleDTO";
  createdBy: Maybe<Scalars["UUID"]>;
  createdOn: Maybe<Scalars["String"]>;
  description: Maybe<Scalars["String"]>;
  endpoint: Maybe<Scalars["String"]>;
  groups: Maybe<Array<Maybe<RetailGroupDto>>>;
  id: Maybe<Scalars["UUID"]>;
  imageUrl: Maybe<Scalars["String"]>;
  name: Maybe<Scalars["String"]>;
  page: Maybe<Scalars["String"]>;
  permissions: Maybe<Array<Maybe<RetailPermissionDto>>>;
  updatedOn: Maybe<Scalars["String"]>;
}

export interface RetailRouteAddressProjection {
  __typename?: "RetailRouteAddressProjection";
  city: Maybe<Scalars["String"]>;
  company: Maybe<Scalars["String"]>;
  contact: Maybe<Scalars["String"]>;
  country: Maybe<Scalars["String"]>;
  phone: Maybe<Scalars["String"]>;
  street: Maybe<Scalars["String"]>;
  street2: Maybe<Scalars["String"]>;
  zipcode: Maybe<Scalars["String"]>;
}

export interface RetailRouteItemProjection {
  __typename?: "RetailRouteItemProjection";
  city: Maybe<Scalars["String"]>;
  completedOn: Maybe<Scalars["String"]>;
  delay: Maybe<Scalars["Int"]>;
  eta: Maybe<Scalars["String"]>;
  itemType: Maybe<Scalars["String"]>;
  plannedCarHandoverOn: Maybe<Scalars["String"]>;
  plannedHandoverFrom: Maybe<Scalars["String"]>;
  plannedHandoverTo: Maybe<Scalars["String"]>;
  state: Maybe<Scalars["String"]>;
}

export interface RetailSubClaimCommentCreateProjectionInput {
  sourceType?: InputMaybe<Scalars["String"]>;
  text?: InputMaybe<Scalars["String"]>;
  visibleForCustomer?: InputMaybe<Scalars["Boolean"]>;
}

export interface RetailSubClaimCommentProjection {
  __typename?: "RetailSubClaimCommentProjection";
  createdBy: Maybe<UserProjection>;
  createdOn: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  sourceType: Maybe<Scalars["String"]>;
  subClaimId: Maybe<Scalars["UUID"]>;
  text: Maybe<Scalars["String"]>;
  visibleForCustomer: Maybe<Scalars["Boolean"]>;
}

export interface RetailSubClaimCreateDocumentProjectionInput {
  description?: InputMaybe<Scalars["String"]>;
  documentTypeId?: InputMaybe<Scalars["UUID"]>;
  fileName?: InputMaybe<Scalars["String"]>;
  fileSizeKb?: InputMaybe<Scalars["Int"]>;
  fullUrl?: InputMaybe<Scalars["String"]>;
  sourceType?: InputMaybe<Scalars["String"]>;
  visibleForCustomer?: InputMaybe<Scalars["Boolean"]>;
}

export interface RetailSubClaimCreateProjectionInput {
  areaId?: InputMaybe<Scalars["UUID"]>;
  carPartId?: InputMaybe<Scalars["UUID"]>;
  comment?: InputMaybe<RetailSubClaimCommentCreateProjectionInput>;
  comments?: InputMaybe<
    Array<InputMaybe<RetailSubClaimCommentCreateProjectionInput>>
  >;
  complaintId?: InputMaybe<Scalars["UUID"]>;
  damageId?: InputMaybe<Scalars["UUID"]>;
  liablePartyType?: InputMaybe<Scalars["String"]>;
}

export interface RetailSubClaimDocumentProjection {
  __typename?: "RetailSubClaimDocumentProjection";
  createdBy: Maybe<UserProjection>;
  createdOn: Maybe<Scalars["String"]>;
  deleted: Maybe<Scalars["Boolean"]>;
  description: Maybe<Scalars["String"]>;
  documentTypeId: Maybe<Scalars["UUID"]>;
  fileName: Maybe<Scalars["String"]>;
  fileSizeKb: Maybe<Scalars["Int"]>;
  fullUrl: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  sourceType: Maybe<Scalars["String"]>;
  subClaimId: Maybe<Scalars["UUID"]>;
  visibleForCustomer: Maybe<Scalars["Boolean"]>;
}

export interface RetailSubClaimProjection {
  __typename?: "RetailSubClaimProjection";
  areaId: Maybe<Scalars["UUID"]>;
  carPartId: Maybe<Scalars["UUID"]>;
  claimId: Maybe<Scalars["UUID"]>;
  comments: Maybe<Array<Maybe<RetailSubClaimCommentProjection>>>;
  complaintId: Maybe<Scalars["UUID"]>;
  costCurrencyCode: Maybe<Scalars["String"]>;
  costMinorUnits: Maybe<Scalars["Long"]>;
  createdBy: Maybe<UserProjection>;
  createdOn: Maybe<Scalars["String"]>;
  createdOnMillis: Maybe<Scalars["Long"]>;
  damageId: Maybe<Scalars["UUID"]>;
  documents: Maybe<Array<Maybe<RetailSubClaimDocumentProjection>>>;
  id: Maybe<Scalars["UUID"]>;
  liablePartyType: Maybe<Scalars["String"]>;
  number: Maybe<Scalars["String"]>;
  root: Maybe<Scalars["Boolean"]>;
  sourceType: Maybe<Scalars["String"]>;
  updatedOn: Maybe<Scalars["String"]>;
}

export interface RetailSubClaimUpdateProjectionInput {
  areaId?: InputMaybe<Scalars["UUID"]>;
  carPartId?: InputMaybe<Scalars["UUID"]>;
  damageId?: InputMaybe<Scalars["UUID"]>;
  liablePartyType?: InputMaybe<Scalars["String"]>;
}

export interface RetailSubProductDetailsProjection {
  __typename?: "RetailSubProductDetailsProjection";
  childProducts: Array<Maybe<RetailBaseProductProjection>>;
  primaryProduct: RetailBaseProductProjection;
}

export interface RetailSurveyScoreProjection {
  __typename?: "RetailSurveyScoreProjection";
  score: Maybe<Scalars["Int"]>;
  surveyType: Maybe<Scalars["String"]>;
}

export interface RetailTaxBpmProjection {
  __typename?: "RetailTaxBPMProjection";
  amount: Maybe<PriceDto>;
  id: Maybe<Scalars["UUID"]>;
}

export interface RetailTaxBpmUpdateProjectionInput {
  amountMinorUnits?: InputMaybe<Scalars["BigInteger"]>;
}

export interface RetailTaxNoVaProjection {
  __typename?: "RetailTaxNoVAProjection";
  applicable: Scalars["Boolean"];
  id: Maybe<Scalars["UUID"]>;
  note: Maybe<Scalars["String"]>;
}

export interface RetailTaxNoVaUpdateProjectionInput {
  applicable?: InputMaybe<Scalars["Boolean"]>;
  note?: InputMaybe<Scalars["String"]>;
}

export interface RetailTradeInCreateProjection {
  __typename?: "RetailTradeInCreateProjection";
  accountingDate: Maybe<Scalars["String"]>;
  carleadId: Maybe<Scalars["String"]>;
  countryCode: Maybe<Scalars["String"]>;
  make: Maybe<Scalars["String"]>;
  model: Maybe<Scalars["String"]>;
  onsitePrice: Maybe<Scalars["Long"]>;
  price: Maybe<RetailFinancePriceProjection>;
  stockNumber: Maybe<Scalars["String"]>;
  subModel: Maybe<Scalars["String"]>;
  vatKey: Maybe<Scalars["String"]>;
  vin: Maybe<Scalars["String"]>;
}

export interface RetailTradeInCreateProjectionInput {
  accountingDate?: InputMaybe<Scalars["String"]>;
  carleadId?: InputMaybe<Scalars["String"]>;
  countryCode?: InputMaybe<Scalars["String"]>;
  make?: InputMaybe<Scalars["String"]>;
  model?: InputMaybe<Scalars["String"]>;
  onsitePrice?: InputMaybe<Scalars["Long"]>;
  price?: InputMaybe<RetailFinancePriceProjectionInput>;
  stockNumber?: InputMaybe<Scalars["String"]>;
  subModel?: InputMaybe<Scalars["String"]>;
  vatKey?: InputMaybe<Scalars["String"]>;
  vin?: InputMaybe<Scalars["String"]>;
}

export interface RetailTradeInExtendedProjection {
  __typename?: "RetailTradeInExtendedProjection";
  accountingDate: Maybe<Scalars["String"]>;
  active: Scalars["Boolean"];
  attachable: Maybe<Scalars["Boolean"]>;
  carleadId: Maybe<Scalars["String"]>;
  confirmedFinancingDueAmountMinorUnits: Maybe<Scalars["Long"]>;
  costGrossPNL: Maybe<Scalars["Long"]>;
  costPNLVatPercentage: Maybe<Scalars["Float"]>;
  country: Maybe<Scalars["String"]>;
  createdBy: Maybe<Scalars["UUID"]>;
  createdOn: Maybe<Scalars["String"]>;
  currencyCode: Maybe<Scalars["String"]>;
  defaulted: Scalars["Boolean"];
  grossPriceMinorUnits: Maybe<Scalars["Long"]>;
  id: Maybe<Scalars["UUID"]>;
  internalReferenceName: Maybe<Scalars["String"]>;
  invoiceName: Maybe<Scalars["String"]>;
  isFinanced: Maybe<Scalars["Boolean"]>;
  netPriceMinorUnits: Maybe<Scalars["Long"]>;
  onsitePriceMinorUnits: Maybe<Scalars["Long"]>;
  orderId: Maybe<Scalars["UUID"]>;
  orderNumber: Maybe<Scalars["String"]>;
  outstandingFinancingDueAmountMinorUnits: Maybe<Scalars["Long"]>;
  partnerName: Maybe<Scalars["String"]>;
  price: Maybe<PriceDto>;
  publishedScope: Maybe<Scalars["String"]>;
  sellPriceValidTill: Maybe<Scalars["String"]>;
  shippingRequired: Scalars["Boolean"];
  state: Maybe<Scalars["String"]>;
  stockNumber: Maybe<Scalars["String"]>;
  updatedOn: Maybe<Scalars["String"]>;
  validTill: Maybe<Scalars["String"]>;
  vatKey: Maybe<Scalars["String"]>;
  vatPercentage: Maybe<Scalars["Float"]>;
  vin: Maybe<Scalars["String"]>;
}

export interface RetailTradeInItemProjection {
  __typename?: "RetailTradeInItemProjection";
  created: Maybe<Scalars["String"]>;
  currencyCode: Maybe<Scalars["String"]>;
  discountGrossMinorUnits: Maybe<Scalars["Long"]>;
  discountMinorUnits: Maybe<Scalars["Long"]>;
  externalId: Maybe<Scalars["UUID"]>;
  externalType: Maybe<Scalars["Int"]>;
  id: Maybe<Scalars["UUID"]>;
  invoiceDescription: Maybe<Scalars["String"]>;
  invoiceName: Maybe<Scalars["String"]>;
  orderId: Maybe<Scalars["UUID"]>;
  parentId: Maybe<Scalars["UUID"]>;
  priceDiscountGross: Maybe<PriceDto>;
  priceDiscountNet: Maybe<PriceDto>;
  priceDiscountVat: Maybe<PriceDto>;
  priceGross: Maybe<PriceDto>;
  priceGrossMinorUnits: Maybe<Scalars["Long"]>;
  priceNet: Maybe<PriceDto>;
  priceNetMinorUnits: Maybe<Scalars["Long"]>;
  priceTaxAdvantageAmount: Maybe<PriceDto>;
  priceVatAmount: Maybe<PriceDto>;
  tradeIn: Maybe<RetailTradeInProjection>;
  type: Maybe<Scalars["String"]>;
  updated: Maybe<Scalars["String"]>;
  vatAmountMinorUnits: Maybe<Scalars["Long"]>;
  vatKey: Maybe<Scalars["String"]>;
  vatRate: Maybe<Scalars["Float"]>;
}

export interface RetailTradeInProjection {
  __typename?: "RetailTradeInProjection";
  accountingDate: Maybe<Scalars["String"]>;
  active: Scalars["Boolean"];
  carleadId: Maybe<Scalars["String"]>;
  confirmedFinancingDueAmountMinorUnits: Maybe<Scalars["Long"]>;
  costGrossPNL: Maybe<Scalars["Long"]>;
  costPNLVatPercentage: Maybe<Scalars["Float"]>;
  country: Maybe<Scalars["String"]>;
  createdBy: Maybe<Scalars["UUID"]>;
  createdOn: Maybe<Scalars["String"]>;
  currencyCode: Maybe<Scalars["String"]>;
  defaulted: Scalars["Boolean"];
  grossPriceMinorUnits: Maybe<Scalars["Long"]>;
  id: Maybe<Scalars["UUID"]>;
  internalReferenceName: Maybe<Scalars["String"]>;
  invoiceName: Maybe<Scalars["String"]>;
  isFinanced: Maybe<Scalars["Boolean"]>;
  netPriceMinorUnits: Maybe<Scalars["Long"]>;
  onsitePriceMinorUnits: Maybe<Scalars["Long"]>;
  outstandingFinancingDueAmountMinorUnits: Maybe<Scalars["Long"]>;
  partnerName: Maybe<Scalars["String"]>;
  price: Maybe<PriceDto>;
  publishedScope: Maybe<Scalars["String"]>;
  sellPriceValidTill: Maybe<Scalars["String"]>;
  shippingRequired: Scalars["Boolean"];
  state: Maybe<Scalars["String"]>;
  stockNumber: Maybe<Scalars["String"]>;
  updatedOn: Maybe<Scalars["String"]>;
  validTill: Maybe<Scalars["String"]>;
  vatKey: Maybe<Scalars["String"]>;
  vatPercentage: Maybe<Scalars["Float"]>;
  vin: Maybe<Scalars["String"]>;
}

export interface RetailTransitionInternalTransferDtoInput {
  category?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["UUID"]>;
}

export interface RetailTrustpilotCustomerReviewProjection {
  __typename?: "RetailTrustpilotCustomerReviewProjection";
  stars: Scalars["Int"];
}

export interface RetailTrustpilotInvitationProjection {
  __typename?: "RetailTrustpilotInvitationProjection";
  consumerEmail: Maybe<Scalars["String"]>;
  createdOn: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  orderId: Maybe<Scalars["UUID"]>;
  preferredSendTime: Maybe<Scalars["String"]>;
  templateId: Maybe<Scalars["String"]>;
}

export interface RetailUnidentifiedPaymentDistributionItemProjectionInput {
  amountMinorUnits?: InputMaybe<Scalars["Long"]>;
  category?: InputMaybe<Scalars["String"]>;
  orderNumber?: InputMaybe<Scalars["String"]>;
}

export interface RetailUnidentifiedPaymentDistributionProjectionInput {
  items?: InputMaybe<
    Array<InputMaybe<RetailUnidentifiedPaymentDistributionItemProjectionInput>>
  >;
  unidentifiedPaymentId?: InputMaybe<Scalars["UUID"]>;
}

export interface RetailUnidentifiedPaymentProjection {
  __typename?: "RetailUnidentifiedPaymentProjection";
  accountingDate: Maybe<Scalars["String"]>;
  creationSource: Maybe<Scalars["String"]>;
  errorMessage: Maybe<Scalars["String"]>;
  externalId: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  paymentData: Maybe<RetailPaymentDataProjection>;
  status: Maybe<Scalars["String"]>;
  type: Maybe<Scalars["String"]>;
  uploadDate: Maybe<Scalars["String"]>;
}

export interface RetailUnidentifiedPaymentUpdateProjectionInput {
  accountHolder?: InputMaybe<Scalars["String"]>;
  bic?: InputMaybe<Scalars["String"]>;
  customerPaymentReference?: InputMaybe<Scalars["String"]>;
  iban?: InputMaybe<Scalars["String"]>;
  paymentType?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
}

export interface RetailUserDto {
  __typename?: "RetailUserDTO";
  accessParameters: Maybe<Array<Maybe<RetailAccessParamDto>>>;
  billingAddressId: Maybe<Scalars["UUID"]>;
  carRegistrationAddressId: Maybe<Scalars["UUID"]>;
  country: Maybe<Scalars["String"]>;
  createdBy: Maybe<Scalars["UUID"]>;
  createdOn: Maybe<Scalars["String"]>;
  deliveryAddressId: Maybe<Scalars["UUID"]>;
  effectivePermissions: Maybe<Array<Maybe<Scalars["String"]>>>;
  email: Maybe<Scalars["String"]>;
  enabled: Scalars["Boolean"];
  firstName: Maybe<Scalars["String"]>;
  groups: Maybe<Array<Maybe<RetailGroupDto>>>;
  id: Maybe<Scalars["UUID"]>;
  lastName: Maybe<Scalars["String"]>;
  locale: Maybe<Scalars["String"]>;
  loginTime: Maybe<Scalars["String"]>;
  phone: Maybe<Scalars["String"]>;
  registeredFrom: Maybe<Scalars["Int"]>;
  salutation: Maybe<Scalars["Int"]>;
  userRegistrationSource: Maybe<Scalars["String"]>;
  userType: Maybe<Scalars["Int"]>;
  verified: Scalars["Boolean"];
}

export interface RetailVehiclePreparationCreateProjectionInput {
  active: Scalars["Boolean"];
  country: Scalars["String"];
  currencyCode: Scalars["String"];
  defaulted: Scalars["Boolean"];
  grossPriceMinorUnits: Scalars["Int"];
  internalReferenceName?: InputMaybe<Scalars["String"]>;
  invoiceDescription: Scalars["String"];
  invoiceName?: InputMaybe<Scalars["String"]>;
  partnerName: Scalars["String"];
  pnlCostGrossPriceMinorUnits?: InputMaybe<Scalars["Int"]>;
  pnlCostVatKey?: InputMaybe<VatKeyType>;
  pnlCostVatPercentage?: InputMaybe<Scalars["Float"]>;
  publishedScope: PublishedScopeType;
  quantity: Scalars["Int"];
  recommended?: InputMaybe<Scalars["Boolean"]>;
  refId: Scalars["String"];
  shippingRequired: Scalars["Boolean"];
  vatKey: VatKeyType;
  vatPercentage: Scalars["Float"];
}

export interface RetailVehiclePreparationProjection {
  __typename?: "RetailVehiclePreparationProjection";
  active: Scalars["Boolean"];
  country: Scalars["String"];
  createdBy: Maybe<Scalars["UUID"]>;
  createdOn: Maybe<Scalars["String"]>;
  currencyCode: Scalars["String"];
  deactivatedOn: Maybe<Scalars["String"]>;
  defaulted: Scalars["Boolean"];
  id: Scalars["UUID"];
  internalReferenceName: Maybe<Scalars["String"]>;
  invoiceDescription: Scalars["String"];
  invoiceName: Maybe<Scalars["String"]>;
  partnerName: Scalars["String"];
  pnlCostPrice: Maybe<PriceDetailsProjection>;
  price: PriceDetailsProjection;
  priceDetails: Maybe<Array<Maybe<PriceDetailsProjection>>>;
  productType: Scalars["String"];
  publishedScope: PublishedScopeType;
  quantity: Scalars["Int"];
  recommended: Maybe<Scalars["Boolean"]>;
  refId: Scalars["String"];
  shippingRequired: Scalars["Boolean"];
  subProductDetails: Maybe<RetailSubProductDetailsProjection>;
  updatedOn: Maybe<Scalars["String"]>;
}

export interface RetailVehiclePreparationSearchProjectionInput {
  adId?: InputMaybe<Scalars["UUID"]>;
  country?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<Scalars["Map_String_ObjectScalar"]>;
  onlyAvailable?: InputMaybe<Scalars["Boolean"]>;
  orderId?: InputMaybe<Scalars["UUID"]>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  priceModifiers?: InputMaybe<Scalars["Map_String_PriceModifierPayloadScalar"]>;
  sorts?: InputMaybe<Array<InputMaybe<SortOrderProjectionInput>>>;
  zipCode?: InputMaybe<Scalars["String"]>;
}

export interface RetailVehiclePreparationUpdateProjectionInput {
  active?: InputMaybe<Scalars["Boolean"]>;
  country?: InputMaybe<Scalars["String"]>;
  defaulted?: InputMaybe<Scalars["Boolean"]>;
  grossPriceMinorUnits?: InputMaybe<Scalars["Long"]>;
  internalReferenceName?: InputMaybe<Scalars["String"]>;
  invoiceDescription?: InputMaybe<Scalars["String"]>;
  invoiceName?: InputMaybe<Scalars["String"]>;
  partnerName?: InputMaybe<Scalars["String"]>;
  pnlCostGrossPriceMinorUnits?: InputMaybe<Scalars["Long"]>;
  pnlCostVatKey?: InputMaybe<VatKeyType>;
  pnlCostVatPercentage?: InputMaybe<Scalars["Float"]>;
  publishedScope?: InputMaybe<PublishedScopeType>;
  quantity?: InputMaybe<Scalars["Int"]>;
  recommended?: InputMaybe<Scalars["Boolean"]>;
  shippingRequired?: InputMaybe<Scalars["Boolean"]>;
  vatKey?: InputMaybe<VatKeyType>;
  vatPercentage?: InputMaybe<Scalars["Float"]>;
}

export interface RetailWarrantyCreateProjectionInput {
  active: Scalars["Boolean"];
  applicableCarVatKey?: InputMaybe<VatKeyType>;
  country: Scalars["String"];
  currencyCode: Scalars["String"];
  defaulted: Scalars["Boolean"];
  grossPriceMinorUnits: Scalars["Int"];
  insuranceTax?: InputMaybe<Scalars["BigDecimal"]>;
  internalReferenceName?: InputMaybe<Scalars["String"]>;
  invoiceDescription: Scalars["String"];
  invoiceName?: InputMaybe<Scalars["String"]>;
  level: WarrantyLevelType;
  maxTotalMileage?: InputMaybe<MileageProjectionInput>;
  mileageCovered?: InputMaybe<MileageProjectionInput>;
  monthsCovered: Scalars["Int"];
  partnerName: Scalars["String"];
  pnlCostGrossPriceMinorUnits?: InputMaybe<Scalars["Int"]>;
  pnlCostVatKey?: InputMaybe<VatKeyType>;
  pnlCostVatPercentage?: InputMaybe<Scalars["Float"]>;
  publishedScope: PublishedScopeType;
  quantity: Scalars["Int"];
  recommended?: InputMaybe<Scalars["Boolean"]>;
  refId: Scalars["String"];
  shippingRequired: Scalars["Boolean"];
  source: WarrantySourceType;
  vatKey: VatKeyType;
  vatPercentage: Scalars["Float"];
}

export interface RetailWarrantyProjection {
  __typename?: "RetailWarrantyProjection";
  active: Scalars["Boolean"];
  applicableCarVatKey: Maybe<VatKeyType>;
  country: Scalars["String"];
  createdBy: Maybe<Scalars["UUID"]>;
  createdOn: Maybe<Scalars["String"]>;
  currencyCode: Scalars["String"];
  deactivatedOn: Maybe<Scalars["String"]>;
  defaulted: Scalars["Boolean"];
  id: Scalars["UUID"];
  insuranceTax: Maybe<Scalars["BigDecimal"]>;
  internalReferenceName: Maybe<Scalars["String"]>;
  invoiceDescription: Scalars["String"];
  invoiceName: Maybe<Scalars["String"]>;
  level: WarrantyLevelType;
  maxTotalMileage: Maybe<MileageProjection>;
  mileageCovered: Maybe<MileageProjection>;
  monthsCovered: Scalars["Int"];
  partnerName: Scalars["String"];
  pnlCostPrice: Maybe<PriceDetailsProjection>;
  price: PriceDetailsProjection;
  priceDetails: Maybe<Array<Maybe<PriceDetailsProjection>>>;
  productType: Scalars["String"];
  publishedScope: PublishedScopeType;
  quantity: Scalars["Int"];
  recommended: Maybe<Scalars["Boolean"]>;
  refId: Scalars["String"];
  shippingRequired: Scalars["Boolean"];
  source: WarrantySourceType;
  subProductDetails: Maybe<RetailSubProductDetailsProjection>;
  updatedOn: Maybe<Scalars["String"]>;
}

export interface RetailWarrantySearchProjectionInput {
  adId?: InputMaybe<Scalars["UUID"]>;
  country?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<Scalars["Map_String_ObjectScalar"]>;
  onlyAvailable?: InputMaybe<Scalars["Boolean"]>;
  orderId?: InputMaybe<Scalars["UUID"]>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  priceModifiers?: InputMaybe<Scalars["Map_String_PriceModifierPayloadScalar"]>;
  sorts?: InputMaybe<Array<InputMaybe<SortOrderProjectionInput>>>;
  zipCode?: InputMaybe<Scalars["String"]>;
}

export interface RetailWarrantyUpdateProjectionInput {
  active?: InputMaybe<Scalars["Boolean"]>;
  applicableCarVatKey?: InputMaybe<VatKeyType>;
  country?: InputMaybe<Scalars["String"]>;
  defaulted?: InputMaybe<Scalars["Boolean"]>;
  grossPriceMinorUnits?: InputMaybe<Scalars["Long"]>;
  insuranceTax?: InputMaybe<Scalars["BigDecimal"]>;
  internalReferenceName?: InputMaybe<Scalars["String"]>;
  invoiceDescription?: InputMaybe<Scalars["String"]>;
  invoiceName?: InputMaybe<Scalars["String"]>;
  partnerName?: InputMaybe<Scalars["String"]>;
  pnlCostGrossPriceMinorUnits?: InputMaybe<Scalars["Long"]>;
  pnlCostVatKey?: InputMaybe<VatKeyType>;
  pnlCostVatPercentage?: InputMaybe<Scalars["Float"]>;
  publishedScope?: InputMaybe<PublishedScopeType>;
  quantity?: InputMaybe<Scalars["Int"]>;
  recommended?: InputMaybe<Scalars["Boolean"]>;
  shippingRequired?: InputMaybe<Scalars["Boolean"]>;
  source?: InputMaybe<WarrantySourceType>;
  vatKey?: InputMaybe<VatKeyType>;
  vatPercentage?: InputMaybe<Scalars["Float"]>;
}

export interface RetailYouSignSignatureRequestProjection {
  __typename?: "RetailYouSignSignatureRequestProjection";
  createdOn: Scalars["String"];
  expirationDate: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  orderId: Scalars["UUID"];
  retailExternalId: Scalars["UUID"];
  signedOn: Maybe<Scalars["String"]>;
  signerId: Maybe<Scalars["UUID"]>;
  status: SignatureRequestState;
  type: DocumentSignInProcessType;
  updatedOn: Maybe<Scalars["String"]>;
  yousignRequestId: Maybe<Scalars["UUID"]>;
  yousignSignatureLink: Maybe<Scalars["String"]>;
  yousignSignatureUrl: Maybe<Scalars["String"]>;
}

export type RimMaterial =
  | "ALLOY"
  | "ALLOY_OEM"
  | "NO_RIMS"
  | "STEEL"
  | "STEEL_COVER";

export interface RimSize {
  __typename?: "RimSize";
  unit: Maybe<Unit>;
  value: Maybe<Scalars["Int"]>;
}

export type RimType =
  | "ALUMINIUM_SUPPLIER"
  | "ORIGINAL"
  | "STEEL"
  | "STEEL_WITH_COVER";

export interface RiskAgentProjection {
  __typename?: "RiskAgentProjection";
  createdOn: Maybe<Scalars["String"]>;
  financingId: Maybe<Scalars["UUID"]>;
  id: Maybe<Scalars["UUID"]>;
  riskAgentId: Maybe<Scalars["UUID"]>;
  updatedOn: Maybe<Scalars["String"]>;
}

export interface RiskData {
  __typename?: "RiskData";
  arvatoScore: Maybe<Scalars["Int"]>;
  averageDaysOverdrawn: Maybe<Scalars["Int"]>;
  countryCode: Maybe<Scalars["String"]>;
  encashmentPayments: Maybe<AmountInfo>;
  gamblingExpenses: Maybe<AmountInfo>;
  hasCreditCard: Maybe<Scalars["Boolean"]>;
  monthlyNetIncome: Maybe<AmountInfo>;
  unemployedBenefits: Maybe<AmountInfo>;
}

export interface RoleCreateProjectionInput {
  description?: InputMaybe<Scalars["String"]>;
  endpoint?: InputMaybe<Scalars["String"]>;
  imageUrl?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  page?: InputMaybe<Scalars["String"]>;
}

export interface RoleDto {
  __typename?: "RoleDTO";
  id: Maybe<Scalars["UUID"]>;
  name: Maybe<Scalars["String"]>;
  permissions: Maybe<Array<Maybe<PermissionDto>>>;
}

export interface RoleProjection {
  __typename?: "RoleProjection";
  createdBy: Maybe<Scalars["UUID"]>;
  createdOn: Maybe<Scalars["String"]>;
  description: Maybe<Scalars["String"]>;
  endpoint: Maybe<Scalars["String"]>;
  groups: Maybe<Array<Maybe<GroupProjection>>>;
  id: Maybe<Scalars["UUID"]>;
  imageUrl: Maybe<Scalars["String"]>;
  name: Maybe<Scalars["String"]>;
  page: Maybe<Scalars["String"]>;
  permissions: Maybe<Array<Maybe<PermissionProjection>>>;
  updatedOn: Maybe<Scalars["String"]>;
}

export interface RoleUpdateProjectionInput {
  description?: InputMaybe<Scalars["String"]>;
  page?: InputMaybe<Scalars["String"]>;
}

export type RouteStatus =
  | "CANCELLED"
  | "DRAFT"
  | "FINISHED"
  | "NOT_STARTED"
  | "STARTED";

export interface SalesAgentProjection {
  __typename?: "SalesAgentProjection";
  firstName: Maybe<Scalars["String"]>;
  lastName: Maybe<Scalars["String"]>;
  uuid: Maybe<Scalars["UUID"]>;
}

export type Salutation = "COMPANY" | "MR" | "MRS";

export interface SaveFinanceRiskEvaluationProjectionInput {
  contractType?: InputMaybe<Scalars["String"]>;
  employedUnderLocalWorkContract?: InputMaybe<Scalars["Boolean"]>;
  employmentStatus?: InputMaybe<Scalars["String"]>;
  orderId?: InputMaybe<Scalars["UUID"]>;
  sameEmployerOverTimePeriod?: InputMaybe<Scalars["Boolean"]>;
}

export interface SearchProjection2Input {
  filter?: InputMaybe<Scalars["Map_String_ObjectScalar"]>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sorts?: InputMaybe<Array<InputMaybe<SortOrderProjectionInput>>>;
}

export interface SearchRetailDocumentProjection {
  __typename?: "SearchRetailDocumentProjection";
  attachedTo: Maybe<Scalars["UUID"]>;
  /** @deprecated This field is kept for backwards compatibility with front-end. After front-end has stopped using it, it will be removed */
  createdBy: Maybe<Scalars["UUID"]>;
  createdByUser: Maybe<UserProjection>;
  createdOn: Maybe<Scalars["String"]>;
  /** @deprecated This field is kept for backwards compatibility with front-end. After front-end has stopped using it, it will be removed */
  deletedBy: Maybe<Scalars["UUID"]>;
  deletedByUser: Maybe<UserProjection>;
  deletedOn: Maybe<Scalars["String"]>;
  description: Maybe<Scalars["String"]>;
  documentType: Maybe<RetailDocumentTypeDto>;
  documentUploadSourceType: Maybe<Scalars["String"]>;
  fileName: Maybe<Scalars["String"]>;
  fullUrl: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  signatureExternalId: Maybe<Scalars["UUID"]>;
  signatureProvider: Maybe<Scalars["String"]>;
  signed: Maybe<Scalars["Boolean"]>;
  signedBy: Maybe<Scalars["UUID"]>;
  signedByUser: Maybe<UserProjection>;
  signedOn: Maybe<Scalars["String"]>;
  state: Maybe<Scalars["String"]>;
  verified: Maybe<Scalars["Boolean"]>;
  /** @deprecated This field is kept for backwards compatibility with front-end. After front-end has stopped using it, it will be removed */
  verifiedBy: Maybe<Scalars["UUID"]>;
  verifiedByUser: Maybe<UserProjection>;
  verifiedOn: Maybe<Scalars["String"]>;
}

export type SecondaryWheelsDamageCondition =
  | "DAMAGE"
  | "FULL_DAMAGE"
  | "MINOR_DAMAGE"
  | "NO_DAMAGE";

export interface SecondaryWheelsItemProjection {
  __typename?: "SecondaryWheelsItemProjection";
  created: Maybe<Scalars["String"]>;
  currencyCode: Maybe<Scalars["String"]>;
  discountGrossMinorUnits: Maybe<Scalars["Long"]>;
  discountMinorUnits: Maybe<Scalars["Long"]>;
  externalId: Maybe<Scalars["UUID"]>;
  externalType: Maybe<Scalars["Int"]>;
  id: Maybe<Scalars["UUID"]>;
  invoiceDescription: Maybe<Scalars["String"]>;
  invoiceName: Maybe<Scalars["String"]>;
  orderId: Maybe<Scalars["UUID"]>;
  parentId: Maybe<Scalars["UUID"]>;
  priceDiscountGross: Maybe<PriceDto>;
  priceDiscountNet: Maybe<PriceDto>;
  priceDiscountVat: Maybe<PriceDto>;
  priceGross: Maybe<PriceDto>;
  priceGrossMinorUnits: Maybe<Scalars["Long"]>;
  priceNet: Maybe<PriceDto>;
  priceNetMinorUnits: Maybe<Scalars["Long"]>;
  priceTaxAdvantageAmount: Maybe<PriceDto>;
  priceVatAmount: Maybe<PriceDto>;
  retailAdSecondaryWheels: Maybe<RetailAdSecondaryWheelsProjection>;
  type: Maybe<Scalars["String"]>;
  updated: Maybe<Scalars["String"]>;
  vatAmountMinorUnits: Maybe<Scalars["Long"]>;
  vatKey: Maybe<Scalars["String"]>;
  vatRate: Maybe<Scalars["Float"]>;
}

export type SecondaryWheelsLocation =
  | "IN_BRANCH"
  | "IN_CAR"
  | "OTHER"
  | "TWO_IN_CAR_TWO_IN_BRANCH";

export interface SecondaryWheelsOptionsProjection {
  __typename?: "SecondaryWheelsOptionsProjection";
  profileDepth: Maybe<Array<Maybe<Scalars["Int"]>>>;
  rimDamageTypes: Maybe<Array<Maybe<DamageTypeProjection>>>;
  rimType: Maybe<Array<Maybe<RimMaterial>>>;
  seasons: Maybe<Array<Maybe<TireSeasonType>>>;
  tireDamageTypes: Maybe<Array<Maybe<DamageTypeProjection>>>;
  wheelRadius: Maybe<Array<Maybe<Scalars["Int"]>>>;
}

export interface SellerAddressCreateProjectionInput {
  address2?: InputMaybe<Scalars["String"]>;
  city?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  houseNumber?: InputMaybe<Scalars["Int"]>;
  houseNumberAdditive?: InputMaybe<Scalars["String"]>;
  location?: InputMaybe<LocationInput>;
  street?: InputMaybe<Scalars["String"]>;
  zipcode?: InputMaybe<Scalars["String"]>;
}

export interface SellerAddressDto {
  __typename?: "SellerAddressDTO";
  address2: Maybe<Scalars["String"]>;
  city: Maybe<Scalars["String"]>;
  country: Maybe<Scalars["String"]>;
  created: Maybe<Scalars["String"]>;
  displayPhone: Scalars["Boolean"];
  houseNumber: Maybe<Scalars["Int"]>;
  houseNumberAdditive: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  location: Maybe<Location>;
  phone: Maybe<Scalars["String"]>;
  sellerId: Maybe<Scalars["UUID"]>;
  street: Maybe<Scalars["String"]>;
  updated: Maybe<Scalars["String"]>;
  zipcode: Maybe<Scalars["String"]>;
}

export interface SellerAddressProjection {
  __typename?: "SellerAddressProjection";
  address2: Maybe<Scalars["String"]>;
  city: Maybe<Scalars["String"]>;
  country: Maybe<Scalars["String"]>;
  created: Maybe<Scalars["String"]>;
  houseNumber: Maybe<Scalars["Int"]>;
  houseNumberAdditive: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  location: Maybe<Location>;
  sellerId: Maybe<Scalars["UUID"]>;
  street: Maybe<Scalars["String"]>;
  updated: Maybe<Scalars["String"]>;
  zipcode: Maybe<Scalars["String"]>;
}

export interface SellerAddressProjectionInput {
  address2?: InputMaybe<Scalars["String"]>;
  city?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  created?: InputMaybe<Scalars["String"]>;
  houseNumber?: InputMaybe<Scalars["Int"]>;
  houseNumberAdditive?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["UUID"]>;
  location?: InputMaybe<LocationInput>;
  sellerId?: InputMaybe<Scalars["UUID"]>;
  street?: InputMaybe<Scalars["String"]>;
  updated?: InputMaybe<Scalars["String"]>;
  zipcode?: InputMaybe<Scalars["String"]>;
}

export interface SellerContactDto {
  __typename?: "SellerContactDTO";
  countryCode: Maybe<Scalars["String"]>;
  created: Maybe<Scalars["String"]>;
  email: Maybe<Scalars["String"]>;
  firstname: Maybe<Scalars["String"]>;
  formattedPhoneNumber: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  lastname: Maybe<Scalars["String"]>;
  locale: Maybe<Scalars["String"]>;
  phone: Maybe<Scalars["String"]>;
  phoneNumber: Maybe<Scalars["String"]>;
  sellerId: Maybe<Scalars["UUID"]>;
  updated: Maybe<Scalars["String"]>;
}

export interface SellerDto {
  __typename?: "SellerDTO";
  aboutMeDescription: Maybe<Scalars["String"]>;
  addresses: Maybe<Array<Maybe<SellerAddressDto>>>;
  billingAddress: Maybe<SellerAddressDto>;
  billingContact: Maybe<SellerContactDto>;
  companyAddress: Maybe<SellerAddressDto>;
  companyContact: Maybe<SellerContactDto>;
  companyName: Maybe<Scalars["String"]>;
  contacts: Maybe<Array<Maybe<SellerContactDto>>>;
  defaultAddress: Maybe<SellerAddressDto>;
  defaultContact: Maybe<SellerContactDto>;
  description: Maybe<Scalars["String"]>;
  externalId: Maybe<Scalars["UUID"]>;
  firstName: Maybe<Scalars["String"]>;
  headerImage: Maybe<ImageDto>;
  id: Maybe<Scalars["UUID"]>;
  inventoryUpperBound: Maybe<Scalars["Int"]>;
  lastName: Maybe<Scalars["String"]>;
  legalAddress: Maybe<SellerAddressDto>;
  legalContact: Maybe<SellerContactDto>;
  legalForm: Maybe<Scalars["String"]>;
  logoImage: Maybe<ImageDto>;
  name: Maybe<Scalars["String"]>;
  openingHours: Maybe<Scalars["Map_String_OpeningHoursDTOScalar"]>;
  pathTitle: Maybe<Scalars["String"]>;
  salutation: Maybe<Salutation>;
  services: Maybe<Scalars["String"]>;
  type: Maybe<SellerType>;
  typeCode: Maybe<SellerType>;
  vehicleAddress: Maybe<SellerAddressDto>;
  vehicleContact: Maybe<SellerContactDto>;
  website: Maybe<Scalars["String"]>;
}

export type SellerType =
  | "AUX"
  | "MERCHANT"
  | "PRIVATE_LISTING"
  | "PRIVATE_SALES"
  | "RETAIL";

export interface SeverityProjection {
  __typename?: "SeverityProjection";
  severity: Maybe<Scalars["String"]>;
  translation: Maybe<Scalars["String"]>;
}

export interface ShippingAddressDetails {
  __typename?: "ShippingAddressDetails";
  city: Maybe<Scalars["String"]>;
  company: Maybe<Scalars["String"]>;
  contact: Maybe<Scalars["String"]>;
  country: Maybe<Scalars["String"]>;
  houseNumber: Maybe<Scalars["Int"]>;
  street: Maybe<Scalars["String"]>;
  street2: Maybe<Scalars["String"]>;
  zipcode: Maybe<Scalars["String"]>;
}

export interface ShippingProviderProjection {
  __typename?: "ShippingProviderProjection";
  address: Maybe<Scalars["String"]>;
  city: Scalars["String"];
  contactName: Maybe<Scalars["String"]>;
  country: Scalars["String"];
  email: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  name: Scalars["String"];
  phoneNumber: Maybe<Scalars["String"]>;
  typesOfDelivery: Array<Maybe<Scalars["String"]>>;
}

export type SignatureRequestState =
  | "APPROVAL"
  | "CANCELED"
  | "DELETED"
  | "DONE"
  | "DRAFT"
  | "EXPIRED"
  | "ONGOING"
  | "REJECTED";

export interface SortOrderProjectionInput {
  direction?: InputMaybe<Scalars["String"]>;
  property?: InputMaybe<Scalars["String"]>;
}

export interface Source {
  __typename?: "Source";
  channel: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["String"]>;
  rawExtraInfo: Maybe<Scalars["String"]>;
  type: Maybe<Type>;
}

export interface SparePartDto {
  __typename?: "SparePartDTO";
  comment: Maybe<Scalars["String"]>;
  createdBy: Maybe<Scalars["UUID"]>;
  createdOn: Maybe<Scalars["LocalDateTime"]>;
  id: Maybe<Scalars["UUID"]>;
  lastStatusUpdatedOn: Maybe<Scalars["LocalDateTime"]>;
  refurbishmentServiceId: Maybe<Scalars["UUID"]>;
  status: Maybe<Scalars["String"]>;
  updatedBy: Maybe<Scalars["UUID"]>;
  updatedOn: Maybe<Scalars["LocalDateTime"]>;
}

export interface SurveyAggregationProjection {
  __typename?: "SurveyAggregationProjection";
  activePage: Maybe<Scalars["Int"]>;
  answerMap: Maybe<
    Scalars["Map_String_SurveyAggregationAnswerProjectionScalar"]
  >;
  id: Maybe<Scalars["UUID"]>;
  notes: Maybe<Scalars["String"]>;
  pages: Maybe<Array<Maybe<SurveyPageProjection>>>;
  subTitle: Maybe<Scalars["String"]>;
  surveyStatus: Maybe<Scalars["String"]>;
  title: Maybe<Scalars["String"]>;
  type: Maybe<Scalars["String"]>;
}

export interface SurveyAnsweredQuestionProjection {
  __typename?: "SurveyAnsweredQuestionProjection";
  answerOptionTitles: Maybe<Array<Maybe<Scalars["String"]>>>;
  freeText: Maybe<Scalars["String"]>;
  questionTitle: Maybe<Scalars["String"]>;
  scores: Maybe<Scalars["Int"]>;
  subQuestions: Maybe<Array<Maybe<SurveyAnsweredQuestionProjection>>>;
  type: Maybe<Scalars["String"]>;
}

export interface SurveyAnsweredQuestionsProjection {
  __typename?: "SurveyAnsweredQuestionsProjection";
  questions: Maybe<Array<Maybe<SurveyAnsweredQuestionProjection>>>;
  updated: Maybe<Scalars["String"]>;
  updatedBy: Maybe<UserProjection>;
}

export interface SurveyPageProjection {
  __typename?: "SurveyPageProjection";
  pageNumber: Maybe<Scalars["Int"]>;
  surveyQuestions: Maybe<Array<Maybe<SurveyQuestionProjection>>>;
}

export interface SurveyQuestionOptionProjection {
  __typename?: "SurveyQuestionOptionProjection";
  id: Maybe<Scalars["UUID"]>;
  nextQuestionId: Maybe<Scalars["UUID"]>;
  order: Maybe<Scalars["Int"]>;
  scores: Maybe<Scalars["Int"]>;
  title: Maybe<Scalars["String"]>;
  value: Maybe<Scalars["String"]>;
}

export interface SurveyQuestionProjection {
  __typename?: "SurveyQuestionProjection";
  id: Maybe<Scalars["UUID"]>;
  order: Maybe<Scalars["Int"]>;
  questionOptions: Maybe<Array<Maybe<SurveyQuestionOptionProjection>>>;
  required: Scalars["Boolean"];
  subQuestions: Maybe<Array<Maybe<SurveyQuestionProjection>>>;
  subTitle: Maybe<Scalars["String"]>;
  title: Maybe<Scalars["String"]>;
  type: Maybe<Scalars["String"]>;
  visibilityDependsOnOptionId: Maybe<Scalars["UUID"]>;
}

export interface Tuvdto {
  __typename?: "TUVDTO";
  color: Maybe<Scalars["String"]>;
  huCertificate: Maybe<Scalars["String"]>;
  initialRegistration: Maybe<Scalars["String"]>;
  licensePlate: Maybe<Scalars["String"]>;
  manufacturer: Maybe<Scalars["String"]>;
  mileage: Maybe<Scalars["String"]>;
  number: Maybe<Scalars["String"]>;
  salesDescription: Maybe<Scalars["String"]>;
  vin: Maybe<Scalars["String"]>;
}

export interface TuvdtoInput {
  color?: InputMaybe<Scalars["String"]>;
  huCertificate?: InputMaybe<Scalars["String"]>;
  initialRegistration?: InputMaybe<Scalars["String"]>;
  licensePlate?: InputMaybe<Scalars["String"]>;
  manufacturer?: InputMaybe<Scalars["String"]>;
  mileage?: InputMaybe<Scalars["String"]>;
  number?: InputMaybe<Scalars["String"]>;
  salesDescription?: InputMaybe<Scalars["String"]>;
  vin?: InputMaybe<Scalars["String"]>;
}

export type TagType = "IMPERFECTION";

export interface TaskAssigneeProjection {
  __typename?: "TaskAssigneeProjection";
  countryAccess: Maybe<Array<Maybe<Scalars["String"]>>>;
  taskName: Maybe<Scalars["String"]>;
  userId: Maybe<Scalars["UUID"]>;
  userName: Maybe<Scalars["String"]>;
}

export interface TaskManagementBaseTaskProjection {
  __typename?: "TaskManagementBaseTaskProjection";
  action: Maybe<Scalars["String"]>;
  assignedOn: Maybe<Scalars["String"]>;
  assignedTo: Maybe<UserProjection>;
  assignee: Maybe<UserProjection>;
  closedBy: Maybe<UserProjection>;
  closedOn: Maybe<Scalars["String"]>;
  comment: Maybe<Scalars["String"]>;
  commentsCount: Maybe<Scalars["Int"]>;
  completedBy: Maybe<UserProjection>;
  completedOn: Maybe<Scalars["String"]>;
  createdOn: Maybe<Scalars["String"]>;
  financingStatus: Maybe<Scalars["String"]>;
  flowVersion: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  name: Maybe<Scalars["String"]>;
  order: Maybe<OrderProjection>;
  orderId: Maybe<Scalars["UUID"]>;
  orderNumber: Maybe<Scalars["String"]>;
  orderState: Maybe<Scalars["String"]>;
  paymentStatus: Maybe<Scalars["String"]>;
  paymentType: Maybe<Scalars["String"]>;
  reason: Maybe<Scalars["String"]>;
  repeatAt: Maybe<Scalars["String"]>;
  sequenceIndex: Scalars["Int"];
  state: Maybe<Scalars["String"]>;
  stockNumber: Maybe<Scalars["String"]>;
  taskDefinitionKey: Maybe<Scalars["String"]>;
  taskStatus: Maybe<Scalars["String"]>;
  taskType: Maybe<Scalars["String"]>;
  taskUpdatedAt: Maybe<Scalars["String"]>;
}

export interface TaskManagerAssigneeProjection {
  __typename?: "TaskManagerAssigneeProjection";
  countryAccess: Maybe<Array<Maybe<Scalars["String"]>>>;
  enabled: Scalars["Boolean"];
  taskNames: Maybe<Array<Maybe<Scalars["String"]>>>;
  userId: Maybe<Scalars["UUID"]>;
  userName: Maybe<Scalars["String"]>;
}

export interface TaskManagerCommentCreateProjectionInput {
  comment?: InputMaybe<Scalars["String"]>;
  taskId?: InputMaybe<Scalars["UUID"]>;
}

export interface TaskManagerCommentProjection {
  __typename?: "TaskManagerCommentProjection";
  comment: Maybe<Scalars["String"]>;
  createdBy: Maybe<RetailUserDto>;
  createdOn: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  orderId: Maybe<Scalars["UUID"]>;
  taskId: Maybe<Scalars["UUID"]>;
}

export interface TaskSpecificActionsProjection {
  __typename?: "TaskSpecificActionsProjection";
  completeActions: Maybe<Array<Maybe<Scalars["String"]>>>;
  taskDefinitionKey: Maybe<Scalars["String"]>;
  updateActions: Maybe<Array<Maybe<Scalars["String"]>>>;
}

export interface TasksManagerSearchOptionsProjection {
  __typename?: "TasksManagerSearchOptionsProjection";
  allowedToCommentTasks: Maybe<Array<Maybe<Scalars["String"]>>>;
  financingStatuses: Maybe<Array<Maybe<Scalars["String"]>>>;
  orderRetailCountries: Maybe<Array<Maybe<Scalars["String"]>>>;
  orderStates: Maybe<Array<Maybe<Scalars["String"]>>>;
  paymentStatuses: Maybe<Array<Maybe<Scalars["String"]>>>;
  paymentTypes: Maybe<Array<Maybe<Scalars["String"]>>>;
  taskActions: Maybe<Array<Maybe<RetailOrderBasedTaskAction>>>;
  taskManagerAssignees: Maybe<Array<Maybe<TaskManagerAssigneeProjection>>>;
  taskNames: Maybe<Array<Maybe<Scalars["String"]>>>;
  taskSpecificActions: Maybe<Array<Maybe<TaskSpecificActionsProjection>>>;
}

export interface TasksSearchOptionsProjection {
  __typename?: "TasksSearchOptionsProjection";
  orderRetailCountries: Maybe<Array<Maybe<Scalars["String"]>>>;
  orderStates: Maybe<Array<Maybe<Scalars["String"]>>>;
  taskNames: Maybe<Array<Maybe<Scalars["String"]>>>;
  tasksAssignees: Maybe<Array<Maybe<TaskAssigneeProjection>>>;
}

export type TaxAdvantageSource = "ALL" | "CAR_LEAD" | "RETAIL_AD";

export interface TemplateProjection {
  __typename?: "TemplateProjection";
  id: Maybe<Scalars["UUID"]>;
  locale: Maybe<Scalars["String"]>;
  market: Maybe<Scalars["String"]>;
  project: Maybe<Scalars["String"]>;
  replyRequired: Maybe<Scalars["Boolean"]>;
  title: Maybe<Scalars["String"]>;
  type: Maybe<Scalars["String"]>;
}

export type TireSeasonType = "ALL_SEASON" | "EMERGENCY" | "SUMMER" | "WINTER";

export type TireType = "ALL_WEATHER" | "EMERGENCY" | "SUMMER" | "WINTER";

export type Type = "AUTOMATIC_IMPORT" | "MANUAL_IMPORT";

export type Unit = "CM" | "IN" | "MM";

export type UnpublishedReason =
  | "CONSIGNED_TO_PRIVATE_SALE"
  | "EXPIRED"
  | "INVENTORY_INVALIDATED"
  | "MANUAL"
  | "SOLD_TO_AUTO1";

export interface UpdateDocumentPackageInput {
  deliveredOn?: InputMaybe<Scalars["String"]>;
  deliveryAddress: DocumentInventoryPackageAddressInput;
  documentCarItems: Array<InputMaybe<Scalars["UUID"]>>;
  estimatedDeliveryDate?: InputMaybe<Scalars["String"]>;
  packageId: Scalars["UUID"];
  pickedUpOn?: InputMaybe<Scalars["String"]>;
  shippingProvider: Scalars["Int"];
  trackingCode?: InputMaybe<Scalars["String"]>;
}

export interface UpdateDocumentPackagePartialInput {
  deliveredOn?: InputMaybe<Scalars["String"]>;
  estimatedDeliveryDate?: InputMaybe<Scalars["String"]>;
  packageId: Scalars["UUID"];
  pickedUpOn?: InputMaybe<Scalars["String"]>;
  shippingProvider?: InputMaybe<Scalars["Int"]>;
  trackingCode?: InputMaybe<Scalars["String"]>;
}

export type UpdateDocumentPackagePartialResult =
  | DocumentPackageNotOpenProblem
  | UpdateDocumentPackageProjection;

export interface UpdateDocumentPackageProjection {
  __typename?: "UpdateDocumentPackageProjection";
  documentInventoryPackages: ResultConnection_DocumentInventoryPackageProjection;
}

export type UpdateDocumentPackageResult =
  | DocumentItemsInPackageProblem
  | DocumentPackageConflictProblem
  | DocumentPackageEmptyBranchNameProblem
  | DocumentPackageEmptyFirstNameProblem
  | DocumentPackageEmptyLastNameProblem
  | DocumentPackageNoHouseNumAddressProblem
  | DocumentPackageNotOpenProblem
  | DocumentPackageZeroItemsProblem
  | DocumentPackagesGeneralProblem
  | UpdateDocumentPackageProjection;

export interface UpdateExternalFinanceRiskEvaluationProjectionInput {
  contractType?: InputMaybe<Scalars["String"]>;
  employmentStatus?: InputMaybe<Scalars["String"]>;
  monthlyNetIncomeMinorUnits?: InputMaybe<Scalars["Long"]>;
  sameEmployerOverTimePeriod?: InputMaybe<Scalars["Boolean"]>;
}

export interface UpdateExternalRiskUserInfoProjectionInput {
  accountHolder?: InputMaybe<Scalars["String"]>;
  additionalIncomeMinorUnits?: InputMaybe<Scalars["Long"]>;
  bankAccountOpenDate?: InputMaybe<Scalars["String"]>;
  contractValidFor?: InputMaybe<Scalars["Boolean"]>;
  employedBy?: InputMaybe<Scalars["String"]>;
  employmentContractType?: InputMaybe<Scalars["String"]>;
  employmentEndDate?: InputMaybe<Scalars["String"]>;
  employmentStartDate?: InputMaybe<Scalars["String"]>;
  employmentStatus?: InputMaybe<Scalars["String"]>;
  employmentType?: InputMaybe<Scalars["String"]>;
  housingConstruction?: InputMaybe<Scalars["String"]>;
  housingType?: InputMaybe<Scalars["String"]>;
  iban?: InputMaybe<Scalars["String"]>;
  incomeFromSubsidiesMinorUnits?: InputMaybe<Scalars["Long"]>;
  incomeSpread?: InputMaybe<Scalars["Int"]>;
  jobTitle?: InputMaybe<Scalars["String"]>;
  maritalStatus?: InputMaybe<Scalars["String"]>;
  mortgageOrRentPaymentMinorUnits?: InputMaybe<Scalars["Long"]>;
  movingDate?: InputMaybe<Scalars["String"]>;
  nationality?: InputMaybe<Scalars["String"]>;
  numberOfDependents?: InputMaybe<Scalars["Int"]>;
  numberOfOtherDependents?: InputMaybe<Scalars["Int"]>;
  numberOfOtherLoans?: InputMaybe<Scalars["Int"]>;
  otherLoanPaymentsMinorUnits?: InputMaybe<Scalars["Long"]>;
  pep?: InputMaybe<Scalars["Boolean"]>;
  placeOfBirth?: InputMaybe<Scalars["String"]>;
  previousAddressMovingDate?: InputMaybe<Scalars["String"]>;
  primaryIncomeMinorUnits?: InputMaybe<Scalars["Long"]>;
  residentSinceDate?: InputMaybe<Scalars["String"]>;
  sameEmployerOverTimePeriod?: InputMaybe<Scalars["Boolean"]>;
  sector?: InputMaybe<Scalars["String"]>;
  seniority?: InputMaybe<Scalars["String"]>;
  separationAllowanceMinorUnits?: InputMaybe<Scalars["Long"]>;
  separationOfGoodsInMarriage?: InputMaybe<Scalars["Boolean"]>;
  type?: InputMaybe<Scalars["String"]>;
  userId?: InputMaybe<Scalars["UUID"]>;
}

export interface UpdateRetailFeatureProjectionInput {
  condition?: InputMaybe<Scalars["Map_String_ObjectScalar"]>;
  description?: InputMaybe<Scalars["String"]>;
  enabled?: InputMaybe<Scalars["Boolean"]>;
}

export interface UpdateSurveyDtoInputInput {
  answers?: InputMaybe<Array<InputMaybe<UpsertAnswerDtoInputInput>>>;
}

export interface UpdateTestOrderDownPaymentProjectionInput {
  depositAmount?: InputMaybe<Scalars["Int"]>;
}

export interface UpsertAnswerDtoInputInput {
  freeText?: InputMaybe<Scalars["String"]>;
  optionId?: InputMaybe<Scalars["UUID"]>;
  questionId?: InputMaybe<Scalars["UUID"]>;
}

export interface UserCreateProjectionInput {
  checkoutAdId?: InputMaybe<Scalars["UUID"]>;
  country?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  locale?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  registeredFrom?: InputMaybe<Scalars["Int"]>;
  salutation?: InputMaybe<Scalars["Int"]>;
  userType?: InputMaybe<Scalars["Int"]>;
}

export interface UserOrdersInfoForVerificationProjection {
  __typename?: "UserOrdersInfoForVerificationProjection";
  auxUserId: Maybe<Scalars["UUID"]>;
  numberOfCanceledOrdersAfterHandover: Scalars["Long"];
  numberOfCanceledOrdersInLastSixMonths: Scalars["Long"];
  numberOfVerifiedOrders: Scalars["Long"];
}

export interface UserPasswordlessProjectionInput {
  checkoutAdId?: InputMaybe<Scalars["UUID"]>;
  email?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  locale?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  registeredFrom?: InputMaybe<Scalars["Int"]>;
  registrationUserSource?: InputMaybe<RegistrationUserSource>;
  salutation?: InputMaybe<Scalars["Int"]>;
}

export interface UserProjection {
  __typename?: "UserProjection";
  accessParameters: Maybe<Array<Maybe<RetailAccessParamDto>>>;
  billingAddressId: Maybe<Scalars["UUID"]>;
  carRegistrationAddressId: Maybe<Scalars["UUID"]>;
  country: Maybe<Scalars["String"]>;
  createdBy: Maybe<UserProjection>;
  createdOn: Maybe<Scalars["String"]>;
  deliveryAddressId: Maybe<Scalars["UUID"]>;
  effectivePermissions: Maybe<Array<Maybe<Scalars["String"]>>>;
  email: Maybe<Scalars["String"]>;
  enabled: Scalars["Boolean"];
  firstName: Maybe<Scalars["String"]>;
  groups: Maybe<Array<Maybe<GroupProjection>>>;
  id: Maybe<Scalars["UUID"]>;
  lastName: Maybe<Scalars["String"]>;
  locale: Maybe<Scalars["String"]>;
  loginTime: Maybe<Scalars["String"]>;
  phone: Maybe<Scalars["String"]>;
  properties: Maybe<Array<Maybe<UserPropertyProjection>>>;
  registeredFrom: Maybe<Scalars["Int"]>;
  salutation: Maybe<Scalars["Int"]>;
  userType: Maybe<Scalars["Int"]>;
  verified: Scalars["Boolean"];
}

export interface UserPropertyProjection {
  __typename?: "UserPropertyProjection";
  name: Maybe<Scalars["String"]>;
  value: Maybe<Scalars["String"]>;
}

export interface UserPropertyProjectionInput {
  name?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
}

export interface UserUpdateProjectionInput {
  billingAddressId?: InputMaybe<Scalars["UUID"]>;
  carRegistrationAddressId?: InputMaybe<Scalars["UUID"]>;
  country?: InputMaybe<Scalars["String"]>;
  deliveryAddressId?: InputMaybe<Scalars["UUID"]>;
  email?: InputMaybe<Scalars["String"]>;
  enabled?: InputMaybe<Scalars["Boolean"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  locale?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  registeredFrom?: InputMaybe<Scalars["Int"]>;
  salutation?: InputMaybe<Scalars["Int"]>;
  userType?: InputMaybe<Scalars["Int"]>;
  verified?: InputMaybe<Scalars["Boolean"]>;
}

export type VatKeyType = "E" | "I" | "M" | "R" | "S" | "V";

export interface VatRateProjection {
  __typename?: "VatRateProjection";
  countryCode: Maybe<Scalars["String"]>;
  vatRate: Maybe<Scalars["Float"]>;
}

export interface VehicleInfoProjection {
  __typename?: "VehicleInfoProjection";
  adId: Maybe<Scalars["UUID"]>;
  model: Maybe<Scalars["String"]>;
  stockNumber: Maybe<Scalars["String"]>;
  vin: Maybe<Scalars["String"]>;
}

export interface VehiclePreparationDto {
  __typename?: "VehiclePreparationDTO";
  active: Maybe<Scalars["Boolean"]>;
  country: Maybe<Scalars["String"]>;
  createdBy: Maybe<Scalars["UUID"]>;
  createdOn: Maybe<Scalars["String"]>;
  currencyCode: Maybe<Scalars["String"]>;
  deactivatedOn: Maybe<Scalars["String"]>;
  defaulted: Maybe<Scalars["Boolean"]>;
  id: Maybe<Scalars["UUID"]>;
  internalReferenceName: Maybe<Scalars["String"]>;
  invoiceDescription: Maybe<Scalars["String"]>;
  invoiceName: Maybe<Scalars["String"]>;
  partnerName: Maybe<Scalars["String"]>;
  pnlCostPrice: Maybe<PriceDetailsDto>;
  price: Maybe<PriceDetailsDto>;
  productType: Maybe<Scalars["String"]>;
  publishedScope: Maybe<Scalars["String"]>;
  quantity: Maybe<Scalars["Int"]>;
  recommended: Maybe<Scalars["Boolean"]>;
  refId: Maybe<Scalars["String"]>;
  shippingRequired: Maybe<Scalars["Boolean"]>;
  subProductDetails: Maybe<RetailBaseProductDtoSubProductDetails>;
  updatedOn: Maybe<Scalars["String"]>;
}

export interface VehiclePreparationItemProjection {
  __typename?: "VehiclePreparationItemProjection";
  created: Maybe<Scalars["String"]>;
  currencyCode: Maybe<Scalars["String"]>;
  discountGrossMinorUnits: Maybe<Scalars["Long"]>;
  discountMinorUnits: Maybe<Scalars["Long"]>;
  externalId: Maybe<Scalars["UUID"]>;
  externalType: Maybe<Scalars["Int"]>;
  id: Maybe<Scalars["UUID"]>;
  invoiceDescription: Maybe<Scalars["String"]>;
  invoiceName: Maybe<Scalars["String"]>;
  orderId: Maybe<Scalars["UUID"]>;
  parentId: Maybe<Scalars["UUID"]>;
  priceDiscountGross: Maybe<PriceDto>;
  priceDiscountNet: Maybe<PriceDto>;
  priceDiscountVat: Maybe<PriceDto>;
  priceGross: Maybe<PriceDto>;
  priceGrossMinorUnits: Maybe<Scalars["Long"]>;
  priceNet: Maybe<PriceDto>;
  priceNetMinorUnits: Maybe<Scalars["Long"]>;
  priceTaxAdvantageAmount: Maybe<PriceDto>;
  priceVatAmount: Maybe<PriceDto>;
  type: Maybe<Scalars["String"]>;
  updated: Maybe<Scalars["String"]>;
  vatAmountMinorUnits: Maybe<Scalars["Long"]>;
  vatKey: Maybe<Scalars["String"]>;
  vatRate: Maybe<Scalars["Float"]>;
  vehiclePreparationDTO: Maybe<VehiclePreparationDto>;
}

export interface VehicleProjection {
  __typename?: "VehicleProjection";
  acType: Maybe<Scalars["Int"]>;
  accidentCar: Maybe<Scalars["Boolean"]>;
  bodyForm: Maybe<Scalars["Int"]>;
  bodyType: Maybe<Scalars["Int"]>;
  builtYear: Maybe<Scalars["Int"]>;
  carAttrAccidentBool: Maybe<Scalars["Boolean"]>;
  carPreownerCount: Maybe<Scalars["Int"]>;
  carType: Maybe<CarType>;
  categorizedCustomEquipment: Maybe<Array<Maybe<CategorizedCustomEquipment>>>;
  ccm: Maybe<Scalars["Int"]>;
  certificates: Maybe<Scalars["Map_String_ObjectScalar"]>;
  co2Value: Maybe<Scalars["Int"]>;
  color: Maybe<ColorDto>;
  commonFuelConsumption: Maybe<Scalars["BigDecimal"]>;
  countryOfOrigin: Maybe<Scalars["String"]>;
  cylinderCount: Maybe<Scalars["Int"]>;
  damageBool: Maybe<Scalars["Boolean"]>;
  damageHistory: Maybe<Scalars["Int"]>;
  doorCount: Maybe<Scalars["Int"]>;
  emissionClass: Maybe<Scalars["String"]>;
  emissionStandard: Maybe<Scalars["Int"]>;
  emissionSticker: Maybe<Scalars["String"]>;
  energyEfficiencyClass: Maybe<Scalars["String"]>;
  equipmentGroups: Maybe<Array<Maybe<Scalars["Long"]>>>;
  equipments: Maybe<Array<Maybe<Scalars["Long"]>>>;
  externalId: Maybe<Scalars["String"]>;
  features: Maybe<Array<Maybe<Feature>>>;
  fuelConsumption: Maybe<FuelConsumptionDto>;
  fuelType: Maybe<Scalars["Int"]>;
  fuelTypeTranslation: Maybe<Scalars["String"]>;
  gearType: Maybe<Scalars["Int"]>;
  handoverKeyCount: Maybe<Scalars["Int"]>;
  hasAbs: Maybe<Scalars["Boolean"]>;
  hasAllWheelDrive: Maybe<Scalars["Boolean"]>;
  hasEsp: Maybe<Scalars["Boolean"]>;
  hasHandsFreeSystem: Maybe<Scalars["Boolean"]>;
  hasPdc: Maybe<Scalars["Boolean"]>;
  hasSeatHeating: Maybe<Scalars["Boolean"]>;
  hasTowHook: Maybe<Scalars["Boolean"]>;
  horsePower: Maybe<Scalars["Int"]>;
  huCertificate: Maybe<Scalars["Int"]>;
  inspectionExpiryDate: Maybe<Scalars["String"]>;
  isNewVehicle: Maybe<Scalars["Boolean"]>;
  kw: Maybe<Scalars["BigDecimal"]>;
  lastServiceMileage: Maybe<MileageDto>;
  lastServiceOn: Maybe<Scalars["String"]>;
  licensePlate: Maybe<Scalars["String"]>;
  make: Maybe<Scalars["String"]>;
  manufacturer: Maybe<Scalars["String"]>;
  manufacturerCode: Maybe<Scalars["String"]>;
  mileageDTO: Maybe<MileageDto>;
  model: Maybe<Scalars["String"]>;
  modelApi: Maybe<ModelDto>;
  modelCode: Maybe<Scalars["String"]>;
  navigationSystem: Maybe<Scalars["Int"]>;
  originalEngine: Maybe<Scalars["Boolean"]>;
  radioSystem: Maybe<Scalars["Int"]>;
  recentlyInspected: Maybe<Scalars["Boolean"]>;
  recentlyServiced: Maybe<Scalars["Boolean"]>;
  registration: Maybe<Scalars["String"]>;
  seatCount: Maybe<Scalars["Int"]>;
  serviceBook: Maybe<Scalars["Int"]>;
  smoker: Maybe<Scalars["Boolean"]>;
  stockNumber: Maybe<Scalars["String"]>;
  subType: Maybe<Scalars["String"]>;
  subTypeExtra: Maybe<Scalars["String"]>;
  taxDeductible: Maybe<Scalars["Boolean"]>;
  taxRating: Maybe<Scalars["Int"]>;
  technicalInspectionCertificateIssueDate: Maybe<Scalars["String"]>;
  technicalInspectionCertificateIssueMileage: Maybe<MileageDto>;
  testDrive: Maybe<Scalars["String"]>;
  tuv: Maybe<Tuvdto>;
  type: Maybe<Scalars["Int"]>;
  upholstery: Maybe<Scalars["Int"]>;
  upholsteryOriginal: Maybe<Scalars["Boolean"]>;
  vatType: Maybe<Scalars["Int"]>;
  vin: Maybe<Scalars["String"]>;
  wasInCommercialUse: Maybe<Scalars["Boolean"]>;
  weight: Maybe<Scalars["Int"]>;
  wheelSets: Maybe<WheelSetsDto>;
  xenonLight: Maybe<Scalars["Int"]>;
}

export interface VerificationCallTaskProjection {
  __typename?: "VerificationCallTaskProjection";
  action: Maybe<Scalars["String"]>;
  assignedOn: Maybe<Scalars["String"]>;
  assignedTo: Maybe<UserProjection>;
  assignee: Maybe<UserProjection>;
  closedBy: Maybe<UserProjection>;
  closedOn: Maybe<Scalars["String"]>;
  comment: Maybe<Scalars["String"]>;
  commentsCount: Maybe<Scalars["Int"]>;
  completedBy: Maybe<UserProjection>;
  completedOn: Maybe<Scalars["String"]>;
  createdOn: Maybe<Scalars["String"]>;
  customerEmail: Maybe<Scalars["String"]>;
  customerLeadId: Maybe<Scalars["UUID"]>;
  customerName: Maybe<Scalars["String"]>;
  customerPhoneNumber: Maybe<Scalars["String"]>;
  financingStatus: Maybe<Scalars["String"]>;
  flowVersion: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  name: Maybe<Scalars["String"]>;
  order: Maybe<OrderProjection>;
  orderId: Maybe<Scalars["UUID"]>;
  orderNumber: Maybe<Scalars["String"]>;
  orderState: Maybe<Scalars["String"]>;
  paymentStatus: Maybe<Scalars["String"]>;
  paymentType: Maybe<Scalars["String"]>;
  reason: Maybe<Scalars["String"]>;
  repeatAt: Maybe<Scalars["String"]>;
  retailCountry: Maybe<Scalars["String"]>;
  sequenceIndex: Scalars["Int"];
  state: Maybe<Scalars["String"]>;
  stockNumber: Maybe<Scalars["String"]>;
  taskDefinitionKey: Maybe<Scalars["String"]>;
  taskStatus: Maybe<Scalars["String"]>;
  taskType: Maybe<Scalars["String"]>;
  taskUpdatedAt: Maybe<Scalars["String"]>;
}

export interface WarningLightsDto {
  __typename?: "WarningLightsDTO";
  absWarning: Maybe<Scalars["Boolean"]>;
  airbagWarning: Maybe<Scalars["Boolean"]>;
  batteryWarning: Maybe<Scalars["Boolean"]>;
  breakPadWarning: Maybe<Scalars["Boolean"]>;
  breakWarning: Maybe<Scalars["Boolean"]>;
  coolantTemperatureWarning: Maybe<Scalars["Boolean"]>;
  engineOilWarning: Maybe<Scalars["Boolean"]>;
  engineWarning: Maybe<Scalars["Boolean"]>;
  lowTirePressureWarning: Maybe<Scalars["Boolean"]>;
  tractionControlSystemWarning: Maybe<Scalars["Boolean"]>;
}

export interface WarningLightsDtoInput {
  absWarning?: InputMaybe<Scalars["Boolean"]>;
  airbagWarning?: InputMaybe<Scalars["Boolean"]>;
  batteryWarning?: InputMaybe<Scalars["Boolean"]>;
  breakPadWarning?: InputMaybe<Scalars["Boolean"]>;
  breakWarning?: InputMaybe<Scalars["Boolean"]>;
  coolantTemperatureWarning?: InputMaybe<Scalars["Boolean"]>;
  engineOilWarning?: InputMaybe<Scalars["Boolean"]>;
  engineWarning?: InputMaybe<Scalars["Boolean"]>;
  lowTirePressureWarning?: InputMaybe<Scalars["Boolean"]>;
  tractionControlSystemWarning?: InputMaybe<Scalars["Boolean"]>;
}

export interface WarrantyCarPricePriceModifierProjection {
  __typename?: "WarrantyCarPricePriceModifierProjection";
  active: Scalars["Boolean"];
  category: Scalars["String"];
  createdBy: Scalars["UUID"];
  createdOn: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  pnlCostPrice: Maybe<PriceDetailsProjection>;
  price: Maybe<PriceDetailsProjection>;
  priceMaxMinorUnits: Scalars["Long"];
  priceMinMinorUnits: Scalars["Long"];
  priceModifierInstance: Scalars["String"];
  productId: Scalars["UUID"];
  updatedOn: Maybe<Scalars["String"]>;
}

export interface WarrantyCarPricePriceModifierUpdateProjectionInput {
  active?: InputMaybe<Scalars["Boolean"]>;
  grossPriceMinorUnits?: InputMaybe<Scalars["Long"]>;
  pnlCostGrossPriceMinorUnits?: InputMaybe<Scalars["Long"]>;
  pnlCostVatKey?: InputMaybe<VatKeyType>;
  pnlCostVatPercentage?: InputMaybe<Scalars["Float"]>;
  priceMaxMinorUnits?: InputMaybe<Scalars["Long"]>;
  priceMinMinorUnits?: InputMaybe<Scalars["Long"]>;
  vatKey?: InputMaybe<VatKeyType>;
  vatPercentage?: InputMaybe<Scalars["Float"]>;
}

export interface WarrantyCarYearsPriceModifierProjection {
  __typename?: "WarrantyCarYearsPriceModifierProjection";
  active: Scalars["Boolean"];
  category: Scalars["String"];
  createdBy: Scalars["UUID"];
  createdOn: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  pnlCostPrice: Maybe<PriceDetailsProjection>;
  price: Maybe<PriceDetailsProjection>;
  priceModifierInstance: Scalars["String"];
  productId: Scalars["UUID"];
  updatedOn: Maybe<Scalars["String"]>;
  yearsMax: Scalars["Int"];
  yearsMin: Scalars["Int"];
}

export interface WarrantyCarYearsPriceModifierUpdateProjectionInput {
  active?: InputMaybe<Scalars["Boolean"]>;
  grossPriceMinorUnits?: InputMaybe<Scalars["Long"]>;
  pnlCostGrossPriceMinorUnits?: InputMaybe<Scalars["Long"]>;
  pnlCostVatKey?: InputMaybe<VatKeyType>;
  pnlCostVatPercentage?: InputMaybe<Scalars["Float"]>;
  vatKey?: InputMaybe<VatKeyType>;
  vatPercentage?: InputMaybe<Scalars["Float"]>;
  yearsMax?: InputMaybe<Scalars["Long"]>;
  yearsMin?: InputMaybe<Scalars["Long"]>;
}

export interface WarrantyDetailsProjection {
  __typename?: "WarrantyDetailsProjection";
  created: Maybe<Scalars["String"]>;
  currencyCode: Maybe<Scalars["String"]>;
  discountGrossMinorUnits: Maybe<Scalars["Long"]>;
  discountMinorUnits: Maybe<Scalars["Long"]>;
  expiryDate: Maybe<Scalars["String"]>;
  externalId: Maybe<Scalars["UUID"]>;
  externalType: Maybe<Scalars["Int"]>;
  id: Maybe<Scalars["UUID"]>;
  invoiceDescription: Maybe<Scalars["String"]>;
  invoiceName: Maybe<Scalars["String"]>;
  maxAllowedMileage: Maybe<MileageDto>;
  orderId: Maybe<Scalars["UUID"]>;
  parentId: Maybe<Scalars["UUID"]>;
  priceDiscountGross: Maybe<PriceDto>;
  priceDiscountNet: Maybe<PriceDto>;
  priceDiscountVat: Maybe<PriceDto>;
  priceGross: Maybe<PriceDto>;
  priceGrossMinorUnits: Maybe<Scalars["Long"]>;
  priceNet: Maybe<PriceDto>;
  priceNetMinorUnits: Maybe<Scalars["Long"]>;
  priceTaxAdvantageAmount: Maybe<PriceDto>;
  priceVatAmount: Maybe<PriceDto>;
  type: Maybe<Scalars["String"]>;
  updated: Maybe<Scalars["String"]>;
  vatAmountMinorUnits: Maybe<Scalars["Long"]>;
  vatKey: Maybe<Scalars["String"]>;
  vatRate: Maybe<Scalars["Float"]>;
  warranty: Maybe<RetailWarrantyProjection>;
}

export interface WarrantyItemProjection {
  __typename?: "WarrantyItemProjection";
  created: Maybe<Scalars["String"]>;
  currencyCode: Maybe<Scalars["String"]>;
  discountGrossMinorUnits: Maybe<Scalars["Long"]>;
  discountMinorUnits: Maybe<Scalars["Long"]>;
  externalId: Maybe<Scalars["UUID"]>;
  externalType: Maybe<Scalars["Int"]>;
  id: Maybe<Scalars["UUID"]>;
  invoiceDescription: Maybe<Scalars["String"]>;
  invoiceName: Maybe<Scalars["String"]>;
  orderId: Maybe<Scalars["UUID"]>;
  parentId: Maybe<Scalars["UUID"]>;
  priceDiscountGross: Maybe<PriceDto>;
  priceDiscountNet: Maybe<PriceDto>;
  priceDiscountVat: Maybe<PriceDto>;
  priceGross: Maybe<PriceDto>;
  priceGrossMinorUnits: Maybe<Scalars["Long"]>;
  priceNet: Maybe<PriceDto>;
  priceNetMinorUnits: Maybe<Scalars["Long"]>;
  priceTaxAdvantageAmount: Maybe<PriceDto>;
  priceVatAmount: Maybe<PriceDto>;
  type: Maybe<Scalars["String"]>;
  updated: Maybe<Scalars["String"]>;
  vatAmountMinorUnits: Maybe<Scalars["Long"]>;
  vatKey: Maybe<Scalars["String"]>;
  vatRate: Maybe<Scalars["Float"]>;
  warranty: Maybe<RetailWarrantyProjection>;
}

export type WarrantyLevelType =
  | "BASIC"
  | "BRONZE"
  | "GOLD"
  | "PREMIUM"
  | "SILVER";

export type WarrantySourceType = "AUTOHERO" | "PARTNER";

export interface WheelDtoInput {
  rimType?: InputMaybe<Scalars["String"]>;
  tireType?: InputMaybe<Scalars["String"]>;
}

export interface WheelPositionProjection {
  __typename?: "WheelPositionProjection";
  position: Maybe<Scalars["String"]>;
  wheels: Maybe<Array<Maybe<WheelProjection>>>;
}

export interface WheelProjection {
  __typename?: "WheelProjection";
  dot: Maybe<Scalars["String"]>;
  loadIndex: Maybe<Scalars["Int"]>;
  manufacturer: Maybe<Scalars["String"]>;
  profile: Maybe<Profile>;
  rimSize: Maybe<RimSize>;
  rimType: Maybe<Scalars["String"]>;
  season: Maybe<Scalars["String"]>;
  seasonLabel: Maybe<Scalars["String"]>;
  speedIndex: Maybe<Scalars["String"]>;
  wheelSize: Maybe<WheelSize>;
}

export type WheelSetDeleteReason =
  | "NOT_LICENSED_FOR_THE_CAR"
  | "WHEELS_CANNOT_BE_SOLD"
  | "WHEELS_DO_NOT_EXIST";

export interface WheelSetsDto {
  __typename?: "WheelSetsDTO";
  primary: Maybe<Scalars["Map_String_List_WheelScalar"]>;
  primaryTranslations: Maybe<Array<Maybe<WheelPositionProjection>>>;
  secondary: Maybe<Scalars["Map_String_List_WheelScalar"]>;
  secondaryTranslations: Maybe<Array<Maybe<WheelPositionProjection>>>;
}

export interface WheelSetsDtoInput {
  primary?: InputMaybe<Scalars["Map_String_List_WheelScalar"]>;
  secondary?: InputMaybe<Scalars["Map_String_List_WheelScalar"]>;
}

export interface WheelSize {
  __typename?: "WheelSize";
  height: Maybe<Scalars["Int"]>;
  unit: Maybe<Unit>;
  value: Maybe<Scalars["Int"]>;
}

export interface WorkshopDto {
  __typename?: "WorkshopDTO";
  branchId: Maybe<Scalars["Int"]>;
  createdBy: Maybe<Scalars["UUID"]>;
  createdOn: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  name: Maybe<Scalars["String"]>;
}

export type DeleteDocumentInventoryItemResult =
  | DeleteDocumentInventoryItemPayload
  | DocumentItemInPackageProblem;

export type RetailAdDeliveryOptionDetails =
  | RetailAdDeliveryAddressDetailsProjection
  | RetailAdDeliveryBranchDetailsProjection
  | RetailAdDeliveryBranchesDetailsProjection;

export type TaskManagementProjection =
  | AccountingDatePendingTaskProjection
  | BpmTaxTaskProjection
  | BankDetailsPendingTaskProjection
  | CancellationRequestedTaskProjection
  | CancellationTaskProjection
  | CarReturnHandoverAppointmentTaskProjection
  | CarReturnedTaskProjection
  | CareCallTaskProjection
  | CashOutRefundApprovalPendingTaskProjection
  | ClaimPartialRefundPendingTaskProjection
  | ClaimProofPendingTaskProjection
  | ClaimProofSubmittedTaskProjection
  | CreditNoteNumberPendingTaskProjection
  | CreditNotePendingTaskProjection
  | DamageCompensationPendingTaskProjection
  | DocumentsReturnedDeregistrationTaskProjection
  | EmailReplyPendingTaskProjection
  | EmailReplyReviewTaskProjection
  | FinancingApplicationPendingBankDecisionTaskProjection
  | FinancingApplicationValidationTaskProjection
  | FinancingInternalApprovalTaskProjection
  | HappinessCallTaskManagementProjection
  | LicensePlateTaskProjection
  | OrderReadyForVerificationTaskProjection
  | OrderShippingDetailsUpdateTaskProjection
  | RefundPendingTaskProjection
  | RepeatHappinessCallTaskManagementProjection
  | TaskManagementBaseTaskProjection
  | VerificationCallTaskProjection;

export type UserPermissionsQueryVariables = Exact<{ [key: string]: never }>;

export type UserPermissionsQuery = {
  __typename?: "Query";
  me: {
    __typename?: "RetailUserDTO";
    id: string | null;
    effectivePermissions: Array<string | null> | null;
  } | null;
};

export type FetchFeatureFlagsQueryVariables = Exact<{
  names: Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>;
  context?: InputMaybe<RetailFeatureStatusContextDtoInput>;
}>;

export type FetchFeatureFlagsQuery = {
  __typename?: "Query";
  flags: Array<{
    __typename?: "RetailFeatureStatusProjectionV2";
    name: string | null;
    enabled: boolean;
  } | null> | null;
};

export type CreateBasketItemMutationVariables = Exact<{
  product: ProductCreateProjectionInput;
}>;

export type CreateBasketItemMutation = {
  __typename?: "Mutation";
  createProduct: {
    __typename?: "RetailProductProjection";
    id: string | null;
  } | null;
};

export type LoadBasketItemQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type LoadBasketItemQuery = {
  __typename?: "Query";
  product: {
    __typename?: "RetailProductProjection";
    id: string | null;
    country: string | null;
    category: string | null;
    type: string | null;
    partner: string | null;
    sku: string | null;
    title: string | null;
    invoiceDescription: string | null;
    quantity: number | null;
    pnlCostNetMinorUnits: number | null;
    pnlVatRate: number | null;
    invoiceItemNetMinorUnits: number | null;
    vatRate: number | null;
    vatKey: VatKeyType | null;
    shippingRequired: boolean;
    active: boolean;
    defaulted: boolean;
    excludedFromInvoice: boolean | null;
  } | null;
};

export type UpdateBasketItemMutationVariables = Exact<{
  id: Scalars["UUID"];
  product: ProductUpdateProjectionInput;
}>;

export type UpdateBasketItemMutation = {
  __typename?: "Mutation";
  updateProduct: {
    __typename?: "RetailProductProjection";
    id: string | null;
    country: string | null;
    category: string | null;
    type: string | null;
    partner: string | null;
    sku: string | null;
    title: string | null;
    invoiceDescription: string | null;
    quantity: number | null;
    pnlCostNetMinorUnits: number | null;
    pnlVatRate: number | null;
    invoiceItemNetMinorUnits: number | null;
    vatRate: number | null;
    vatKey: VatKeyType | null;
    shippingRequired: boolean;
    active: boolean;
    defaulted: boolean;
    excludedFromInvoice: boolean | null;
  } | null;
};

export type ProductFieldsFragment = {
  __typename?: "RetailProductProjection";
  id: string | null;
  country: string | null;
  category: string | null;
  type: string | null;
  partner: string | null;
  sku: string | null;
  title: string | null;
  invoiceDescription: string | null;
  quantity: number | null;
  pnlCostNetMinorUnits: number | null;
  pnlVatRate: number | null;
  invoiceItemNetMinorUnits: number | null;
  vatRate: number | null;
  vatKey: VatKeyType | null;
  shippingRequired: boolean;
  active: boolean;
  defaulted: boolean;
  excludedFromInvoice: boolean | null;
};

export type UpdateBasketItemActiveMutationVariables = Exact<{
  id: Scalars["UUID"];
  isActive: Scalars["Boolean"];
}>;

export type UpdateBasketItemActiveMutation = {
  __typename?: "Mutation";
  updateProduct: {
    __typename?: "RetailProductProjection";
    id: string | null;
    active: boolean;
  } | null;
};

export type LoadBasketItemsQueryVariables = Exact<{
  search: SearchProjection2Input;
}>;

export type LoadBasketItemsQuery = {
  __typename?: "Query";
  search: {
    __typename?: "PageDTO_RetailProductDTO";
    page: number;
    pageSize: number;
    totalPageCount: number;
    totalEntityCount: number | null;
    entities: Array<{
      __typename?: "RetailProductDTO";
      id: string | null;
      country: string | null;
      category: string | null;
      type: string | null;
      title: string | null;
      active: boolean | null;
      partner: string | null;
      vatRate: number | null;
      invoiceItemGross: {
        __typename?: "ProductPriceDTO";
        amountMinorUnits: number | null;
        currencyCode: string | null;
      } | null;
      invoiceItemNet: {
        __typename?: "ProductPriceDTO";
        amountMinorUnits: number | null;
        currencyCode: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type RetailProductDtoFragmentFragment = {
  __typename?: "RetailProductDTO";
  id: string | null;
  country: string | null;
  category: string | null;
  type: string | null;
  title: string | null;
  active: boolean | null;
  partner: string | null;
  vatRate: number | null;
  invoiceItemGross: {
    __typename?: "ProductPriceDTO";
    amountMinorUnits: number | null;
    currencyCode: string | null;
  } | null;
  invoiceItemNet: {
    __typename?: "ProductPriceDTO";
    amountMinorUnits: number | null;
    currencyCode: string | null;
  } | null;
};

export type LoadPartnerOptionsQueryVariables = Exact<{
  country: Scalars["String"];
  category: Scalars["String"];
  type: Scalars["String"];
}>;

export type LoadPartnerOptionsQuery = {
  __typename?: "Query";
  partners: {
    __typename?: "ProductPartnersProjection";
    entities: Array<string | null> | null;
  } | null;
};

export type LoadTypeOptionsQueryVariables = Exact<{
  country: Scalars["String"];
  category: Scalars["String"];
}>;

export type LoadTypeOptionsQuery = {
  __typename?: "Query";
  productTypes: {
    __typename?: "ProductTypesProjection";
    entities: Array<string | null> | null;
  } | null;
};

export type LoadCarRegistrationProductQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type LoadCarRegistrationProductQuery = {
  __typename?: "Query";
  product: {
    __typename?: "RetailCarRegistrationProjection";
    id: string;
    refId: string;
    country: string;
    currencyCode: string;
    publishedScope: PublishedScopeType;
    partnerName: string;
    quantity: number;
    invoiceName: string | null;
    invoiceDescription: string;
    internalReferenceName: string | null;
    shippingRequired: boolean;
    active: boolean;
    defaulted: boolean;
    registrationType: CarRegistrationType;
    registrationInRetailCountry: boolean | null;
    registrationInAnyCountry: boolean | null;
    price: {
      __typename?: "PriceDetailsProjection";
      grossPriceMinorUnits: number | null;
      vatPercentage: number | null;
      vatKey: VatKeyType | null;
    };
    pnlCostPrice: {
      __typename?: "PriceDetailsProjection";
      grossPriceMinorUnits: number | null;
      vatPercentage: number | null;
      vatKey: VatKeyType | null;
    } | null;
  } | null;
};

export type UpdateCarRegistrationProductMutationVariables = Exact<{
  id: Scalars["UUID"];
  product: RetailCarRegistrationUpdateProjectionInput;
}>;

export type UpdateCarRegistrationProductMutation = {
  __typename?: "Mutation";
  update: {
    __typename?: "RetailCarRegistrationProjection";
    id: string;
    refId: string;
    country: string;
    currencyCode: string;
    publishedScope: PublishedScopeType;
    partnerName: string;
    quantity: number;
    invoiceName: string | null;
    invoiceDescription: string;
    internalReferenceName: string | null;
    shippingRequired: boolean;
    active: boolean;
    defaulted: boolean;
    registrationType: CarRegistrationType;
    registrationInRetailCountry: boolean | null;
    registrationInAnyCountry: boolean | null;
    price: {
      __typename?: "PriceDetailsProjection";
      grossPriceMinorUnits: number | null;
      vatPercentage: number | null;
      vatKey: VatKeyType | null;
    };
    pnlCostPrice: {
      __typename?: "PriceDetailsProjection";
      grossPriceMinorUnits: number | null;
      vatPercentage: number | null;
      vatKey: VatKeyType | null;
    } | null;
  } | null;
};

export type CarRegistrationProductFragmentFragment = {
  __typename?: "RetailCarRegistrationProjection";
  id: string;
  refId: string;
  country: string;
  currencyCode: string;
  publishedScope: PublishedScopeType;
  partnerName: string;
  quantity: number;
  invoiceName: string | null;
  invoiceDescription: string;
  internalReferenceName: string | null;
  shippingRequired: boolean;
  active: boolean;
  defaulted: boolean;
  registrationType: CarRegistrationType;
  registrationInRetailCountry: boolean | null;
  registrationInAnyCountry: boolean | null;
  price: {
    __typename?: "PriceDetailsProjection";
    grossPriceMinorUnits: number | null;
    vatPercentage: number | null;
    vatKey: VatKeyType | null;
  };
  pnlCostPrice: {
    __typename?: "PriceDetailsProjection";
    grossPriceMinorUnits: number | null;
    vatPercentage: number | null;
    vatKey: VatKeyType | null;
  } | null;
};

export type UpdateCarRegistrationActiveMutationVariables = Exact<{
  id: Scalars["UUID"];
  active: Scalars["Boolean"];
}>;

export type UpdateCarRegistrationActiveMutation = {
  __typename?: "Mutation";
  updateCarRegistrationProduct: {
    __typename?: "RetailCarRegistrationProjection";
    id: string;
    active: boolean;
  } | null;
};

export type LoadCarRegistrationsListQueryVariables = Exact<{
  search: SearchProjection2Input;
}>;

export type LoadCarRegistrationsListQuery = {
  __typename?: "Query";
  products: {
    __typename?: "PageDTO_RetailCarRegistrationProjection";
    page: number;
    totalPageCount: number;
    totalEntityCount: number | null;
    entities: Array<{
      __typename?: "RetailCarRegistrationProjection";
      id: string;
      active: boolean;
      country: string;
      refId: string;
      registrationType: CarRegistrationType;
      invoiceName: string | null;
      partnerName: string;
      currencyCode: string;
      price: {
        __typename?: "PriceDetailsProjection";
        grossPriceMinorUnits: number | null;
        vatKey: VatKeyType | null;
        vatPercentage: number | null;
      };
    } | null> | null;
  } | null;
};

export type SearchProductChangelogQueryVariables = Exact<{
  search: SearchProjection2Input;
}>;

export type SearchProductChangelogQuery = {
  __typename?: "Query";
  changelog: {
    __typename?: "PageDTO_ChangelogProjection";
    page: number;
    pageSize: number;
    totalEntityCount: number | null;
    totalPageCount: number;
    entities: Array<{
      __typename?: "ChangelogProjection";
      id: number | null;
      action: string | null;
      field: string | null;
      oldValue: string | null;
      newValue: string | null;
      objectId: string | null;
      objectType: string | null;
      createdOn: string | null;
      createdBy: {
        __typename?: "RetailUserDTO";
        id: string | null;
        email: string | null;
        firstName: string | null;
        lastName: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type LoadFeeOptionsQueryVariables = Exact<{
  country: Scalars["String"];
}>;

export type LoadFeeOptionsQuery = {
  __typename?: "Query";
  options: {
    __typename?: "RetailFeeOptionsProjection";
    types: Array<string | null> | null;
  } | null;
};

export type LoadDownPaymentProductQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type LoadDownPaymentProductQuery = {
  __typename?: "Query";
  product: {
    __typename?: "DownPaymentProjection";
    id: string;
    refId: string;
    country: string;
    currencyCode: string;
    publishedScope: PublishedScopeType;
    partnerName: string;
    quantity: number;
    invoiceName: string | null;
    invoiceDescription: string;
    internalReferenceName: string | null;
    shippingRequired: boolean;
    active: boolean;
    defaulted: boolean;
    price: {
      __typename?: "PriceDetailsProjection";
      grossPriceMinorUnits: number | null;
      vatPercentage: number | null;
      vatKey: VatKeyType | null;
    };
    pnlCostPrice: {
      __typename?: "PriceDetailsProjection";
      grossPriceMinorUnits: number | null;
      vatPercentage: number | null;
      vatKey: VatKeyType | null;
    } | null;
  } | null;
};

export type UpdateDownPaymentProductMutationVariables = Exact<{
  id: Scalars["UUID"];
  product: DownPaymentUpdateProjectionInput;
}>;

export type UpdateDownPaymentProductMutation = {
  __typename?: "Mutation";
  update: {
    __typename?: "DownPaymentProjection";
    id: string;
    refId: string;
    country: string;
    currencyCode: string;
    publishedScope: PublishedScopeType;
    partnerName: string;
    quantity: number;
    invoiceName: string | null;
    invoiceDescription: string;
    internalReferenceName: string | null;
    shippingRequired: boolean;
    active: boolean;
    defaulted: boolean;
    price: {
      __typename?: "PriceDetailsProjection";
      grossPriceMinorUnits: number | null;
      vatPercentage: number | null;
      vatKey: VatKeyType | null;
    };
    pnlCostPrice: {
      __typename?: "PriceDetailsProjection";
      grossPriceMinorUnits: number | null;
      vatPercentage: number | null;
      vatKey: VatKeyType | null;
    } | null;
  } | null;
};

export type DownPaymentProductFragmentFragment = {
  __typename?: "DownPaymentProjection";
  id: string;
  refId: string;
  country: string;
  currencyCode: string;
  publishedScope: PublishedScopeType;
  partnerName: string;
  quantity: number;
  invoiceName: string | null;
  invoiceDescription: string;
  internalReferenceName: string | null;
  shippingRequired: boolean;
  active: boolean;
  defaulted: boolean;
  price: {
    __typename?: "PriceDetailsProjection";
    grossPriceMinorUnits: number | null;
    vatPercentage: number | null;
    vatKey: VatKeyType | null;
  };
  pnlCostPrice: {
    __typename?: "PriceDetailsProjection";
    grossPriceMinorUnits: number | null;
    vatPercentage: number | null;
    vatKey: VatKeyType | null;
  } | null;
};

export type UpdateDownPaymentActiveMutationVariables = Exact<{
  id: Scalars["UUID"];
  active: Scalars["Boolean"];
}>;

export type UpdateDownPaymentActiveMutation = {
  __typename?: "Mutation";
  updateDownPaymentProduct: {
    __typename?: "DownPaymentProjection";
    id: string;
    active: boolean;
  } | null;
};

export type LoadDownPaymentsListQueryVariables = Exact<{
  search: SearchProjection2Input;
}>;

export type LoadDownPaymentsListQuery = {
  __typename?: "Query";
  products: {
    __typename?: "PageDTO_DownPaymentProjection";
    page: number;
    totalPageCount: number;
    totalEntityCount: number | null;
    entities: Array<{
      __typename?: "DownPaymentProjection";
      id: string;
      active: boolean;
      country: string;
      refId: string;
      invoiceName: string | null;
      partnerName: string;
      currencyCode: string;
      price: {
        __typename?: "PriceDetailsProjection";
        grossPriceMinorUnits: number | null;
        vatKey: VatKeyType | null;
        vatPercentage: number | null;
      };
    } | null> | null;
  } | null;
};

export type CreateFeeProductMutationVariables = Exact<{
  product: RetailFeeCreateProjectionInput;
}>;

export type CreateFeeProductMutation = {
  __typename?: "Mutation";
  createFeeProduct: { __typename?: "RetailFeeProjection"; id: string } | null;
};

export type LoadFeeProductQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type LoadFeeProductQuery = {
  __typename?: "Query";
  product: {
    __typename?: "RetailFeeProjection";
    id: string;
    refId: string;
    country: string;
    feeType: string | null;
    currencyCode: string;
    publishedScope: PublishedScopeType;
    partnerName: string;
    quantity: number;
    title: string | null;
    invoiceName: string | null;
    invoiceDescription: string;
    internalReferenceName: string | null;
    shippingRequired: boolean;
    active: boolean;
    defaulted: boolean;
    price: {
      __typename?: "PriceDetailsProjection";
      grossPriceMinorUnits: number | null;
      vatPercentage: number | null;
      vatKey: VatKeyType | null;
    };
    pnlCostPrice: {
      __typename?: "PriceDetailsProjection";
      grossPriceMinorUnits: number | null;
      vatPercentage: number | null;
      vatKey: VatKeyType | null;
    } | null;
  } | null;
};

export type UpdateFeeProductMutationVariables = Exact<{
  id: Scalars["UUID"];
  product: RetailFeeUpdateProjectionInput;
}>;

export type UpdateFeeProductMutation = {
  __typename?: "Mutation";
  update: {
    __typename?: "RetailFeeProjection";
    id: string;
    refId: string;
    country: string;
    feeType: string | null;
    currencyCode: string;
    publishedScope: PublishedScopeType;
    partnerName: string;
    quantity: number;
    title: string | null;
    invoiceName: string | null;
    invoiceDescription: string;
    internalReferenceName: string | null;
    shippingRequired: boolean;
    active: boolean;
    defaulted: boolean;
    price: {
      __typename?: "PriceDetailsProjection";
      grossPriceMinorUnits: number | null;
      vatPercentage: number | null;
      vatKey: VatKeyType | null;
    };
    pnlCostPrice: {
      __typename?: "PriceDetailsProjection";
      grossPriceMinorUnits: number | null;
      vatPercentage: number | null;
      vatKey: VatKeyType | null;
    } | null;
  } | null;
};

export type FeeProductFragmentFragment = {
  __typename?: "RetailFeeProjection";
  id: string;
  refId: string;
  country: string;
  feeType: string | null;
  currencyCode: string;
  publishedScope: PublishedScopeType;
  partnerName: string;
  quantity: number;
  title: string | null;
  invoiceName: string | null;
  invoiceDescription: string;
  internalReferenceName: string | null;
  shippingRequired: boolean;
  active: boolean;
  defaulted: boolean;
  price: {
    __typename?: "PriceDetailsProjection";
    grossPriceMinorUnits: number | null;
    vatPercentage: number | null;
    vatKey: VatKeyType | null;
  };
  pnlCostPrice: {
    __typename?: "PriceDetailsProjection";
    grossPriceMinorUnits: number | null;
    vatPercentage: number | null;
    vatKey: VatKeyType | null;
  } | null;
};

export type UpdateFeeProductActiveMutationVariables = Exact<{
  id: Scalars["UUID"];
  active: Scalars["Boolean"];
}>;

export type UpdateFeeProductActiveMutation = {
  __typename?: "Mutation";
  updateFeeProduct: {
    __typename?: "RetailFeeProjection";
    id: string;
    active: boolean;
  } | null;
};

export type LoadFeesListQueryVariables = Exact<{
  search: SearchProjection2Input;
}>;

export type LoadFeesListQuery = {
  __typename?: "Query";
  products: {
    __typename?: "PageDTO_RetailFeeProjection";
    page: number;
    totalPageCount: number;
    totalEntityCount: number | null;
    entities: Array<{
      __typename?: "RetailFeeProjection";
      id: string;
      active: boolean;
      country: string;
      title: string | null;
      feeType: string | null;
      refId: string;
      invoiceName: string | null;
      partnerName: string;
      currencyCode: string;
      price: {
        __typename?: "PriceDetailsProjection";
        grossPriceMinorUnits: number | null;
        vatKey: VatKeyType | null;
        vatPercentage: number | null;
      };
    } | null> | null;
  } | null;
};

export type LoadVehicleInsuranceProductQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type LoadVehicleInsuranceProductQuery = {
  __typename?: "Query";
  product: {
    __typename?: "RetailInsuranceProjection";
    id: string;
    refId: string;
    country: string;
    currencyCode: string;
    publishedScope: PublishedScopeType;
    partnerName: string;
    quantity: number;
    invoiceName: string | null;
    invoiceDescription: string;
    internalReferenceName: string | null;
    shippingRequired: boolean;
    active: boolean;
    defaulted: boolean;
    price: {
      __typename?: "PriceDetailsProjection";
      grossPriceMinorUnits: number | null;
      vatPercentage: number | null;
      vatKey: VatKeyType | null;
    };
    pnlCostPrice: {
      __typename?: "PriceDetailsProjection";
      grossPriceMinorUnits: number | null;
      vatPercentage: number | null;
      vatKey: VatKeyType | null;
    } | null;
  } | null;
};

export type UpdateVehicleInsuranceProductMutationVariables = Exact<{
  id: Scalars["UUID"];
  product: RetailInsuranceUpdateProjectionInput;
}>;

export type UpdateVehicleInsuranceProductMutation = {
  __typename?: "Mutation";
  update: {
    __typename?: "RetailInsuranceProjection";
    id: string;
    refId: string;
    country: string;
    currencyCode: string;
    publishedScope: PublishedScopeType;
    partnerName: string;
    quantity: number;
    invoiceName: string | null;
    invoiceDescription: string;
    internalReferenceName: string | null;
    shippingRequired: boolean;
    active: boolean;
    defaulted: boolean;
    price: {
      __typename?: "PriceDetailsProjection";
      grossPriceMinorUnits: number | null;
      vatPercentage: number | null;
      vatKey: VatKeyType | null;
    };
    pnlCostPrice: {
      __typename?: "PriceDetailsProjection";
      grossPriceMinorUnits: number | null;
      vatPercentage: number | null;
      vatKey: VatKeyType | null;
    } | null;
  } | null;
};

export type VehicleInsuranceProductFragmentFragment = {
  __typename?: "RetailInsuranceProjection";
  id: string;
  refId: string;
  country: string;
  currencyCode: string;
  publishedScope: PublishedScopeType;
  partnerName: string;
  quantity: number;
  invoiceName: string | null;
  invoiceDescription: string;
  internalReferenceName: string | null;
  shippingRequired: boolean;
  active: boolean;
  defaulted: boolean;
  price: {
    __typename?: "PriceDetailsProjection";
    grossPriceMinorUnits: number | null;
    vatPercentage: number | null;
    vatKey: VatKeyType | null;
  };
  pnlCostPrice: {
    __typename?: "PriceDetailsProjection";
    grossPriceMinorUnits: number | null;
    vatPercentage: number | null;
    vatKey: VatKeyType | null;
  } | null;
};

export type UpdateVehicleInsuranceActiveMutationVariables = Exact<{
  id: Scalars["UUID"];
  active: Scalars["Boolean"];
}>;

export type UpdateVehicleInsuranceActiveMutation = {
  __typename?: "Mutation";
  updateInsuranceProduct: {
    __typename?: "RetailInsuranceProjection";
    id: string;
    active: boolean;
  } | null;
};

export type LoadVehicleInsurancesListQueryVariables = Exact<{
  search: SearchProjection2Input;
}>;

export type LoadVehicleInsurancesListQuery = {
  __typename?: "Query";
  products: {
    __typename?: "PageDTO_RetailInsuranceProjection";
    page: number;
    totalPageCount: number;
    totalEntityCount: number | null;
    entities: Array<{
      __typename?: "RetailInsuranceProjection";
      id: string;
      active: boolean;
      country: string;
      refId: string;
      invoiceName: string | null;
      partnerName: string;
      currencyCode: string;
      price: {
        __typename?: "PriceDetailsProjection";
        grossPriceMinorUnits: number | null;
        vatKey: VatKeyType | null;
        vatPercentage: number | null;
      };
    } | null> | null;
  } | null;
};

export type LoadVehiclePreparationProductQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type LoadVehiclePreparationProductQuery = {
  __typename?: "Query";
  product: {
    __typename?: "RetailVehiclePreparationProjection";
    id: string;
    refId: string;
    country: string;
    currencyCode: string;
    publishedScope: PublishedScopeType;
    partnerName: string;
    quantity: number;
    invoiceName: string | null;
    invoiceDescription: string;
    internalReferenceName: string | null;
    shippingRequired: boolean;
    active: boolean;
    defaulted: boolean;
    price: {
      __typename?: "PriceDetailsProjection";
      grossPriceMinorUnits: number | null;
      vatPercentage: number | null;
      vatKey: VatKeyType | null;
    };
    pnlCostPrice: {
      __typename?: "PriceDetailsProjection";
      grossPriceMinorUnits: number | null;
      vatPercentage: number | null;
      vatKey: VatKeyType | null;
    } | null;
  } | null;
};

export type UpdateVehiclePreparationProductMutationVariables = Exact<{
  id: Scalars["UUID"];
  product: RetailVehiclePreparationUpdateProjectionInput;
}>;

export type UpdateVehiclePreparationProductMutation = {
  __typename?: "Mutation";
  update: {
    __typename?: "RetailVehiclePreparationProjection";
    id: string;
    refId: string;
    country: string;
    currencyCode: string;
    publishedScope: PublishedScopeType;
    partnerName: string;
    quantity: number;
    invoiceName: string | null;
    invoiceDescription: string;
    internalReferenceName: string | null;
    shippingRequired: boolean;
    active: boolean;
    defaulted: boolean;
    price: {
      __typename?: "PriceDetailsProjection";
      grossPriceMinorUnits: number | null;
      vatPercentage: number | null;
      vatKey: VatKeyType | null;
    };
    pnlCostPrice: {
      __typename?: "PriceDetailsProjection";
      grossPriceMinorUnits: number | null;
      vatPercentage: number | null;
      vatKey: VatKeyType | null;
    } | null;
  } | null;
};

export type VehiclePreparationProductFragmentFragment = {
  __typename?: "RetailVehiclePreparationProjection";
  id: string;
  refId: string;
  country: string;
  currencyCode: string;
  publishedScope: PublishedScopeType;
  partnerName: string;
  quantity: number;
  invoiceName: string | null;
  invoiceDescription: string;
  internalReferenceName: string | null;
  shippingRequired: boolean;
  active: boolean;
  defaulted: boolean;
  price: {
    __typename?: "PriceDetailsProjection";
    grossPriceMinorUnits: number | null;
    vatPercentage: number | null;
    vatKey: VatKeyType | null;
  };
  pnlCostPrice: {
    __typename?: "PriceDetailsProjection";
    grossPriceMinorUnits: number | null;
    vatPercentage: number | null;
    vatKey: VatKeyType | null;
  } | null;
};

export type UpdateVehiclePreparationActiveMutationVariables = Exact<{
  id: Scalars["UUID"];
  active: Scalars["Boolean"];
}>;

export type UpdateVehiclePreparationActiveMutation = {
  __typename?: "Mutation";
  updateVehiclePreparationProduct: {
    __typename?: "RetailVehiclePreparationProjection";
    id: string;
    active: boolean;
  } | null;
};

export type LoadVehiclePreparationsListQueryVariables = Exact<{
  search: SearchProjection2Input;
}>;

export type LoadVehiclePreparationsListQuery = {
  __typename?: "Query";
  products: {
    __typename?: "PageDTO_RetailVehiclePreparationProjection";
    page: number;
    totalPageCount: number;
    totalEntityCount: number | null;
    entities: Array<{
      __typename?: "RetailVehiclePreparationProjection";
      id: string;
      active: boolean;
      country: string;
      refId: string;
      invoiceName: string | null;
      partnerName: string;
      currencyCode: string;
      price: {
        __typename?: "PriceDetailsProjection";
        grossPriceMinorUnits: number | null;
        vatKey: VatKeyType | null;
        vatPercentage: number | null;
      };
    } | null> | null;
  } | null;
};

export type UpdateWarrantyActiveMutationVariables = Exact<{
  id: Scalars["UUID"];
  active: Scalars["Boolean"];
}>;

export type UpdateWarrantyActiveMutation = {
  __typename?: "Mutation";
  updateWarrantyProduct: {
    __typename?: "RetailWarrantyProjection";
    id: string;
    active: boolean;
  } | null;
};

export type LoadWarrantiesListQueryVariables = Exact<{
  search: SearchProjection2Input;
}>;

export type LoadWarrantiesListQuery = {
  __typename?: "Query";
  products: {
    __typename?: "PageDTO_RetailWarrantyProjection";
    page: number;
    totalPageCount: number;
    totalEntityCount: number | null;
    entities: Array<{
      __typename?: "RetailWarrantyProjection";
      id: string;
      active: boolean;
      country: string;
      refId: string;
      level: WarrantyLevelType;
      monthsCovered: number;
      invoiceName: string | null;
      partnerName: string;
      currencyCode: string;
      price: {
        __typename?: "PriceDetailsProjection";
        grossPriceMinorUnits: number | null;
        vatKey: VatKeyType | null;
        vatPercentage: number | null;
      };
    } | null> | null;
  } | null;
};

export type LoadWarrantyProductQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type LoadWarrantyProductQuery = {
  __typename?: "Query";
  product: {
    __typename?: "RetailWarrantyProjection";
    id: string;
    refId: string;
    country: string;
    currencyCode: string;
    publishedScope: PublishedScopeType;
    partnerName: string;
    quantity: number;
    invoiceName: string | null;
    invoiceDescription: string;
    internalReferenceName: string | null;
    shippingRequired: boolean;
    active: boolean;
    defaulted: boolean;
    level: WarrantyLevelType;
    monthsCovered: number;
    source: WarrantySourceType;
    insuranceTax: any | null;
    applicableCarVatKey: VatKeyType | null;
    recommended: boolean | null;
    price: {
      __typename?: "PriceDetailsProjection";
      grossPriceMinorUnits: number | null;
      vatPercentage: number | null;
      vatKey: VatKeyType | null;
    };
    pnlCostPrice: {
      __typename?: "PriceDetailsProjection";
      grossPriceMinorUnits: number | null;
      vatPercentage: number | null;
      vatKey: VatKeyType | null;
    } | null;
  } | null;
};

export type UpdateWarrantyProductMutationVariables = Exact<{
  id: Scalars["UUID"];
  product: RetailWarrantyUpdateProjectionInput;
}>;

export type UpdateWarrantyProductMutation = {
  __typename?: "Mutation";
  update: {
    __typename?: "RetailWarrantyProjection";
    id: string;
    refId: string;
    country: string;
    currencyCode: string;
    publishedScope: PublishedScopeType;
    partnerName: string;
    quantity: number;
    invoiceName: string | null;
    invoiceDescription: string;
    internalReferenceName: string | null;
    shippingRequired: boolean;
    active: boolean;
    defaulted: boolean;
    level: WarrantyLevelType;
    monthsCovered: number;
    source: WarrantySourceType;
    insuranceTax: any | null;
    applicableCarVatKey: VatKeyType | null;
    recommended: boolean | null;
    price: {
      __typename?: "PriceDetailsProjection";
      grossPriceMinorUnits: number | null;
      vatPercentage: number | null;
      vatKey: VatKeyType | null;
    };
    pnlCostPrice: {
      __typename?: "PriceDetailsProjection";
      grossPriceMinorUnits: number | null;
      vatPercentage: number | null;
      vatKey: VatKeyType | null;
    } | null;
  } | null;
};

export type WarrantyProductFragmentFragment = {
  __typename?: "RetailWarrantyProjection";
  id: string;
  refId: string;
  country: string;
  currencyCode: string;
  publishedScope: PublishedScopeType;
  partnerName: string;
  quantity: number;
  invoiceName: string | null;
  invoiceDescription: string;
  internalReferenceName: string | null;
  shippingRequired: boolean;
  active: boolean;
  defaulted: boolean;
  level: WarrantyLevelType;
  monthsCovered: number;
  source: WarrantySourceType;
  insuranceTax: any | null;
  applicableCarVatKey: VatKeyType | null;
  recommended: boolean | null;
  price: {
    __typename?: "PriceDetailsProjection";
    grossPriceMinorUnits: number | null;
    vatPercentage: number | null;
    vatKey: VatKeyType | null;
  };
  pnlCostPrice: {
    __typename?: "PriceDetailsProjection";
    grossPriceMinorUnits: number | null;
    vatPercentage: number | null;
    vatKey: VatKeyType | null;
  } | null;
};

export const ProductFieldsFragmentDoc = `
    fragment ProductFields on RetailProductProjection {
  id
  country
  category
  type
  partner
  sku
  title
  invoiceDescription
  quantity
  pnlCostNetMinorUnits
  pnlVatRate
  invoiceItemNetMinorUnits
  vatRate
  vatKey
  shippingRequired
  active
  defaulted
  excludedFromInvoice
}
    `;
export const RetailProductDtoFragmentFragmentDoc = `
    fragment RetailProductDTOFragment on RetailProductDTO {
  id
  country
  category
  type
  title
  active
  partner
  vatRate
  invoiceItemGross {
    amountMinorUnits
    currencyCode
  }
  invoiceItemNet {
    amountMinorUnits
    currencyCode
  }
}
    `;
export const CarRegistrationProductFragmentFragmentDoc = `
    fragment CarRegistrationProductFragment on RetailCarRegistrationProjection {
  id
  refId
  country
  currencyCode
  publishedScope
  partnerName
  quantity
  invoiceName
  invoiceDescription
  internalReferenceName
  shippingRequired
  active
  defaulted
  price {
    grossPriceMinorUnits
    vatPercentage
    vatKey
  }
  pnlCostPrice {
    grossPriceMinorUnits
    vatPercentage
    vatKey
  }
  registrationType
  registrationInRetailCountry
  registrationInAnyCountry
}
    `;
export const DownPaymentProductFragmentFragmentDoc = `
    fragment DownPaymentProductFragment on DownPaymentProjection {
  id
  refId
  country
  currencyCode
  publishedScope
  partnerName
  quantity
  invoiceName
  invoiceDescription
  internalReferenceName
  shippingRequired
  active
  defaulted
  price {
    grossPriceMinorUnits
    vatPercentage
    vatKey
  }
  pnlCostPrice {
    grossPriceMinorUnits
    vatPercentage
    vatKey
  }
}
    `;
export const FeeProductFragmentFragmentDoc = `
    fragment FeeProductFragment on RetailFeeProjection {
  id
  refId
  country
  feeType
  currencyCode
  publishedScope
  partnerName
  quantity
  title
  invoiceName
  invoiceDescription
  internalReferenceName
  shippingRequired
  active
  defaulted
  price {
    grossPriceMinorUnits
    vatPercentage
    vatKey
  }
  pnlCostPrice {
    grossPriceMinorUnits
    vatPercentage
    vatKey
  }
}
    `;
export const VehicleInsuranceProductFragmentFragmentDoc = `
    fragment VehicleInsuranceProductFragment on RetailInsuranceProjection {
  id
  refId
  country
  currencyCode
  publishedScope
  partnerName
  quantity
  invoiceName
  invoiceDescription
  internalReferenceName
  shippingRequired
  active
  defaulted
  price {
    grossPriceMinorUnits
    vatPercentage
    vatKey
  }
  pnlCostPrice {
    grossPriceMinorUnits
    vatPercentage
    vatKey
  }
}
    `;
export const VehiclePreparationProductFragmentFragmentDoc = `
    fragment VehiclePreparationProductFragment on RetailVehiclePreparationProjection {
  id
  refId
  country
  currencyCode
  publishedScope
  partnerName
  quantity
  invoiceName
  invoiceDescription
  internalReferenceName
  shippingRequired
  active
  defaulted
  price {
    grossPriceMinorUnits
    vatPercentage
    vatKey
  }
  pnlCostPrice {
    grossPriceMinorUnits
    vatPercentage
    vatKey
  }
}
    `;
export const WarrantyProductFragmentFragmentDoc = `
    fragment WarrantyProductFragment on RetailWarrantyProjection {
  id
  refId
  country
  currencyCode
  publishedScope
  partnerName
  quantity
  invoiceName
  invoiceDescription
  internalReferenceName
  shippingRequired
  active
  defaulted
  price {
    grossPriceMinorUnits
    vatPercentage
    vatKey
  }
  pnlCostPrice {
    grossPriceMinorUnits
    vatPercentage
    vatKey
  }
  level
  monthsCovered
  source
  insuranceTax
  applicableCarVatKey
  recommended
}
    `;
export const UserPermissionsDocument = `
    query UserPermissions {
  me {
    id
    effectivePermissions
  }
}
    `;
export const FetchFeatureFlagsDocument = `
    query FetchFeatureFlags($names: [String]!, $context: RetailFeatureStatusContextDTOInput = {}) {
  flags: retailFeaturesStatusV2(names: $names, context: $context) {
    name
    enabled
  }
}
    `;
export const CreateBasketItemDocument = `
    mutation CreateBasketItem($product: ProductCreateProjectionInput!) {
  createProduct(product: $product) {
    id
  }
}
    `;
export const LoadBasketItemDocument = `
    query LoadBasketItem($id: UUID!) {
  product(id: $id) {
    ...ProductFields
  }
}
    ${ProductFieldsFragmentDoc}`;
export const UpdateBasketItemDocument = `
    mutation UpdateBasketItem($id: UUID!, $product: ProductUpdateProjectionInput!) {
  updateProduct(id: $id, product: $product) {
    ...ProductFields
  }
}
    ${ProductFieldsFragmentDoc}`;
export const UpdateBasketItemActiveDocument = `
    mutation UpdateBasketItemActive($id: UUID!, $isActive: Boolean!) {
  updateProduct(product: {active: $isActive}, id: $id) {
    id
    active
  }
}
    `;
export const LoadBasketItemsDocument = `
    query LoadBasketItems($search: SearchProjection2Input!) {
  search: searchProducts(search: $search) {
    entities {
      ...RetailProductDTOFragment
    }
    page
    pageSize
    totalPageCount
    totalEntityCount
  }
}
    ${RetailProductDtoFragmentFragmentDoc}`;
export const LoadPartnerOptionsDocument = `
    query LoadPartnerOptions($country: String!, $category: String!, $type: String!) {
  partners: productPartners(country: $country, category: $category, type: $type) {
    entities
  }
}
    `;
export const LoadTypeOptionsDocument = `
    query LoadTypeOptions($country: String!, $category: String!) {
  productTypes(country: $country, category: $category) {
    entities
  }
}
    `;
export const LoadCarRegistrationProductDocument = `
    query LoadCarRegistrationProduct($id: UUID!) {
  product: carRegistrationProduct(id: $id) {
    ...CarRegistrationProductFragment
  }
}
    ${CarRegistrationProductFragmentFragmentDoc}`;
export const UpdateCarRegistrationProductDocument = `
    mutation UpdateCarRegistrationProduct($id: UUID!, $product: RetailCarRegistrationUpdateProjectionInput!) {
  update: updateCarRegistrationProduct(id: $id, carRegistrationProduct: $product) {
    ...CarRegistrationProductFragment
  }
}
    ${CarRegistrationProductFragmentFragmentDoc}`;
export const UpdateCarRegistrationActiveDocument = `
    mutation UpdateCarRegistrationActive($id: UUID!, $active: Boolean!) {
  updateCarRegistrationProduct(id: $id, carRegistrationProduct: {active: $active}) {
    id
    active
  }
}
    `;
export const LoadCarRegistrationsListDocument = `
    query LoadCarRegistrationsList($search: SearchProjection2Input!) {
  products: plainSearchCarRegistrationProducts(search: $search) {
    entities {
      id
      active
      country
      refId
      registrationType
      invoiceName
      partnerName
      currencyCode
      price {
        grossPriceMinorUnits
        vatKey
        vatPercentage
      }
    }
    page
    totalPageCount
    totalEntityCount
  }
}
    `;
export const SearchProductChangelogDocument = `
    query SearchProductChangelog($search: SearchProjection2Input!) {
  changelog: searchProductChangelogs(search: $search) {
    entities {
      id
      action
      field
      oldValue
      newValue
      objectId
      objectType
      createdOn
      createdBy {
        id
        email
        firstName
        lastName
      }
    }
    page
    pageSize
    totalEntityCount
    totalPageCount
  }
}
    `;
export const LoadFeeOptionsDocument = `
    query LoadFeeOptions($country: String!) {
  options: retailFeeOptions(request: {country: $country}) {
    types
  }
}
    `;
export const LoadDownPaymentProductDocument = `
    query LoadDownPaymentProduct($id: UUID!) {
  product: downPayment(id: $id) {
    ...DownPaymentProductFragment
  }
}
    ${DownPaymentProductFragmentFragmentDoc}`;
export const UpdateDownPaymentProductDocument = `
    mutation UpdateDownPaymentProduct($id: UUID!, $product: DownPaymentUpdateProjectionInput!) {
  update: updateDownPaymentProduct(id: $id, downPaymentUpdateProduct: $product) {
    ...DownPaymentProductFragment
  }
}
    ${DownPaymentProductFragmentFragmentDoc}`;
export const UpdateDownPaymentActiveDocument = `
    mutation UpdateDownPaymentActive($id: UUID!, $active: Boolean!) {
  updateDownPaymentProduct(id: $id, downPaymentUpdateProduct: {active: $active}) {
    id
    active
  }
}
    `;
export const LoadDownPaymentsListDocument = `
    query LoadDownPaymentsList($search: SearchProjection2Input!) {
  products: plainSearchDownPaymentProducts(search: $search) {
    entities {
      id
      active
      country
      refId
      invoiceName
      partnerName
      currencyCode
      price {
        grossPriceMinorUnits
        vatKey
        vatPercentage
      }
    }
    page
    totalPageCount
    totalEntityCount
  }
}
    `;
export const CreateFeeProductDocument = `
    mutation CreateFeeProduct($product: RetailFeeCreateProjectionInput!) {
  createFeeProduct(retailFeeCreateProduct: $product) {
    id
  }
}
    `;
export const LoadFeeProductDocument = `
    query LoadFeeProduct($id: UUID!) {
  product: retailFee(feeId: $id) {
    ...FeeProductFragment
  }
}
    ${FeeProductFragmentFragmentDoc}`;
export const UpdateFeeProductDocument = `
    mutation UpdateFeeProduct($id: UUID!, $product: RetailFeeUpdateProjectionInput!) {
  update: updateFeeProduct(id: $id, retailFeeUpdateProduct: $product) {
    ...FeeProductFragment
  }
}
    ${FeeProductFragmentFragmentDoc}`;
export const UpdateFeeProductActiveDocument = `
    mutation UpdateFeeProductActive($id: UUID!, $active: Boolean!) {
  updateFeeProduct(id: $id, retailFeeUpdateProduct: {active: $active}) {
    id
    active
  }
}
    `;
export const LoadFeesListDocument = `
    query LoadFeesList($search: SearchProjection2Input!) {
  products: plainSearchFeeProducts(search: $search) {
    entities {
      id
      active
      country
      title
      feeType
      refId
      invoiceName
      partnerName
      currencyCode
      price {
        grossPriceMinorUnits
        vatKey
        vatPercentage
      }
    }
    page
    totalPageCount
    totalEntityCount
  }
}
    `;
export const LoadVehicleInsuranceProductDocument = `
    query LoadVehicleInsuranceProduct($id: UUID!) {
  product: retailInsurance(insuranceId: $id) {
    ...VehicleInsuranceProductFragment
  }
}
    ${VehicleInsuranceProductFragmentFragmentDoc}`;
export const UpdateVehicleInsuranceProductDocument = `
    mutation UpdateVehicleInsuranceProduct($id: UUID!, $product: RetailInsuranceUpdateProjectionInput!) {
  update: updateInsuranceProduct(id: $id, retailInsuranceUpdateProduct: $product) {
    ...VehicleInsuranceProductFragment
  }
}
    ${VehicleInsuranceProductFragmentFragmentDoc}`;
export const UpdateVehicleInsuranceActiveDocument = `
    mutation UpdateVehicleInsuranceActive($id: UUID!, $active: Boolean!) {
  updateInsuranceProduct(id: $id, retailInsuranceUpdateProduct: {active: $active}) {
    id
    active
  }
}
    `;
export const LoadVehicleInsurancesListDocument = `
    query LoadVehicleInsurancesList($search: SearchProjection2Input!) {
  products: plainSearchInsuranceProducts(search: $search) {
    entities {
      id
      active
      country
      refId
      invoiceName
      partnerName
      currencyCode
      price {
        grossPriceMinorUnits
        vatKey
        vatPercentage
      }
    }
    page
    totalPageCount
    totalEntityCount
  }
}
    `;
export const LoadVehiclePreparationProductDocument = `
    query LoadVehiclePreparationProduct($id: UUID!) {
  product: retailVehiclePreparation(vehiclePreparationId: $id) {
    ...VehiclePreparationProductFragment
  }
}
    ${VehiclePreparationProductFragmentFragmentDoc}`;
export const UpdateVehiclePreparationProductDocument = `
    mutation UpdateVehiclePreparationProduct($id: UUID!, $product: RetailVehiclePreparationUpdateProjectionInput!) {
  update: updateVehiclePreparationProduct(
    id: $id
    retailVehiclePreparationUpdateProduct: $product
  ) {
    ...VehiclePreparationProductFragment
  }
}
    ${VehiclePreparationProductFragmentFragmentDoc}`;
export const UpdateVehiclePreparationActiveDocument = `
    mutation UpdateVehiclePreparationActive($id: UUID!, $active: Boolean!) {
  updateVehiclePreparationProduct(
    id: $id
    retailVehiclePreparationUpdateProduct: {active: $active}
  ) {
    id
    active
  }
}
    `;
export const LoadVehiclePreparationsListDocument = `
    query LoadVehiclePreparationsList($search: SearchProjection2Input!) {
  products: plainSearchVehiclePreparationProducts(search: $search) {
    entities {
      id
      active
      country
      refId
      invoiceName
      partnerName
      currencyCode
      price {
        grossPriceMinorUnits
        vatKey
        vatPercentage
      }
    }
    page
    totalPageCount
    totalEntityCount
  }
}
    `;
export const UpdateWarrantyActiveDocument = `
    mutation UpdateWarrantyActive($id: UUID!, $active: Boolean!) {
  updateWarrantyProduct(id: $id, retailWarrantyUpdateProduct: {active: $active}) {
    id
    active
  }
}
    `;
export const LoadWarrantiesListDocument = `
    query LoadWarrantiesList($search: SearchProjection2Input!) {
  products: plainSearchWarrantyProducts(search: $search) {
    entities {
      id
      active
      country
      refId
      level
      monthsCovered
      invoiceName
      partnerName
      currencyCode
      price {
        grossPriceMinorUnits
        vatKey
        vatPercentage
      }
    }
    page
    totalPageCount
    totalEntityCount
  }
}
    `;
export const LoadWarrantyProductDocument = `
    query LoadWarrantyProduct($id: UUID!) {
  product: warranty(id: $id) {
    ...WarrantyProductFragment
  }
}
    ${WarrantyProductFragmentFragmentDoc}`;
export const UpdateWarrantyProductDocument = `
    mutation UpdateWarrantyProduct($id: UUID!, $product: RetailWarrantyUpdateProjectionInput!) {
  update: updateWarrantyProduct(id: $id, retailWarrantyUpdateProduct: $product) {
    ...WarrantyProductFragment
  }
}
    ${WarrantyProductFragmentFragmentDoc}`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (
  action,
  _operationName,
  _operationType
) => action();

export function getSdk(
  client: GraphQLClient,
  withWrapper: SdkFunctionWrapper = defaultWrapper
) {
  return {
    UserPermissions(
      variables?: UserPermissionsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<UserPermissionsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UserPermissionsQuery>(
            UserPermissionsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "UserPermissions",
        "query"
      );
    },
    FetchFeatureFlags(
      variables: FetchFeatureFlagsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<FetchFeatureFlagsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<FetchFeatureFlagsQuery>(
            FetchFeatureFlagsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "FetchFeatureFlags",
        "query"
      );
    },
    CreateBasketItem(
      variables: CreateBasketItemMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<CreateBasketItemMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateBasketItemMutation>(
            CreateBasketItemDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "CreateBasketItem",
        "mutation"
      );
    },
    LoadBasketItem(
      variables: LoadBasketItemQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<LoadBasketItemQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<LoadBasketItemQuery>(
            LoadBasketItemDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "LoadBasketItem",
        "query"
      );
    },
    UpdateBasketItem(
      variables: UpdateBasketItemMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<UpdateBasketItemMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateBasketItemMutation>(
            UpdateBasketItemDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "UpdateBasketItem",
        "mutation"
      );
    },
    UpdateBasketItemActive(
      variables: UpdateBasketItemActiveMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<UpdateBasketItemActiveMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateBasketItemActiveMutation>(
            UpdateBasketItemActiveDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "UpdateBasketItemActive",
        "mutation"
      );
    },
    LoadBasketItems(
      variables: LoadBasketItemsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<LoadBasketItemsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<LoadBasketItemsQuery>(
            LoadBasketItemsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "LoadBasketItems",
        "query"
      );
    },
    LoadPartnerOptions(
      variables: LoadPartnerOptionsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<LoadPartnerOptionsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<LoadPartnerOptionsQuery>(
            LoadPartnerOptionsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "LoadPartnerOptions",
        "query"
      );
    },
    LoadTypeOptions(
      variables: LoadTypeOptionsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<LoadTypeOptionsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<LoadTypeOptionsQuery>(
            LoadTypeOptionsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "LoadTypeOptions",
        "query"
      );
    },
    LoadCarRegistrationProduct(
      variables: LoadCarRegistrationProductQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<LoadCarRegistrationProductQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<LoadCarRegistrationProductQuery>(
            LoadCarRegistrationProductDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "LoadCarRegistrationProduct",
        "query"
      );
    },
    UpdateCarRegistrationProduct(
      variables: UpdateCarRegistrationProductMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<UpdateCarRegistrationProductMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateCarRegistrationProductMutation>(
            UpdateCarRegistrationProductDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "UpdateCarRegistrationProduct",
        "mutation"
      );
    },
    UpdateCarRegistrationActive(
      variables: UpdateCarRegistrationActiveMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<UpdateCarRegistrationActiveMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateCarRegistrationActiveMutation>(
            UpdateCarRegistrationActiveDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "UpdateCarRegistrationActive",
        "mutation"
      );
    },
    LoadCarRegistrationsList(
      variables: LoadCarRegistrationsListQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<LoadCarRegistrationsListQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<LoadCarRegistrationsListQuery>(
            LoadCarRegistrationsListDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "LoadCarRegistrationsList",
        "query"
      );
    },
    SearchProductChangelog(
      variables: SearchProductChangelogQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<SearchProductChangelogQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SearchProductChangelogQuery>(
            SearchProductChangelogDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "SearchProductChangelog",
        "query"
      );
    },
    LoadFeeOptions(
      variables: LoadFeeOptionsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<LoadFeeOptionsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<LoadFeeOptionsQuery>(
            LoadFeeOptionsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "LoadFeeOptions",
        "query"
      );
    },
    LoadDownPaymentProduct(
      variables: LoadDownPaymentProductQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<LoadDownPaymentProductQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<LoadDownPaymentProductQuery>(
            LoadDownPaymentProductDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "LoadDownPaymentProduct",
        "query"
      );
    },
    UpdateDownPaymentProduct(
      variables: UpdateDownPaymentProductMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<UpdateDownPaymentProductMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateDownPaymentProductMutation>(
            UpdateDownPaymentProductDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "UpdateDownPaymentProduct",
        "mutation"
      );
    },
    UpdateDownPaymentActive(
      variables: UpdateDownPaymentActiveMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<UpdateDownPaymentActiveMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateDownPaymentActiveMutation>(
            UpdateDownPaymentActiveDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "UpdateDownPaymentActive",
        "mutation"
      );
    },
    LoadDownPaymentsList(
      variables: LoadDownPaymentsListQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<LoadDownPaymentsListQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<LoadDownPaymentsListQuery>(
            LoadDownPaymentsListDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "LoadDownPaymentsList",
        "query"
      );
    },
    CreateFeeProduct(
      variables: CreateFeeProductMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<CreateFeeProductMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateFeeProductMutation>(
            CreateFeeProductDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "CreateFeeProduct",
        "mutation"
      );
    },
    LoadFeeProduct(
      variables: LoadFeeProductQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<LoadFeeProductQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<LoadFeeProductQuery>(
            LoadFeeProductDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "LoadFeeProduct",
        "query"
      );
    },
    UpdateFeeProduct(
      variables: UpdateFeeProductMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<UpdateFeeProductMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateFeeProductMutation>(
            UpdateFeeProductDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "UpdateFeeProduct",
        "mutation"
      );
    },
    UpdateFeeProductActive(
      variables: UpdateFeeProductActiveMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<UpdateFeeProductActiveMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateFeeProductActiveMutation>(
            UpdateFeeProductActiveDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "UpdateFeeProductActive",
        "mutation"
      );
    },
    LoadFeesList(
      variables: LoadFeesListQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<LoadFeesListQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<LoadFeesListQuery>(LoadFeesListDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "LoadFeesList",
        "query"
      );
    },
    LoadVehicleInsuranceProduct(
      variables: LoadVehicleInsuranceProductQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<LoadVehicleInsuranceProductQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<LoadVehicleInsuranceProductQuery>(
            LoadVehicleInsuranceProductDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "LoadVehicleInsuranceProduct",
        "query"
      );
    },
    UpdateVehicleInsuranceProduct(
      variables: UpdateVehicleInsuranceProductMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<UpdateVehicleInsuranceProductMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateVehicleInsuranceProductMutation>(
            UpdateVehicleInsuranceProductDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "UpdateVehicleInsuranceProduct",
        "mutation"
      );
    },
    UpdateVehicleInsuranceActive(
      variables: UpdateVehicleInsuranceActiveMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<UpdateVehicleInsuranceActiveMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateVehicleInsuranceActiveMutation>(
            UpdateVehicleInsuranceActiveDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "UpdateVehicleInsuranceActive",
        "mutation"
      );
    },
    LoadVehicleInsurancesList(
      variables: LoadVehicleInsurancesListQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<LoadVehicleInsurancesListQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<LoadVehicleInsurancesListQuery>(
            LoadVehicleInsurancesListDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "LoadVehicleInsurancesList",
        "query"
      );
    },
    LoadVehiclePreparationProduct(
      variables: LoadVehiclePreparationProductQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<LoadVehiclePreparationProductQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<LoadVehiclePreparationProductQuery>(
            LoadVehiclePreparationProductDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "LoadVehiclePreparationProduct",
        "query"
      );
    },
    UpdateVehiclePreparationProduct(
      variables: UpdateVehiclePreparationProductMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<UpdateVehiclePreparationProductMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateVehiclePreparationProductMutation>(
            UpdateVehiclePreparationProductDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "UpdateVehiclePreparationProduct",
        "mutation"
      );
    },
    UpdateVehiclePreparationActive(
      variables: UpdateVehiclePreparationActiveMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<UpdateVehiclePreparationActiveMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateVehiclePreparationActiveMutation>(
            UpdateVehiclePreparationActiveDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "UpdateVehiclePreparationActive",
        "mutation"
      );
    },
    LoadVehiclePreparationsList(
      variables: LoadVehiclePreparationsListQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<LoadVehiclePreparationsListQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<LoadVehiclePreparationsListQuery>(
            LoadVehiclePreparationsListDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "LoadVehiclePreparationsList",
        "query"
      );
    },
    UpdateWarrantyActive(
      variables: UpdateWarrantyActiveMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<UpdateWarrantyActiveMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateWarrantyActiveMutation>(
            UpdateWarrantyActiveDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "UpdateWarrantyActive",
        "mutation"
      );
    },
    LoadWarrantiesList(
      variables: LoadWarrantiesListQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<LoadWarrantiesListQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<LoadWarrantiesListQuery>(
            LoadWarrantiesListDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "LoadWarrantiesList",
        "query"
      );
    },
    LoadWarrantyProduct(
      variables: LoadWarrantyProductQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<LoadWarrantyProductQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<LoadWarrantyProductQuery>(
            LoadWarrantyProductDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "LoadWarrantyProduct",
        "query"
      );
    },
    UpdateWarrantyProduct(
      variables: UpdateWarrantyProductMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<UpdateWarrantyProductMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateWarrantyProductMutation>(
            UpdateWarrantyProductDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "UpdateWarrantyProduct",
        "mutation"
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
