import { options as vatKeyOptions } from "@root/utils/fields/vatKey";
import { options as levelOptions } from "@root/utils/fields/warrantyLevel";
import { options as sourceOptions } from "@root/utils/fields/warrantySourceType";
import { formatVatRate } from "@root/utils/format/vatRate";

import {
  FieldConfig,
  booleanFormatter,
  createOptionsFormatter,
} from "../common/components/ProductChangelog";

export const changelogFieldsConfig: Record<string, FieldConfig> = {
  recommended: {
    label: "Recommended",
    formatter: booleanFormatter,
    simplified: true,
  },
  level: {
    label: "Level",
    formatter: createOptionsFormatter(levelOptions),
  },
  monthsCovered: {
    label: "Month covered",
  },
  source: {
    label: "Source",
    formatter: createOptionsFormatter(sourceOptions),
  },
  insuranceTax: {
    label: "Insurance tax",
    formatter: (value) => formatVatRate(Number(value)),
  },
  applicableCarVatKey: {
    label: "Applicable car VAT Key",
    formatter: createOptionsFormatter(vatKeyOptions),
  },
};
