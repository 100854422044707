import { InputNumber, InputNumberProps } from "antd";

import { converter } from "./converter";

export interface PercentInputProps extends InputNumberProps {
  qaSelector?: string;
}

export const PercentInput = ({ qaSelector, ...props }: PercentInputProps) => {
  return (
    <InputNumber
      addonBefore="%"
      step={0.01}
      min={0}
      max={1}
      {...converter}
      data-qa-selector={qaSelector}
      {...props}
    />
  );
};
