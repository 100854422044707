import { PublishedScopeType, VatKeyType } from "@root/generated/graphql";

interface PriceEntity {
  grossPriceMinorUnits: number;
  vatPercentage: number;
  vatKey: VatKeyType;
}

interface NestedValues {
  price: PriceEntity;
  pnlCostPrice: PriceEntity;
}

interface PlainValues {
  refId: string;
  country: string;
  publishedScope: PublishedScopeType;
  partnerName: string;
  quantity: number;
  invoiceName: string;
  invoiceDescription: string;
  internalReferenceName: string;
  shippingRequired: boolean;
  active: boolean;
  defaulted: boolean;
}

export interface ProductFormValues extends PlainValues, NestedValues {}

type PlainFormField = keyof PlainValues;

type NestedFormField = keyof NestedValues;

export function formField<
  F extends NestedFormField,
  S extends keyof NestedValues[F]
>(field: F, subfield: S): [F, S];

export function formField(field: PlainFormField): PlainFormField;

export function formField(
  field: PlainFormField | NestedFormField,
  subfield?: string
) {
  if (["price", "pnlCostPrice"].includes(field)) {
    return [field, subfield];
  }
  return field;
}
