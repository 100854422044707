import { useCallback } from "react";

import { useGeneratePath } from "@root/hooks/useGeneratePath";
import { routesConfig } from "@root/routes/config";

export const useGenerateLoginPath = () => {
  const generatePath = useGeneratePath();

  return useCallback(
    (location: Location) => {
      const params = new URLSearchParams();
      params.append("continue", btoa(`${location.pathname}${location.search}`));

      const loginPath = generatePath(routesConfig.login, {});

      return `${loginPath}?${params.toString()}`;
    },
    [generatePath]
  );
};
