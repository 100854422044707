import { useQuery } from "react-query";

import { Sorts, createFilterPayload } from "@retail/gql-utils";

import { getApiClient } from "@root/apiClient";

import { SearchValues } from "../../common/types/filterValues";
import { filterValuesToQuery } from "../../common/utils/filterValuesToQuery";
import { FeeProduct } from "../types";

const PAGE_SIZE = 10;

export const useFeesList = (searchValues: SearchValues) => {
  const { data, isFetching, refetch } = useQuery(
    ["fees-list", searchValues],
    () =>
      getApiClient().LoadFeesList({
        search: createFilterPayload({
          filter: filterValuesToQuery(searchValues.filters),
          sorts: [Sorts.desk("createdOn")],
          page: searchValues.page - 1,
          pageSize: PAGE_SIZE,
        }),
      }),
    {
      select: ({ products }) =>
        products
          ? {
              ...products,
              entities: products.entities as FeeProduct[],
            }
          : null,
    }
  );

  return {
    data,
    isLoading: isFetching,
    refetch,
    pageSize: PAGE_SIZE,
  };
};
