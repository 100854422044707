export const options = [
  {
    value: "PRODUCT",
    label: "Product",
  },
  {
    value: "SERVICE",
    label: "Service",
  },
  {
    value: "REFURBISHMENT",
    label: "Refurbishment",
  },
  {
    value: "TRANSPORT",
    label: "Transport",
  },
  {
    value: "CUSTOM_LICENSE_PLATE",
    label: "Custom License Plate",
  },
  {
    value: "TAX",
    label: "Tax",
  },
];
