import { Select, SelectProps, Tooltip } from "antd";

import { Nil } from "@root/types";
import { selectQaSelectors } from "@root/utils/antd";

import { useFeeTypeOptions } from "../../hooks/useFeeTypeOptions";

interface FeeTypeProps {
  country: string | Nil;
  selectProps?: SelectProps;
  value: string | Nil;
  onChange: (value: string) => void;
}

export const FeeType = ({
  selectProps,
  country,
  value,
  onChange,
}: FeeTypeProps) => {
  const { options, isLoading } = useFeeTypeOptions({ country });

  return (
    <Tooltip title={country ? "" : "Please select country"}>
      <Select
        {...selectProps}
        {...selectQaSelectors("feeType")}
        options={options}
        loading={isLoading}
        disabled={!country}
        placeholder="Fee type"
        value={value}
        onChange={onChange}
      />
    </Tooltip>
  );
};
