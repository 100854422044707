// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Gx7Qem5emVj_uLTl1PwQ::before {\n  display: inline-block;\n  margin-right: 4px;\n  color: #ff4d4f;\n  font-size: 14px;\n  font-family: SimSun, sans-serif;\n  line-height: 1;\n  content: \"*\";\n}\n", "",{"version":3,"sources":["webpack://./src/routes/basketItems/common/BasketItemForm/styles.less"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,iBAAA;EACA,cAAA;EACA,eAAA;EACA,+BAAA;EACA,cAAA;EACA,YAAA;AACF","sourcesContent":[".requiredMark::before {\n  display: inline-block;\n  margin-right: 4px;\n  color: #ff4d4f;\n  font-size: 14px;\n  font-family: SimSun, sans-serif;\n  line-height: 1;\n  content: \"*\";\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"requiredMark": "Gx7Qem5emVj_uLTl1PwQ"
};
export default ___CSS_LOADER_EXPORT___;
