import { CheckCircleOutlined, CopyOutlined } from "@ant-design/icons";
import { Space } from "antd";
import { useEffect, useState } from "react";

import { Nil } from "@root/types";

import cn from "./styles.local.less";

interface CopyableTextProps {
  text: string | Nil;
}

export const CopyableText = ({ text }: CopyableTextProps) => {
  const [isCopied, setCopied] = useState(false);

  const canCopy =
    text &&
    typeof window !== "undefined" &&
    Boolean(window.navigator.clipboard?.writeText);

  const handleCopy = () => {
    window.navigator.clipboard.writeText(text!).then(() => setCopied(true));
  };

  useEffect(() => {
    const timeoutId = isCopied
      ? window.setTimeout(() => setCopied(false), 1000)
      : undefined;
    return () => window.clearTimeout(timeoutId);
  }, [isCopied]);

  return (
    <Space size="small" className={cn.text}>
      {text}
      {canCopy &&
        (isCopied ? (
          <CheckCircleOutlined />
        ) : (
          <CopyOutlined className={cn.icon} onClick={handleCopy} />
        ))}
    </Space>
  );
};
