import { Result } from "antd";
import { Navigate, Outlet } from "react-router-dom";

import { useIsAuthenticated } from "@root/acl/useIsAuthenticated";
import { useGenerateLoginPath } from "@root/hooks/useGenerateLoginPath";

import { Permission } from "./types";
import { useHasPermission } from "./useHasPermission";

interface AclRouteProps {
  permission: Permission;
}

export const AclRoute = ({ permission }: AclRouteProps) => {
  const hasPermission = useHasPermission();
  const isAuthenticated = useIsAuthenticated();
  const generateLoginPath = useGenerateLoginPath();

  if (isAuthenticated === null) {
    return null;
  }

  if (isAuthenticated === false) {
    return <Navigate to={generateLoginPath(window.location)} />;
  }

  return hasPermission(permission) ? (
    <Outlet />
  ) : (
    <Result
      status={403}
      title={403}
      subTitle="Sorry, you are not authorized to access this page."
    />
  );
};
