import { Space } from "antd";
import React from "react";
import { useMutation } from "react-query";

import { useHasPermission } from "@root/acl";
import { getApiClient } from "@root/apiClient";
import { FIRST_PAGE_NUMBER } from "@root/hooks/useSearchValues";
import { routesConfig } from "@root/routes/config";
import { options as warrantyOptions } from "@root/utils/fields/warrantyLevel";
import { toStableValuesAsString } from "@root/utils/string";

import { ProductsFilters } from "../common/components/ProductsFilters";
import { ProductsTable } from "../common/components/ProductsTable";
import { useSearchValues } from "../common/hooks/useSearchValues";
import { WarrantyProduct } from "./types";
import { useWarrantiesList } from "./useWarrantiesList";

export const WarrantiesOverview = () => {
  const hasPermission = useHasPermission();
  const [searchValues, updateSearchValues] = useSearchValues();
  const { data, isLoading, pageSize, refetch } = useWarrantiesList({
    searchValues,
  });

  const { mutateAsync: onSwitchActive } = useMutation(
    getApiClient().UpdateWarrantyActive
  );

  const handleSwitchActive = async (id: string, active: boolean) => {
    await onSwitchActive({ id, active });
    await refetch();
  };

  return (
    <Space size="middle" direction="vertical" style={{ width: "100%" }}>
      <ProductsFilters
        withWarrantyLevel
        filterValues={searchValues.filters}
        reinitializeKey={toStableValuesAsString(searchValues.filters)}
        onUpdateFilterValues={(filters) =>
          updateSearchValues({ page: FIRST_PAGE_NUMBER, filters })
        }
      />
      <ProductsTable<WarrantyProduct>
        data={data?.entities ?? []}
        isLoading={isLoading}
        extraColumns={[
          {
            key: "level",
            title: "Level",
            render: (_, { level }) =>
              warrantyOptions.find((it) => it.value === level)?.label ?? level,
          },
          {
            key: "monthsCovered",
            title: "Duration",
            render: (_, entity) => entity.monthsCovered,
          },
        ]}
        pageSize={pageSize}
        currentPage={searchValues.page}
        totalEntityCount={data?.totalEntityCount ?? 0}
        editRoute={
          hasPermission("warrantyProductUpdate")
            ? routesConfig.warranty.edit
            : undefined
        }
        canSwitchActive={hasPermission("warrantyProductUpdate")}
        onSwitchActive={handleSwitchActive}
        onChangePage={(page) => updateSearchValues({ ...searchValues, page })}
      />
    </Space>
  );
};
