import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Input,
  Row,
  Select,
  SelectProps,
  Space,
} from "antd";
import React, { useEffect, useState } from "react";

import { useHasPermission } from "@root/acl";
import { Link } from "@root/components/Link";
import { useGeneratePath } from "@root/hooks/useGeneratePath";
import { routesConfig } from "@root/routes/config";
import { options as categoryOptions } from "@root/utils/fields/category";
import { options as countryOptions } from "@root/utils/fields/country";
import { filterOptionsByLabelAndValue } from "@root/utils/select";

import { useTypeOptions } from "../../common/hooks/useTypeOptions";
import { FilterValues } from "../types";
import { Field } from "./Field";

interface FiltersPanelProps {
  filterValues: FilterValues;
  reinitializeKey: string;
  onUpdateFilterValues: (update: FilterValues) => void;
}

export const FiltersPanel = ({
  filterValues: activeValues,
  reinitializeKey,
  onUpdateFilterValues,
}: FiltersPanelProps) => {
  const hasPermission = useHasPermission();
  const generatePath = useGeneratePath();

  const [uiValues, setUiValues] = useState<FilterValues>(activeValues);

  useEffect(() => {
    // When reinitializeKey changes we re-initialize internal state to values from props.
    // It's important in scenarios when user goes "back" or "forward" in browser history
    setUiValues(activeValues);
  }, [reinitializeKey]);

  const typeOptions = useTypeOptions(uiValues);

  const handleValuesChange = (update: Partial<FilterValues>) =>
    setUiValues((values) => ({ ...values, ...update }));

  const handleApplyFilters = () => onUpdateFilterValues(uiValues);

  const commonInputsStyle: React.CSSProperties = { width: "186px" };
  const commonSelectProps: SelectProps = {
    allowClear: true,
    showSearch: true,
    filterOption: true,
    optionFilterProp: "label",
    style: commonInputsStyle,
  };
  return (
    <div style={{ padding: "0 12px" }}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleApplyFilters();
        }}
      >
        <Row justify="end">
          <Col>
            {hasPermission("basketItemsCreatePage") && (
              <Link
                type="primary"
                to={generatePath(routesConfig.basketItems.create, {})}
                data-qa-selector="link_create-basket-item"
              >
                Create Basket Item
              </Link>
            )}
          </Col>
        </Row>
        <Row align="bottom" justify="space-between" wrap={false}>
          <Col flex="auto">
            <Space size={["small", 8]} align="end" wrap>
              <Field label="Country">
                <Select
                  {...commonSelectProps}
                  placeholder="Select Country"
                  value={uiValues.country}
                  options={countryOptions}
                  filterOption={filterOptionsByLabelAndValue}
                  data-qa-selector="select_country"
                  dropdownClassName="QA_ID_dropdown_country"
                  onChange={(country) =>
                    handleValuesChange({ country, type: null })
                  }
                />
              </Field>
              <Field label="Category">
                <Select
                  {...commonSelectProps}
                  placeholder="Select Category"
                  value={uiValues.category}
                  options={categoryOptions}
                  data-qa-selector="select_category"
                  dropdownClassName="QA_ID_dropdown_category"
                  onChange={(category) =>
                    handleValuesChange({ category, type: null })
                  }
                />
              </Field>
              <Field label="Type">
                <Select
                  {...commonSelectProps}
                  placeholder="Select Type"
                  loading={typeOptions.isLoading}
                  value={uiValues.type}
                  options={typeOptions.list}
                  disabled={!typeOptions.list?.length}
                  data-qa-selector="select_type"
                  dropdownClassName="QA_ID_dropdown_type"
                  onChange={(type) => handleValuesChange({ type })}
                />
              </Field>
              <Field label="Partner">
                <Input
                  allowClear
                  placeholder="Partner name contains"
                  style={commonInputsStyle}
                  value={uiValues.partner || ""}
                  data-qa-selector="input_partner-contains"
                  onChange={(e) =>
                    handleValuesChange({ partner: e.target.value })
                  }
                />
              </Field>
              <Field label="Title">
                <Input
                  allowClear
                  placeholder="Title contains"
                  style={commonInputsStyle}
                  value={uiValues.title || ""}
                  data-qa-selector="input_title-contains"
                  onChange={(e) =>
                    handleValuesChange({ title: e.target.value })
                  }
                />
              </Field>
              <Field label="SKU">
                <Input
                  allowClear
                  placeholder="SKU contains"
                  style={commonInputsStyle}
                  value={uiValues.sku || ""}
                  data-qa-selector="input_sku-contains"
                  onChange={(e) => handleValuesChange({ sku: e.target.value })}
                />
              </Field>
              <Checkbox
                checked={uiValues.enabled}
                onChange={(e) =>
                  handleValuesChange({ enabled: e.target.checked })
                }
                style={{ fontWeight: "normal", ...commonInputsStyle }}
                data-qa-selector="checkbox_only-active"
              >
                Only Enabled
              </Checkbox>
            </Space>
          </Col>
          <Col flex="none">
            <Button
              icon={<SearchOutlined />}
              type="primary"
              htmlType="submit"
              data-qa-selector="button_search-submit"
            >
              Search
            </Button>
          </Col>
        </Row>
      </form>
    </div>
  );
};
