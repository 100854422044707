import { PublishedScopeType } from "@root/generated/graphql";

export const options: { value: PublishedScopeType; label: string }[] = [
  {
    value: "GLOBAL",
    label: "Global",
  },
  { value: "ONLINE", label: "Online" },
  { value: "SHOWROOM", label: "Showroom" },
];
