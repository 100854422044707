import { useCallback } from "react";
import { useQuery } from "react-query";

import { getApiClient } from "@root/apiClient";
import { Country } from "@root/types";

export enum FeatureFlag {
  // Declare feature flags to fetch here
  BO_BASKET_ITEM_EXCLUDE_FROM_INVOICE_WITH_NON_ZERO_AMOUNT = "BO_BASKET_ITEM_EXCLUDE_FROM_INVOICE_WITH_NON_ZERO_AMOUNT",
}

const getEnvironment = () =>
  (typeof __config === "object" && __config?.APP_ENV?.toUpperCase()) || null;

interface FeatureFlagContext {
  locale?: null | string;
  country?: null | Country;
}

interface UseFeatureFlagsInput extends FeatureFlagContext {
  skip?: boolean;
}

const getEnabledFeatureFlags = (
  context: FeatureFlagContext
): Promise<Set<string>> =>
  getApiClient()
    .FetchFeatureFlags({
      names: Object.values(FeatureFlag),
      context: { ...context, environment: getEnvironment() },
    })
    .then(({ flags }) =>
      flags!.reduce((acc, flag) => {
        if (flag!.name && flag!.enabled) {
          acc.add(flag!.name);
        }

        return acc;
      }, new Set<string>())
    );

export const useFeatureFlags = ({
  country,
  locale,
  skip,
}: UseFeatureFlagsInput) => {
  const context = { country, locale };
  const { data: enabledFlags, isLoading } = useQuery(
    ["feature-flags", context],
    () => getEnabledFeatureFlags(context),
    {
      enabled: !skip,
      refetchOnWindowFocus: false,
    }
  );

  const isFlagEnabled = useCallback(
    (flag: FeatureFlag) => enabledFlags?.has(flag),
    [enabledFlags]
  );

  return {
    isLoading,
    isFlagEnabled,
  };
};
