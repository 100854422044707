export const filterOptionsByLabelAndValue = (
  input: string,
  option: { label: string; value: string } | undefined
) => {
  const inputLower = input.toLowerCase();
  return (
    option?.label.toLowerCase().includes(inputLower) ||
    option?.value.toLowerCase() === input
  );
};
