import React from "react";
import { useQuery } from "react-query";

import { getApiClient } from "@root/apiClient";

type ContextType = {
  isAuthenticated: boolean | null;
  permissions: Record<string, true>;
};

const defaultContextValue = {
  isAuthenticated: null,
  permissions: {},
};

export const PermissionsContext =
  React.createContext<ContextType>(defaultContextValue);

export const PermissionsProvider = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const { data } = useQuery(
    ["permissions"],
    () => getApiClient().UserPermissions(),
    {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      select: (data) => {
        const permissions = {} as ContextType["permissions"];

        data?.me?.effectivePermissions?.forEach((permission) => {
          if (permission) {
            permissions[permission] = true;
          }
        });

        return {
          isAuthenticated: Boolean(data?.me),
          permissions,
        };
      },
    }
  );

  return (
    <PermissionsContext.Provider value={data || defaultContextValue}>
      {children}
    </PermissionsContext.Provider>
  );
};
