import { useSearchValues as useSearchValuesImpl } from "@root/hooks/useSearchValues";

import { FilterValues } from "../types/filterValues";

export const useSearchValues = () =>
  useSearchValuesImpl({ paramsToFilters: searchParamsToFilters });

const searchParamsToFilters = (params: URLSearchParams): FilterValues => ({
  country: params.get("country"),
  refId: params.get("refId"),
  active: params.get("active") as FilterValues["active"],
  level: params.get("level"),
  registrationType: params.get("registrationType"),
  feeType: params.get("feeType"),
});
