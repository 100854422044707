import { NotificationInstance } from "antd/es/notification";
import { useMutation } from "react-query";

import { getApiClient } from "@root/apiClient";

interface Params {
  notificationApi: NotificationInstance;
  onUpdateDone: () => void;
}

export const useBasketItemUpdateActive = ({
  notificationApi,
  onUpdateDone,
}: Params) => {
  const updateActiveMutation = useMutation(
    getApiClient().UpdateBasketItemActive
  );

  return async (id: string, isActive: boolean) => {
    const data = await updateActiveMutation.mutateAsync({ id, isActive });
    const newStatus = data.updateProduct?.active ? "enabled" : "disabled";
    notificationApi.success({
      message: `Basket item ${newStatus}`,
    });
    onUpdateDone();
  };
};
