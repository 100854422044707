import { EditFilled, MinusOutlined } from "@ant-design/icons";
import {
  Col,
  Pagination,
  Row,
  Space,
  Switch,
  Table,
  TableColumnsType,
  Typography,
  notification,
} from "antd";
import React from "react";
import { Link } from "react-router-dom";

import { Link as ButtonLink } from "@root/components/Link";
import { useGeneratePath } from "@root/hooks/useGeneratePath";
import { useCreateReturnLocation } from "@root/hooks/useReturnLocation";
import { RouteConfig } from "@root/routes/config";
import { Nil } from "@root/types";
import { formatCurrency } from "@root/utils/format/currency";
import { formatVatRate } from "@root/utils/format/vatRate";

import { ActiveSwitch } from "./ActiveSwitch";
import { CopyableText } from "./CopyableText";

interface ProductEntity {
  id: string;
  active: boolean;
  refId: string;
  country: string;
  invoiceName: string | Nil;
  partnerName: string;
  currencyCode: string;
  price: {
    grossPriceMinorUnits: number | Nil;
    vatKey: string | Nil;
    vatPercentage: number | Nil;
  };
}

interface ProductsTableProps<T extends ProductEntity> {
  data: T[];
  isLoading: boolean;
  extraColumns?: TableColumnsType<T>;
  showVat?: boolean;
  pageSize: number;
  currentPage: number;
  totalEntityCount: number;
  editRoute?: RouteConfig<{ id: string }>;
  createRoute?: RouteConfig<{}>;
  canSwitchActive: boolean;
  onSwitchActive: (id: string, active: boolean) => Promise<unknown>;
  onChangePage: (page: number, pageSize: number) => void;
}

export const ProductsTable = <T extends ProductEntity>({
  data,
  isLoading,
  extraColumns = [],
  currentPage,
  pageSize,
  totalEntityCount,
  editRoute,
  createRoute,
  canSwitchActive,
  onSwitchActive,
  onChangePage,
  showVat = true,
}: ProductsTableProps<T>) => {
  const generatePath = useGeneratePath();
  const [notificationApi, notificationContextHolder] =
    notification.useNotification();
  const createReturnLocation = useCreateReturnLocation();

  const handleSwitchActive = async (id: string, active: boolean) => {
    try {
      await onSwitchActive(id, active);
      const newState = active ? "activated" : "deactivated";
      notificationApi.success({ message: `Product successfully ${newState}` });
    } catch (e) {
      const newState = active ? "activate" : "deactivate";
      notificationApi.error({ message: `Failed to ${newState} product` });
      throw e;
    }
  };

  const vatColumns: TableColumnsType<ProductEntity> = [
    {
      key: "vatKey",
      title: "Vat key",
      render: (_, entity) => entity.price.vatKey,
    },
    {
      key: "vatRate",
      title: "Vat rate",
      render: (_, entity) => formatVatRate(entity.price.vatPercentage),
    },
  ];

  const columns: TableColumnsType<ProductEntity> = [
    {
      key: "refId",
      title: "Ref id",
      render: (_, entity) => <CopyableText text={entity.refId} />,
    },
    {
      key: "country",
      title: "Country",
      render: (_, entity) => entity.country,
    },
    ...(extraColumns as TableColumnsType<ProductEntity>),
    {
      key: "invoiceName",
      title: "Invoice name",
      render: (_, entity) => entity.invoiceName,
    },
    {
      key: "partner",
      title: "Partner",
      render: (_, entity) => entity.partnerName,
    },
    {
      key: "grossPrice",
      title: "Gross price",
      render: (_, entity) =>
        entity.price.grossPriceMinorUnits != null ? (
          formatCurrency({
            currencyCode: entity.currencyCode,
            amountMinorUnits: entity.price.grossPriceMinorUnits,
          })
        ) : (
          <MinusOutlined />
        ),
    },
    ...(showVat ? vatColumns : []),
    {
      key: "active",
      title: "Enabled",
      render: (_, entity) =>
        canSwitchActive ? (
          <ActiveSwitch product={entity} onUpdate={handleSwitchActive} />
        ) : (
          <Switch checked={entity.active} disabled />
        ),
    },
    ...(editRoute
      ? ([
          {
            key: "actions",
            title: "Actions",
            render: (_, entity) => (
              <Link
                to={{
                  pathname: generatePath(editRoute, { id: entity.id }),
                  search: new URLSearchParams(
                    createReturnLocation()
                  ).toString(),
                }}
              >
                <EditFilled style={{ fontSize: "18px" }} />
              </Link>
            ),
          },
        ] as TableColumnsType<ProductEntity>)
      : []),
  ];

  const pagination = (
    <Pagination
      hideOnSinglePage
      showSizeChanger={false}
      current={currentPage}
      pageSize={pageSize}
      total={totalEntityCount}
      onChange={onChangePage}
    />
  );

  return (
    <>
      <Space size="middle" direction="vertical" style={{ width: "100%" }}>
        <Row align="middle" justify="space-between">
          <Col>
            <Space size="large">
              <Typography.Title level={4} style={{ margin: 0 }}>
                {totalEntityCount} total results
              </Typography.Title>
              {createRoute && (
                <ButtonLink
                  type="primary"
                  to={{
                    pathname: generatePath(createRoute, {}),
                    search: new URLSearchParams(
                      createReturnLocation()
                    ).toString(),
                  }}
                >
                  Create new
                </ButtonLink>
              )}
            </Space>
          </Col>
          <Col>{pagination}</Col>
        </Row>
        <Table
          rowKey="id"
          dataSource={data}
          loading={isLoading}
          columns={columns}
          pagination={false}
        />
        <Row align="middle" justify="end">
          <Col>{pagination}</Col>
        </Row>
      </Space>
      {notificationContextHolder}
    </>
  );
};
