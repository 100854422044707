import { useCallback, useContext } from "react";

import { PermissionsContext } from "./PermissionsProvider";
import { PERMISSIONS_MAP, Permission } from "./types";

export const useHasPermission = () => {
  const { permissions: allowedPermissions } = useContext(PermissionsContext);

  return useCallback(
    (requestedPermissions: Permission[] | Permission) => {
      const permissions = Array.isArray(requestedPermissions)
        ? requestedPermissions
        : [requestedPermissions];

      return permissions.every((permission) =>
        PERMISSIONS_MAP[permission].every(
          (permissionId) => allowedPermissions[permissionId]
        )
      );
    },
    [allowedPermissions]
  );
};
