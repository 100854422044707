import { useQuery } from "react-query";

import { getApiClient } from "@root/apiClient";
import { Nil } from "@root/types";

export const useFeeTypeOptions = ({ country }: { country: string | Nil }) => {
  const { data, isLoading } = useQuery(
    ["fee-options", country],
    async () => {
      const { options } = await getApiClient().LoadFeeOptions({
        country: country!,
      });

      return options?.types?.map((type) => ({ value: type!, label: type! }));
    },
    {
      enabled: Boolean(country),
      refetchOnMount: "always",
    }
  );

  return {
    options: data ?? [],
    isLoading,
  };
};
