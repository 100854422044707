import { Popconfirm, Switch } from "antd";
import React from "react";

interface ActiveSwitchProps {
  product: { id: string; active: boolean };
  onUpdate: (id: string, active: boolean) => Promise<void>;
}

export const ActiveSwitch = ({ product, onUpdate }: ActiveSwitchProps) => {
  const newActive = !product.active;

  const title = `Are you sure you want to ${
    newActive ? "activate" : "deactivate"
  } this product item?`;

  return (
    <Popconfirm
      title={title}
      placement="topRight"
      okText={newActive ? "Activate" : "Deactivate"}
      onConfirm={() => onUpdate(product.id, newActive)}
    >
      <Switch checked={product.active ?? false} />
    </Popconfirm>
  );
};
