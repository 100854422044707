import { Nil } from "@root/types";

export interface FormValues {
  country: string | Nil;
  category: string | Nil;
  type: string | Nil;
  partner: string | Nil;
  sku: string | Nil;
  title: string | Nil;
  invoiceDescription: string | Nil;
  quantity: number | Nil;
  pnlCostNetMinorUnits: number | Nil;
  pnlVatRate: number | Nil;
  invoiceItemNetMinorUnits: number | Nil;
  vatRate: number | Nil;
  vatKey: string | Nil;
  shippingRequired: boolean;
  active: boolean;
  defaulted: boolean;
  excludedFromInvoice: boolean | Nil;
}

export type FormField = keyof FormValues;

export const formField = (field: FormField) => field;
