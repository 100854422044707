import { MinusOutlined } from "@ant-design/icons";
import {
  Col,
  Pagination,
  Row,
  Space,
  Table,
  Typography,
  notification,
} from "antd";
import { ColumnType } from "antd/es/table";
import React from "react";

import { addCellQaId } from "@root/utils/antd";
import { formatVatRate } from "@root/utils/format/vatRate";

import { SearchValues } from "../types";
import { Actions } from "./columns/Actions";
import { ActiveSwitch } from "./columns/ActiveSwitch";
import { FormattedPrice } from "./columns/FormattedPrice";
import { BasketItemEntity } from "./types";
import { useBasketItemUpdateActive } from "./useBasketItemUpdateActive";
import { useBasketItemsData } from "./useBasketItemsData";

interface ItemsTableProps {
  searchValues: SearchValues;
  onChangePage: (page: number) => void;
}

export const ItemsTable = ({ searchValues, onChangePage }: ItemsTableProps) => {
  const basketItems = useBasketItemsData(searchValues);

  const [notificationApi, notificationContextHolder] =
    notification.useNotification();

  const basketItemUpdateActive = useBasketItemUpdateActive({
    notificationApi,
    onUpdateDone: basketItems.refetch,
  });

  const dynamicColumns: Record<
    "country" | "category" | "type",
    ColumnType<BasketItemEntity>
  > = {
    country: {
      key: "country",
      title: "Country",
      width: "60px",
      render: (_, item) => item.country,
      ...addCellQaId("country"),
    },
    category: {
      key: "category",
      title: "Category",
      width: "10%",
      render: (_, item) => item.category,
      ...addCellQaId("category"),
    },
    type: {
      key: "type",
      title: "Type",
      width: "10%",
      render: (_, item) => item.type,
      ...addCellQaId("type"),
    },
  };

  const columns: ColumnType<BasketItemEntity>[] = [
    ...(searchValues.filters.country ? [] : [dynamicColumns.country]),
    {
      key: "title",
      title: "Title",
      width: "15%",
      render: (_, item) => item.title || <MinusOutlined />,
      ...addCellQaId("title"),
    },
    ...(searchValues.filters.category ? [] : [dynamicColumns.category]),
    ...(searchValues.filters.type ? [] : [dynamicColumns.type]),
    {
      key: "partner",
      title: "Partner",
      width: "10%",
      render: (_, item) => item.partner,
      ...addCellQaId("partner"),
    },
    {
      key: "invoiceNetPrice",
      title: "Invoice Net Price",
      width: "95px",
      render: (_, { invoiceItemNet }) => (
        <FormattedPrice price={invoiceItemNet} />
      ),
      ...addCellQaId("invoiceNetPrice"),
    },
    {
      key: "invoiceVatRate",
      title: "Invoice VAT Rate",
      width: "90px",
      render: (_, item) => formatVatRate(item.vatRate),
      ...addCellQaId("invoiceVatRate"),
    },
    {
      key: "invoiceGrossPrice",
      title: "Invoice Gross Price",
      width: "95px",
      render: (_, { invoiceItemGross }) => (
        <FormattedPrice price={invoiceItemGross} />
      ),
      ...addCellQaId("invoiceGrossPrice"),
    },
    {
      key: "enabled",
      title: "Enabled",
      width: "70px",
      render: (_, item) => (
        <ActiveSwitch item={item} onUpdate={basketItemUpdateActive} />
      ),
      ...addCellQaId("active"),
    },
    {
      key: "actions",
      title: "Actions",
      align: "center",
      width: "65px",
      render: (_, item) => <Actions item={item} />,
      ...addCellQaId("actions"),
    },
  ];

  const pagination = (
    <Pagination
      hideOnSinglePage
      showSizeChanger={false}
      current={searchValues.page}
      pageSize={basketItems.pageSize}
      total={basketItems.data?.totalEntityCount || undefined}
      onChange={onChangePage}
    />
  );

  return (
    <Space direction="vertical" size="middle" style={{ width: "100%" }}>
      <Row align="middle" justify="space-between">
        <Col>
          <Typography.Title
            level={4}
            {...{ "data-qa-selector": "total-results" }}
          >
            {basketItems.data?.totalEntityCount ?? 0} total results
          </Typography.Title>
        </Col>
        <Col>{pagination}</Col>
      </Row>
      <Table
        rowKey="id"
        size="small"
        columns={columns}
        dataSource={basketItems.data?.list ?? []}
        loading={basketItems.isLoading}
        locale={{
          emptyText: "Documents not found",
        }}
        pagination={false}
      />
      <Row align="middle" justify="end">
        <Col>{pagination}</Col>
      </Row>
      {notificationContextHolder}
    </Space>
  );
};
