import { InputNumber } from "antd";

import {
  getConversionMajorByCurrencyCode,
  unitsFromMinorToMajor,
  unitsFromNetToGross,
} from "@retail/currency";

import { FormField } from "./types";
import { useWatchField } from "./useWatchField";

export const unitsFromNetMinorToGrossMajor = ({
  netMinorUnits,
  vatRate,
  currencyCode,
}: {
  netMinorUnits: number;
  vatRate: number;
  currencyCode: string;
}) => {
  const conversionMajor = getConversionMajorByCurrencyCode(currencyCode);
  const grossMinorUnits = unitsFromNetToGross(netMinorUnits, vatRate);
  return unitsFromMinorToMajor(grossMinorUnits, conversionMajor);
};

interface GrossPriceIndicatorProps {
  currencyCode: string;
  netPriceField: FormField;
  vatRateField: FormField;
}

export const GrossPriceIndicator = ({
  currencyCode,
  netPriceField,
  vatRateField,
}: GrossPriceIndicatorProps) => {
  const netMinorUnits = useWatchField<FormField, number>(netPriceField);
  const vatRate = useWatchField<FormField, number>(vatRateField);

  return (
    <InputNumber
      style={{ width: "100%" }}
      value={unitsFromNetMinorToGrossMajor({
        netMinorUnits,
        vatRate,
        currencyCode,
      })}
      readOnly
      disabled
    />
  );
};
