import { Checkbox, Form, Select } from "antd";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";

import { useHasPermission } from "@root/acl";
import { getApiClient } from "@root/apiClient";
import { routesConfig } from "@root/routes/config";
import { selectQaSelectors } from "@root/utils/antd";
import { options as registrationTypeOptions } from "@root/utils/fields/registrationType";

import { EditPageLayout } from "../common/components/EditPageLayout";
import { ProductChangelog } from "../common/components/ProductChangelog";
import { ProductForm } from "../common/components/ProductForm";
import { useProductEditingFormActions } from "../common/hooks/useProductEditingFormActions";
import { mapProductFormValuesForEditSubmit } from "../common/utils/mapProductFormValuesForEditSubmit";
import { changelogFieldsConfig } from "./changelogFieldsConfig";
import { CarRegistrationProductFormValues, formField } from "./types";

const { Item } = Form;

export const CarRegistrationEdit = () => {
  const { id: productId } = useParams<"id">();
  const hasPermission = useHasPermission();

  const [formInstance] = Form.useForm<CarRegistrationProductFormValues>();

  const { data, isFetching: isLoading } = useQuery(
    ["car-registration-product", productId],
    () =>
      getApiClient()
        .LoadCarRegistrationProduct({ id: productId! })
        .then((data) => data.product),
    {
      onSuccess: (data) =>
        formInstance.setFieldsValue(data as CarRegistrationProductFormValues),
    }
  );

  const { mutateAsync: update, isLoading: isSubmitting } = useMutation(
    getApiClient().UpdateCarRegistrationProduct,
    {
      onSuccess: (data) =>
        formInstance.setFieldsValue(
          data.update as CarRegistrationProductFormValues
        ),
    }
  );

  const { handleSubmit, handleCancel } = useProductEditingFormActions({
    returnRoute: routesConfig.carRegistration.overview,
    mutation: (values) =>
      update({
        id: data!.id!,
        product: mapProductFormValuesForEditSubmit(values),
      }),
  });

  return (
    <EditPageLayout title="Car registration edit">
      <ProductForm
        isLoading={isLoading}
        isSubmitting={isSubmitting}
        mode="EDIT"
        formInstance={formInstance}
        productCurrencyCode={data?.currencyCode}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        slots={{
          beforePrices: (
            <Item
              name={formField("registrationType")}
              label="Registration type"
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                options={registrationTypeOptions}
                {...selectQaSelectors("registrationType")}
              />
            </Item>
          ),
          afterCheckboxes: (
            <>
              <Item
                name={formField("registrationInRetailCountry")}
                valuePropName="checked"
              >
                <Checkbox data-qa-selector="checkbox_registrationInRetailCountry">
                  Registration in retail country
                </Checkbox>
              </Item>
              <Item
                name={formField("registrationInAnyCountry")}
                valuePropName="checked"
              >
                <Checkbox data-qa-selector="checkbox_registrationInAnyCountry">
                  Registration in any country
                </Checkbox>
              </Item>
            </>
          ),
        }}
      />
      {data?.id &&
        data?.currencyCode &&
        hasPermission("productChangelogSearch") && (
          <ProductChangelog
            productId={data.id}
            currencyCode={data.currencyCode}
            extraFieldsConfig={changelogFieldsConfig}
          />
        )}
    </EditPageLayout>
  );
};
