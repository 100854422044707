import { Space, Typography } from "antd";
import React from "react";

interface EditPageLayoutProps {
  title: React.ReactNode;
  children: React.ReactNode;
}

export const EditPageLayout = ({ title, children }: EditPageLayoutProps) => (
  <>
    <div
      style={{
        width: "100%",
        paddingLeft: "15px",
        paddingRight: "15px",
      }}
    >
      <Space
        size="middle"
        direction="vertical"
        style={{
          display: "flex",
          width: "100%",
          maxWidth: "1200px",
          marginRight: "auto",
          marginLeft: "auto",
        }}
      >
        <Typography.Title>{title}</Typography.Title>
        {children}
      </Space>
    </div>
    <div style={{ height: "300px" }} />
  </>
);
