import { Tabs, Typography } from "antd";
import { Outlet, useNavigate } from "react-router-dom";

import { useHasPermission } from "@root/acl";
import { useGeneratePath } from "@root/hooks/useGeneratePath";
import { routesConfig } from "@root/routes/config";

const tabs = [
  {
    key: "basket-items",
    title: "Basket Items",
    route: routesConfig.basketItems.overview,
    requiredPermission: "basketItemsSearchPage",
  },
  {
    key: "warranties",
    title: "Warranties",
    route: routesConfig.warranty.overview,
    requiredPermission: "warrantyProductsSearch",
  },
  {
    key: "car-registrations",
    title: "Car registrations",
    route: routesConfig.carRegistration.overview,
    requiredPermission: "carRegistrationProductsSearch",
  },
  {
    key: "down-payments",
    title: "Down payment",
    route: routesConfig.downPayment.overview,
    requiredPermission: "downPaymentProductsSearch",
  },
  {
    key: "vehicle-preparations",
    title: "Vehicle preparations",
    route: routesConfig.vehiclePreparation.overview,
    requiredPermission: "vehiclePreparationProductsSearch",
  },
  {
    key: "vehicle-insurances",
    title: "Vehicle insurances",
    route: routesConfig.vehicleInsurance.overview,
    requiredPermission: "vehicleInsuranceProductsSearch",
  },
  {
    key: "fees",
    title: "Fees",
    route: routesConfig.fee.overview,
    requiredPermission: "feeProductsSearch",
  },
] as const;

interface ProductCatalogTabsProps {
  activeKey: (typeof tabs)[number]["key"];
}

export const ProductCatalogTabs = ({ activeKey }: ProductCatalogTabsProps) => {
  const generatePath = useGeneratePath();
  const navigate = useNavigate();
  const hasPermission = useHasPermission();

  const handleChange = (newKey: string) => {
    const tab = tabs.find((it) => it.key === newKey);
    if (tab) {
      navigate(generatePath(tab.route, {}));
    }
  };

  return (
    <div style={{ width: "100%", paddingLeft: "15px", paddingRight: "15px" }}>
      <Typography.Title>Product Catalog</Typography.Title>
      <Tabs type="card" activeKey={activeKey} onChange={handleChange}>
        {tabs
          .filter((entry) => hasPermission(entry.requiredPermission))
          .map(({ key, title }) => (
            <Tabs.TabPane key={key} tab={title}>
              <Outlet />
            </Tabs.TabPane>
          ))}
      </Tabs>
    </div>
  );
};
