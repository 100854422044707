import { InputNumber, InputNumberProps } from "antd";

import {
  getConversionMajorByCurrencyCode,
  getCurrencySymbolByCode,
  unitsFromMajorToMinor,
  unitsFromMinorToMajor,
} from "@retail/currency";

interface PriceInputProps extends InputNumberProps {
  currencyCode: string | undefined;
  disabled?: boolean;
  qaSelector: string;
}

export const PriceInput = ({
  currencyCode,
  qaSelector,
  ...props
}: PriceInputProps) => {
  const conversionMajor = currencyCode
    ? getConversionMajorByCurrencyCode(currencyCode)
    : undefined;

  return (
    <InputNumber
      style={{ width: "100%" }}
      addonBefore={
        currencyCode ? getCurrencySymbolByCode(currencyCode) : undefined
      }
      formatter={(value) =>
        value == null
          ? ""
          : String(unitsFromMinorToMajor(Number(value), conversionMajor))
      }
      parser={(value) =>
        value == null
          ? NaN
          : unitsFromMajorToMinor(Number(value), conversionMajor)
      }
      data-qa-selector={qaSelector}
      {...props}
    />
  );
};
