import { FormItemProps } from "antd";

import { NotNil } from "@root/types";

export const addCellQaId = (qaCellName: string) => ({
  onCell: () =>
    ({
      "data-qa-selector": `cell-${qaCellName}`,
    } as any),
});

export const selectQaSelectors = (qaSelector: string) => ({
  "data-qa-selector": `select_${qaSelector}`,
  dropdownClassName: `QA_ID_dropdown_${qaSelector}`,
});

export const formValidateMessages = {
  required: "Field is required",
};

export type ValidationRule = NotNil<FormItemProps["rules"]>[number];

export const validationRuleIntegerNumber: ValidationRule = {
  validator: async (_, value: number) => {
    if (!Number.isInteger(value)) {
      throw new Error("Must be integer");
    }
  },
};
