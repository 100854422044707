import { Filter, FilterType, createFilter } from "@retail/gql-utils";

import { FilterValues } from "../types/filterValues";

interface FilterConfigEntry {
  queryField?: string;
  filterType?: FilterType;
}

const filtersConfig: Partial<Record<keyof FilterValues, FilterConfigEntry>> = {
  refId: {
    filterType: FilterType.LIKE,
  },
};

export const filterValuesToQuery = (filters: FilterValues) => {
  const gqlFilters: Filter[] = [];

  Object.entries(filters).forEach(([filterName, value]) => {
    if (value) {
      const { filterType = FilterType.EQUAL, queryField = filterName } =
        filtersConfig[filterName as keyof typeof filters] || {};

      gqlFilters.push(createFilter(queryField, filterType, value));
    }
  });

  return gqlFilters.length === 0
    ? undefined
    : gqlFilters.length === 1
    ? gqlFilters[0]
    : // @ts-ignore
      createFilter(null, FilterType.AND, gqlFilters);
};
