import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

const PARAM_RETURN_LOCATION = "returnLocation";

export const useCreateReturnLocation = () =>
  useCallback(() => {
    const { pathname, search, hash } = window.location;
    return {
      [PARAM_RETURN_LOCATION]: `${pathname}${search}${hash}`,
    };
  }, []);

export const useReturnLocationParam = () => {
  const [params] = useSearchParams();
  return params.get(PARAM_RETURN_LOCATION);
};
