import { RightCircleTwoTone } from "@ant-design/icons";
import { Button, Table, TableColumnsType } from "antd";
import { useRef, useState } from "react";
import { useQuery } from "react-query";

import { Filters, Sorts, createFilterPayload } from "@retail/gql-utils";

import { getApiClient } from "@root/apiClient";
import { formatDateTime } from "@root/utils/format/datetime";

import { ChangeView } from "./ChangeView";
import { FieldConfig } from "./fieldsConfig";
import { ChangelogEntity } from "./types";

interface ProductChangelogProps {
  productId: string;
  currencyCode: string;
  extraFieldsConfig?: Record<string, FieldConfig>;
}

const PAGE_SIZE = 5;

export const ProductChangelog = ({
  productId,
  currencyCode,
  extraFieldsConfig,
}: ProductChangelogProps) => {
  const [isExpanded, setExpanded] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const tableDivRef = useRef<HTMLDivElement>(null);

  const { data, isLoading, isFetching, refetch } = useQuery(
    ["product-changelog", { productId, pageNumber }],
    () =>
      getApiClient()
        .SearchProductChangelog({
          search: createFilterPayload({
            page: pageNumber - 1,
            pageSize: PAGE_SIZE,
            filter: Filters.equal("objectId", productId),
            sorts: [Sorts.desk("createdOn")],
          }),
        })
        .then(({ changelog }) =>
          changelog
            ? {
                ...changelog,
                entities: changelog.entities as ChangelogEntity[],
              }
            : null
        ),
    {
      keepPreviousData: true,
      enabled: isExpanded,
    }
  );

  const handleToggleClick = () => {
    if (isExpanded) {
      setExpanded(false);
    } else {
      refetch().then(() =>
        tableDivRef.current?.scrollIntoView?.({ behavior: "smooth" })
      );
      setExpanded(true);
    }
  };

  const columns: TableColumnsType<ChangelogEntity> = [
    {
      key: "createdOn",
      title: "Date",
      width: "25%",
      render: (_, { createdOn }) =>
        createdOn ? formatDateTime(createdOn) : "-",
    },
    {
      key: "user",
      title: "User",
      width: "30%",
      render: (_, { createdBy }) => createdBy?.email ?? "-",
    },
    {
      key: "changes",
      title: "Changes",
      render: (_, entity) => (
        <ChangeView
          currencyCode={currencyCode}
          entity={entity}
          extraFieldsConfig={extraFieldsConfig}
        />
      ),
    },
  ];

  return (
    <>
      <Button
        size="large"
        shape="round"
        style={{ marginBottom: "8px" }}
        loading={isLoading}
        icon={<RightCircleTwoTone rotate={isExpanded ? 90 : 0} />}
        onClick={handleToggleClick}
      >
        Changes History
      </Button>
      {isExpanded && (
        <Table
          ref={tableDivRef}
          rowKey="id"
          dataSource={data?.entities}
          loading={isFetching}
          columns={columns}
          pagination={{
            current: pageNumber,
            pageSize: PAGE_SIZE,
            total: data?.totalEntityCount ?? 0,
            showSizeChanger: false,
          }}
          onChange={(pagination) => {
            const newPage =
              pagination.current ?? pagination.defaultCurrent ?? 0;
            setPageNumber(newPage);
          }}
        />
      )}
    </>
  );
};
