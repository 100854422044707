import { ConfigProvider, notification } from "antd";
import { QueryClientProvider } from "react-query";
import { RouterProvider } from "react-router-dom";

import { ANTD_EXTERNAL_VERSION_LESS } from "@root/antdConstants";
import { createQueryClient } from "@root/apiClient";
import { router } from "@root/routes/router";
import { formValidateMessages } from "@root/utils/antd";
import { truncateWithEllipsis } from "@root/utils/string";

import { PermissionsProvider } from "./acl";

const onError = (err: unknown) => {
  const errorMessage = err instanceof Error ? err.message : String(err);

  notification.error({
    message: "Server error",
    description: truncateWithEllipsis(errorMessage, 150),
  });
};

const queryClient = createQueryClient({ onError });

export default function Root() {
  return (
    <QueryClientProvider client={queryClient}>
      <PermissionsProvider>
        <ConfigProvider
          prefixCls={ANTD_EXTERNAL_VERSION_LESS}
          form={{ validateMessages: formValidateMessages }}
        >
          <RouterProvider router={router} />
        </ConfigProvider>
      </PermissionsProvider>
    </QueryClientProvider>
  );
}
