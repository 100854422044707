const NUMBER_REGEX = /^\d+\.?\d*$/;

const moveDot = (valueAsString: string, indexDiff: number, dot = ".") => {
  const valueWithDot = valueAsString.includes(dot)
    ? valueAsString
    : `${valueAsString}${dot}0`;

  const zeroes = "0".repeat(Math.abs(indexDiff));
  const paddedValue =
    indexDiff < 0 ? `${zeroes}${valueWithDot}` : `${valueWithDot}${zeroes}`;

  const dotIdx = paddedValue.indexOf(dot);
  const asArray = [...paddedValue];
  asArray.splice(dotIdx, 1);
  asArray.splice(dotIdx + indexDiff, 0, dot);
  const resultValue = asArray.join("");

  return Number(resultValue);
};

export const converter = {
  parser(value: string | undefined) {
    if (value == null || value === "" || !NUMBER_REGEX.test(value)) {
      return NaN;
    }

    return moveDot(value, -2);
  },
  formatter(value: string | number | undefined) {
    return value != null ? String(moveDot(String(value), +2)) : "";
  },
};
