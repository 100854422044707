import { Select, Tooltip } from "antd";

import { VAT_RATES } from "@root/utils/fields/vatRate";
import { formatVatRate } from "@root/utils/format/vatRate";

import { useWatchField } from "./useWatchField";

interface VatRateInputProps {
  disabled?: boolean;
  qaSelector: string;
}

export const VatRateInput = ({
  disabled,
  qaSelector,
  ...props
}: VatRateInputProps) => {
  const country = useWatchField("country");

  const vatRates = country ? VAT_RATES[country] : [];
  return (
    <Tooltip title={!country && !disabled ? "Please select Country" : ""}>
      <Select
        disabled={!country || disabled}
        options={vatRates.map((rate) => ({
          value: rate,
          label: formatVatRate(rate),
        }))}
        data-qa-selector={qaSelector}
        dropdownClassName={`QA_ID_${qaSelector}`}
        {...props}
      />
    </Tooltip>
  );
};
