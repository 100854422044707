import { useCallback } from "react";

import { ParamsType, RouteConfig } from "@root/routes/config";

import { useLang } from "./useLang";

export const useGeneratePath = () => {
  const lang = useLang();

  return useCallback(
    <T extends ParamsType>(route: RouteConfig<T>, params: Omit<T, "lang">) =>
      // @ts-ignore
      route.generatePath({ lang, ...params }),
    [lang]
  );
};
