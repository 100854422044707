import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

export const FIRST_PAGE_NUMBER = 1;

export interface SearchValues<F> {
  filters: F;
  page: number;
}

interface ParamsMapper<T> {
  (params: URLSearchParams): T;
}

export const useSearchValues = <F>({
  paramsToFilters,
}: {
  paramsToFilters: ParamsMapper<F>;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const searchValues = useMemo(
    () => paramsToValues(searchParams, paramsToFilters),
    [searchParams, paramsToFilters]
  );

  const updateSearchValues = useCallback(
    (update: Partial<SearchValues<F>>) => {
      setSearchParams((currentParams) => {
        const newValues = {
          ...paramsToValues(currentParams, paramsToFilters),
          ...update,
        };

        const newParams = new URLSearchParams();
        const { page, filters } = newValues;

        for (let filter in filters) {
          const value = filters[filter as keyof typeof filters];
          if (value) {
            newParams.set(filter, String(value));
          }
        }

        if (page !== FIRST_PAGE_NUMBER) {
          newParams.set("page", String(page));
        }

        return newParams;
      });
    },
    [setSearchParams, paramsToFilters]
  );

  return [searchValues, updateSearchValues] as const;
};

const paramsToValues = <F>(
  params: URLSearchParams,
  paramsToFilters: ParamsMapper<F>
) => ({
  filters: paramsToFilters(params),
  page: params.get("page") ? Number(params.get("page")) : FIRST_PAGE_NUMBER,
});
