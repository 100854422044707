export enum Country {
  AT = "AT",
  BE = "BE",
  DE = "DE",
  ES = "ES",
  FR = "FR",
  IT = "IT",
  NL = "NL",
  PL = "PL",
  SE = "SE",
}
