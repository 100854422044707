import { Popconfirm, Switch } from "antd";
import React from "react";

import { BasketItemEntity } from "../types";

interface ActiveSwitchProps {
  item: BasketItemEntity;
  onUpdate: (id: string, active: boolean) => Promise<void>;
}

export const ActiveSwitch = ({ item, onUpdate }: ActiveSwitchProps) => {
  const newActive = !item.active;

  const title = `Are you sure you want to ${
    newActive ? "enable" : "disable"
  } this basket item?`;

  return (
    <Popconfirm
      title={title}
      placement="topRight"
      okText={newActive ? "Enable" : "Disable"}
      onConfirm={() => onUpdate(item.id, newActive)}
    >
      <Switch checked={item.active ?? false} />
    </Popconfirm>
  );
};
