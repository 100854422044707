import { Form } from "antd";
import { useMutation } from "react-query";

import { getApiClient } from "@root/apiClient";
import { routesConfig } from "@root/routes/config";

import { EditPageLayout } from "../common/components/EditPageLayout";
import {
  FeeProductForm,
  FeeProductFormValues,
} from "../common/components/FeeProductForm";
import { useProductCreationFormActions } from "../common/hooks/useProductCreationFormActions";
import { mapProductFormValuesForCreateSubmit } from "../common/utils/mapProductFormValuesForCreateSubmit";

export const FeeCreate = () => {
  const [formInstance] = Form.useForm<FeeProductFormValues>();

  const { mutateAsync: createProduct, isLoading: isSubmitting } = useMutation(
    getApiClient().CreateFeeProduct
  );

  const { handleSubmit, handleCancel } = useProductCreationFormActions({
    mutation: (values) =>
      createProduct({
        product: mapProductFormValuesForCreateSubmit(values),
      }),
    returnRoute: routesConfig.fee.overview,
  });

  return (
    <EditPageLayout title="Create Fee">
      <FeeProductForm
        mode="CREATE"
        isSubmitting={isSubmitting}
        formInstance={formInstance}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />
    </EditPageLayout>
  );
};
