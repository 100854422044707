import { COUNTRIES } from "@retail/i18n/constants";

export const VAT_RATES: Record<string, number[]> = {
  [COUNTRIES.AT]: [0, 0.2],
  [COUNTRIES.BE]: [0, 0.21],
  [COUNTRIES.DE]: [0, 0.19],
  [COUNTRIES.ES]: [0, 0.21],
  [COUNTRIES.FR]: [0, 0.2],
  [COUNTRIES.IT]: [0, 0.22],
  [COUNTRIES.NL]: [0, 0.21],
  [COUNTRIES.PL]: [0, 0.23],
  [COUNTRIES.SE]: [0, 0.25],
};
