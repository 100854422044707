export enum AccessDomain {
  ProductManagement = "product.management",
  BackofficeManagement = "bo.management",
}

const formatPermission = (domain: AccessDomain, permission: string) =>
  `gqls://${domain}/${permission}`;

export const PERMISSIONS_MAP = {
  basketItemsSearchPage: [
    formatPermission(AccessDomain.ProductManagement, "query/searchproducts"),
  ],
  basketItemsEditPage: [
    formatPermission(AccessDomain.ProductManagement, "mutation/updateproduct"),
  ],
  basketItemsCreatePage: [
    formatPermission(AccessDomain.ProductManagement, "mutation/createproduct"),
  ],
  warrantyProductsSearch: [
    formatPermission(
      AccessDomain.ProductManagement,
      "query/plainsearchwarrantyproducts"
    ),
  ],
  warrantyProductUpdate: [
    formatPermission(
      AccessDomain.ProductManagement,
      "mutation/updatewarrantyproduct"
    ),
  ],
  carRegistrationProductsSearch: [
    formatPermission(
      AccessDomain.ProductManagement,
      "query/plainsearchcarregistrationproducts"
    ),
  ],
  carRegistrationProductUpdate: [
    formatPermission(
      AccessDomain.ProductManagement,
      "mutation/updatecarregistrationproduct"
    ),
  ],
  downPaymentProductsSearch: [
    formatPermission(
      AccessDomain.ProductManagement,
      "query/plainsearchdownpaymentproducts"
    ),
  ],
  downPaymentProductUpdate: [
    formatPermission(
      AccessDomain.ProductManagement,
      "mutation/updatedownpaymentproduct"
    ),
  ],
  vehiclePreparationProductsSearch: [
    formatPermission(
      AccessDomain.ProductManagement,
      "query/plainsearchvehiclepreparationproducts"
    ),
  ],
  vehiclePreparationProductUpdate: [
    formatPermission(
      AccessDomain.ProductManagement,
      "mutation/updatevehiclepreparationproduct"
    ),
  ],
  vehicleInsuranceProductsSearch: [
    formatPermission(
      AccessDomain.ProductManagement,
      "query/plainsearchinsuranceproducts"
    ),
  ],
  vehicleInsuranceProductUpdate: [
    formatPermission(
      AccessDomain.ProductManagement,
      "mutation/updateinsuranceproduct"
    ),
  ],
  feeProductsSearch: [
    formatPermission(
      AccessDomain.ProductManagement,
      "query/plainsearchfeeproducts"
    ),
  ],
  feeProductUpdate: [
    formatPermission(
      AccessDomain.ProductManagement,
      "mutation/updatefeeproduct"
    ),
  ],
  feeProductCreate: [
    formatPermission(
      AccessDomain.ProductManagement,
      "mutation/createfeeproduct"
    ),
  ],
  productChangelogSearch: [
    formatPermission(
      AccessDomain.ProductManagement,
      "query/searchproductchangelogs"
    ),
  ],
};

export type Permission = keyof typeof PERMISSIONS_MAP;
