import { Tooltip } from "antd";

import { CreatableSelect } from "@root/components/CreatableSelect";

import { useTypeOptions } from "../hooks/useTypeOptions";
import { useWatchField } from "./useWatchField";

interface TypeSelectProps {
  onChange?: (value: string) => void;
}

export const TypeSelect = (props: TypeSelectProps) => {
  const country = useWatchField("country");
  const category = useWatchField("category");

  const typeOptions = useTypeOptions({ country, category });

  const isDisabled = !country || !category;

  return (
    <Tooltip title={isDisabled ? "Please select Country and Category" : ""}>
      <CreatableSelect
        options={typeOptions.list ?? []}
        isLoading={typeOptions.isLoading}
        disabled={isDisabled}
        data-qa-selector="select_type"
        dropdownClassName="QA_ID_dropdown_type"
        {...props}
      />
    </Tooltip>
  );
};
