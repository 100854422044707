import { ProductFormValues } from "../types/productFormValues";

export const mapProductFormValuesForEditSubmit = ({
  refId: _,
  price,
  pnlCostPrice,
  ...rest
}: ProductFormValues) => ({
  ...rest,
  ...price,
  pnlCostGrossPriceMinorUnits: pnlCostPrice.grossPriceMinorUnits,
  pnlCostVatKey: pnlCostPrice.vatKey,
  pnlCostVatPercentage: pnlCostPrice.vatPercentage,
});
