import { Outlet, createBrowserRouter } from "react-router-dom";

import { AclRoute } from "@root/acl";
import { ProductCatalogTabs } from "@root/routes/ProductCatalogTabs";
import { routesConfig } from "@root/routes/config";
import { CarRegistrationEdit } from "@root/routes/products/carRegistrationEdit";
import { CarRegistrations } from "@root/routes/products/carRegistrations";
import { DownPaymentEdit } from "@root/routes/products/downPaymentEdit";
import { DownPayments } from "@root/routes/products/downPayments";
import { FeeCreate } from "@root/routes/products/feeCreate";
import { FeeEdit } from "@root/routes/products/feeEdit";
import { Fees } from "@root/routes/products/fees";
import { VehicleInsuranceEdit } from "@root/routes/products/vehicleInsuranceEdit";
import { VehicleInsurances } from "@root/routes/products/vehicleInsurances";
import { VehiclePreparationEdit } from "@root/routes/products/vehiclePreparationEdit";
import { VehiclePreparations } from "@root/routes/products/vehiclePreparations";
import { WarrantiesOverview } from "@root/routes/products/warranties";
import { WarrantyEdit } from "@root/routes/products/warrantyEdit";

import { ErrorElement } from "./RouteErrorElement";
import { Create } from "./basketItems/Create";
import { Edit } from "./basketItems/Edit";
import { Overview } from "./basketItems/Overview";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Outlet />,
    errorElement: <ErrorElement />,
    children: [
      {
        path: routesConfig.basketItems.overview.path,
        element: <AclRoute permission="basketItemsSearchPage" />,
        children: [
          {
            element: <ProductCatalogTabs activeKey="basket-items" />,
            children: [{ index: true, element: <Overview /> }],
          },
        ],
      },
      {
        path: routesConfig.basketItems.create.path,
        element: <AclRoute permission="basketItemsCreatePage" />,
        children: [{ index: true, element: <Create /> }],
      },
      {
        path: routesConfig.basketItems.edit.path,
        element: <AclRoute permission="basketItemsEditPage" />,
        children: [{ index: true, element: <Edit /> }],
      },
      {
        path: routesConfig.warranty.overview.path,
        element: <AclRoute permission="warrantyProductsSearch" />,
        children: [
          {
            element: <ProductCatalogTabs activeKey="warranties" />,
            children: [{ index: true, element: <WarrantiesOverview /> }],
          },
        ],
      },
      {
        path: routesConfig.warranty.edit.path,
        element: <AclRoute permission="warrantyProductUpdate" />,
        children: [
          {
            index: true,
            element: <WarrantyEdit />,
          },
        ],
      },
      {
        path: routesConfig.carRegistration.overview.path,
        element: <AclRoute permission="carRegistrationProductsSearch" />,
        children: [
          {
            element: <ProductCatalogTabs activeKey="car-registrations" />,
            children: [{ index: true, element: <CarRegistrations /> }],
          },
        ],
      },
      {
        path: routesConfig.carRegistration.edit.path,
        element: <AclRoute permission="carRegistrationProductUpdate" />,
        children: [
          {
            index: true,
            element: <CarRegistrationEdit />,
          },
        ],
      },
      {
        path: routesConfig.downPayment.overview.path,
        element: <AclRoute permission="downPaymentProductsSearch" />,
        children: [
          {
            element: <ProductCatalogTabs activeKey="down-payments" />,
            children: [{ index: true, element: <DownPayments /> }],
          },
        ],
      },
      {
        path: routesConfig.downPayment.edit.path,
        element: <AclRoute permission="downPaymentProductUpdate" />,
        children: [
          {
            index: true,
            element: <DownPaymentEdit />,
          },
        ],
      },
      {
        path: routesConfig.vehiclePreparation.overview.path,
        element: <AclRoute permission="vehiclePreparationProductsSearch" />,
        children: [
          {
            element: <ProductCatalogTabs activeKey="vehicle-preparations" />,
            children: [{ index: true, element: <VehiclePreparations /> }],
          },
        ],
      },
      {
        path: routesConfig.vehiclePreparation.edit.path,
        element: <AclRoute permission="vehiclePreparationProductUpdate" />,
        children: [
          {
            index: true,
            element: <VehiclePreparationEdit />,
          },
        ],
      },
      {
        path: routesConfig.vehicleInsurance.overview.path,
        element: <AclRoute permission="vehicleInsuranceProductsSearch" />,
        children: [
          {
            element: <ProductCatalogTabs activeKey="vehicle-insurances" />,
            children: [{ index: true, element: <VehicleInsurances /> }],
          },
        ],
      },
      {
        path: routesConfig.vehicleInsurance.edit.path,
        element: <AclRoute permission="vehicleInsuranceProductUpdate" />,
        children: [
          {
            index: true,
            element: <VehicleInsuranceEdit />,
          },
        ],
      },
      {
        path: routesConfig.fee.overview.path,
        element: <AclRoute permission="feeProductsSearch" />,
        children: [
          {
            element: <ProductCatalogTabs activeKey="fees" />,
            children: [{ index: true, element: <Fees /> }],
          },
        ],
      },
      {
        path: routesConfig.fee.create.path,
        element: <AclRoute permission="feeProductCreate" />,
        children: [{ index: true, element: <FeeCreate /> }],
      },
      {
        path: routesConfig.fee.edit.path,
        element: <AclRoute permission="feeProductUpdate" />,
        children: [
          {
            index: true,
            element: <FeeEdit />,
          },
        ],
      },
    ],
  },
]);
