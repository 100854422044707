import { useQuery } from "react-query";

import {
  Filter,
  FilterType,
  Sorts,
  createFilter,
  createFilterPayload,
} from "@retail/gql-utils";

import { getApiClient } from "@root/apiClient";

import { FilterValues, SearchValues } from "../../types";
import { BasketItemEntity } from "../types";

const PAGE_SIZE = 10;

export const useBasketItemsData = (searchValues: SearchValues) => {
  const query = useQuery(
    ["basket-items-list", searchValues],
    () =>
      getApiClient().LoadBasketItems({
        search: searchValuesToQuery(searchValues),
      }),
    {
      select: (data) => {
        const { entities, totalPageCount, totalEntityCount } = data.search!;
        return {
          list: entities as BasketItemEntity[],
          totalPageCount,
          totalEntityCount,
        };
      },
    }
  );

  return {
    isLoading: query.isFetching,
    data: query.data,
    pageSize: PAGE_SIZE,
    refetch: query.refetch,
  };
};

interface FilterConfigEntry {
  queryField?: string;
  filterType?: FilterType;
}

const filtersConfig: Partial<Record<keyof FilterValues, FilterConfigEntry>> = {
  title: {
    filterType: FilterType.LIKE,
  },
  partner: {
    filterType: FilterType.LIKE,
  },
  sku: {
    filterType: FilterType.LIKE,
  },
  enabled: {
    queryField: "active",
  },
};

const searchValuesToQuery = ({ page, filters }: SearchValues) => {
  const gqlFilters: Filter[] = [];

  for (let _filterName in filters) {
    const filterName = _filterName as keyof typeof filters;
    const value = filters[filterName];
    if (value) {
      const { filterType = FilterType.EQUAL, queryField = filterName } =
        filtersConfig[filterName] || {};

      gqlFilters.push(createFilter(queryField, filterType, value));
    }
  }

  return createFilterPayload({
    filter:
      gqlFilters.length === 0
        ? undefined
        : gqlFilters.length === 1
        ? gqlFilters[0]
        : // @ts-ignore
          createFilter(null, FilterType.AND, gqlFilters),
    sorts: [Sorts.desk("createdOn")],
    page: page - 1,
    pageSize: PAGE_SIZE,
  });
};
