import { useQuery } from "react-query";

import { getApiClient } from "@root/apiClient";
import { Nil } from "@root/types";

type UseTypeOptionsParams = {
  country: string | Nil;
  category: string | Nil;
};

export const useTypeOptions = ({ country, category }: UseTypeOptionsParams) => {
  const query = useQuery(
    ["type-options", country, category],
    () =>
      getApiClient().LoadTypeOptions({
        country: country!,
        category: category!,
      }),
    {
      enabled: Boolean(country && category),
      select: (data) =>
        data.productTypes?.entities?.map((type) => ({
          label: type!,
          value: type!,
        })),
    }
  );

  return {
    isLoading: query.isLoading,
    list: query.data,
  };
};
