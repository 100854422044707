import { getCurrencyCodeByCountryCode } from "@retail/currency";

import { ProductFormValues } from "../types/productFormValues";
import { mapProductFormValuesForEditSubmit } from "./mapProductFormValuesForEditSubmit";

export const mapProductFormValuesForCreateSubmit = (
  values: ProductFormValues
) => ({
  ...mapProductFormValuesForEditSubmit(values),
  refId: values.refId,
  currencyCode: getCurrencyCodeByCountryCode(values.country),
});
