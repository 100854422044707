import { Button, Select, SelectProps } from "antd";
import { useState } from "react";

interface CreatableSelectProps extends Omit<SelectProps, "loading"> {
  isLoading?: boolean;
  disabled: boolean;
  options: { value: string; label: string }[];
  onChange?: (value: string) => void;
}

export const CreatableSelect = ({
  isLoading,
  disabled,
  options,
  onChange,
  ...rest
}: CreatableSelectProps) => {
  const [isOpen, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [newOption, setNewOption] = useState<string | null>(null);

  const handleCreateNewOption = () => {
    setNewOption(search);
    onChange?.(search);
    setOpen(false);
  };

  const optionsToDisplay = [
    ...options,
    ...(newOption ? [{ value: newOption, label: newOption }] : []),
  ];

  const showCreateButton =
    !isLoading &&
    search &&
    !optionsToDisplay.some(({ value }) => value === search);

  return (
    <Select
      showSearch
      filterOption
      open={isOpen}
      options={optionsToDisplay}
      loading={isLoading}
      disabled={disabled}
      notFoundContent="No matching options"
      dropdownRender={(menu) => (
        <>
          {showCreateButton && (
            <Button
              type="dashed"
              style={{ width: "calc(100% - 8px)", margin: "0 4px" }}
              onClick={handleCreateNewOption}
            >
              Create option "{search}"
            </Button>
          )}
          {menu}
        </>
      )}
      onDropdownVisibleChange={setOpen}
      onSearch={setSearch}
      onChange={onChange}
      {...rest}
    />
  );
};
