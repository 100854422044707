export const VAT_KEY_VALUES = {
  M: "M",
  S: "S",
  R: "R",
  D: "D",
} as const;

export const options = Object.entries(VAT_KEY_VALUES).map(([key, value]) => ({
  value,
  label: key,
}));
